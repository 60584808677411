import { Button, Box, Typography, InputAdornment } from '@mui/material'
import { FC, Fragment, useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { useMenuById } from '../../hooks'
import { SearchOutlined } from '@mui/icons-material'
import { CommonTextField } from '../../components'

interface MenuHeaderProps {
  locationId: string
  menuId: string
  onClickPublish: () => any
  openCatalogItems: () => any
  openCreateCategory: () => any
  onClickRearrangeSave: () => any
  openSortCategories: () => any
  reorder: boolean
  isExpandedCategories: boolean
  handleExpandCollapseAll: () => void
  searchQuery: string
  setSearchQuery: (val: any) => void
}
export const MenuHeader: FC<MenuHeaderProps> = (props: any) => {
  const {
    locationId,
    menuId,
    onClickPublish,
    openCatalogItems,
    // openCreateCategory,
    onClickRearrangeSave,
    openSortCategories,
    reorder,
    isExpandedCategories,
    handleExpandCollapseAll,
    searchQuery,
    setSearchQuery,
  } = props
  const menu = useMenuById(locationId!, menuId!, {
    refetchOnMount: 'always',
  })
  const [searchFilter, setSearchFilter] = useState<any>({
    query: searchQuery?.query,
  })
  useEffect(() => {
    setSearchFilter({ query: searchQuery?.query })
  }, [searchQuery])
  const intl = useIntl()
  const handleReorderClick = () => {
    localStorage.removeItem('expandedCategoryId')
    onClickRearrangeSave()
  }
  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchFilter({ query: event.target.value })
    setSearchQuery({ query: event.target.value })
  }
  return (
    <Fragment>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Box pb={1}>
          <Typography variant="subtitle1" sx={{ px: 1 }}>
            {menu.data?.data!.description}
          </Typography>
        </Box>
        <Box sx={{ width: '300px', pb: 1 }}>
          <CommonTextField
            id="outlined-basic"
            size="small"
            label={intl.formatMessage({ id: 'action_search' })}
            onChange={handleSearchChange}
            // autoFocus
            value={searchFilter.query || ''}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <SearchOutlined />
                </InputAdornment>
              ),
            }}
          />
        </Box>
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Box>
          {/* <Button onClick={() => openCreateCategory()} sx={{ mr: 1 }}>
            {intl.formatMessage({ id: 'action_create_category' })}
          </Button> */}
          <Button onClick={() => openSortCategories()} sx={{ mr: 1 }}>
            {intl.formatMessage({ id: 'action_sort_category' })}
          </Button>
          <Button onClick={() => handleExpandCollapseAll()}>
            {isExpandedCategories
              ? `${intl.formatMessage({
                  id: 'action_collapse_all',
                })} ${intl.formatMessage({ id: 'label_categories' })}`
              : `${intl.formatMessage({
                  id: 'action_expand_all',
                })} ${intl.formatMessage({ id: 'label_categories' })}`}
          </Button>
        </Box>
        <Box>
          {reorder ? (
            <Button color="success" onClick={handleReorderClick} sx={{ mr: 2 }}>
              {intl.formatMessage({ id: 'action_save_changes' })}
            </Button>
          ) : null}
          <Button onClick={() => openCatalogItems()} sx={{ mr: 1 }}>
            {intl.formatMessage({ id: 'action_import_items' })}
          </Button>
          <Button color="success" onClick={onClickPublish}>
            {intl.formatMessage({ id: 'action_publish' })}
          </Button>
        </Box>
      </Box>
    </Fragment>
  )
}
