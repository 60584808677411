import { FC, SyntheticEvent, useRef } from 'react'
import { BasePicker, BasePickerProps } from '../BasePicker'
import { Grid, TextField, TextFieldProps, Typography } from '@mui/material'
import usePlacesAutocomplete, {
  HookArgs,
  Suggestion,
  getDetails,
} from 'use-places-autocomplete'

export const getAddressComponentsFromPlace = (
  place: google.maps.places.PlaceResult
) => {
  const components = place.address_components || []

  const getAddressComponent = (type: string): string => {
    const component = components.find((c) => c.types.includes(type))
    return component ? component.long_name : ''
  }

  return {
    area:
      getAddressComponent('sublocality') || getAddressComponent('neighborhood'),
    country: getAddressComponent('country'),
    flatNo: getAddressComponent('subpremise'),
    city: getAddressComponent('locality'),
    postalCode: getAddressComponent('postal_code'),
    houseNo: getAddressComponent('street_number'),
    addressLine1: getAddressComponent('route'),
    addressLine2: getAddressComponent('premise'),
    state: getAddressComponent('administrative_area_level_1'),
  }
}

/**
 * Google Address Picker Props
 */
export interface GoogleAddressPickerProps
  extends Omit<
    BasePickerProps<Suggestion, false, false, false>,
    'options' | 'autoComplete' | 'renderInput'
  > {
  autoCompleteProps?: HookArgs
  textFieldProps?: TextFieldProps
  onPlaceSelected?: (value: google.maps.places.PlaceResult) => void
}

/**
 * Google Address Picker
 */
export const GoogleAddressPicker: FC<GoogleAddressPickerProps> = (props) => {
  const sessionToken = useRef<google.maps.places.AutocompleteSessionToken>(
    new google.maps.places.AutocompleteSessionToken()
  )
  const { autoCompleteProps, onPlaceSelected, textFieldProps, ...otherProps } =
    props
  const autocomplete = usePlacesAutocomplete({
    debounce: 300,
    ...autoCompleteProps,
    requestOptions: {
      language: 'en',
      types: ['street_address', 'premise'],
      sessionToken: sessionToken.current,
      ...autoCompleteProps?.requestOptions,
    },
  })

  /**
   * We intercept the selection so that we can use getGeocode to get the full address, lat and lng
   */
  const onSuggestionSelect = (
    event: SyntheticEvent,
    suggestion: any,
    details: any
  ) => {
    if (onPlaceSelected) {
      void getDetails({
        placeId: suggestion.place_id,
        sessionToken: sessionToken.current,
      }).then((details) => {
        void onPlaceSelected!(details as google.maps.places.PlaceResult)
      })
    } else {
      props.onChange && props.onChange(event, suggestion, details)
    }
  }

  return (
    <BasePicker
      autoComplete
      includeInputInList
      clearOnEscape
      filterOptions={(x) => x}
      disableClearable={false}
      options={autocomplete.suggestions.data}
      loading={autocomplete.suggestions.loading}
      loadingText="Loading..."
      noOptionsText="No Address Found"
      getOptionLabel={(option: Suggestion) => option.description}
      renderOption={(props, option: Suggestion, { selected }) => {
        return (
          <li {...props} key={option.place_id}>
            <Grid container>
              <Grid item xs={12}>
                <Typography variant="body2">
                  {option.structured_formatting.main_text}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="caption" color="textSecondary">
                  {option.structured_formatting.secondary_text}
                </Typography>
              </Grid>
            </Grid>
          </li>
        )
      }}
      renderInput={(params) => (
        <TextField
          autoComplete="off"
          label={'Select Address'}
          {...params}
          {...textFieldProps}
          inputProps={{
            ...params.inputProps,
            ...textFieldProps?.inputProps,
          }}
          onChange={(e) => autocomplete.setValue(e.target.value)}
        />
      )}
      {...otherProps}
      onChange={onSuggestionSelect}
    />
  )
}
