import { FC, useEffect, useMemo, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import {
  getLocationQueryById,
  useCatalogCategory,
  useCategory,
  useCategoryById,
  useCategoryUpdate,
  useItemById,
  useMenuById,
  useMenuItems,
  useProducts,
} from '../../hooks'
import { AddItemDialog } from './dialogs/AddItemDialog'
import { AddCategoryDialog } from './dialogs/AddCategoryDialog'
import { ChooseCatalogItemsDialog } from './dialogs/ChooseCatalogItemsDialog'

// Import the icons
import { DeleteConfirmationDialog } from './dialogs/DeleteCategoryDialog'
import { MenuHeader } from './menuHeader'
import { MenuCategories } from './menuCategory'
import {
  handleDeleteConfirmed,
  handleUndo,
  highlightedGridStyle,
  handleSnackbarClose,
  afterSnackBarClosed,
  updatePositionsByKey,
  usePaginatedQuery,
} from '../util'
import {
  ItemsWarningDialog,
  MenuSyncDialog,
  SortCategoryDialog,
  UndoSnackbar,
} from '../../components'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
} from '@mui/material'
import { useMenuRearrange } from '../../hooks/useMenuRearrange'
import { useQueryString } from '../../hooks/useQueryString'
import { PageContainer } from '../../components/Page/PageContainer'
// import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'

export interface rearrangeStateProps {
  categories?: any[]
  items?: any[]
  modifiers?: any[]
  modifierGroups?: any[]
}
/**
 * Menu Manager V2
 */
export const MenuV2: FC = () => {
  /**
   * Using the params, get the current lcoationId and menuId we are working with
   */
  const { locationId, menuId } = useParams()
  const navigate = useNavigate()
  const [itemsState, setItemsState] = useState<any[]>([])
  const [categoriesState, setCategoriesState] = useState<any[]>([])
  const menuRearrange = useMenuRearrange(locationId!, menuId!)
  const [dialogState, setDialogState] = useState({
    addCategoryOpen: false,
    deleteCategoryId: undefined,
    deleteItemId: undefined,
    editCategoryId: undefined,
    addItemToCategoryId: undefined,
    syncDialogId: undefined,
    showWarningDialog: false,
    chooseCatalogItemsOpen: false,
    confirmSaveChanges: false,
  })
  const [queryParams, setSearchQuery]: any = useQueryString(
    window.location,
    useNavigate()
  )
  const [filteredCategories, setFilteredCategories] = useState<any[]>([])
  const [filteredItems, setFilteredItems] = useState<any[]>([])
  const menu = useMenuById(locationId!, menuId!)

  useEffect(() => {
    if (!queryParams?.query) {
      setFilteredCategories(categoriesState)
      setFilteredItems(itemsState)
      return
    }
    const lowercasedQuery: any = queryParams?.query?.toLowerCase()
    const matchingCategories = categoriesState.filter((category) =>
      category.name.toLowerCase().includes(lowercasedQuery)
    )
    const matchingItems = itemsState.filter((item) =>
      item.name.toLowerCase().includes(lowercasedQuery)
    )
    const matchingCategoryIds = new Set(
      matchingItems.flatMap((item) => item.categories)
    )
    const additionalCategories = categoriesState.filter((category) =>
      matchingCategoryIds.has(category.id)
    )
    const uniqueCategories = Array.from(
      new Map(
        [...matchingCategories, ...additionalCategories].map((c) => [c.id, c])
      ).values()
    )
    const itemsFromMatchingCategories = itemsState.filter((item) =>
      item.categories.some((catId: any) =>
        matchingCategories.some((category) => category.id === catId)
      )
    )
    const uniqueItems = Array.from(
      new Map(
        [...matchingItems, ...itemsFromMatchingCategories].map((item) => [
          item.id,
          item,
        ])
      ).values()
    )
    setFilteredCategories(uniqueCategories)
    setFilteredItems(uniqueItems)
  }, [queryParams?.query, categoriesState, itemsState])

  const updateDialogState = (updates: any) =>
    setDialogState((prev) => ({ ...prev, ...updates }))

  const categories = usePaginatedQuery(
    useCategory,
    [locationId, menuId],
    (data) =>
      setCategoriesState(
        data?.pages
          .flatMap((page: any) => page.data)
          .sort((a: any, b: any) => a.position - b.position)
      ),
    {
      refetchOnMount: 'always',
    }
  )
  const items = usePaginatedQuery(
    useMenuItems,
    [locationId, menuId],
    (data) => setItemsState(data?.pages.flatMap((page: any) => page.data)),
    {
      refetchOnMount: 'always',
    }
  )
  useEffect(() => {
    if (categories?.data?.pages) {
      setCategoriesState(
        categories.data.pages
          .flatMap((page: any) => page.data)
          .sort((a: any, b: any) => a.position - b.position)
      )
    }
  }, [categories.data]) // Update only when categories.data changes

  useEffect(() => {
    if (items?.data?.pages) {
      setItemsState(items.data.pages.flatMap((page: any) => page.data))
    }
  }, [items.data])

  const [deletedIndex, setDeletedIndex] = useState(-1)
  const [deletedList, setDeletedList] = useState<any>([])
  const [deletedItem, setDeletedItem] = useState<any>()
  const [selectedProduct, setSelectedProduct] = useState<any>()
  const [productDelete, setProductDelete] = useState<any>()
  const [showUndoSnackbar, setShowUndoSnackbar] = useState(false)
  const [highlightedRow, setHighlightedRow] = useState(false)
  const [itemError, setItemError] = useState(false)
  const [catError, setCatError] = useState(false)
  const [rearrangeState, setRearrangeState] =
    useState<rearrangeStateProps | null>(null)
  const [sortCategories, setSortCategories] = useState(false)
  useEffect(() => {
    catError && void categories.refetch()
    itemError && void items.refetch()
  }, [catError, itemError])

  // fetching the location by id
  const locations = getLocationQueryById(locationId)
  const locationName = locations.data?.data?.name
  let breadcrumbs: any = [
    { title: 'Locations', path: '/app/locations' },
    { title: locationName, path: `/app/locations/${locationId}/analytics` },
    { title: 'Menus', path: `/app/locations/${locationId}/menus` },
  ]
  // fetching the catalog products and categories
  const catalogProducts = usePaginatedQuery(
    useProducts,
    [locationId],
    (data, fetchNextPage) => {
      const lastPage = data.pages.length - 1
      if (data.pages[lastPage]?.hasNextPage) {
        void fetchNextPage()
      }
    }
  )
  const catalogCategories = usePaginatedQuery(
    useCatalogCategory,
    [locationId],
    (data, fetchNextPage) => {
      const lastPage = data.pages.length - 1
      if (data.pages[lastPage]?.hasNextPage) {
        void fetchNextPage()
      }
    }
  )

  const [selectedCatId, setSelectedCatId] = useState<string | null>(null)
  const categoryUpdateMutation = useCategoryUpdate(
    locationId!,
    menuId!,
    selectedCatId!
  )

  /**
   * State for the dialogs
   */
  const [selectedCategoryData, setSelectedCategoryData] =
    useState<any>(undefined)
  const [dataAfterPublish, setDataAfterPublish] = useState<any>(undefined)
  const getCategoryDetailsById = useCategoryById(
    locationId,
    menuId,
    dialogState?.editCategoryId!
  ).data?.data
  const [editItemId, setEditItemId] = useState<string | undefined>(undefined)
  const [previousActionState, setPreviousActionState] = useState<any>(undefined)
  const [isExpandedCategories, setIsExpandedCategories] = useState(
    queryParams?.expand === 'true' || (queryParams?.expand !== 'false' && true)
  )
  const localStorageExpandedCategoryId: any =
    localStorage.getItem('expandedCategoryId')
  const [expandedCategories, setExpandedCategories] = useState<any>(
    localStorage.getItem('expandedCategoryId')
      ? { [localStorageExpandedCategoryId]: true }
      : {}
  )
  const [scrollToCategoryId, setScrollToCategoryId] = useState<string | null>(
    null
  )
  useEffect(() => {
    const expandedCategoryId = localStorage.getItem('expandedCategoryId')
    if (expandedCategoryId) {
      if (queryParams?.query) {
        setSearchQuery({ expand: true })
      }
      setExpandedCategories({ [expandedCategoryId]: true })
      setScrollToCategoryId(localStorage.getItem('expandedCategoryId') || null)
      localStorage.removeItem('expandedCategoryId')
    }
  }, [])

  useEffect(() => {
    if (isExpandedCategories! && categoriesState.length > 0) {
      setExpandedCategories(
        Object.fromEntries(
          categoriesState.map((category) => [category.id, true])
        )
      )
    }
  }, [isExpandedCategories, categoriesState])

  const handleExpandCollapseAll = () => {
    setIsExpandedCategories((prev: any) => !prev)
    setSearchQuery({ expand: !isExpandedCategories })
    setExpandedCategories({}) // Reset individual expansion when toggling all
  }

  const getItemDetailsById = useItemById(locationId!, menuId!, editItemId!)
  useEffect(() => {
    if (editItemId) void getItemDetailsById.refetch()
    if (dialogState?.editCategoryId) {
      setSelectedCategoryData({
        name: getCategoryDetailsById?.name,
        description: getCategoryDetailsById?.description,
        showOnline: getCategoryDetailsById?.showOnline,
      })
    }
  }, [editItemId, getCategoryDetailsById])

  const handleNavigationWhileRearrangeState = (data: any) => {
    updateDialogState({ confirmSaveChanges: true })
    setPreviousActionState(data)
  }
  const handleEditCatogryByIdClick = (categoryId: string) => {
    if (rearrangeState) {
      handleNavigationWhileRearrangeState({ type: 'category', id: categoryId })
    } else {
      updateDialogState({ editCategoryId: categoryId })
    }
  }
  const handleEditItemByIdClick = (itemId: string, categoryId: string) => {
    localStorage.setItem('expandedCategoryId', categoryId)
    if (rearrangeState) {
      handleNavigationWhileRearrangeState({
        type: 'item',
        id: itemId,
      })
    } else {
      setEditItemId(itemId)
      navigate(`items/${itemId}/edit`, {
        state: {
          back: `${window.location.pathname}${window.location.search}`,
        },
      })
    }
  }

  const handleOnClosePublishDialog = (data: any) => {
    setDataAfterPublish(data),
      data?.warnings?.length > 0 &&
        updateDialogState({ showWarningDialog: true })
    updateDialogState({ syncDialogId: undefined })
  }
  const onCancelItemsWarning = () => {
    updateDialogState({ showWarningDialog: false })
    setDataAfterPublish(undefined)
  }
  const handleEditCategoryDialogClose = () => {
    updateDialogState({ editCategoryId: undefined })
    setSelectedCategoryData(undefined)
  }

  const [type, setType] = useState<'category' | 'item'>('item')
  const onDeleteConfirmation = () => {
    dialogState?.deleteCategoryId !== undefined
      ? updateDialogState({ deleteCategoryId: undefined })
      : updateDialogState({ deleteItemId: undefined }) //to close the dialog box
    void handleDeleteConfirmed({
      setCategoriesState,
      categoriesState,
      setShowUndoSnackbar,
      deletedList,
      setDeletedList,
      setDeletedIndex,
      setDeletedItem,
      selectedProduct,
      itemsState,
      setItemsState,
      type,
    })
  }

  const onUndo = () => {
    handleUndo({
      setShowUndoSnackbar,
      setDeletedList,
      deletedList,
      deletedProduct: deletedItem,
      categoriesState,
      deletedIndex,
      setCategoriesState,
      setItemsState,
      itemsState,
      setHighlightedRow,
      type,
    })
  }
  useEffect(() => {
    if (highlightedRow !== false) {
      const timeoutId = setTimeout(() => {
        setHighlightedRow(false)
      }, 1500)
      return () => clearTimeout(timeoutId)
    }
  }, [highlightedRow])

  const highlightStyle = (product: any) => {
    return highlightedGridStyle(product, highlightedRow, deletedItem)
  }
  const performDeletion = (reason: any = 'external') => {
    handleSnackbarClose(reason, setShowUndoSnackbar)
    afterSnackBarClosed({
      setShowUndoSnackbar,
      deletedProduct: deletedItem,
      productDelete,
      type,
      setItemError: () => setItemError(!itemError),
      setCatError: () => setCatError(!catError),
    })
  }
  const handleRearrangeSave = () => {
    rearrangeState?.categories &&
      updatePositionsByKey(
        rearrangeState,
        categoriesState,
        setCategoriesState,
        'categories'
      )
    const changeCatState = rearrangeState?.categories?.length ? true : false
    const changeItemState = rearrangeState?.items?.length ? true : false
    void menuRearrange.mutate(rearrangeState!, {
      onSuccess: () => {
        setRearrangeState(null)
        updateDialogState({ confirmSaveChanges: false })
        changeCatState && void categories.refetch()
        changeItemState && void items.refetch()
        if (previousActionState?.type === 'category')
          updateDialogState({ editCategoryId: previousActionState?.id })
        if (previousActionState?.type === 'item') {
          setEditItemId(previousActionState?.id)
          navigate(`items/${previousActionState?.id}/edit`)
        }
      },
    })
  }
  const handleCancelConfirmClick = () => {
    updateDialogState({ confirmSaveChanges: false })
    setRearrangeState(null)
    if (previousActionState?.type === 'category')
      updateDialogState({ editCategoryId: previousActionState?.id })
    if (previousActionState?.type === 'item') {
      setEditItemId(previousActionState?.id)
      navigate(`items/${previousActionState?.id}/edit`)
    }
  }
  return (
    <PageContainer title={menu.data?.data?.name} breadcrumbs={breadcrumbs}>
      <Box
        sx={{
          position: 'sticky',
          top: 55,
          zIndex: 999,
        }}
      >
        {/* Add Category Dialog */}
        {(dialogState?.addCategoryOpen || dialogState?.editCategoryId) && (
          <AddCategoryDialog
            open
            locationId={locationId!}
            menuId={menuId!}
            onClose={() =>
              dialogState?.editCategoryId
                ? handleEditCategoryDialogClose()
                : updateDialogState({ addCategoryOpen: false })
            }
            items={items}
            selectedCategoryData={selectedCategoryData}
            setSelectedCategoryData={setSelectedCategoryData}
            selectedCatId={dialogState?.editCategoryId}
            setCategoriesState={setCategoriesState}
            categoriesState={categoriesState}
            setEditCategoryId={(id: any) =>
              updateDialogState({ editCategoryId: id })
            }
            setSelectedCatId={setSelectedCatId}
          />
        )}
        {/* Choose Items from Catalog Dialog */}
        {dialogState?.chooseCatalogItemsOpen && (
          <ChooseCatalogItemsDialog
            open
            locationId={locationId!}
            menuId={menuId!}
            categoryId={dialogState?.addItemToCategoryId!}
            onClose={() => updateDialogState({ chooseCatalogItemsOpen: false })}
            products={catalogProducts}
            categories={catalogCategories}
          />
        )}
        {/* Delete Category Dialog */}
        {(dialogState?.deleteCategoryId !== undefined ||
          dialogState?.deleteItemId !== undefined) && (
          <DeleteConfirmationDialog
            open
            locationId={locationId!}
            menuId={menuId!}
            categoryId={
              dialogState?.deleteCategoryId! || dialogState?.deleteItemId!
            }
            onClose={() =>
              dialogState?.deleteCategoryId !== undefined
                ? updateDialogState({ deleteCategoryId: undefined })
                : updateDialogState({ deleteItemId: undefined })
            }
            type={
              dialogState?.deleteCategoryId !== undefined ? 'category' : 'item'
            }
            setProductDelete={(val) => setProductDelete(val)}
            onDeleteConfirmation={() => onDeleteConfirmation()}
          />
        )}
        {/* Add Item Dialog */}
        {dialogState?.addItemToCategoryId !== undefined && (
          <AddItemDialog
            open
            locationId={locationId!}
            menuId={menuId!}
            categoryId={dialogState?.addItemToCategoryId!}
            onClose={() =>
              updateDialogState({ addItemToCategoryId: undefined })
            }
            products={catalogProducts}
            categories={catalogCategories}
          />
        )}
        {/* Header */}
        <MenuHeader
          locationId={locationId!}
          menuId={menuId!}
          onClickPublish={() => updateDialogState({ syncDialogId: menuId })}
          onClickRearrangeSave={handleRearrangeSave}
          reorder={rearrangeState ? true : false}
          openCatalogItems={() =>
            updateDialogState({ chooseCatalogItemsOpen: true })
          }
          openCreateCategory={() =>
            updateDialogState({ addCategoryOpen: true })
          }
          openSortCategories={() => setSortCategories(true)}
          isExpandedCategories={isExpandedCategories}
          handleExpandCollapseAll={handleExpandCollapseAll}
          searchQuery={queryParams}
          setSearchQuery={setSearchQuery}
        />
      </Box>
      <Box sx={{ maxHeight: 'calc(100vh - 175px)', overflowY: 'auto' }}>
        {/* Categories and Items */}
        <MenuCategories
          locationId={locationId!}
          menuId={menuId!}
          onDeleteCategoryById={(categoryId) => {
            setType('category')
            updateDialogState({ deleteCategoryId: categoryId })
          }}
          onEditCategoryById={(categoryId) =>
            handleEditCatogryByIdClick(categoryId)
          }
          onEditItemById={(itemId, categoryId) =>
            handleEditItemByIdClick(itemId, categoryId)
          }
          onItemAdd={(categoryId) =>
            updateDialogState({ addItemToCategoryId: categoryId })
          }
          setSelectedCatId={setSelectedCatId}
          categoryUpdateMutation={categoryUpdateMutation}
          selectedCatId={selectedCatId}
          onDeleteItemById={(itemId) => {
            setType('item')
            updateDialogState({ deleteItemId: itemId })
          }}
          categoriesState={filteredCategories}
          setSelectedProduct={(val) => setSelectedProduct(val)}
          itemsState={filteredItems}
          highlightStyle={highlightStyle}
          showUndoSnackbar={showUndoSnackbar}
          performDeletion={performDeletion}
          setRearrangeState={setRearrangeState}
          scrollToCategoryId={scrollToCategoryId}
          isExpandedCategories={isExpandedCategories}
          expandedCategories={expandedCategories}
          setExpandedCategories={setExpandedCategories}
        />
        {dialogState?.syncDialogId && (
          <MenuSyncDialog
            menuId={dialogState?.syncDialogId}
            onClose={handleOnClosePublishDialog}
          />
        )}
        {dialogState?.showWarningDialog && (
          <ItemsWarningDialog
            dataAfterPublish={dataAfterPublish}
            onCancelItemsWarning={onCancelItemsWarning}
          />
        )}
        {sortCategories && (
          <SortCategoryDialog
            categories={categoriesState}
            setRearrangeState={setRearrangeState}
            onCancel={() => {
              handleCancelConfirmClick()
              setSortCategories(false)
            }}
            onSave={() => {
              handleRearrangeSave()
              setSortCategories(false)
            }}
          />
        )}
        <Dialog open={dialogState?.confirmSaveChanges}>
          <DialogContent>
            <Box>
              <Typography variant="body2">
                You have unsaved changes. Do you want to save the changes before
                proceeding?
              </Typography>
            </Box>
          </DialogContent>
          <DialogActions>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                width: '100%',
              }}
            >
              <Button sx={{ m: 1 }} onClick={() => handleCancelConfirmClick()}>
                Cancel
              </Button>
              <Button
                sx={{ m: 1 }}
                color="success"
                onClick={() => handleRearrangeSave()}
              >
                Save Changes
              </Button>
            </Box>
          </DialogActions>
        </Dialog>
        <UndoSnackbar
          {...{
            showUndoSnackbar,
            setShowUndoSnackbar: (val: any) => setShowUndoSnackbar(val),
            deletedProduct: deletedItem,
            onUndo: () => onUndo(),
            performDeletion,
          }}
        />
      </Box>
    </PageContainer>
  )
}
