import { FC } from 'react'
import { BasePicker, BasePickerProps } from '../BasePicker'
import { MenuItem, TextField } from '@mui/material'
import { data, CurrencyCodeRecord, code } from 'currency-codes'

export const getDefaultCurrencyValueFromCode = (value?: string) => {
  return value ? code(value) : undefined
}

/**
 *
 */
export interface CurrencyPickerProps
  extends Omit<
    BasePickerProps<CurrencyCodeRecord, false, false, false>,
    'options' | 'renderOption' | 'renderInput'
  > {}

/**
 * Order Status Picker component
 * @returns
 */
export const CurrencyPicker: FC<CurrencyPickerProps> = (props) => {
  return (
    <BasePicker
      disableClearable
      options={data}
      getOptionLabel={(option) => option.currency}
      filterOptions={filterOptions}
      isOptionEqualToValue={(option, value) =>
        typeof value === 'string'
          ? option.code === value
          : option.code === value
      }
      renderOption={(props, option, { selected }) => (
        <MenuItem {...props} selected={selected}>
          {option.currency} ({option.code})
        </MenuItem>
      )}
      renderInput={(params) => (
        <TextField label={'Select Currency'} {...params} />
      )}
      {...props}
    />
  )
}

const filterOptions = (options: CurrencyCodeRecord[], state: any) => {
  // Search the options basedo n the name, or code
  return options.filter(
    (option) =>
      option.currency.toLowerCase().includes(state.inputValue.toLowerCase()) ||
      option.code.toLowerCase().includes(state.inputValue.toLowerCase())
  )
}
