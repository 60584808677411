import { ChangeEvent, FC, useEffect, useState } from 'react'
import { Dialog, DialogProps } from '../../../components/Dialog'
import {
  useItemCreate,
  useMenuModifierCreate,
  useMenuModifierGroupCreate,
  useMenuModifierGroups,
  useMenuModifiers,
  useModifierGroups,
  useModifiers,
} from '../../../hooks'
import {
  Button,
  DialogContent,
  Grid,
  Typography,
  DialogActions,
  CardMedia,
  CardContent,
  Card,
  InputAdornment,
  Box,
  styled,
  DialogTitle,
  Tooltip,
} from '@mui/material'
import { useIntl } from 'react-intl'
import { MenuItemCreateEntity } from '../../../types'
import { toast } from 'react-toastify'
import { LoadingButton } from '../../../components'
import {
  extractSelectionIds,
  findIdsFromMenuData,
  rebuildMenuItemData,
  selectionsMenuIdsMap,
  shortenText,
} from '../../util'
import { CommonTextField } from '../../../components'
import { SearchOutlined } from '@mui/icons-material'
// @ts-ignore
import noImageSmall from '../../../assets/no_image_small.jpeg'
import { capitalizeFirstLetter } from '../../Locations/utils'
import { extractMenuItemCreatePayload } from '../../Locations/CardSelect/Menu/utils'

/**
 * Props for the AddItemDialog component.
 */
export interface AddItemDialogProps extends DialogProps {
  locationId: string
  menuId: string
  categoryId: string
  products: any
  categories: any
}

/**
 * Dialog for editing an item in the menu.
 */
export const AddItemDialog: FC<AddItemDialogProps> = (props) => {
  const intl = useIntl()
  const {
    locationId,
    menuId,
    categoryId,
    products,
    categories,
    ...dialogProps
  } = props
  const addMenuItem = useItemCreate(locationId, menuId)
  const createMenuModifier = useMenuModifierCreate(locationId!, menuId!)
  const createMenuModifierGroup = useMenuModifierGroupCreate(
    locationId!,
    menuId!
  )
  const [productsData, setProductsData] = useState<any[] | undefined>(undefined)
  const [searchedVal, setSearchedVal] = useState<string>('')
  const [selectedProductIds, setSelectedProductIds] = useState<string[]>([])
  const handleProductSelect = (productId: string) => {
    if (selectedProductIds.includes(productId)) {
      setSelectedProductIds(selectedProductIds.filter((id) => id !== productId))
    } else {
      setSelectedProductIds([...selectedProductIds, productId])
    }
  }
  // Fetch the catalog modifiers and modifier groups data
  const catalogModifierGroups = useModifierGroups(locationId, {
    refetchOnMount: true,
    onSuccess(data: any) {
      const d = data.pages.length
      if (data.pages[d - 1].hasNextPage) {
        void catalogModifierGroups.fetchNextPage()
      }
    },
  })
  const catalogModifiers = useModifiers(locationId, {
    refetchOnMount: true,
    onSuccess(data: any) {
      const d = data.pages.length
      if (data.pages[d - 1].hasNextPage) {
        void catalogModifiers.fetchNextPage()
      }
    },
  })
  const catalogModifierGroupsData: any = catalogModifierGroups?.data?.pages
    ?.map((value: any) => value.data)
    .flat()
  const catalogModifiersData = catalogModifiers?.data?.pages
    ?.map((value) => value.data)
    .flat()

  // Fetch the menu modifier groups
  const menuModifierGroups = useMenuModifierGroups(locationId, menuId, {
    refetchOnMount: true,
    onSuccess(data: any) {
      const d = data.pages.length
      if (data.pages[d - 1].hasNextPage) {
        void menuModifierGroups.fetchNextPage()
      }
    },
  })
  const menuModifierGroupsData = menuModifierGroups?.data?.pages
    ?.map((value) => value.data)
    .flat()

  // Fetch the menu Modifers
  const menuModifiers = useMenuModifiers(locationId!, menuId!, {
    refetchOnMount: true,
    onSuccess(data: any) {
      const d = data.pages.length
      if (data.pages[d - 1].hasNextPage) {
        void menuModifiers.fetchNextPage()
      }
    },
  })
  const menuModifiersData = menuModifiers?.data?.pages
    ?.map((value) => value.data)
    .flat()

  // Combine the catalog modifier groups and modifiers
  const combinedCatalogModifiers = catalogModifierGroupsData?.map(
    (catalogModifierGroup: any) => {
      // Find modifiers for the current catalogModifierGroup
      const modifiers = catalogModifiersData?.filter((modifier) =>
        catalogModifierGroup.modifiers.some(
          (catalogModifierId: any) => catalogModifierId.id === modifier.id
        )
      )
      return {
        ...catalogModifierGroup,
        modifiers: modifiers?.map(
          ({
            id,
            name,
            price,
            description,
            showOnline,
            imageUrl,
            position,
          }) => ({
            id,
            name,
            price,
            description,
            showOnline,
            imageUrl,
            position,
          })
        ),
      }
    }
  )
  useEffect(() => {
    const data = products?.data?.pages.map((value: any) => value.data).flat()
    setProductsData(data)
  }, [products.data])
  const [uploadModifierGroups, setUploadModifierGroups] = useState<any[]>([])
  const [isLoading, setIsLoading] = useState(false)

  /**  creating item manually from catalog 
  we will check the modifier group existed in the menu modifier group DB or not
  then we create the modifiers and modifier groups if they are not existed in the menu modifier group DB 
  */
  const handleCreateModifiers = async (modifier: any) => {
    try {
      const response = await createMenuModifier.mutateAsync({
        catalogModifierId: modifier?.id,
        price: modifier.price || 0,
        showOnline: modifier.showOnline || true,
        imageUrl: modifier.imageUrl,
        name: capitalizeFirstLetter(modifier.name),
        description: modifier.description || '',
        position: modifier.position,
      })
      return response.data // Return modifier ID
    } catch (error) {
      console.error('Error creating menu modifier:', error)
      throw error // Rethrow error to handle it outside
    }
  }

  useEffect(() => {
    const selectedProducts: any[] = []

    // Call the asynchronous function
    selectedProductIds.map((id) => {
      const product = productsData?.find((product: any) => product.id === id)
      selectedProducts.push(product)
    })
    const modifierGroupsFromSelectedProducts = selectedProducts
      .map((product) => product.modifierGroups)
      .flat()
      //remove duplicates from modifierGroupsFromSelectedProducts
      .filter((value, index, self) => self.indexOf(value) === index)
    setUploadModifierGroups(modifierGroupsFromSelectedProducts)
  }, [selectedProductIds])

  const handleCreateModifierGroupForSelection = async (
    catalogModifierGroupsData: any[],
    createdModifiers: any[]
  ) => {
    let createdModifierGroupIds: any[] = [] // To store created Modifier Group IDs

    try {
      for (const catalogModifierGroup of catalogModifierGroupsData) {
        if (!catalogModifierGroup) continue // Skip if the group data is missing

        // Match the created modifiers with this group's catalog modifiers
        const modifierIdsForGroup = catalogModifierGroup.modifiers
          ?.filter((modifier: any) =>
            createdModifiers.some(
              (createdModifier: any) =>
                createdModifier.catalogModifierId === modifier.id
            )
          )
          .map(
            (modifier: any) =>
              createdModifiers.find(
                (createdModifier: any) =>
                  createdModifier.catalogModifierId === modifier.id
              )?.id // Get the created modifier ID
          )
          .filter(Boolean) // Remove undefined values

        if (modifierIdsForGroup?.length) {
          // Create the Modifier Group
          const createdModifierGroup =
            await createMenuModifierGroup.mutateAsync({
              catalogModifierGroupId: catalogModifierGroup.id,
              name: capitalizeFirstLetter(catalogModifierGroup.name),
              description: catalogModifierGroup.description || '',
              maxPermitted: catalogModifierGroup.maxPermitted ?? 0,
              minPermitted: catalogModifierGroup.minPermitted ?? 0,
              position: catalogModifierGroup.position,
              modifiers: modifierIdsForGroup, // Add modifiers to the group
            })

          // Collect the created Modifier Group ID
          if (createdModifierGroup?.data) {
            createdModifierGroupIds.push(createdModifierGroup.data)
          }
        }
      }

      return createdModifierGroupIds // Return the created Modifier Group IDs
    } catch (error) {
      console.error('Error in creating Modifier Groups for selection:', error)
      throw error // Rethrow error to handle it outside
    }
  }

  // Save the item
  const save = async () => {
    var toastMessage = ''
    setIsLoading(true)
    const itemsToAdd = await Promise.all(
      selectedProductIds.map(async (productId) => {
        const product = productsData?.find(
          (product) => product.id === productId
        )
        let modifierGroupIdsData: any[] = []
        // ModifierGroup data from the product
        const {
          matchingIds: modifierGroupData,
          nonMatchingIds: nonMatchingModifierGroupData,
        } = findIdsFromMenuData(
          product.modifierGroups,
          menuModifierGroupsData,
          'ModifierGroup'
        )
        //find the modifiers from the nonMatchingModifierGroupData
        if (nonMatchingModifierGroupData.length > 0) {
          const allModifiersFromNonMatchingModifierGroupData =
            nonMatchingModifierGroupData.map((modifierGroupId: any) => {
              const modifierGroupData = catalogModifierGroupsData?.find(
                (group: any) => group.id === modifierGroupId
              )
              return modifierGroupData
            })
          const allModifiers = allModifiersFromNonMatchingModifierGroupData
            .map((group: any) =>
              group?.modifiers?.map((modifier: any) => modifier.id)
            )
            .flat()
          const {
            matchingIds: matchingModifierIds,
            nonMatchingIds: nonMatchingModifierIds,
          } = findIdsFromMenuData(allModifiers, menuModifiersData, 'Modifier')

          let createdModifierIds = []
          if (nonMatchingModifierIds.length > 0) {
            createdModifierIds = await Promise.all(
              nonMatchingModifierIds.map((modifierId: any) => {
                const modifierData = catalogModifiersData?.find(
                  (mod: any) => mod.id === modifierId
                )
                return handleCreateModifiers(modifierData)
              })
            )
          }
          let createdModifierGroupIds: any[] = []
          // Use the helper function to create modifier groups and add modifiers to them
          createdModifierGroupIds = await handleCreateModifierGroupForSelection(
            catalogModifierGroupsData?.filter((group: any) =>
              nonMatchingModifierGroupData.includes(group.id)
            ),
            [...matchingModifierIds, ...createdModifierIds]
          )
          // Combine matching and newly created IDs
          modifierGroupIdsData = [
            ...modifierGroupData,
            ...createdModifierGroupIds,
          ]
        } else {
          modifierGroupIdsData = modifierGroupData
        }

        // Selections from the product
        // Extract modifier and modifier group IDs from the product from selections
        const { modifierGroupIds, modifierIds } = extractSelectionIds(
          product?.selections!
        )

        // Find matching and non-matching modifier and modifier group IDs
        const {
          matchingIds: matchingModifierIds,
          nonMatchingIds: nonMatchingModifierIds,
        } = findIdsFromMenuData(modifierIds, menuModifiersData, 'Modifier')
        const {
          matchingIds: matchingModifierGroupIds,
          nonMatchingIds: nonMatchingModifierGroupIds,
        } = findIdsFromMenuData(
          modifierGroupIds,
          menuModifierGroupsData,
          'ModifierGroup'
        )

        // Handle non-matching modifiers
        let createdModifierIds = []
        if (nonMatchingModifierIds.length > 0) {
          createdModifierIds = await Promise.all(
            nonMatchingModifierIds.map((modifierId: any) => {
              const modifierData = catalogModifiersData?.find(
                (mod: any) => mod.id === modifierId
              )
              return handleCreateModifiers(modifierData)
            })
          )
        }

        // Handle non-matching modifier groups
        let createdModifierGroupIds: any[] = []
        if (nonMatchingModifierGroupIds.length > 0) {
          // Use the helper function to create modifier groups and add modifiers to them
          createdModifierGroupIds = await handleCreateModifierGroupForSelection(
            catalogModifierGroupsData?.filter((group: any) =>
              nonMatchingModifierGroupIds.includes(group.id)
            ),
            [...matchingModifierIds, ...createdModifierIds]
          )
        }

        // Combine matching and newly created IDs
        const allModifierIds = [...matchingModifierIds, ...createdModifierIds]
        const allModifierGroupIds = [
          ...matchingModifierGroupIds,
          ...createdModifierGroupIds,
        ]
        // Rebuild menu item data
        const menuItemData = rebuildMenuItemData(product)

        return {
          ...menuItemData,
          categories: [categoryId],
          showOnline: menuItemData?.showOnline ?? true,
          modifierGroups: modifierGroupIdsData.map((group: any) => group.id),
          ...(product?.selections?.length
            ? {
                selections: selectionsMenuIdsMap(
                  allModifierGroupIds,
                  allModifierIds,
                  product?.selections
                ),
              }
            : {}),
        }
      })
    )
    itemsToAdd.forEach((item) => {
      addMenuItem.mutate(
        extractMenuItemCreatePayload({
          ...item,
          name: capitalizeFirstLetter(item?.name),
        }) as MenuItemCreateEntity,
        {
          onSuccess: () => {
            setIsLoading(false)
            dialogProps.onClose?.({}, 'escapeKeyDown')
          },
        }
      )
    })
    toastMessage =
      selectedProductIds.length > 1 ? 'Items Created' : 'Item Created'
    toast.success(toastMessage)
  }
  const handleChangeText = (event: ChangeEvent<HTMLInputElement>) => {
    if (event?.target?.value.trim() !== '') {
      setSearchedVal(event?.target?.value)
    } else {
      setSearchedVal('')
    }
  }

  return (
    <Dialog
      {...dialogProps}
      fullWidth
      maxWidth={'md'}
      sx={{ p: '0 !important' }}
    >
      <DialogTitle sx={{ textAlign: 'center', mb: 1 }}>
        <Typography component="h5" variant="h5" sx={{ mb: 1 }}>
          {intl.formatMessage({ id: 'label_items' })}
        </Typography>
        <CommonTextField
          autoFocus
          sx={{ paddingLeft: 0 }}
          size="small"
          placeholder="Search for items..."
          onChange={handleChangeText}
          InputProps={{
            startAdornment: (
              <InputAdornment position="end">
                <SearchOutlined />
              </InputAdornment>
            ),
          }}
        />
      </DialogTitle>
      <DialogContent>
        {/* List of products */}
        <CatalogProducts
          products={productsData || []}
          selectedProductIds={selectedProductIds}
          onItemSelect={(productId) => handleProductSelect(productId)}
          searchedVal={searchedVal}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => dialogProps.onClose?.({}, 'escapeKeyDown')}>
          {intl.formatMessage({ id: 'action_cancel' })}
        </Button>
        <LoadingButton
          color="success"
          disabled={selectedProductIds.length == 0}
          loading={isLoading}
          onClick={() => save()}
          children={`${intl.formatMessage({ id: 'action_add_items' })}
          ${
            selectedProductIds?.length > 0
              ? ` (${selectedProductIds.length})`
              : ''
          }`}
        />
      </DialogActions>
    </Dialog>
  )
}

const StyledCardContent = styled(CardContent)(() => ({
  padding: 0,
  display: 'flex',
  '&:last-child': {
    paddingBottom: 0,
  },
}))
export const CatalogProducts: FC<{
  products: any[]
  selectedProductIds: string[]
  onItemSelect: (productId: string) => void
  searchedVal: string
}> = (props) => {
  const { products, selectedProductIds, onItemSelect, searchedVal } = props

  return (
    <>
      <Grid container spacing={1} sx={{ padding: 1 }}>
        {products
          ?.filter(
            (row) =>
              !searchedVal.length ||
              (row as any)?.name
                ?.toString()
                ?.toLowerCase()
                ?.includes(searchedVal.toString().toLowerCase())
          )
          ?.map((product) => (
            <Grid item xs={12} sm={6} md={3} key={product.id}>
              <Card
                onClick={() => onItemSelect(product.id)}
                sx={{
                  cursor: 'pointer',
                  height: '70px',
                  backgroundColor: selectedProductIds.includes(product.id)
                    ? 'primary.light'
                    : 'transparent',
                  borderRadius: 2,
                  padding: 1,
                }}
              >
                <StyledCardContent
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    padding: 0,
                    alignItems: 'center',
                  }}
                >
                  <CardMedia
                    sx={{ height: 40, width: 40, borderRadius: 1 }}
                    image={product?.imageUrl ? product?.imageUrl : noImageSmall}
                    title={`${product?.name} product`}
                  />
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      paddingLeft: 1,
                    }}
                  >
                    <Tooltip
                      title={product.name.length > 12 ? product.name : ''}
                    >
                      <Typography gutterBottom variant="body1" component="div">
                        {shortenText(product.name, 12)}
                      </Typography>
                    </Tooltip>
                    {product?.price ? (
                      <Typography
                        variant="body2"
                        color="text.secondary"
                        component="div"
                      >
                        {product?.price / 100}
                      </Typography>
                    ) : (
                      <Typography
                        variant="body2"
                        color="text.secondary"
                        component="div"
                      >
                        {0}
                      </Typography>
                    )}
                  </Box>
                </StyledCardContent>
              </Card>
            </Grid>
          ))}
      </Grid>
    </>
  )
}
