// HubNode.jsx
import { FC } from 'react'
import { Handle, NodeProps, Position } from '@xyflow/react'
import logo from '../../../../assets/logo.png'

const handleStyles: React.CSSProperties = {
  background: '#333',
  visibility: 'hidden',
}
/**
 * HubNode component
 */
export const HubNode: FC<NodeProps> = () => {
  return (
    <>
      <div
        style={{
          width: 80,
          height: 30,
          background: `url(${logo})`,
          backgroundSize: 'contain',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
        }}
      >
        <Handle
          id="source-right"
          type="source"
          position={Position.Right}
          style={handleStyles}
        />
        <Handle
          id="source-left"
          type="source"
          position={Position.Left}
          style={handleStyles}
        />
        <Handle
          id="source-top"
          type="source"
          position={Position.Top}
          style={handleStyles}
        />
        <Handle
          id="source-bottom"
          type="source"
          position={Position.Bottom}
          style={handleStyles}
        />
        <Handle
          id="target-left"
          type="target"
          position={Position.Left}
          style={handleStyles}
        />
        <Handle
          id="target-right"
          type="target"
          position={Position.Right}
          style={handleStyles}
        />
        <Handle
          id="target-top"
          type="target"
          position={Position.Top}
          style={handleStyles}
        />
        <Handle
          id="target-bottom"
          type="target"
          position={Position.Bottom}
          style={handleStyles}
        />
      </div>
      {/* Only one handle at the bottom for example, or keep them hidden */}
    </>
  )
}
