import { Grid, Box, Typography } from '@mui/material'
import { memo, useEffect, useState } from 'react'
import {
  ClickableIcon,
  CommonPriceInputField,
  CommonTextField,
} from '../../../components'
import { EditOutlined } from '@mui/icons-material'
import DoneIcon from '@mui/icons-material/Done'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import { useIntl } from 'react-intl'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import DragIndicatorOutlinedIcon from '@mui/icons-material/DragIndicatorOutlined'
import MinMaxField from '../../../components/MinMaxFields'
import { handleDragEndGeneric } from '../../util'
import { ShowOnlinePicker } from '../../../components/Pickers'

export const RenderListView = memo((props: any) => {
  const {
    listOptions,
    handleNavigation,
    title,
    handleNameChange,
    handlePriceChange,
    handleShowOnlineChange,
    handleSaveIconClick,
    currency,
    itemId,
    handleMinMaxChange,
    handleChangeSequence,
    hasSequenceChanged,
    setHasSequenceChanged,
  } = props
  const intl = useIntl()
  const [sortedRecords, setSortedRecords] = useState<any[]>([])
  const isModifierGroup =
    title === intl.formatMessage({ id: 'label_modifier_groups' })
  useEffect(() => {
    if (hasSequenceChanged) {
      return
    } else {
      setSortedRecords(listOptions)
    }
  }, [listOptions])
  const handleDragEnd = (result: any) => {
    setHasSequenceChanged(true)
    const rearrangeData = handleDragEndGeneric({
      result: result, // or simply result,
      sortedList: sortedRecords, // pass sortedCategories as sortedList
      rearrangeKey: isModifierGroup ? 'modifierGroups' : 'modifiers', // string indicating the key to update in rearrange state
      // setRearrangeState: setRearrangeState,
      setSortedList: setSortedRecords,
      mergeExisting: true, // optional; for categories, usually false
    })
    handleChangeSequence(rearrangeData)
  }
  return (
    <Grid item xs={12} mt={{ xs: 2, sm: 1 }}>
      <Grid container spacing={2}>
        <Grid item xs={0.3} />
        <Grid item xs={!isModifierGroup ? 4.7 : 5}>
          <Typography variant="subtitle2">
            {title} {intl.formatMessage({ id: 'label_name' })}
          </Typography>
        </Grid>
        <Grid item xs={1.5}>
          <Typography variant="subtitle2">
            {!isModifierGroup
              ? intl.formatMessage({ id: 'label_price' })
              : null}
          </Typography>
        </Grid>
        <Grid item xs={!isModifierGroup ? 1.5 : 1.5}>
          <Typography variant="subtitle2">
            {intl.formatMessage({ id: 'label_min_required' })}
          </Typography>
        </Grid>
        <Grid item xs={!isModifierGroup ? 1.5 : 1.5}>
          <Typography variant="subtitle2">
            {intl.formatMessage({ id: 'label_max_permitted' })}
          </Typography>
        </Grid>
        {!isModifierGroup ? (
          <Grid item xs={1}>
            <Typography variant="subtitle2">
              {intl.formatMessage({ id: 'label_show_online' })}
            </Typography>
          </Grid>
        ) : null}
        <Grid item xs={!isModifierGroup ? 1.5 : 2}>
          <Typography variant="subtitle2">
            {intl.formatMessage({ id: 'label_actions' })}
          </Typography>
        </Grid>
      </Grid>
      <RenderDraggableView
        listOptions={sortedRecords}
        currency={currency}
        handleDragEnd={handleDragEnd}
        handleNameChange={handleNameChange}
        isModifierGroup={isModifierGroup}
        handlePriceChange={handlePriceChange}
        handleShowOnlineChange={handleShowOnlineChange}
        handleNavigation={handleNavigation}
        handleSaveIconClick={handleSaveIconClick}
        itemId={itemId}
        handleMinMaxChange={handleMinMaxChange}
      />
    </Grid>
  )
})
const RenderDraggableView = memo((props: any) => {
  const {
    listOptions,
    currency,
    handleDragEnd,
    handleNameChange,
    isModifierGroup,
    handlePriceChange,
    handleShowOnlineChange,
    handleNavigation,
    handleSaveIconClick,
    itemId,
    handleMinMaxChange,
  } = props
  const intl = useIntl()
  const [errorMinMax, setErrorMinMax] = useState<any[]>([])
  const [tempName, setTempName] = useState<{ [key: string]: string }>({})
  const changeMinMax = (data: any, error: boolean, id: string) => {
    handleMinMaxChange(data, id)
    setErrorMinMax((prev) => {
      if (error) {
        return prev.includes(id) ? prev : [...prev, id] // Prevent duplicate entries
      } else {
        return prev.filter((item) => item !== id)
      }
    })
  }
  // Function to handle temporary name change
  const handleTempNameChange = (id: string, value: string) => {
    setTempName((prev) => ({ ...prev, [id]: value }))
  } // Function to update final name on blur
  const handleBlur = (id: string, originalName: string) => {
    handleNameChange(tempName[id] ?? originalName, id) // Update name in the main state
    setTempName((prev) => {
      const newState = { ...prev }
      delete newState[id] // Remove temp value after saving
      return newState
    })
  }
  return (
    <DragDropContext onDragEnd={handleDragEnd}>
      <Droppable droppableId="categories" direction="vertical">
        {(provided: any) => (
          <Box
            ref={provided.innerRef}
            {...provided.droppableProps}
            sx={{ width: '100%' }}
          >
            {listOptions?.map((group: any, index: any) => (
              <Draggable key={group?.id} draggableId={group?.id} index={index}>
                {(provided: any) => (
                  <Grid
                    item
                    xs={12}
                    key={group?.id}
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    sx={{ marginBottom: '8px' }} // Adds spacing to avoid overlap
                  >
                    <Grid container spacing={2} sx={{ alignItems: 'center' }}>
                      <Grid
                        item
                        sm={0.3}
                        xs={0.3}
                        sx={{ display: 'flex', alignItems: 'center' }}
                        {...provided.dragHandleProps}
                      >
                        <DragIndicatorOutlinedIcon
                          sx={{ color: 'gray', mr: 1 }}
                        />
                      </Grid>
                      <Grid item sm={!isModifierGroup ? 4.7 : 5} xs={11.6}>
                        <CommonTextField
                          id={`input-${group?.id}`}
                          value={tempName[group?.id] ?? group?.name} // Use tempName if available
                          onChange={(e: any) =>
                            handleTempNameChange(group?.id, e.target.value)
                          }
                          onBlur={() => handleBlur(group?.id, group?.name)}
                        />
                      </Grid>
                      <Grid item sm={1.5} xs={12}>
                        {!isModifierGroup ? (
                          <CommonPriceInputField
                            currency={currency}
                            value={group?.price! || 0}
                            onChange={(data: any) =>
                              handlePriceChange(data, group?.id)
                            }
                          />
                        ) : null}
                      </Grid>
                      <MinMaxField
                        size={{ xs: 12, sm: !isModifierGroup ? 1.5 : 1.5 }}
                        minValue={group?.minPermitted || 0}
                        maxValue={group?.maxPermitted || 0}
                        onChange={(data: any, error: boolean) =>
                          changeMinMax(data, error, group?.id)
                        }
                        labelVisiblity={false}
                      />
                      {!isModifierGroup ? (
                        <Grid item sm={1} xs={12}>
                          <ShowOnlinePicker
                            value={group.showOnline}
                            onChange={(value) =>
                              handleShowOnlineChange(value, group.id)
                            }
                          />
                        </Grid>
                      ) : null}
                      <Grid item sm={!isModifierGroup ? 1.5 : 2} xs={12}>
                        <ClickableIcon
                          handleNavigate={(e: any) => {
                            e.stopPropagation() // Prevents the TableCell click from triggering
                            handleNavigation(group?.id)
                          }}
                          children={<EditOutlined fontSize="small" />}
                          tootltipTitle={intl.formatMessage({
                            id: 'action_edit',
                          })}
                        />
                        <ClickableIcon
                          handleNavigate={(e: any) => {
                            e.stopPropagation()
                            handleSaveIconClick(group?.id)
                          }}
                          iconColor="green"
                          children={
                            <DoneIcon
                              fontSize="small"
                              sx={{
                                // color: 'green',
                                transform: 'scale(1.2)', // Adjust size (default is 1)
                              }}
                            />
                          }
                          tootltipTitle={intl.formatMessage({
                            id: 'action_save',
                          })}
                          disabled={errorMinMax.includes(group?.id)}
                        />
                        {group?.isNested ? (
                          <ClickableIcon
                            handleNavigate={(e: any) => {
                              e.stopPropagation()
                            }}
                            children={
                              <InfoOutlinedIcon
                                sx={{
                                  transform: 'scale(1.2)', // Adjust size (default is 1)
                                  color: 'gray',
                                }}
                              />
                            }
                            tootltipTitle={intl.formatMessage({
                              id: 'label_nested_modifiers',
                            })}
                          />
                        ) : null}
                      </Grid>
                      <Grid item xs={12}>
                        {/* UI for Error message */}
                      </Grid>
                    </Grid>
                  </Grid>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </Box>
        )}
      </Droppable>
    </DragDropContext>
  )
})
