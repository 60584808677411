import { FC } from 'react'
import { BasePicker, BasePickerProps } from '../BasePicker'
import { MenuItem, TextField } from '@mui/material'
import { getAllCountries, getCountry, Country } from 'countries-and-timezones'

/**
 * Global instance of the immutable country data list
 */
const countryDataList = Object.values(getAllCountries())

export const getDefaultCountryValueFromCode = (
  code?: string
): Country | undefined => {
  return code ? getCountry(code) : undefined
}

/**
 *
 */
export interface CountryPickerProps
  extends Omit<
    BasePickerProps<Country, false, false, false>,
    'options' | 'renderOption' | 'renderInput'
  > {}

/**
 * Order Status Picker component
 * @returns
 */
export const CountryPicker: FC<CountryPickerProps> = (props) => {
  return (
    <BasePicker
      options={countryDataList}
      getOptionLabel={(option) => option.name}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      renderOption={(props, option, { selected }) => (
        <MenuItem {...props} selected={selected}>
          {option.name}
        </MenuItem>
      )}
      renderInput={(params) => (
        <TextField {...params} label={'Select Country'} />
      )}
      {...props}
    />
  )
}
