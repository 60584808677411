import { AppBar as MuiAppBar, Toolbar, Box, IconButton } from '@mui/material'
import { MenuTwoTone, MenuOpen } from '@mui/icons-material'
import { FC, useEffect, useState } from 'react'
import logo from '../../assets/logo.png'
import { UserDropdown } from './UserDropdown'
import { useResponsiveQuery } from '../../hooks/useResponsiveQuery'
import { useIntl } from 'react-intl'
import { useBootstrapLogo } from '../../hooks/useBootstrap'

interface AppBarProps {
  status?: boolean
  setSideBar: (value: boolean) => void
}

export const AppBar: FC<AppBarProps> = ({ status, setSideBar }) => {
  const intl = useIntl()
  const resellerLogo = useBootstrapLogo()
  const isTablet = useResponsiveQuery('md')
  const [isOpen, setOpen] = useState(false)

  useEffect(() => {
    if (!status) {
      setOpen(false)
    }
  }, [status])

  const toggleSidebar = () => {
    const newState = !isOpen
    setOpen(newState)
    setSideBar(newState)
  }

  return (
    <MuiAppBar
      color="inherit"
      sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
      elevation={0}
    >
      <Toolbar>
        {isTablet && (
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label={
              isOpen
                ? intl.formatMessage({ id: 'action_close' })
                : intl.formatMessage({ id: 'action_open' })
            }
            onClick={toggleSidebar}
            sx={{ mr: 2 }}
          >
            {isOpen ? <MenuOpen /> : <MenuTwoTone />}
          </IconButton>
        )}
        {resellerLogo && (
          <img
            src={resellerLogo}
            onError={(e) => (e.currentTarget.src = logo)}
            alt="logo"
            style={{ width: 140 }}
          />
        )}
        <Box sx={{ flexGrow: 1 }} />
        <UserDropdown />
      </Toolbar>
    </MuiAppBar>
  )
}
