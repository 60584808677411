import { IconButton, Tooltip } from '@mui/material'
interface ClickableIconProps {
  handleNavigate: (e: any) => void
  children: React.ReactNode
  disabled?: boolean
  tootltipTitle?: string | undefined
  iconColor?: string
}

export const ClickableIcon = (props: ClickableIconProps) => {
  const { handleNavigate, children, disabled, tootltipTitle, iconColor } = props
  return (
    <Tooltip title={tootltipTitle ? tootltipTitle : ''} arrow>
      <IconButton
        sx={{
          mx: 0.2,
          p: 1,
          color: disabled ? 'gray' : iconColor || 'inherit',
        }}
        size="small"
        onClick={handleNavigate}
        disabled={disabled || false}
      >
        {children}
      </IconButton>
    </Tooltip>
  )
}
