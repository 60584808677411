import { useEffect, useMemo, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import {
  getLocationQueryById,
  useItemById,
  useItemUpdate,
  useMenuById,
  useMenuModifierGroupById,
  useMenuModifierGroups,
  useMenuModifierGroupUpdate,
  useMenuModifiers,
  useMenuModifierUpdateForItemEdit,
  useModifierGroupById,
  useProductById,
} from '../../../hooks'
import { ModifierGroupEditForm } from '../../../components/ItemEditForm/ModifierGroupEditForm'
import {
  defaultMenuModifierGroupGetState,
  extractMenuModifierGroupPatchPayload,
} from '../../Locations/CardSelect/Menu/utils'
import { Box } from '@mui/material'
import { selectionsMenuIdsMap } from '../../util'
import { useMenuRearrange } from '../../../hooks/useMenuRearrange'
import { PageContainer } from '../../../components/Page/PageContainer'

export function MenuItemModifierGroupEdit() {
  const { locationId, menuId, itemId, modifierGroupId } = useParams()
  const menuRearrange = useMenuRearrange(locationId!, menuId!)
  const isMenuV2 = window.location.pathname.includes('menus')
  const urlMenu = isMenuV2 ? 'menus' : 'menu'
  const urlItem = isMenuV2 ? 'items' : 'item'
  const navigate = useNavigate()
  const [isNestedModifiers, setIsNestedModifiers] = useState(false)
  const [modifierGroupState, setModifierGroupState] = useState<any>(
    defaultMenuModifierGroupGetState
  )
  const [catalogProductId, setCatalogProductId] = useState<any>(null)
  const [catalogProductData, setCatalogProductData] = useState<any>(null)
  const catalogProduct = useProductById(locationId!, catalogProductId!)

  const updateCatalogProductData = (
    modifiers: any[],
    modifierGroups: any[],
    catalogProduct: any
  ) => {
    setCatalogProductData({
      ...catalogProduct,
      ...(catalogProduct?.selections?.length
        ? {
            selections: selectionsMenuIdsMap(
              modifierGroups,
              modifiers,
              catalogProduct?.selections
            ),
          }
        : {}),
    })
  }
  //  fetch Location data
  const locations = getLocationQueryById(locationId)
  const menuData = useMenuById(locationId!, menuId!, {
    refetchOnMount: 'always',
  })
  const itemData = useItemById(locationId!, menuId!, itemId!, {
    refetchOnMount: 'always',
    onSuccess: (data: any) => {
      if (data?.data.selections) {
        const findSelections = data?.data?.selections?.find(
          (selection: any) => selection.menuModifierGroupId === modifierGroupId
        )
        setCatalogProductId(data?.data?.catalogProductId)
        setModifierGroupState({
          ...modifierGroupState,
          ...(data?.data.selections.length
            ? { selections: data?.data.selections }
            : {}),
          ...(findSelections?.minPermitted
            ? { minPermitted: findSelections?.minPermitted }
            : {}),
          ...(findSelections?.maxPermitted
            ? { maxPermitted: findSelections?.maxPermitted }
            : {}),
        })
        // find the modifier group from selections, if the modifiers inside it have selections length then set isNestedModifiers to true
        const findModifierGroupFromSelections: any =
          data?.data.selections?.find(
            (group: any) => modifierGroupId === group.menuModifierGroupId
          ) || {}
        setIsNestedModifiers(
          findModifierGroupFromSelections?.modifiers?.some(
            (modifier: any) => modifier.selections?.length
          )
        )
      }
    },
  })
  const itemUpdateMutation = useItemUpdate(locationId!, menuId!, itemId!)
  const modifierGroupData = useMenuModifierGroupById(
    locationId!,
    menuId!,
    modifierGroupId!,
    {
      onSuccess: (data) => {
        if (data?.data) {
          const findSelections = itemData?.data?.data?.selections?.find(
            (selection: any) =>
              selection.menuModifierGroupId === modifierGroupId
          )

          // Update state whether minPermitted and maxPermitted are present or not
          setModifierGroupState({
            ...data.data,
            ...(itemData?.data?.data?.selections?.length
              ? { selections: itemData?.data?.data?.selections }
              : {}),
            minPermitted:
              findSelections?.minPermitted ?? data.data?.minPermitted, // Default to 0 if not present
            maxPermitted:
              findSelections?.maxPermitted ?? data.data?.maxPermitted, // Default to 0 if not present
          })
        }
      },
      refetchOnMount: 'always',
    }
  )
  const handleModifierGroupUpdate = (data: any) => {
    modifierGroupUpdate.mutate(extractMenuModifierGroupPatchPayload(data), {
      onSuccess: () => {
        handleNavigation()
      },
    })
  }
  const handleNestedModifierGroupUpdate = (data: any) => {
    // If `name` exists and has changed, trigger a separate mutation
    if (data?.name || data?.description) {
      modifierGroupUpdate.mutate(
        {
          ...(data.name ? { name: data.name } : {}),
          ...(data.description ? { description: data.description } : {}),
        },
        {
          onSuccess: () => handleNavigation(),
        }
      )
    }
    if (data.selections) {
      itemUpdateMutation.mutate(
        { selections: data.selections },
        {
          onSuccess: () => {
            handleNavigation()
          },
        }
      )
    }
  }

  const modifierGroupUpdate = useMenuModifierGroupUpdate(
    locationId!,
    menuId!,
    modifierGroupId!
  )
  const modifierUpdateMutation = useMenuModifierUpdateForItemEdit(
    locationId!,
    menuId!
  )

  const menuModifiers = useMenuModifiers(locationId!, menuId!, {
    refetchOnMount: 'always',
    onSuccess(data: any) {
      const d = data.pages.length
      if (data.pages[d - 1].hasNextPage) {
        void menuModifiers.fetchNextPage()
      }
      updateCatalogProductData(
        data.pages.map((page: any) => page.data).flat()!,
        menuModifierGroups?.data?.pages.map((page: any) => page.data).flat()!,
        catalogProductData
      )
    },
  })
  const menuModifiersData: any[] = useMemo(() => {
    if (!menuModifiers?.data?.pages) return []
    return menuModifiers?.data?.pages.flatMap((page: any) => page.data) || []
  }, [menuModifiers?.data?.pages])

  const menuModifierGroups = useMenuModifierGroups(locationId!, menuId!, {
    refetchOnMount: 'always',
    onSuccess(data: any) {
      const d = data.pages.length
      if (data.pages[d - 1].hasNextPage) {
        void menuModifierGroups.fetchNextPage()
      }
      updateCatalogProductData(
        menuModifiersData!,
        data.pages.map((page: any) => page.data).flat()!,
        catalogProductData
      )
    },
  })
  useEffect(() => {
    updateCatalogProductData(
      menuModifiersData!,
      menuModifierGroups?.data?.pages.map((page: any) => page.data).flat()!,
      catalogProduct?.data?.data
    )
  }, [catalogProduct?.data])
  const catalogModifierGroup = useModifierGroupById(
    locationId!,
    modifierGroupState.catalogModifierGroupId
  )
  const catalogModifierGroupData = catalogModifierGroup.data?.data
  const isDecimal = (price: any) => Number(price) % 1 !== 0
  const modifierOptions = useMemo(() => {
    if (menuModifiersData.length === 0) return []

    // Map catalogModifierGroupData.modifiers to match menuModifiersData
    const options =
      catalogModifierGroupData?.modifiers
        ?.map((modifier: any) =>
          menuModifiersData.find(
            (group: any) => group.catalogModifierId === modifier.id
          )
        )
        ?.filter(Boolean) // Remove undefined values if no match is found
        ?.map((group: any) => ({
          ...group,
          price:
            !isDecimal(group.price) && group.price !== 0
              ? group.price / 100
              : group.price, // Adjust only if necessary
        })) || []

    // Sort options based on modifierGroupState.modifiers order
    return options.sort((a: any, b: any) => {
      const indexA = modifierGroupState.modifiers.indexOf(a.id)
      const indexB = modifierGroupState.modifiers.indexOf(b.id)

      if (indexA === -1) return 1 // Place items not found at the end
      if (indexB === -1) return -1
      return indexA - indexB // Sort based on index position in modifierGroupState.modifiers
    })
  }, [
    menuModifiersData,
    catalogModifierGroupData?.modifiers,
    modifierGroupState.modifiers,
  ])
  const handleNavigation = () => {
    if (itemId && modifierGroupId) {
      const path = isMenuV2
        ? `/app/locations/${locationId}/${urlMenu}/${menuId}/${urlItem}/${itemId}/edit`
        : `/app/locations/${locationId}/menu/${menuId}/${urlItem}/${itemId}`
      navigate(path)
    } else {
      navigate(`/app/locations/${locationId}/menu/${menuId}/modifierGroups`)
    }
  }

  const handleModifierChange = (updatedModifierData: any) => {
    const selection = itemData?.data?.data?.selections?.find(
      (selection: any) => selection.menuModifierGroupId === modifierGroupId
    )

    let finalModifierData: any

    if (selection) {
      const modifier = selection?.modifiers?.find(
        (modifier: any) => modifier.menuModifierId === updatedModifierData.id
      )

      if (modifier) {
        finalModifierData = {
          ...menuModifiersData?.find(
            (data: any) => data.id === updatedModifierData.id
          ),
          ...modifier,
        }
      }
    } else {
      finalModifierData = {
        ...menuModifiersData?.find(
          (data: any) => data.id === updatedModifierData.id
        ),
      }
    }
    // Define fields to compare
    const fieldsToCompare = [
      'price',
      'minPermitted',
      'maxPermitted',
      'name',
      'showOnline',
    ]

    // Multiply the price by 100 for comparison and mutation
    const modifiedDataWithUpdatedPrice = {
      ...updatedModifierData,
      price: parseFloat((updatedModifierData.price * 100).toFixed(2)), // Multiply the price by 100
    }

    // Check if there are any changes between `updatedModifierData` and `modifierData`
    const hasChanges = fieldsToCompare.some(
      (field) =>
        modifiedDataWithUpdatedPrice[field] !== finalModifierData[field]
    )

    if (!hasChanges) {
      return // Exit the function if no changes are detected
    }

    // Check if the name has changed specifically for modifierUpdateMutation
    const isNameChanged =
      modifiedDataWithUpdatedPrice.name !== finalModifierData.name

    if (modifierGroupState?.selections?.length) {
      // Find and update the modifier from the selected modifierGroup from selections
      const updatedSelections = modifierGroupState?.selections?.map(
        (group: any) => {
          if (modifierGroupId === group.menuModifierGroupId) {
            return {
              ...group,
              modifiers: group.modifiers?.map((modifier: any) => {
                return modifier.menuModifierId === updatedModifierData.id
                  ? {
                      ...modifier,
                      // Include only the fields that have changed, excluding `name`
                      ...fieldsToCompare.reduce((acc: any, field) => {
                        if (
                          field !== 'name' &&
                          modifiedDataWithUpdatedPrice[field] !==
                            finalModifierData[field]
                        ) {
                          acc[field] = modifiedDataWithUpdatedPrice[field]
                        }
                        return acc
                      }, {}),
                    }
                  : modifier
              }),
            }
          }
          return group
        }
      )
      itemUpdateMutation.mutate(
        { selections: [...updatedSelections] },
        {
          onSuccess: (data: any) => {
            void itemData.refetch()
            setModifierGroupState({
              ...modifierGroupState,
              selections: data.data.selections,
            })
          },
        }
      )

      // Call modifierUpdateMutation only if the name has changed
      if (isNameChanged) {
        modifierUpdateMutation.mutate({
          modifierId: updatedModifierData.id,
          modifier: {
            name: modifiedDataWithUpdatedPrice.name,
          },
        })
      }
    } else {
      // Call modifierUpdateMutation for changes other than `name`
      modifierUpdateMutation.mutate({
        modifierId: updatedModifierData.id,
        modifier: {
          ...fieldsToCompare.reduce((acc: any, field) => {
            if (
              modifiedDataWithUpdatedPrice[field] !== finalModifierData[field]
            ) {
              acc[field] = modifiedDataWithUpdatedPrice[field]
            }
            return acc
          }, {}),
        },
      })
    }
  }
  // PageNavigation View constants
  const locationName = locations.data?.data?.name
  const menuName = menuData.data?.data?.name
  const itemName = itemData.data?.data?.name
  const modifierGroupName = modifierGroupData.data?.data?.name
  let breadcrumbs: any = [
    { title: 'Locations', path: '/app/locations' },
    { title: locationName, path: `/app/locations/${locationId}/analytics` },
    { title: 'Menus', path: `/app/locations/${locationId}/menus` },
    {
      title: menuName,
      path: isMenuV2
        ? `/app/locations/${locationId}/menus/${menuId}/`
        : `/app/locations/${locationId}/menu/${menuId}/items`,
    },
    {
      title: itemName,
      path: isMenuV2
        ? `/app/locations/${locationId}/${urlMenu}/${menuId}/${urlItem}/${itemId}/edit`
        : `/app/locations/${locationId}/${urlMenu}/${menuId}/${urlItem}/${itemId}`,
    },
  ]
  const handleChangeSequence = (result: any): Promise<void> => {
    setModifierGroupState({
      ...modifierGroupState,
      modifiers: result.modifiers,
    })
    return new Promise((resolve, reject) => {
      let updatedSelections = []

      if (modifierGroupState?.selections?.length) {
        updatedSelections = modifierGroupState?.selections?.map(
          (group: any) => {
            if (modifierGroupId === group.menuModifierGroupId) {
              return {
                ...group,
                modifiers: result.modifiers?.map((id: any) =>
                  group.modifiers.find(
                    (modifier: any) => modifier.menuModifierId === id
                  )
                ),
              }
            }
            return group
          }
        )
      }

      // Call modifierGroupUpdate mutation
      modifierGroupUpdate.mutate(result, {
        onSuccess: () => {
          void modifierGroupData.refetch()
          // If updatedSelections exist, update item selections
          if (updatedSelections.length) {
            itemUpdateMutation.mutate(
              { selections: updatedSelections },
              {
                onSuccess: () => {
                  // Call menuRearrange mutation
                  menuRearrange.mutate(result, {
                    onSuccess: () => {
                      void menuModifiers.refetch()
                      resolve() // Resolve only after all updates succeed
                    },
                    onError: reject, // Reject if menuRearrange fails
                  })
                },
                onError: reject, // Reject if itemUpdateMutation fails
              }
            )
          } else {
            // If no selections update, just run menuRearrange directly
            menuRearrange.mutate(result, {
              onSuccess: () => {
                void menuModifiers.refetch()
                resolve()
              },
              onError: reject,
            })
          }
        },
        onError: reject, // Reject if modifierGroupUpdate fails
      })
    })
  }

  return (
    <PageContainer title={modifierGroupName} breadcrumbs={breadcrumbs}>
      <Box sx={{ p: 3 }}>
        <ModifierGroupEditForm
          modifierGroupState={modifierGroupState}
          modifierOptions={modifierOptions}
          handleModifierGroupUpdate={
            isNestedModifiers || modifierGroupState?.selections?.length
              ? handleNestedModifierGroupUpdate
              : handleModifierGroupUpdate
          }
          handleNavigation={handleNavigation}
          handleModifierChangeMutation={handleModifierChange}
          catalogProductData={catalogProductData}
          handleChangeSequence={handleChangeSequence}
        />
      </Box>
    </PageContainer>
  )
}
