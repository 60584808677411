import { Box, Typography } from '@mui/material'
import { useIntl } from 'react-intl'
import { useNavigate } from 'react-router-dom/dist'

export const SignInFooter = () => {
  const intl = useIntl()
  const navigate = useNavigate()
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        marginTop: 4,
      }}
    >
      <Typography component={'span'}>
        <Typography component={'span'} marginRight={1} id="id_no_account_yet">
          {intl.formatMessage({ id: 'misc_no_account_yet_question' })}
        </Typography>
        <Typography
          component={'span'}
          sx={{ cursor: 'pointer' }}
          color={'primary'}
          onClick={() => navigate('/register')}
          id="id_register"
        >
          {intl.formatMessage({ id: 'action_register' })}
        </Typography>
      </Typography>
    </Box>
  )
}
