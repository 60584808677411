import {
  Box,
  Grid,
  Button,
  Typography,
  Autocomplete,
  Checkbox,
  TextField,
  FormControlLabel,
  FormHelperText,
} from '@mui/material'
import { useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import {
  useMenuCreate,
  useMenuUpdate,
  useMenuById,
  getLocationQueryById,
  useCatalogCategory,
} from '../../../../../hooks'

import {
  AppLoader,
  ServiceAvailabilityInput,
  CommonTextField,
} from '../../../../../components'
import { MenuCreateEntity, MenuPatchEntity } from '../../../../../types'
import { useIntl } from 'react-intl'
import {
  defaultMenuState,
  extractMenuCreatePayload,
  extractMenuPatchPayload,
  validateMenuEntity,
} from '../utils'
import { CheckBoxOutlineBlank, CheckBox } from '@mui/icons-material'
import { toast } from 'react-toastify'
import { capitalizeFirstLetter } from '../../../utils'
import { PageContainer } from '../../../../../components/Page/PageContainer'

const fulfillmentTypes = ['DELIVERY', 'PICKUP', 'DINE-IN']

const createDefaultMenuName = () => {
  let currentDate = new Date()
  let hours = currentDate.getHours()
  const minutes = currentDate.getMinutes().toString().padStart(2, '0')
  const ampm = hours >= 12 ? 'PM' : 'AM'

  hours = hours % 12 || 12 // Convert 0 (midnight) to 12

  // Use toLocaleDateString to format the date
  let formattedDate = currentDate.toLocaleDateString('en-US', {
    month: 'short', // "Oct"
    day: 'numeric', // "18"
    year: 'numeric', // "2024"
  })

  // Remove the comma by replacing it with an empty space
  formattedDate = formattedDate.replace(',', '')

  const time = `${hours}:${minutes} ${ampm}`

  // Create the desired string
  const menuString = `Menu ${formattedDate} ${time}`

  return menuString
}

export function MenuUpsert() {
  const intl = useIntl()
  const navigate = useNavigate()
  const { locationId, menuId } = useParams()
  const [checked, setChecked] = useState(true)
  const [enabled, setEnabled] = useState(false)
  const [inputValue, setInputValue] = useState<any>(
    menuId ? '' : createDefaultMenuName()
  )
  const [menuState, setMenuState] = useState<
    MenuCreateEntity | MenuPatchEntity
  >({ ...defaultMenuState, name: createDefaultMenuName() })

  const locationDetails = getLocationQueryById(locationId!, {
    refetchOnMount: 'always',
    // onSuccess: (data) => {
    //   data?.data &&
    //     !menuId &&
    //     setMenuState((state) => ({
    //       ...state,
    //       serviceAvailability: data.data?.businessHours,
    //     }))
    // },
  })

  const categories = useCatalogCategory(locationId!)
  const catelogDetails = categories.data?.pages
    ?.map((value: any) => value.data)
    .flat()

  const menuCreationMutation = useMenuCreate(locationId!, checked!)
  const menuUpdateMutation = useMenuUpdate(locationId!, menuId!, checked!)

  const isValid = validateMenuEntity(menuState)

  // Refactor the initialization of menuState and handling of availability
  useEffect(() => {
    if (menuId) {
      // If editing, ensure that the existing menu availability is used first
      if (!menuState.availability && menuState.serviceAvailability?.length) {
        setMenuState((prevState) => ({
          ...prevState,
          availability: {
            delivery: prevState.serviceAvailability ?? [],
            collection: prevState.serviceAvailability ?? [],
          },
        }))
      }
    } else {
      // If creating, populate with location availability or fallback to businessHours
      if (!menuState.availability && locationDetails.data?.data?.availability) {
        setMenuState((prevState: any) => ({
          ...prevState,
          availability: locationDetails.data.data?.availability,
        }))
      } else if (
        !menuState.availability &&
        locationDetails.data?.data?.businessHours
      ) {
        setMenuState((prevState) => ({
          ...prevState,
          availability: {
            delivery: locationDetails.data.data?.businessHours ?? [],
            collection: locationDetails.data.data?.businessHours ?? [],
          },
        }))
      }
    }
  }, [menuId, locationDetails.data?.data, menuState])

  // fetching the location by id
  const locationName = locationDetails.data?.data?.name
  let breadcrumbs: any = [
    { title: 'Locations', path: '/app/locations' },
    { title: locationName, path: `/app/locations/${locationId}/analytics` },
    { title: 'Menus', path: `/app/locations/${locationId}/menus` },
  ]

  // Fetch next page for menu items

  const isCatelog = catelogDetails && catelogDetails?.length > 0

  const handleChange = (event: any) => {
    setChecked(event.target.checked)
  }

  const menuDetails = useMenuById(locationId, menuId, {
    refetchOnMount: 'always',
    onSuccess: (data) => {
      if (data?.data) {
        setMenuState(data.data)
        setInputValue(data.data.name)
      }
    },
  })

  const isLoading =
    menuDetails.isLoading ||
    menuCreationMutation.isLoading ||
    menuUpdateMutation.isLoading

  const handleMenuChange = async () => {
    if (menuId) {
      menuUpdateMutation.mutate(
        extractMenuPatchPayload({
          ...menuState,
          name: capitalizeFirstLetter(menuState.name!),
        }),
        {
          onSuccess: () => {
            toast.success('Menu Updated')
            navigate(`/app/locations/${locationId}/menus`)
          },
        }
      )
    } else {
      menuCreationMutation.mutate(
        extractMenuCreatePayload({
          ...menuState,
          name: capitalizeFirstLetter(menuState.name!),
        }),
        {
          onSuccess: async (data) => {
            toast.success('Menu Created')
            navigate(`/app/locations/${locationId}/menus/${data.data?.id}/`)
          },
        }
      )
    }
  }

  const handleDescriptionChange = (event: any) => {
    setMenuState((state) => ({ ...state, description: event.target.value }))
  }
  const handleNameChange = (event: any) => {
    const value = event.target.value
    setInputValue(value) // Update local state immediately
    setMenuState((state) => ({ ...state, name: value })) // Sync with main state
  }

  const handleFullFillmentType = (event: any, value: any) => {
    setMenuState((state) => ({
      ...state,
      fulfillmentTypes: value,
    }))
  }

  // Handle only collection availability changes
  const onAvailabilityCollectionChange = (availability: any) => {
    setMenuState((state: any) => ({
      ...state,
      availability: {
        ...state.availability,
        collection: availability,
        ...(enabled && {
          delivery: availability,
        }), // Sync only if enabled
      },
    }))
  }

  // Handle only delivery availability changes
  const onAvailabilityDeliveryChange = (availability: any) => {
    setMenuState((state: any) => ({
      ...state,
      availability: {
        ...state.availability,
        delivery: availability,
      },
    }))
  }

  // Toggle the sync between collection and delivery
  const handleLocationEnabled = (event: any) => {
    const isChecked = event.target.checked
    setEnabled(isChecked) // Update the local enabled state

    // Sync collection and delivery availabilities if checked
    if (isChecked) {
      setMenuState((state: any) => ({
        ...state,
        availability: {
          ...state.availability,
          delivery: state.availability.collection, // Sync delivery with collection
        },
      }))
    }
  }

  // Handle the syncing of collection and delivery service availabilities
  const onAvailabilityCollectionAndDeliveryChange = (availability: any) => {
    setMenuState((state: any) => ({
      ...state,
      availability: {
        collection: availability,
        delivery: availability, // Sync with collection
      },
    }))
  }

  if (isLoading) {
    return <AppLoader />
  }

  return (
    <PageContainer title={'Edit Menu'} breadcrumbs={breadcrumbs}>
      <Box sx={{ p: 3 }}>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12} sm={6} md={9}>
            <Box marginTop={1}>
              <Typography variant="h6" component="h1">
                {!menuId
                  ? `${intl.formatMessage({ id: 'action_create' })}`
                  : `${intl.formatMessage({ id: 'action_update' })}`}{' '}
                {intl.formatMessage({ id: 'label_menu' })}
              </Typography>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            md={3}
            justifyContent="flex-end"
            display="flex"
          >
            <Button
              onClick={() => navigate(`/app/locations/${locationId}/menus`)}
            >
              {intl.formatMessage({ id: 'action_cancel' })}
            </Button>
            <Button
              onClick={handleMenuChange}
              disabled={!isValid}
              color="success"
              sx={{ ml: 2 }}
            >
              {menuId
                ? `${intl.formatMessage({ id: 'action_update' })}`
                : `${intl.formatMessage({ id: 'action_create' })}`}
            </Button>
          </Grid>
        </Grid>
        <Grid container spacing={2} sx={{ marginTop: 1 }}>
          <Grid item xs={12} sm={5}>
            <CommonTextField
              id="outlined-basic"
              label={`${intl.formatMessage({
                id: 'label_menu_name',
              })}${' *'}`}
              onChange={handleNameChange}
              value={inputValue} // Use local state
              autoFocus
            />
          </Grid>

          <Grid item xs={12} sm={7}>
            <Autocomplete
              multiple
              limitTags={3}
              id="checkboxes-tags-demos"
              options={fulfillmentTypes ? fulfillmentTypes : []}
              onChange={handleFullFillmentType}
              value={
                menuState.fulfillmentTypes ? menuState.fulfillmentTypes : []
              }
              disableCloseOnSelect
              renderOption={(props, optionScope, { selected }) => (
                <li {...props}>
                  <Checkbox
                    icon={<CheckBoxOutlineBlank fontSize="small" />}
                    checkedIcon={<CheckBox fontSize="small" />}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {optionScope}
                </li>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  value={menuState.fulfillmentTypes || ''}
                  label={`${intl.formatMessage({
                    id: 'label_fulfilment_type',
                  })} *`}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} mt={{ xs: 2, sm: 1 }}>
            <CommonTextField
              id="outlined-multiline-static"
              onChange={handleDescriptionChange}
              value={menuState?.description || ''}
              multiline
              rows={2}
              label={`${intl.formatMessage({ id: 'label_description' })}`}
            />
          </Grid>
          {!menuId && (
            <Grid item xs={12} mt={{ xs: 2, sm: 1 }}>
              <FormControlLabel
                onChange={handleChange}
                control={<Checkbox checked={checked} disabled={!isCatelog} />}
                label="Add default menu items"
              />
              <FormHelperText>
                By enabling this checkbox, we will create a default menu from
                you catalog.
                <br /> (Note: This will be enabled only if you have the menu
                available in your catalog)
              </FormHelperText>
            </Grid>
          )}
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={enabled} // Use local `enabled` state
                  onChange={handleLocationEnabled} // Toggle local state
                />
              }
              label="Use the same service availability for both collection and delivery."
            />
          </Grid>
          {!enabled ? (
            <>
              <Grid item xs={12} sm={6}>
                <ServiceAvailabilityInput
                  availabilities={menuState.availability?.collection}
                  onChange={onAvailabilityCollectionChange}
                  isMandatory={true}
                  title={'Collection Hours'}
                  type={'location'}
                  setLocationState={setMenuState}
                  fullWidth={true}
                  enableDefault={false}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <ServiceAvailabilityInput
                  availabilities={menuState.availability?.delivery}
                  onChange={onAvailabilityDeliveryChange}
                  isMandatory={true}
                  title={'Delivery Hours'}
                  type={'location'}
                  setLocationState={setMenuState}
                  fullWidth={true}
                  enableDefault={false}
                />
              </Grid>
            </>
          ) : (
            <Grid item xs={12} sm={6}>
              <ServiceAvailabilityInput
                availabilities={menuState?.availability?.collection}
                onChange={onAvailabilityCollectionAndDeliveryChange}
                isMandatory={true}
                title={'Delivery & Collection Hours'}
                type={'location'}
                setLocationState={setMenuState}
                fullWidth={true}
                enableDefault={false}
              />
            </Grid>
          )}
        </Grid>
      </Box>
    </PageContainer>
  )
}
