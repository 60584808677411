import { FC, Fragment, useState } from 'react'
import { Autocomplete, Button, Checkbox, Grid, TextField } from '@mui/material'
import { CheckBoxOutlineBlank, CheckBox } from '@mui/icons-material'
import { useIntl } from 'react-intl'
export interface AuditFilterProps {
  onApply: (data: any) => void
  onReset: () => void
  queryParams: any
  clientId: string
  aggs: any
}
interface Client {
  key: string
  doc_count: number
  name: string
}

const iconCheckBox = <CheckBoxOutlineBlank fontSize="small" />
const iconCheckBoxChecked = <CheckBox fontSize="small" />

export const DeveloperAuditFilter: FC<AuditFilterProps> = (props) => {
  const intl = useIntl()
  // for the connected application as client

  //client name should dynamic

  const aggs = props?.aggs

  let locationList: { [id: string]: string } = {}
  aggs?.[0]?.availableLocationId?.buckets?.map((obj: Client) => {
    if (obj.name) {
      locationList[obj.key] = obj.name
    }
  })

  const extractKeys = (aggs: any, key: any) =>
    (aggs && aggs[0]?.[key]?.buckets?.map((item: any) => String(item.key))) ||
    []

  const aggregators = {
    availableMethods: extractKeys(aggs, 'availableMethods'),
    availableResponseCode: extractKeys(aggs, 'availableResponseCode'),
    availableresourcePath: extractKeys(aggs, 'availableresourcePath'),
    availableClientId: extractKeys(aggs, 'availableClientId'),
  }
  /**
   * We create local state of the supported filters so we can power the UI,
   * once the user clicks apply we will inform the parent component of the new filters,
   * which will be persisted in the URL
   */
  const [filters, setFilters] = useState<any>({
    query: props.queryParams.query,
    requestMethod: props.queryParams.requestMethod,
    resourcePath: props.queryParams.resourcePath,
    responseCode: props.queryParams.responseCode,
    locationId: props.queryParams.locationId,
  })

  const handleChangeMethod = (event: any, value: any) => {
    const requestMethod = value.length > 0 ? value.join(',') : undefined
    setFilters({ ...filters, requestMethod })
  }

  const handleChangeResponse = (event: any, value: any[]) => {
    const responseCode = value.length > 0 ? value.join(',') : undefined
    setFilters({ ...filters, responseCode })
  }
  const handleChangeLocation = (event: any, value: any[]) => {
    const locationId = value.length > 0 ? value.join(',') : undefined
    setFilters({ ...filters, locationId })
  }
  const handleChangeResourcePath = (event: any, value: any[]) => {
    const resourcePath = value.length > 0 ? value.join(',') : undefined
    setFilters({ ...filters, resourcePath })
  }

  return (
    <Grid container spacing={1}>
      {/* filter component Row */}

      <Fragment>
        <Grid item xs={12} md={2.2} lg={2.2}>
          <Autocomplete
            color="info"
            size="small"
            multiple
            limitTags={1}
            id="application-checkboxes-tags-demo"
            value={filters.locationId?.split(',') || []}
            options={Object.keys(locationList)}
            getOptionLabel={(option) => locationList[option]}
            onChange={handleChangeLocation}
            disableCloseOnSelect
            renderOption={(props, option, { selected }) => (
              <li {...props} style={{ padding: 0, textTransform: 'uppercase' }}>
                <Checkbox
                  color="info"
                  size="small"
                  style={{ marginRight: 8 }}
                  checked={selected}
                />
                {locationList[option]}
              </li>
            )}
            renderInput={(params) => (
              <TextField {...params} label={'Locations'} />
            )}
          />
        </Grid>

        <Grid item xs={12} md={2.2} lg={2.2}>
          <Autocomplete
            color="info"
            multiple
            size="small"
            limitTags={1}
            id="method-checkboxes-tags-demo"
            options={aggregators?.availableMethods}
            onChange={handleChangeMethod}
            value={filters.requestMethod?.split(',') || []}
            disableCloseOnSelect
            renderOption={(props, option, { selected }) => (
              <li {...props} style={{ padding: 0 }}>
                <Checkbox
                  size="small"
                  icon={iconCheckBox}
                  checkedIcon={iconCheckBoxChecked}
                  style={{ marginRight: 8 }}
                  checked={selected}
                />
                {option}
              </li>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                label={intl.formatMessage({ id: 'label_request_methods' })}
              />
            )}
          />
        </Grid>

        <Grid item xs={12} md={4} lg={4}>
          <Autocomplete
            color="info"
            size="small"
            multiple
            limitTags={1}
            id="endpoint-checkboxes-tags-demo"
            value={filters.resourcePath?.split(',') || []}
            options={aggregators?.availableresourcePath}
            onChange={handleChangeResourcePath}
            disableCloseOnSelect
            renderOption={(props, option, { selected }) => (
              <li
                {...props}
                style={{
                  padding: 0,
                  textTransform: 'uppercase',
                  fontSize: '12px',
                  whiteSpace: 'normal',
                  wordBreak: 'break-all',
                  maxWidth: '100%',
                }}
              >
                <Checkbox
                  color="info"
                  size="small"
                  icon={iconCheckBox}
                  checkedIcon={iconCheckBoxChecked}
                  style={{ marginRight: 5 }}
                  checked={selected}
                />
                <span style={{ textOverflow: 'ellipsis', overflow: 'hidden' }}>
                  {option}
                </span>
              </li>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                label={intl.formatMessage({ id: 'label_endpoints' })}
              />
            )}
          />
        </Grid>

        <Grid item xs={12} md={2} lg={2}>
          <Autocomplete
            color="info"
            size="small"
            multiple
            limitTags={1}
            id="response-checkboxes-tags-demo"
            value={filters.responseCode?.split(',') || []}
            options={aggregators?.availableResponseCode}
            onChange={handleChangeResponse}
            disableCloseOnSelect
            renderOption={(props, option, { selected }) => (
              <li {...props} style={{ padding: 0, textTransform: 'uppercase' }}>
                <Checkbox
                  color="info"
                  size="small"
                  icon={iconCheckBox}
                  checkedIcon={iconCheckBoxChecked}
                  style={{ marginRight: 8 }}
                  checked={selected}
                />
                {option}
              </li>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                label={intl.formatMessage({ id: 'label_response_codes' })}
              />
            )}
          />
        </Grid>

        <Grid
          item
          xs={12}
          lg={0.8}
          sx={{ display: 'flex', justifyContent: 'flex-end' }}
        >
          <Button
            color="success"
            onClick={() => {
              props.onApply(filters)
            }}
            sx={{ width: 100, height: 40 }}
          >
            {intl.formatMessage({ id: 'action_apply' })}
          </Button>
        </Grid>
        <Grid
          item
          xs={12}
          lg={0.7}
          sx={{ display: 'flex', justifyContent: 'center' }}
        >
          <Button
            size="medium"
            onClick={() => {
              setFilters({})
              props.onApply({
                query: undefined,
                requestMethod: undefined,
                resourcePath: undefined,
                responseCode: undefined,
                locationId: undefined,
              })
            }}
          >
            {intl.formatMessage({ id: 'action_reset' })}
          </Button>
        </Grid>
      </Fragment>
    </Grid>
  )
}
