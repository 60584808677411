import { FC } from 'react'
import { ApplicationEntity } from '../../../types'
import { BasePicker, BasePickerProps } from '../BasePicker'
import { Box, MenuItem, TextField, Typography } from '@mui/material'
import {
  Assignment,
  DeliveryDining,
  Fastfood,
  PointOfSale,
} from '@mui/icons-material'
import { useIntl } from 'react-intl'

/**
 * Static list of supported order statuses
 */
const supportedCategories: ApplicationEntity['category'][] = [
  'MARKETPLACE',
  'DELIVERY',
  'OTHER',
  'POS',
]

/**
 * categoryIconMap
 */
const categoryIconMap: Record<ApplicationEntity['category'], JSX.Element> = {
  MARKETPLACE: <Fastfood />,
  DELIVERY: <DeliveryDining />,
  OTHER: <Assignment />,
  POS: <PointOfSale />,
}

/**
 * categoryNameMap
 */
const categoryNameMap: Record<ApplicationEntity['category'], string> = {
  MARKETPLACE: 'Marketplace',
  DELIVERY: 'Delivery',
  OTHER: 'Other',
  POS: 'Point of Sale',
}

/**
 * Description for each of the application types
 */
export const categoryDescriptionMap: Record<
  ApplicationEntity['category'],
  string
> = {
  MARKETPLACE:
    'Application that consumes a menu and produces orders based on the menu',
  DELIVERY: 'Applications that can supply delivery services',
  OTHER: 'Other',
  POS: 'Application that can supply a catalog and receive/processes orders',
}

/**
 *
 */
export interface ApplicationCategoryPickerProps
  extends Omit<
    BasePickerProps<ApplicationEntity['category'], false, false, false>,
    'options' | 'renderOption' | 'renderInput'
  > {}

/**
 * Order Status Picker component
 * @returns
 */
export const ApplicationCategoryPicker: FC<ApplicationCategoryPickerProps> = (
  props
) => {
  const intl = useIntl()
  return (
    <BasePicker
      options={supportedCategories}
      disableClearable
      getOptionLabel={(option) =>
        categoryNameMap[option as ApplicationEntity['category']]
      }
      renderOption={(props, option, { selected }) => (
        <MenuItem {...props} selected={selected}>
          {/* Subtext */}
          <Typography variant="caption"></Typography>
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <Typography variant="body1">
              {categoryNameMap[option as ApplicationEntity['category']]}
            </Typography>
            <Typography variant="caption" color="textSecondary">
              {categoryDescriptionMap[option as ApplicationEntity['category']]}
            </Typography>
          </Box>
        </MenuItem>
      )}
      renderInput={(params) => (
        <TextField {...params} label={'Application Cateogry'} />
      )}
      {...props}
    />
  )
}
