import {
  Avatar,
  Box,
  Container,
  Grid,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material'
import { WorldSvg } from '../components/WorldSvg'
import { FC, useEffect, useRef, useState } from 'react'

export const CoverageSection: FC<{ applications: any[] }> = (props) => {
  const theme = useTheme()
  const svgRef = useRef<SVGSVGElement>(null)
  const [activeCountry, setActiveCountry] = useState<string | null>(null)

  /**
   * Handle when the SVG Ref is loaded
   */
  useEffect(() => {
    if (!svgRef.current) return
  }, [svgRef])

  /**
   * Handle mouse over event
   */
  const onMouseOver: React.MouseEventHandler<SVGSVGElement> = (event) => {
    if (event.target instanceof SVGPathElement) {
      setActiveCountry(event.target.id)
    }
  }

  const onMouseOut: React.MouseEventHandler<SVGSVGElement> = (event) => {
    if (event.target instanceof SVGPathElement) {
      setActiveCountry(event.target.id === activeCountry ? null : activeCountry)
    }
  }

  return (
    <Container>
      <Grid container>
        <Grid item xs={12}>
          <Box sx={{ width: { sm: '100%', md: '100%', textAlign: 'center' } }}>
            <Typography
              component="h2"
              variant="h4"
              gutterBottom
              sx={{ color: 'text.primary' }}
            >
              Global Coverage
            </Typography>
            <Typography
              variant="body1"
              sx={{ color: 'text.secondary', mb: { xs: 2, sm: 4 } }}
            >
              A map of the operational coverage of our applications, we are
              aiming to have global coverage in the near future.
            </Typography>
          </Box>
        </Grid>

        {props.applications.map((app) => (
          <Grid item xs={1} key={app.name} pb={2}>
            <Tooltip title={app.name} placement="top">
              <Avatar
                variant="rounded"
                alt={app.name}
                sx={{
                  background: theme.palette.common.white,
                  width: 50,
                  height: 50,
                  opacity: !activeCountry
                    ? 1
                    : app.availableCountries?.includes(activeCountry) === true
                    ? 1
                    : 0.4,
                }}
                src={app.imageUrl}
              />
            </Tooltip>
          </Grid>
        ))}

        <Grid item xs={12} mt={4}>
          <WorldSvg
            ref={svgRef}
            style={{ width: '100%', height: 'auto' }}
            onMouseOverCapture={onMouseOver}
            onMouseOutCapture={onMouseOut}
          />
        </Grid>
      </Grid>
    </Container>
  )
}
