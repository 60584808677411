import { getAllTimezones, Timezone, getTimezone } from 'countries-and-timezones'
import { FC } from 'react'
import { BasePicker, BasePickerProps } from '../BasePicker'
import { MenuItem, TextField } from '@mui/material'

/**
 * Global instance of the immutable country data list
 */
const timezoneOptions = Object.values(getAllTimezones())

export const getDefaultTimezoneValueFromCode = (
  code?: string
): Timezone | undefined => {
  return code ? getTimezone(code) : undefined
}

/**
 *
 */
export interface TimezonePickerProps
  extends Omit<
    BasePickerProps<Timezone, false, false, false>,
    'options' | 'renderOption' | 'renderInput'
  > {}

/**
 * Order Status Picker component
 * @returns
 */
export const TimezonePicker: FC<TimezonePickerProps> = (props) => {
  return (
    <BasePicker
      disableClearable
      options={timezoneOptions}
      getOptionLabel={(option) => option.name}
      isOptionEqualToValue={(option, value) => option.name === value.name}
      renderOption={(props, option, { selected }) => (
        <MenuItem {...props} selected={selected}>
          {option.name} ({option.utcOffsetStr})
        </MenuItem>
      )}
      renderInput={(params) => (
        <TextField label={'Select Timezone'} {...params} />
      )}
      {...props}
    />
  )
}
