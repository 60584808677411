import { Box, InputAdornment, IconButton, Typography } from '@mui/material'
import { useIntl } from 'react-intl'
import { useLocation, useNavigate } from 'react-router'
import { Visibility, VisibilityOff, EmailOutlined } from '@mui/icons-material'
import { CommonTextField, LoadingButton } from '../../components'
import { ChangeEvent, FC, useState } from 'react'
import { UseMutationResult } from 'react-query'
import { LoginModel } from '../../model/LoginModel'

export interface SignInPasswordProps {
  loginMutation: UseMutationResult<any, any, any>
}

export const SignInPassword: FC<SignInPasswordProps> = (props) => {
  const { loginMutation } = props
  const intl = useIntl()
  const navigate = useNavigate()
  const location = useLocation()
  const isEmailState = location?.state?.email
  const [myInstance] = useState(
    new LoginModel({
      userId: location.state?.from === 'register' ? isEmailState : undefined,
    })
  )
  const [isValid, setValid] = useState(false)
  const [isEmailValid, setEmailValid] = useState(true)
  const [isPasswordValid, setPasswordValid] = useState(true)
  const [showPassword, setShowPassword] = useState(false)

  const handleEmailChange = (event: ChangeEvent<HTMLInputElement>) => {
    myInstance.username = event.target.value
    setValid(validate(myInstance))
  }

  const handlePasswordChange = (event: ChangeEvent<HTMLInputElement>) => {
    myInstance.password = event.target.value
    setValid(validate(myInstance))
  }

  const validateEmail = (email: any) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    setEmailValid(emailRegex.test(email))
    return emailRegex.test(email)
  }

  const validatePassword = (password: any) => {
    setPasswordValid(password?.length >= 4)
    return password?.length >= 4
  }

  const handleTogglePassword = () => {
    setShowPassword(!showPassword)
  }

  const validate = (data: LoginModel) => {
    return (
      data.username != null &&
      validateEmail(data?.username) &&
      data.password != null &&
      validatePassword(data?.password.trim())
    )
  }
  const handleChangeLogin = () => {
    loginMutation.mutate(myInstance, {
      onError: () => {
        setValid(false)
      },
    })
  }

  return (
    <>
      <Box sx={{ marginTop: 4 }}>
        <CommonTextField
          id="id_user_email"
          type="email"
          label={intl.formatMessage({ id: 'label_email' })}
          onChange={handleEmailChange}
          error={!isEmailValid}
          disabled={loginMutation.isLoading}
          autoFocus={true}
          helperText={
            !isEmailValid
              ? `${intl.formatMessage({ id: 'error_invalid_email' })}`
              : ' '
          }
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  disableFocusRipple
                  sx={{
                    cursor: 'default',
                  }}
                  id="id_icon_email"
                >
                  <EmailOutlined />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Box>
      <Box sx={{ marginTop: 4 }}>
        <CommonTextField
          id="id_user_password"
          type={showPassword ? 'text' : 'password'}
          label={intl.formatMessage({ id: 'label_password' })}
          onChange={handlePasswordChange}
          error={!isPasswordValid}
          disabled={loginMutation.isLoading}
          onKeyDown={(event) => {
            if (event.key == 'Enter' && isValid) handleChangeLogin()
          }}
          helperText={
            !isPasswordValid
              ? `${intl.formatMessage({ id: 'misc_password_min_length' })}`
              : ' '
          }
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={handleTogglePassword}
                  id="id_view_password"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            paddingTop: 1,
          }}
        >
          <Typography
            component={'span'}
            sx={{ cursor: 'pointer' }}
            onClick={() => navigate('/reset-password')}
            id="id_forgot_password"
          >
            {intl.formatMessage({ id: 'action_forgot_password' })}
          </Typography>
        </Box>
      </Box>
      <Box sx={{ marginTop: 4 }}>
        <LoadingButton
          sx={{ height: '50px' }}
          onClick={handleChangeLogin}
          disabled={!isValid}
          loading={loginMutation.isLoading}
          fullWidth
          size="large"
          children={intl.formatMessage({ id: 'action_login' })}
          id="id_login"
        />
      </Box>
    </>
  )
}
