import {
  Box,
  Button,
  Dialog,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
  Snackbar,
  SnackbarContentProps,
  Paper,
} from '@mui/material'
import { useNavigate, useParams } from 'react-router-dom'
import {
  AppLoader,
  NoRecordFound,
  StyledButtonContainerForTable,
  ProductListTable,
  AppWarningDialog,
  ClickableIcon,
  FormattedDateTime,
  CreateButtonFlexEnd,
} from '../../../../../../components'
import {
  useCategory,
  useCategoryDelete,
  useCategoryUpdate,
  useItemDelete,
  useItemUpdate,
  useItems,
  useProducts,
} from '../../../../../../hooks'
import { DeleteOutline, EditOutlined } from '@mui/icons-material'
import { Fragment, useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import {
  defaultImageUrl,
  getCurrencySymbol,
  shortenText,
  tableImageStyles,
} from '../../../../../util'
import { MenuItemEntity } from '../../../../../../types'
import { toast } from 'react-toastify'
import { ShowOnlinePicker } from '../../../../../../components/Pickers'

export function MenuItems() {
  const { locationId, menuId } = useParams()
  const navigate = useNavigate()
  const currency = getCurrencySymbol()
  const intl = useIntl()
  const menu = useItems(locationId!, menuId!, {
    refetchOnMount: 'always',
    onSuccess: (data: any) => {
      const d = data.pages.length
      if (data.pages[d - 1].hasNextPage) {
        void menu.fetchNextPage()
        setItemsFectching(true)
      }
      setItemsFectching(false)
      setMenuItemsState(data?.pages?.map((value: any) => value.data).flat())
    },
  })
  const [categoryId, setCategoryId] = useState('')
  const [selectedItemId, setSelectedItemId] = useState(undefined)
  const itemUpdateMutation = useItemUpdate(
    locationId!,
    menuId!,
    selectedItemId!
  )
  const categoryUpdate = useCategoryUpdate(
    locationId!,
    menuId!,
    categoryId!,
    false
  )
  const category = useCategory(locationId!, menuId!, {
    onSuccess(data: any) {
      const d = data.pages.length
      if (data.pages[d - 1].hasNextPage) {
        void category.fetchNextPage()
      }
    },
  })
  const categoryDelete = useCategoryDelete(locationId, menuId)
  const products = useProducts(locationId!, {
    onSuccess(data: any) {
      const d = data.pages.length
      if (data.pages[d - 1].hasNextPage) {
        void products.fetchNextPage()
      }
    },
  })
  const [open, setOpen] = useState(false)
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false)
  const [selectedItem, setSelectedItem] = useState({} as MenuItemEntity)
  const itemDelete = useItemDelete(locationId!, menuId!)
  const menuItems = menu.data?.pages?.map((value) => value.data).flat()
  const categoryList = category.data?.pages
    ?.map((value: any) => value.data)
    .flat()
  const [showUndoSnackbar, setShowUndoSnackbar] = useState(false)
  const [highlightedRow, setHighlightedRow] = useState(false)
  const [deletedItem, setDeletedItem] = useState<any>(null)
  const [menuItemsState, setMenuItemsState] = useState<any>(menuItems)
  const [deletedList, setDeletedList] = useState<string[]>([])
  const [deletedIndex, setDeletedIndex] = useState<any>(null)
  const [itemsFectching, setItemsFectching] = useState(false)
  const [sortedRecords, setSortedRecords] = useState<any>([])

  const undoDuration = 3000

  useEffect(() => {
    setSortedRecords(
      menuItemsState?.sort((a: any, b: any) => a.position - b.position)
    )
  }, [menuItemsState])
  // Fetch next page for menu items
  useEffect(() => {
    const updatedMenu =
      selectedItem && deletedList.length
        ? menuItems?.filter(
            (item: any) => ![...deletedList, selectedItem.id].includes(item.id)
          )
        : menuItems
    setMenuItemsState(updatedMenu)
  }, [menu.data])

  /**
   * This is to get the selected items category list to delete the item from the category
   */
  const selectedItemsCategoryList =
    (categoryList &&
      categoryList.filter((category: any) =>
        category.items?.includes(selectedItem.id)
      )) ||
    []

  // this is to delete the item from the category while we are deleting the item
  useEffect(() => {
    if (categoryId) {
      selectedItemsCategoryList.forEach((category: any) => {
        try {
          const updatedItems = category.items.filter(
            (item: any) => item !== selectedItem.id
          )
          handleCategoryUpdate(category, updatedItems)
          setCategoryId('')
        } catch (error) {
          // Handle error if needed
          console.log(error)
        }
      })
    }
  }, [categoryId])
  const deleteItem = () => {
    for (let i = 0; i < deletedList.length; i++) {
      const toDelete = deletedList[i]
      const deleted = menuItems?.filter((item: any) => item.id === toDelete)
      itemDelete.mutate(toDelete, {
        onSuccess: () => {},
        onError: () => {
          deleted && setMenuItemsState([deleted[0], ...menuItemsState])
        },
      })
    }
    setDeletedList([])
  }

  const handleCategoryUpdate = (category: any, updatedItems: any) => {
    if (category.items.length === 1) {
      categoryDelete.mutate(category.id, {
        onSuccess: () => {
          deleteItem()
        },
      })
    } else {
      categoryUpdate.mutate(
        {
          // items: updatedItems,
          name: category.name,
          description: category.description,
        },
        {
          onSuccess: () => {
            deleteItem()
          },
        }
      )
    }
  }

  const handleUndo = () => {
    setShowUndoSnackbar(false)
    setHighlightedRow(true)
    setDeletedList([...deletedList.slice(0, -1)])
    if (deletedItem) {
      // Create a copy of the menuItems array
      const updatedMenu = sortedRecords as any

      if (deletedIndex !== -1) {
        updatedMenu.splice(deletedIndex, 0, deletedItem)

        const uniqueMenu = updatedMenu.filter((item: any, index: number) => {
          return (
            updatedMenu.findIndex((obj: any) => obj.id === item.id) === index
          )
        })

        setSortedRecords(uniqueMenu)
      }
    }
  }

  const action = (
    <Fragment>
      <Button
        variant="text"
        color="info"
        size="medium"
        onClick={handleUndo}
        style={{ textTransform: 'none' }}
      >
        Undo
      </Button>
    </Fragment>
  )
  useEffect(() => {
    if (highlightedRow !== false) {
      const timeoutId = setTimeout(() => {
        setHighlightedRow(false)
      }, 1500)
      return () => clearTimeout(timeoutId)
    }
  }, [highlightedRow])

  const handleDeleteConfirmed = async () => {
    setShowConfirmationDialog(false)
    setShowUndoSnackbar(true)
    setDeletedList([...deletedList, selectedItem.id])

    const updatedMenu = menuItemsState?.filter(
      (item: any) => ![...deletedList, selectedItem.id].includes(item.id)
    )
    const deletedItem = menuItems?.find(
      (item: any) => item.id === selectedItem.id
    )
    const deletedItemIndex = menuItemsState?.findIndex(
      (item: any) => item.id === deletedItem?.id
    )
    setDeletedIndex(deletedItemIndex)

    setDeletedItem(deletedItem)

    setMenuItemsState(updatedMenu as any)
  }

  const afterSnackBarClosed = () => {
    setShowUndoSnackbar(false)
    //actuall deletion
    if (selectedItemsCategoryList.length > 0) {
      selectedItemsCategoryList.map((category: any) => {
        try {
          setCategoryId(category.id)
        } catch (error) {
          // Handle error if needed
        }
      })
    } else {
      deleteItem()
    }
  }
  useEffect(() => {
    selectedItemId !== undefined &&
      itemUpdateMutation.mutate(
        { showOnline: !selectedItem?.showOnline },
        {
          onSuccess: () => {
            setSelectedItemId(undefined)
            toast.success('Item Updated')
          },
        }
      )
  }, [selectedItemId])

  useEffect(() => {
    let timeoutId: any
    if (showUndoSnackbar) {
      timeoutId = setTimeout(() => {
        setShowUndoSnackbar(false)
        afterSnackBarClosed()
      }, undoDuration)
    }
    return () => clearTimeout(timeoutId)
  }, [showUndoSnackbar, setShowUndoSnackbar, afterSnackBarClosed])

  if (
    menu.isLoading ||
    products.isLoading ||
    category.isLoading //itemDelete.isLoading  itemCreate.isLoading
  ) {
    return <AppLoader />
  }
  if (menu.isError) {
    return (
      <Typography mt={2}>
        {intl.formatMessage({ id: 'error_try_again' })}
      </Typography>
    )
  }
  const handleShowOnlineChange = (e: boolean, item: any) => {
    setSelectedItemId(item.id)
    setSelectedItem(item)
  }
  return (
    <>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Typography variant="body2">
          {itemsFectching
            ? `${intl.formatMessage({ id: 'label_fetching_data' })}`
            : ''}
        </Typography>

        <CreateButtonFlexEnd
          handleClick={() => setOpen(true)}
          buttonType="item"
        />
      </Box>
      {menu.isSuccess &&
      !(menu?.data?.pages?.map((value) => value.data).flat().length > 0) ? (
        <NoRecordFound title="Items" />
      ) : (
        <>
          <TableContainer component={Paper}>
            <Table stickyHeader aria-label="sticky table" size="small">
              <TableHead>
                <TableRow>
                  <TableCell>
                    {intl.formatMessage({ id: 'label_image' })}
                  </TableCell>
                  <TableCell>
                    {intl.formatMessage({ id: 'label_name' })}
                  </TableCell>
                  <TableCell>
                    {intl.formatMessage({ id: 'label_show_online' })}
                  </TableCell>
                  <TableCell>
                    {intl.formatMessage({ id: 'label_price' })}
                  </TableCell>
                  <TableCell sx={{ width: '300px' }}>
                    {intl.formatMessage({ id: 'label_created_at' })}
                  </TableCell>
                  <TableCell>
                    {intl.formatMessage({ id: 'label_actions' })}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {sortedRecords?.map((itemData: any, index: any) => {
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={index}
                      onClick={() =>
                        navigate(
                          `/app/locations/${locationId}/menu/${menuId}/item/${itemData.id}`
                        )
                      }
                      sx={{
                        cursor: 'default !important',
                        backgroundColor:
                          highlightedRow &&
                          deletedItem?.name === itemData.name &&
                          index == deletedIndex
                            ? '#fff1d5'
                            : 'inherit',
                        transition: 'background-color 0.5s linear',
                      }}
                    >
                      <TableCell>
                        <img
                          style={{ ...tableImageStyles }}
                          src={
                            itemData.imageUrl
                              ? itemData.imageUrl
                              : defaultImageUrl
                          }
                        />
                      </TableCell>
                      <TableCell>
                        {itemData.name?.length > 25 ? (
                          <Tooltip title={itemData.name}>
                            <Typography variant="body2">
                              {shortenText(itemData.name, 25)}
                            </Typography>
                          </Tooltip>
                        ) : (
                          itemData.name
                        )}
                      </TableCell>
                      <TableCell onClick={(e) => e.stopPropagation()}>
                        <ShowOnlinePicker
                          value={itemData.showOnline}
                          onChange={(value: boolean) =>
                            handleShowOnlineChange(value, itemData)
                          }
                        />
                      </TableCell>
                      <TableCell>
                        {currency}
                        {itemData.price
                          ? (
                              parseFloat(itemData.price.toString()) / 100
                            ).toFixed(2)
                          : '0.00'}
                      </TableCell>
                      <TableCell>
                        <FormattedDateTime value={itemData.createdAt} />
                      </TableCell>
                      <TableCell onClick={(e) => e.stopPropagation()}>
                        <Box sx={{ display: 'flex' }}>
                          <ClickableIcon
                            handleNavigate={() =>
                              navigate(
                                `/app/locations/${locationId}/menu/${menuId}/item/${itemData.id}`
                              )
                            }
                            disabled={itemsFectching}
                            children={<EditOutlined />}
                          />
                          <ClickableIcon
                            handleNavigate={() => [
                              setShowConfirmationDialog(true),
                              setSelectedItem(itemData),
                              showUndoSnackbar && afterSnackBarClosed(),
                            ]}
                            disabled={itemsFectching}
                            children={<DeleteOutline />}
                          />
                        </Box>
                      </TableCell>
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
          </TableContainer>
          {menu.hasNextPage && (
            <StyledButtonContainerForTable
              display="flex"
              justifyContent="center"
              py={2}
            >
              <Button
                onClick={() => menu.fetchNextPage()}
                disabled={menu.isFetching}
              >
                {menu.isFetching
                  ? `${intl.formatMessage({ id: 'status_loading' })}`
                  : `${intl.formatMessage({ id: 'action_load_more' })}`}
              </Button>
            </StyledButtonContainerForTable>
          )}
        </>
      )}
      <Dialog
        open={open}
        fullWidth
        maxWidth={'lg'}
        aria-labelledby="responsive-dialog-title"
        onClose={(event, reason) => {
          if (reason !== 'backdropClick') {
            setOpen(false)
          }
        }}
      >
        <ProductListTable
          handleClose={() => setOpen(false)}
          locationId={locationId!}
        />
      </Dialog>
      <AppWarningDialog
        name={selectedItem.name}
        source="items"
        open={showConfirmationDialog}
        onConfirm={handleDeleteConfirmed}
        onCancel={() => setShowConfirmationDialog(false)}
      />
      <Snackbar
        open={showUndoSnackbar}
        autoHideDuration={undoDuration}
        onClose={(event, reason) => {
          if (reason === 'clickaway') {
            return
          }
          afterSnackBarClosed()
        }}
        message={
          <Typography variant="body2" sx={{ color: 'black' }}>
            Deleted{' '}
            <span style={{ fontWeight: 'bold' }}>{deletedItem?.name}</span>
          </Typography>
        }
        action={action}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        ContentProps={{
          sx: {
            backgroundColor: 'white',
          } as SnackbarContentProps['sx'],
        }}
      />
    </>
  )
}
