import { Box } from '@mui/material'
import { useEffect, useState } from 'react'
import { Navigate, useNavigate, useParams } from 'react-router-dom'
import {
  getAccountQueryById,
  useAccountCreate,
  useAccountUpdateMutaion,
  useAuthStore,
} from '../../../hooks'
import { AccountModel } from '../../../model/AccountModel'
import { AppLoader } from '../../../components'
import { useQueryClient } from 'react-query'
import { AccountEditView } from '../AccountEditForm'

export function AccountUpsert() {
  const navigate = useNavigate()
  const client = useQueryClient()
  const { accountId } = useParams()
  const accountDetails = accountId ? getAccountQueryById() : null
  const accountCreateMutation = accountId != null ? null : useAccountCreate()
  const accountUpdateMutation =
    accountId != null ? useAccountUpdateMutaion() : null
  const [id] = useAuthStore((t) => [t.accountId])
  const [myInstance, setMyInstance] = useState(
    new AccountModel(
      accountId && accountDetails?.isSuccess ? accountDetails.data.data : null
    )
  )
  useEffect(() => {
    if (accountDetails?.data) {
      setMyInstance(
        new AccountModel(
          accountId && accountDetails?.isSuccess
            ? accountDetails.data.data
            : null
        )
      )
    }
  }, [accountDetails?.data])

  const handleAccountChange = async (updatedInstance: any) => {
    accountId
      ? accountUpdateMutation?.mutate(updatedInstance)
      : accountCreateMutation?.mutate(updatedInstance, {
          onSuccess: () => {
            void client.refetchQueries(['v1', 'accounts'])
            navigate('/app/locations/new')
          },
        })
  }

  if (id && !accountId) {
    return <Navigate to={'/app/locations/new'} replace />
  }
  if (
    accountDetails?.isLoading ||
    accountCreateMutation?.isLoading ||
    accountUpdateMutation?.isLoading
  ) {
    return <AppLoader />
  }

  if (accountCreateMutation?.isSuccess) {
    return <Navigate to={'/app/locations/new'} replace />
  }
  if (accountUpdateMutation?.isSuccess) {
    return <Navigate to={'/app/accounts'} replace />
  }

  return (
    <Box>
      <AccountEditView
        accountId={accountId}
        myInstance={myInstance}
        onCancel={() => navigate('/app/accounts')}
        handleAccountChange={handleAccountChange}
        disableAddressChange={accountId != null ? true : false}
      />
    </Box>
  )
}
