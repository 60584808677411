import { EditOutlined } from '@mui/icons-material'
import {
  Grid,
  Box,
  CardMedia,
  CardContent,
  IconButton,
  Tooltip,
  Button,
  Divider,
} from '@mui/material'
import { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { useIntl } from 'react-intl'
import { useItemUpdate } from '../../hooks'
import { ItemCard, PriceInput } from './components'
import { getCurrency, handleDragEndGeneric } from '../util'
import DragIndicatorOutlinedIcon from '@mui/icons-material/DragIndicatorOutlined'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import { CommonTextField } from '../../components'
import { TruncateName } from './components/TruncateName'
import { Delete as RemoveIcon } from '@mui/icons-material'
import { ShowOnlinePicker } from '../../components/Pickers'

/**
 * Menu Category Items
 */
export const MenuCategoryItems: FC<{
  category: any
  onItemAdd: (categoryId: string) => any
  onDeleteItem: (item: any) => any
  items: any
  setSelectedProduct: (val: any) => void
  highlightStyle: (prod: any, index: any, type: any) => any
  performDeletion: any
  showUndoSnackbar: any
  onEditItemById: (id: string, categoryId: string) => void
  handleChangeSequence: (result: any) => void
}> = (props) => {
  const {
    items,
    category,
    onDeleteItem,
    onItemAdd,
    setSelectedProduct,
    highlightStyle,
    performDeletion,
    showUndoSnackbar,
    onEditItemById,
    handleChangeSequence,
  } = props
  const intl = useIntl()
  const currency = getCurrency()
  const records = useMemo(() => {
    return items?.filter((item: any) => item?.categories?.includes(category.id))
  }, [category, items])
  const [sortedRecords, setSortedRecords] = useState<any>([])

  useEffect(() => {
    if (records) {
      setSortedRecords(
        records.slice().sort((a: any, b: any) => a.position - b.position)
      )
    }
  }, [records])

  const handleDragEnd = (result: any) => {
    const rearrangeData = handleDragEndGeneric({
      result: result, // or simply result,
      sortedList: sortedRecords, // pass sortedCategories as sortedList
      rearrangeKey: 'items', // string indicating the key to update in rearrange state
      setSortedList: setSortedRecords,
      mergeExisting: true, // optional; for categories, usually false
    })
    handleChangeSequence(rearrangeData)
  }
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
      <Divider sx={{ marginTop: 1, marginBottom: 0.5 }} />
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'center',
          width: '100%',
        }}
      >
        <Button variant="text" onClick={() => onItemAdd(category.id)}>
          {intl.formatMessage({ id: 'action_add_new_item_to_category' })}
        </Button>
      </Box>
      <RenderDraggableView
        sortedRecords={sortedRecords}
        currency={currency}
        onDeleteItem={onDeleteItem}
        setSelectedProduct={setSelectedProduct}
        highlightStyle={highlightStyle}
        performDeletion={performDeletion}
        showUndoSnackbar={showUndoSnackbar}
        handleDragEnd={handleDragEnd}
        onEditItemById={onEditItemById}
        categoryId={category.id}
      />
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-start',
          alignItems: 'center',
          width: '100%',
        }}
      >
        <Button variant="text" onClick={() => onItemAdd(category.id)}>
          {intl.formatMessage({ id: 'action_add_new_item_to_category' })}
        </Button>
      </Box>
    </Box>
  )
}
const ItemCardView = (props: any) => {
  const {
    item,
    itemName,
    onDeleteItem,
    setSelectedProduct,
    performDeletion,
    showUndoSnackbar,
    highlightStyle,
    index,
    dragHandleProps,
    onEditItemById,
    categoryId,
  } = props
  const [itemState, setItemState] = useState<any>(item)
  const [priceValue, setPriceValue] = useState<any>(undefined)
  useEffect(() => {
    setItemState(item)
  }, [item])
  const itemUpdateMutation = useItemUpdate(
    item.locationId,
    item.menuId,
    item.id
  )
  const handleShowOnline = (showOnline: boolean) => {
    itemUpdateMutation.mutate({ showOnline: showOnline })
  }

  const handleNameChange = (e: any) => {
    const value = e.target.value
    itemUpdateMutation.mutate({ name: value })
  }
  const handleItemPriceChange = useCallback((e) => {
    const value = e.target.value
    if (!isNaN(value) || value === '') {
      setPriceValue(value)
    }
  }, [])

  const handleItemPriceBlur = () => {
    if (priceValue !== undefined) {
      applyPriceChange(priceValue)
    }
  }

  const applyPriceChange = (price: any) => {
    const formattedPrice: number = Math.round(parseFloat(price) * 100)
    setItemState({ ...itemState, price: formattedPrice! || 0 })
    itemUpdateMutation.mutate(
      { price: formattedPrice! || 0 },
      {
        onSuccess: () => {
          setPriceValue(undefined)
        },
      }
    )
  }
  return (
    <ItemCard
      onClick={() => onEditItemById(item.id, categoryId)}
      sx={{
        position: 'relative',
        ...highlightStyle(itemState, index, 'item'),
        alignItems: 'center',
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center' }} {...dragHandleProps}>
        <DragIndicatorOutlinedIcon sx={{ color: 'gray', mr: 1 }} />
      </Box>
      <CardMedia
        component="img"
        sx={{ width: 80, borderRadius: 2, height: 50 }}
        image={itemState.imageUrl || 'https://placehold.co/600x400'}
        title={itemName}
      />
      <CardContent>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            width: '300px',
          }}
          onClick={(e) => e.stopPropagation()}
        >
          <CommonTextField
            id="outlined-basic"
            size="small"
            onChange={(e) =>
              setItemState({ ...itemState, name: e.target.value })
            }
            onBlur={handleNameChange}
            value={itemState.name}
          />
        </Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            minWidth: 210,
          }}
          onClick={(e) => e.stopPropagation()}
        >
          <Box width={150} mx={1}>
            <ShowOnlinePicker
              value={itemState.showOnline}
              onChange={(value) => handleShowOnline(value)}
            />
          </Box>
          <PriceInput
            value={priceValue ?? itemState.price / 100}
            size="small"
            sx={{
              width: '90px',
            }}
            onChange={(e) => {
              handleItemPriceChange(e)
            }}
            onBlur={handleItemPriceBlur}
            type="number"
            inputProps={{ step: '0.01' }} // Allow decimals
          />
          <Tooltip title="Edit Item">
            <IconButton
              onClick={(e) => {
                e.stopPropagation()
                onEditItemById(item.id, categoryId)
              }}
            >
              <EditOutlined fontSize="small" />
            </IconButton>
          </Tooltip>
          <Tooltip title="Delete Item">
            <IconButton
              onClick={(e) => {
                e.stopPropagation()
                setSelectedProduct(itemState)
                showUndoSnackbar && performDeletion()
                onDeleteItem(itemState)
              }}
            >
              <RemoveIcon fontSize="small" />
            </IconButton>
          </Tooltip>
        </Box>
      </CardContent>
    </ItemCard>
  )
}
const RenderDraggableView = (props: any) => {
  const {
    sortedRecords,
    currency,
    onDeleteItem,
    setSelectedProduct,
    highlightStyle,
    performDeletion,
    showUndoSnackbar,
    handleDragEnd,
    onEditItemById,
    categoryId,
  } = props
  return (
    <DragDropContext onDragEnd={handleDragEnd}>
      <Droppable droppableId="categories" direction="vertical">
        {(provided: any) => (
          <Box
            ref={provided.innerRef}
            {...provided.droppableProps}
            sx={{ width: '100%' }}
          >
            {sortedRecords?.map((item: any, index: any) => (
              <Draggable key={item.id} draggableId={item.id} index={index}>
                {(provided: any) => (
                  <Grid
                    item
                    xs={12}
                    key={item.id}
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    sx={{ marginBottom: '8px' }} // Adds spacing to avoid overlap
                  >
                    <ItemCardView
                      item={item}
                      currency={currency}
                      itemName={item.name}
                      onDeleteItem={onDeleteItem}
                      setSelectedProduct={setSelectedProduct}
                      performDeletion={performDeletion}
                      showUndoSnackbar={showUndoSnackbar}
                      highlightStyle={highlightStyle}
                      index={index}
                      dragHandleProps={{ ...provided.dragHandleProps }}
                      onEditItemById={onEditItemById}
                      categoryId={categoryId}
                    />
                  </Grid>
                )}
              </Draggable>
            ))}
            {/* This ensures the container doesn't collapse */}
            {provided.placeholder}
          </Box>
        )}
      </Droppable>
    </DragDropContext>
  )
}
