import { FC, PropsWithChildren } from 'react'
import {
  AppBar,
  AppBarProps,
  Box,
  Button,
  Container,
  Toolbar,
  Typography,
} from '@mui/material'
import {
  WebsiteAppBarNavigation,
  WebsiteAppBarMobileNavigation,
} from './WebsiteAppBarNavigation'
import { useNavigate } from 'react-router'

export interface WebsiteAppBarProps extends AppBarProps {}

export const WebsiteAppBar: FC<PropsWithChildren<WebsiteAppBarProps>> = (
  props
) => {
  const navigate = useNavigate()
  return (
    <AppBar color="inherit" elevation={1} {...props}>
      <Container maxWidth="lg">
        <Toolbar disableGutters>
          {/* Brand Section */}
          <Typography
            variant="h6"
            noWrap
            onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
            sx={{
              mr: 2,
              display: 'flex',
              fontWeight: 700,
              textDecoration: 'none',
              color: 'inherit',
            }}
          >
            POS Hub
          </Typography>

          {/* Desktop Navigation (hidden on mobile) */}

          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
            <WebsiteAppBarNavigation />
          </Box>

          {/* Mobile Menu Icon (hamburger) */}
          <Box
            sx={{
              flexGrow: 1,
              display: { xs: 'flex', md: 'none' },
              justifyContent: 'flex-end',
            }}
          >
            <WebsiteAppBarMobileNavigation />
          </Box>

          {/* Add a buttun to go the the app */}
          <Button onClick={() => navigate('/app/locations')}>Open App</Button>
        </Toolbar>
      </Container>
    </AppBar>
  )
}
