import {
  Box,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import { FormattedMoney } from '../FormattedMoney'
import { getCurrency } from '../../pages/util'
import { UseQueryResult } from 'react-query'
import { Fragment } from 'react'

export interface OrderViewItemsProps {
  order: UseQueryResult<any, any>
}

export const OrderViewItems = (props: OrderViewItemsProps) => {
  const { order } = props
  const currencyValue = getCurrency()
  return (
    <Grid container>
      <Grid item xs={12} md={12}>
        <TableContainer component={Box} style={{ border: '1px solid #e0e0e0' }}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>Qty</TableCell>
                <TableCell>Item</TableCell>
                <TableCell align="right">Price</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {order.data?.data.items.map((item: any, index: any) => (
                <Fragment key={index}>
                  <TableRow key={index} id={`id_order_item_${item.name}`}>
                    <TableCell>
                      <Typography component="span" variant="body1" id="id_qty">
                        {item.quantity}
                      </Typography>
                    </TableCell>
                    <TableCell sx={{ width: '100%' }}>
                      <Typography
                        component="span"
                        variant="body1"
                        id="id_item_name"
                      >
                        {item.name}
                      </Typography>
                      <Typography
                        component="span"
                        variant="caption"
                        color="textSecondary"
                        id="id_customer_notes"
                      >
                        {item.customerNotes}
                      </Typography>
                    </TableCell>
                    <TableCell align="right">
                      <Typography
                        variant="body1"
                        component="span"
                        id="id_price"
                      >
                        {item.price === 0 ? (
                          'Free'
                        ) : (
                          <FormattedMoney
                            value={(item.price * item.quantity) / 100}
                            currency={currencyValue}
                            decimalValue={true}
                          />
                        )}
                      </Typography>
                    </TableCell>
                  </TableRow>
                  {item.options?.map((option: any) => {
                    return (
                      <TableRow key={index} id={`id_addon_${option.name}`}>
                        <TableCell>
                          <Typography
                            component="span"
                            variant="body2"
                            id="id_addon_qty"
                          >
                            {option.quantity}
                          </Typography>
                        </TableCell>
                        <TableCell sx={{ paddingLeft: 4 }}>
                          <Typography
                            component="span"
                            variant="body2"
                            id="id_addon_name"
                          >
                            {option.name}
                          </Typography>
                        </TableCell>
                        <TableCell align="right">
                          <Typography
                            variant="body2"
                            component="span"
                            id="id_addon_price"
                          >
                            <FormattedMoney
                              value={
                                (option?.price *
                                  (option?.quantity * item?.quantity)) /
                                100
                              }
                              currency={currencyValue}
                              decimalValue={true}
                            />
                          </Typography>
                        </TableCell>
                      </TableRow>
                    )
                  })}
                </Fragment>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  )
}
