import { Box, Switch, Tooltip, Typography } from '@mui/material'
import { useIntl } from 'react-intl'

export const ShowOnlineView = (props: any) => {
  const { disabled, checked = true, label, flexDirection } = props
  const intl = useIntl()

  if (label) {
    return (
      <Box
        sx={{
          justifyContent: 'flex-end',
          alignItems: 'center',
          display: 'flex',
        }}
      >
        <label
          style={{
            display: 'flex',
            alignItems: 'center',
            cursor: 'pointer',
            flexDirection: flexDirection || 'row',
          }}
        >
          <Switch checked={checked} disabled={disabled} size="small" />
          <Typography variant="caption" sx={{ textAlign: 'center' }}>
            {label}
          </Typography>
        </label>
      </Box>
    )
  } else {
    return (
      <Tooltip
        title={intl.formatMessage({
          id: 'label_show_online',
        })}
      >
        <Switch checked={checked} disabled={disabled} size="small" />
      </Tooltip>
    )
  }
}
