import { FC, useState } from 'react'
import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  DialogTitle,
  CircularProgress,
} from '@mui/material'
import {
  useAccountConnections,
  useApplicationById,
  useLocations,
  useMenuById,
  useMenuPublishMutation,
} from '../../hooks'
import { useIntl } from 'react-intl'
import { LocationCheckBox } from './DialogList'
import { useParams } from 'react-router-dom'
import { findNameById } from '../../pages/util'

interface MenuSyncDialogProps {
  menuId: string
  onClose: (data: any) => void
}

export const MenuSyncDialog: FC<MenuSyncDialogProps> = (props) => {
  const menu = useMenuById(props.menuId)
  const intl = useIntl()
  const menuPublishMutation = useMenuPublishMutation()
  const {
    data: connections,
    isLoading: connectionsLoading,
    isError: connectionsError,
  } = useAccountConnections()

  const {
    data: locations,
    isLoading: locationsLoading,
    isError: locationsError,
  } = useLocations()
  const [selectedConnectionIds, setSelectedConnectionIds] = useState<
    { id: string; autoPublishInterval: number }[]
  >([])
  const [isSelected, setIsSelected] = useState(false)
  /**
   * Define the cancel button
   */
  const cancelButton = (
    <Button onClick={props.onClose}>
      {intl.formatMessage({ id: 'action_cancel' })}
    </Button>
  )
  /**
   * Show loading view
   */
  if (menu.isLoading || locationsLoading || connectionsLoading) {
    return (
      <Dialog open={true} fullWidth sx={{ borderRadius: 2 }}>
        <DialogContent>
          <Typography>
            {intl.formatMessage({ id: 'status_loading' })}
          </Typography>
        </DialogContent>
        <DialogActions>{cancelButton}</DialogActions>
      </Dialog>
    )
  }
  /**
   * Show Error view
   */
  if (menu.isError || locationsError || connectionsError) {
    return (
      <Dialog open={true} fullWidth sx={{ borderRadius: 2 }}>
        <DialogContent>
          <Typography>
            {intl.formatMessage({ id: 'error_try_again' })}
          </Typography>
        </DialogContent>
        <DialogActions>{cancelButton}</DialogActions>
      </Dialog>
    )
  }

  /**
   * Helper method to handle connection selection
   * @param connectionId
   * @param checked
   */
  const onConnectionSelect = (connectionData: any) => {
    const selectedID: any[] = []
    // Check if any category or subcategory is selected to enable the publish button
    const anyItemSelected = connectionData.channels.some(
      (item: any) => item.selected
    )
    setIsSelected(anyItemSelected)

    // Check if any subcategory is deselected, and if so, deselect the parent category
    const anySubcategoryDeselected = connectionData.channels?.some(
      (channel: any) => !channel.selected
    )
    if (anySubcategoryDeselected) {
      connectionData.selected = false
    }

    // Check if all subcategory is selected, and if so, select the parent category
    const allSubcategorySelected = connectionData.channels?.every(
      (channel: any) => channel.selected
    )
    if (allSubcategorySelected) {
      connectionData.selected = true
    }
    const channels = connectionData.channels
    // Check if any channel has selected set to true
    channels?.map((channel: any) => {
      if (channel.selected === true) {
        selectedID.push({
          id: channel.id,
          autoPublishInterval: channel.autoPublishInterval,
        })
      } else {
        selectedID.filter((conn) => conn.id !== channel.id)
      }
    })
    setSelectedConnectionIds(selectedID)
  }
  /**
   *
   */
  const doPublish = () => {
    for (const connectionData of selectedConnectionIds) {
      // find the connection by connectionId
      const connection = connections.find(
        (item: any) => connectionData.id === item.id
      )
      menuPublishMutation.mutate(
        {
          menuId: props.menuId,
          applicationId: connection.applicationId,
          connectionId: connectionData.id!,
          locationId: connection.locationId,
          autoPublishInterval: +connectionData.autoPublishInterval,
        },
        {
          onSuccess: (data) => {
            props.onClose(data.data)
          },
          onError: (error) => {
            const errorResponse = error?.response?.data.error
              ? error?.response?.data.error
              : error?.message
            props.onClose(errorResponse)
          },
        }
      )
    }
  }

  return (
    <Dialog
      open={true}
      fullWidth
      sx={{ borderRadius: 2 }}
      onClose={props.onClose}
    >
      <DialogTitle>
        {intl.formatMessage({ id: 'action_publish_menu_to_marketplace' })}
      </DialogTitle>
      <DialogContent>
        <MenuSyncConnections
          connections={connections}
          onConnectionSelect={onConnectionSelect}
          locations={locations}
          menuId={props.menuId}
        />
      </DialogContent>
      <DialogActions>
        {cancelButton}
        <Button
          color="success"
          disabled={!isSelected || menuPublishMutation.isLoading}
          endIcon={
            menuPublishMutation.isLoading ? (
              <CircularProgress size="1.5rem" color="success" />
            ) : undefined
          }
          onClick={() => doPublish()}
        >
          {intl.formatMessage({ id: 'action_publish' })}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export interface MenuSyncConnectionsProps {
  connections: any
  onConnectionSelect: (connectionId: string) => void
  locations: any
  menuId: any
}

export const MenuSyncConnections: FC<MenuSyncConnectionsProps> = (props) => {
  const { connections, onConnectionSelect, locations, menuId } = props
  const { locationId } = useParams()
  const locationsList: any[] = []
  locations.pages.forEach((page: any) => {
    const data = page?.data
    if (data) {
      locationsList.push(...data)
    } else {
      console.error('Data inside the Locations pages are undefined or empty.')
    }
  })
  const filteredConnections = connections.filter(
    (connection: any) => connection.locationId === locationId
  )

  const applicationResponse = filteredConnections.map((object: any) => {
    return useApplicationById(object.applicationId)
  })
  const [groupedData, setGroupedData]: any = useState({})
  // Check if any query is loading or has an error
  const applicationLoading = applicationResponse.some(
    (query: any) => query.isLoading
  )
  const applicationError = applicationResponse.some(
    (query: any) => query.isError
  )

  if (applicationLoading) {
    return <p>Loading...</p>
  }
  if (applicationError) {
    return <p>Error: Something went wrong!</p>
  }
  const mergeConnectionsAndApplicationResponse = (
    connections: any,
    applicationResponse: any,
    menuId: string
  ) => {
    const result: {
      connectionDetails: any
      channels: { applicationId: any; category: any; selected: boolean }[]
      selected: boolean
      locationName: string
      locationId: string
      menuId: string
    } = {
      channels: [],
      selected: false,
      connectionDetails: [],
      locationName: '',
      locationId: '',
      menuId: '',
    }
    // Create a map for faster lookup
    const applicationMap = new Map(
      applicationResponse.map((app: any) => [
        app.data?.data?.id,
        app.data?.data,
      ])
    )
    // Set to keep track of processed locationIds
    const processedLocationIds = new Set()

    const filteredConnections = connections.filter(
      (item: any) => item.locationId === locationId
    )
    // Process only the first element of filteredConnections
    const connection = filteredConnections[0]

    // Check if locationId is already processed
    if (connection && !processedLocationIds.has(connection.locationId)) {
      const locationName = findNameById(connection.locationId, locationsList)
      const channelsArray: {
        applicationId: any
        category: any
        selected: boolean
        name: any
        id: any
        autoPublishInterval: number
      }[] = []
      // Iterate over applicationMap values
      Array.from(applicationMap.values()).forEach((app: any) => {
        if (app?.category === 'MARKETPLACE') {
          const connectionSelected = filteredConnections.find(
            (item: any) => item?.applicationId === app?.id
          )
          channelsArray.push({
            applicationId: app?.id,
            category: app?.category || null,
            selected: false,
            name: app?.name,
            id: connectionSelected?.id,
            autoPublishInterval: connectionSelected?.autoPublishInterval ?? 0,
          })
        }
      })

      result.channels = [...channelsArray, ...(connection.channels || [])]
      result.selected = false
      result.connectionDetails = [...filteredConnections]
      result.locationName = locationName
      result.locationId = connection.locationId
      result.menuId = menuId
      // Mark locationId as processed
      processedLocationIds.add(connection.locationId)
    }
    return result
  }

  // Usage
  const mergedArray = mergeConnectionsAndApplicationResponse(
    connections,
    applicationResponse,
    menuId
  )
  //Handle the selected Locations
  const handleLocationSelect = () => {
    const updatedData = { ...groupedData }
    updatedData.selected = !updatedData.selected
    if (updatedData.channels) {
      updatedData.channels.forEach((channel: any) => {
        channel.selected = updatedData.selected
      })
    }
    onConnectionSelect(updatedData)
    setGroupedData(updatedData)
  }
  return (
    <div>
      <LocationCheckBox
        mergedArray={mergedArray}
        handleLocationSelect={handleLocationSelect}
        groupedData={groupedData}
        setGroupedData={setGroupedData}
        onConnectionSelect={onConnectionSelect}
      />
    </div>
  )
}
