import { useQuery } from 'react-query'
import { api } from '../services/api'
import { useProfile } from './useProfile'

/**
 * @name useBootstrap
 * @description Returns the bootstrap data (typically the reseller context the application is running in)
 * @returns {QueryObserverResult} The query result
 */
export const useBootstrap = () => {
  const profile = useProfile()
  return useQuery(['auth', 'bootstrap', profile.data?.data.resellerId], () =>
    api
      .get('/auth/bootstrap', {
        headers: {
          // e.g if a reseller of Company A logs in to Company B's domain, we can use the resellerId\
          // to get the context of the reseller the user is in, and update the branding accordingly
          ...(profile.data?.data.resellerId
            ? { 'X-Bootstrap-Context': profile.data?.data.resellerId }
            : {}),
        },
      })
      .then(({ data }) => data)
  )
}

/**
 * @name useBootstrapLogo
 * @description Returns the current logo from the bootstrap data
 * @returns {string | undefined} The logo URL (either absolute or relative)
 */
export const useBootstrapLogo = () => {
  return useBootstrap().data?.resellerLogo
}

/**
 * @name useBootstrapTheme
 * @description Returns the current theme from the bootstrap data
 * @returns {object | undefined} The theme (can be mixed with base theme)
 */
export const useBootstrapTheme = () => {
  return useBootstrap().data?.theme
}
