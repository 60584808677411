import { useNavigate, useParams } from 'react-router-dom'
import { useEffect, useState } from 'react'
import {
  getLocationQueryById,
  useCategory,
  useItemById,
  useItemUpdate,
  useMenuById,
  useMenuModifierGroups,
  useMenuModifierGroupUpdateForItemEdit,
  useProducts,
} from '../../../../../../../hooks'
import { ItemEditForm } from '../../../../../../../components'
import { MenuItemPatchEntity } from '../../../../../../../types'
import { defaultItemPatchState } from '../../../utils'
import { toast } from 'react-toastify'
import { Box } from '@mui/material'
import { PageContainer } from '../../../../../../../components/Page/PageContainer'

export function MenuItemUpsert() {
  const { locationId, menuId, itemId } = useParams()
  const navigate = useNavigate()
  const isMenuV2 = window.location.pathname.includes('menus')
  //  fetch Location data
  const locations = getLocationQueryById(locationId)
  const locationName = locations.data?.data?.name
  // fetch Menu data
  const menuData = useMenuById(locationId!, menuId!, {
    refetchOnMount: 'always',
  })
  const itemUpdate = useItemUpdate(locationId!, menuId!, itemId!)
  const modiferGroupUpdateMutation = useMenuModifierGroupUpdateForItemEdit(
    locationId!,
    menuId!
  )
  const itemById = useItemById(locationId!, menuId!, itemId!, {
    refetchOnMount: 'always',
    onSuccess: (data: any) => {
      if (
        (data?.data?.fulfillmentTypes && data.data.fulfillmentTypes?.length) ||
        (data?.data?.serviceAvailability &&
          data.data.serviceAvailability?.length)
      ) {
        setItemSpecificAvailability(true)
      }
    },
  })
  const itemData = itemById?.data?.data
  const menuDetails = menuData.data?.data
  // fetch Catalog Products Data
  const fetchItemData = (catalogProducts: any) => {
    const item = catalogProducts?.find(
      (item: any) => item.id === itemData?.catalogProductId
    )
    setCatalogProductData(item)
  }
  const catalogProducts = useProducts(locationId!, {
    onSuccess(data: any) {
      const item = data.pages.map((page: any) => page.data).flat()
      fetchItemData(item)
      const d = data.pages.length
      if (data.pages[d - 1].hasNextPage) {
        void catalogProducts.fetchNextPage()
      }
    },
  })

  const categories = useCategory(locationId!, menuId!, {
    onSuccess(data: any) {
      const d = data.pages.length
      if (data.pages[d - 1].hasNextPage) {
        void categories.fetchNextPage()
      }
    },
  })

  const modifierGroups = useMenuModifierGroups(locationId!, menuId!, {
    onSuccess(data: any) {
      const d = data.pages.length
      if (data.pages[d - 1].hasNextPage) {
        void modifierGroups.fetchNextPage()
      }
    },
  })
  const modifierGroupsData = modifierGroups?.data?.pages
    ?.map((page: any) => page.data)
    .flat()
  const [menuItemState, setMenuItemState] = useState<MenuItemPatchEntity>(
    defaultItemPatchState
  )
  const [catalogProductData, setCatalogProductData] = useState<any>(undefined)
  const modifierGroupOptions = modifierGroupsData?.filter((group: any) =>
    catalogProductData?.modifierGroups?.includes(group.catalogModifierGroupId)
  )
  const categoriesOptions = categories.data?.pages
    .map((value: any) => value.data)
    .flat()
    ?.map((record) => record)

  //used to check if the item has specific availability
  const [itemSpecificAvailability, setItemSpecificAvailability] =
    useState(false)
  const menuName = menuDetails?.name
  let breadcrumbs: any = [
    { title: 'Locations', path: '/app/locations' },
    { title: locationName, path: `/app/locations/${locationId}/analytics` },
    { title: 'Menus', path: `/app/locations/${locationId}/menus` },
    {
      title: menuName,
      path: isMenuV2
        ? `/app/locations/${locationId}/menus/${menuId}`
        : `/app/locations/${locationId}/menu/${menuId}/items`,
    },
  ]

  useEffect(() => {
    catalogProducts?.data &&
      fetchItemData(
        catalogProducts?.data?.pages.map((page: any) => page.data).flat()
      )
    if (itemData) {
      setMenuItemState({
        ...itemData,
        price: itemData.price / 100,
        fulfillmentTypes: itemData?.fulfillmentTypes?.length
          ? itemData.fulfillmentTypes
          : menuDetails?.fulfillmentTypes,
        serviceAvailability: itemData?.serviceAvailability?.length
          ? itemData.serviceAvailability
          : menuDetails?.serviceAvailability,
        isBikeFriendly: itemData?.isBikeFriendly || true,
        ...(itemData?.inStorePrice
          ? { inStorePrice: itemData.inStorePrice / 100 }
          : {}),
      })
    }
  }, [itemData, menuData.data?.data])

  const handleNavigation = () => {
    isMenuV2
      ? navigate(`/app/locations/${locationId}/menus/${menuId}`)
      : navigate(`/app/locations/${locationId}/menu/${menuId}/items`)
  }
  const areArraysEqual = (arr1: string[], arr2: string[]) => {
    // Check if both arrays exist and have the same length
    if (!arr1 || !arr2 || arr1.length !== arr2.length) return false

    // Sort both arrays to ensure they can be compared
    const sortedArr1 = [...arr1].sort()
    const sortedArr2 = [...arr2].sort()

    // Compare each element of the sorted arrays
    return sortedArr1.every((value, index) => value === sortedArr2[index])
  }
  const areTimePeriodsEqual = (arr1: any[], arr2: any[]) => {
    // Check if both arrays exist and have the same length
    if (!arr1 || !arr2 || arr1.length !== arr2.length) return false

    // Sort both arrays to ensure they can be compared
    const sortedArr1 = [...arr1].sort((a, b) =>
      a.startTime.localeCompare(b.startTime)
    )
    const sortedArr2 = [...arr2].sort((a, b) =>
      a.startTime.localeCompare(b.startTime)
    )

    // Compare each element of the sorted arrays
    return sortedArr1.every((item, index) => {
      const correspondingItem = sortedArr2[index]
      return (
        item.startTime === correspondingItem.startTime &&
        item.endTime === correspondingItem.endTime
      )
    })
  }

  const areServiceAvailabilityEqual = (arr1: any[], arr2: any[]) => {
    // Check if both arrays exist and have the same length
    if (!arr1 || !arr2 || arr1.length !== arr2.length) return false

    // Sort both arrays by weekday
    const sortedArr1 = [...arr1].sort((a, b) =>
      a.weekday.localeCompare(b.weekday)
    )
    const sortedArr2 = [...arr2].sort((a, b) =>
      a.weekday.localeCompare(b.weekday)
    )

    // Compare each item in the sorted arrays
    return sortedArr1.every((item, index) => {
      const correspondingItem = sortedArr2[index]

      // Check if the weekdays are the same
      if (item.weekday !== correspondingItem.weekday) return false

      // Check if the timePeriods are equal using the helper function
      return areTimePeriodsEqual(
        item.timePeriods,
        correspondingItem.timePeriods
      )
    })
  }

  const handleSubmitChange = (data: any) => {
    const updatedState = { ...data }
    // Handle price conversion with rounding
    if (data.price) {
      updatedState.price = Math.round(parseFloat(data.price) * 100)
    }

    if (data.inStorePrice) {
      updatedState.inStorePrice = Math.round(
        parseFloat(data.inStorePrice) * 100
      )
    }
    // Handle fulfillment types update
    if (data.fulfillmentTypes) {
      const isEqual = areArraysEqual(
        data.fulfillmentTypes,
        menuDetails?.fulfillmentTypes || []
      )
      updatedState.fulfillmentTypes = isEqual ? [] : data.fulfillmentTypes
    }

    // Handle service availability update
    if (data.serviceAvailability) {
      const serviceAvailabilityEqual = areServiceAvailabilityEqual(
        data.serviceAvailability,
        menuDetails?.serviceAvailability || []
      )
      updatedState.serviceAvailability = serviceAvailabilityEqual
        ? []
        : data.serviceAvailability
    }
    // Perform the mutation
    itemUpdate.mutate(updatedState, {
      onSuccess: () => {
        toast.success('Item Updated')
        void handleNavigation()
      },
    })
  }

  const handleModifierGroupChange = (data: any) => {
    const { id: modifierGroupId, ...modifierGroup } = data // Extract modifierGroupId from data
    const foundModifierGroup = modifierGroupOptions?.find(
      (group: any) => group.id === modifierGroupId
    )
    const isNameChanged = modifierGroup.name !== foundModifierGroup.name
    if (itemData?.selections?.length) {
      const updatedSelections = itemData.selections?.map((selection: any) => {
        if (selection.menuModifierGroupId === modifierGroupId) {
          return {
            ...selection,
            minPermitted: modifierGroup.minPermitted,
            maxPermitted: modifierGroup.maxPermitted,
          }
        }
        return selection
      })
      itemUpdate.mutate(
        { selections: [...updatedSelections] },
        {
          onSuccess: (data: any) => {
            void itemById.refetch()
            setMenuItemState({
              ...data.data,
              price: data.data.price / 100,
              fulfillmentTypes: data.data?.fulfillmentTypes?.length
                ? data.data.fulfillmentTypes
                : menuDetails?.fulfillmentTypes,
              serviceAvailability: data.data?.serviceAvailability?.length
                ? data.data.serviceAvailability
                : menuDetails?.serviceAvailability,
              isBikeFriendly: data.data?.isBikeFriendly || true,
              inStorePrice: data.data?.inStorePrice! / 100,
            })
          },
        }
      )
      if (isNameChanged) {
        modiferGroupUpdateMutation.mutate({
          modifierGroupId,
          modifierGroup: {
            name: modifierGroup.name,
          },
        })
      }
    } else {
      modiferGroupUpdateMutation.mutate({
        modifierGroupId,
        modifierGroup: {
          name: modifierGroup.name,
          minPermitted: modifierGroup.minPermitted,
          maxPermitted: modifierGroup.maxPermitted,
        },
      })
    }
  }

  return (
    <PageContainer title={'Items'} breadcrumbs={breadcrumbs}>
      <Box sx={{ p: 3 }}>
        <ItemEditForm
          menuItemState={menuItemState}
          categoriesOptions={categoriesOptions}
          modifierGroupOptions={modifierGroupOptions}
          itemSpecificAvailability={itemSpecificAvailability}
          setItemSpecificAvailability={setItemSpecificAvailability}
          handleSave={handleSubmitChange}
          handleNavigation={handleNavigation}
          handleModifierGroupChangeMutation={handleModifierGroupChange}
        />
      </Box>
    </PageContainer>
  )
}
