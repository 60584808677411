import { useMutation } from 'react-query'
import {
  ErrorResponse,
  GetAccountMenuByIdResponse,
  MenuRearrangeRequest,
} from '../types'
import { useAuthStore } from './useAuthStore'
import { useAccessToken } from './useAccessToken'
import { api } from '../services/api'
import { toast } from 'react-toastify'

export const useMenuRearrange = (
  locationId: string,
  menuId: string,
  options?: any
) => {
  const [accountId] = useAuthStore((t) => [t.accountId])
  const token = useAccessToken()

  return useMutation<
    GetAccountMenuByIdResponse,
    ErrorResponse,
    MenuRearrangeRequest
  >(
    (data: MenuRearrangeRequest) =>
      api
        .patch<GetAccountMenuByIdResponse>(
          `/v1/accounts/${accountId}/locations/${locationId}/menus/${menuId}/rearrange`,
          data,
          {
            headers: { Authorization: `Bearer ${token?.access_token}` },
          }
        )
        .then(({ data }) => data),
    {
      onSuccess: () => {},
      onError: (error) => {
        console.error('useMenuRearrange', error)
        toast.error('Failed to rearrange menu')
      },
      ...options,
    }
  )
}
