import * as React from 'react'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Card from '@mui/material/Card'
import MuiChip from '@mui/material/Chip'
import Container from '@mui/material/Container'
import Typography from '@mui/material/Typography'
import { styled } from '@mui/material/styles'

import dashboardImageLight from '../assets/dashboard_ss_light.png'
import dashboardImageDark from '../assets/dashboard_ss_dark.png'
import appsImageLight from '../assets/apps_ss_light.png'
import appsImageDark from '../assets/apps_ss_dark.png'
import ordersImageLight from '../assets/orders_ss_light.png'
import ordersImageDark from '../assets/orders_ss_dark.png'
import menuImageLight from '../assets/menu_ss_light.png'
import menuImageDark from '../assets/menu_ss_dark.png'

const items = [
  {
    title: 'Dashboard',
    description:
      'POS Dashboards offer a comprehensive view of your business, allowing you to track sales, inventory, and customer data in real-time.',
    imageLight: `url("${dashboardImageLight}")`,
    imageDark: `url("${dashboardImageDark}")`,
  },
  {
    title: 'App Store',
    description:
      'POS Hub provides an an application store with a variety of applications that can be installed to handle different tasks and operations in real time.',
    imageLight: `url("${appsImageLight}")`,
    imageDark: `url("${appsImageDark}")`,
  },
  {
    title: 'Order Aggregation',
    description:
      'Orders received from marketplaces are aggregated and injected directly into your POS system, allowing you manage the fulfilment process for all marketplaces from a single system.',
    imageLight: `url("${ordersImageLight}")`,
    imageDark: `url("${ordersImageDark}")`,
  },
  {
    title: 'Menu Management',
    description:
      'Products from your POS are continuesly syncronised to our platform, where you can build flexible menus that can be published to online marketplaces',
    imageLight: `url("${menuImageLight}")`,
    imageDark: `url("${menuImageDark}")`,
  },
]

interface ChipProps {
  selected?: boolean
}

const Chip = styled(MuiChip)<ChipProps>(({ theme }) => ({
  variants: [
    {
      props: ({ selected }: any) => selected,
      style: {
        background:
          'linear-gradient(to bottom right, hsl(210, 98%, 48%), hsl(210, 98%, 35%))',
        color: 'hsl(0, 0%, 100%)',
        borderColor: theme.palette.primary.light,
        '& .MuiChip-label': {
          color: 'hsl(0, 0%, 100%)',
        },
        ...(theme.palette.mode === 'dark' && {
          borderColor: theme.palette.primary.dark,
        }),
      },
    },
  ],
}))

interface MobileLayoutProps {
  selectedItemIndex: number
  handleItemClick: (index: number) => void
  selectedFeature: typeof items[0]
}

export function MobileLayout({
  selectedItemIndex,
  handleItemClick,
  selectedFeature,
}: MobileLayoutProps) {
  if (!items[selectedItemIndex]) {
    return null
  }

  return (
    <Box
      sx={{
        display: { xs: 'flex', sm: 'none' },
        flexDirection: 'column',
        gap: 2,
      }}
    >
      <Box sx={{ display: 'flex', gap: 2, overflow: 'auto' }}>
        {items.map(({ title }, index) => (
          <Chip
            size="medium"
            key={index}
            label={title}
            onClick={() => handleItemClick(index)}
            selected={selectedItemIndex === index}
          />
        ))}
      </Box>
      <Card variant="outlined">
        <Box
          sx={(theme) => ({
            mb: 2,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            minHeight: 280,
            backgroundImage: 'var(--items-imageLight)',
            ...(theme.palette.mode === 'dark' && {
              backgroundImage: 'var(--items-imageDark)',
            }),
          })}
          style={
            items[selectedItemIndex]
              ? ({
                  '--items-imageLight': items[selectedItemIndex].imageLight,
                  '--items-imageDark': items[selectedItemIndex].imageDark,
                } as any)
              : {}
          }
        />
        <Box sx={{ px: 2, pb: 2 }}>
          <Typography
            gutterBottom
            sx={{ color: 'text.primary', fontWeight: 'medium' }}
          >
            {selectedFeature.title}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary', mb: 1.5 }}>
            {selectedFeature.description}
          </Typography>
        </Box>
      </Card>
    </Box>
  )
}

export const FeaturesSection = () => {
  const [selectedItemIndex, setSelectedItemIndex] = React.useState(0)

  const handleItemClick = (index: number) => {
    setSelectedItemIndex(index)
  }

  const selectedFeature = items[selectedItemIndex]

  return (
    <Container id="features" sx={{ py: { xs: 8, sm: 16 } }} disableGutters>
      <Box sx={{ width: { sm: '100%', md: '100%', textAlign: 'center' } }}>
        <Typography
          component="h2"
          variant="h4"
          gutterBottom
          sx={{ color: 'text.primary' }}
        >
          Features
        </Typography>
        <Typography
          variant="body1"
          sx={{ color: 'text.secondary', mb: { xs: 2, sm: 4 } }}
        >
          POS Hub offers a variety of features tha will help you manage your
          business more effectively
        </Typography>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', md: 'row-reverse' },
          gap: 2,
        }}
      >
        <div>
          <Box
            sx={{
              display: { xs: 'none', sm: 'flex' },
              flexDirection: 'column',
              gap: 2,
              height: '100%',
            }}
          >
            {items.map(({ title, description }, index) => (
              <Box
                key={index}
                component={Button}
                onClick={() => handleItemClick(index)}
                sx={[
                  { p: 2, height: '100%', width: '100%' },
                  selectedItemIndex === index && {
                    backgroundColor: 'action.hover',
                  },
                ]}
              >
                <Box
                  sx={[
                    {
                      width: '100%',
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'left',
                      gap: 1,
                      textAlign: 'left',
                      textTransform: 'none',
                      color: 'text.secondary',
                    },
                    selectedItemIndex === index && {
                      color: 'text.primary',
                    },
                  ]}
                >
                  <Typography variant="h6">{title}</Typography>
                  <Typography variant="body2">{description}</Typography>
                </Box>
              </Box>
            ))}
          </Box>
          <MobileLayout
            selectedItemIndex={selectedItemIndex}
            handleItemClick={handleItemClick}
            selectedFeature={selectedFeature}
          />
        </div>
        {/* Image Area */}
        <Box
          sx={{
            display: { xs: 'none', sm: 'flex' },
            width: { xs: '100%', md: '70%' },
            height: 'var(--items-image-height)',
          }}
        >
          <Card
            variant="outlined"
            sx={{
              height: '100%',
              width: '100%',
              display: { xs: 'none', sm: 'flex' },
              pointerEvents: 'none',
            }}
          >
            <Box
              sx={(theme) => ({
                m: '0px',
                width: '100%',
                height: 500,
                backgroundSize: 'cover',
                backgroundImage: 'var(--items-imageLight)',
                ...(theme.palette.mode === 'dark' && {
                  backgroundImage: 'var(--items-imageDark)',
                }),
              })}
              style={
                items[selectedItemIndex]
                  ? ({
                      '--items-imageLight': items[selectedItemIndex].imageLight,
                      '--items-imageDark': items[selectedItemIndex].imageDark,
                    } as any)
                  : {}
              }
            />
          </Card>
        </Box>
      </Box>
    </Container>
  )
}
