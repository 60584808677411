import {
  Box,
  CardContent,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  useMediaQuery,
} from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { FC, useState } from 'react'
import { AppLoader } from '../AppLoader'
import { useIntl } from 'react-intl'
import { DeleteOutline, EditOutlined } from '@mui/icons-material'
import { ClickableIcon } from '../ClickableIcon'
import { CreateButtonFlexEnd } from '../CreateButtonFlexEnd'
import { UseInfiniteQueryResult } from 'react-query/types'

export interface Column {
  id: string
  label: string
  minWidth?: number
  align?: 'right'
  padding?: 'none' | 'normal' | 'checkbox'
  component?: (value: any) => JSX.Element
}

export interface GenericTableProps {
  query: any
  columns: readonly Column[]
  noDataMessage: string
  enableDelete: boolean
  handleDelete?: (account: any) => void
  tableType: string
  filters?: any
}
export const GenericTable: FC<{
  query: UseInfiniteQueryResult<any, any>
  columns: readonly Column[]
  noDataMessage: string
  enableDelete: boolean
  handleDelete?: (account: any) => void
  tableType: string
  filters?: any
}> = (props: GenericTableProps) => {
  const {
    query,
    columns,
    noDataMessage,
    enableDelete,
    handleDelete,
    tableType,
    filters,
  } = props
  const intl = useIntl()
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(25)
  const navigate = useNavigate()
  const isTablet = useMediaQuery((theme: any) => theme.breakpoints.up('md'))
  const records = query.data?.pages.map((value: any) => value.data).flat()
  // Locale Messages
  const labelorderDetails = intl.formatMessage({ id: 'label_order_details' })
  const actionLoadMore = intl.formatMessage({ id: 'action_load_more' })

  if (query.isLoading) {
    return <AppLoader />
  }

  if (query.isError) {
    return (
      <Typography component="span" id="id_error_try_again">
        {intl.formatMessage({ id: 'error_try_again' })}
      </Typography>
    )
  }

  const onPagePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    targetPage: number
  ) => {
    // If the page is not in the cache, fetch it
    if (!query.data?.pages[targetPage]) {
      void query.fetchNextPage().then(() => {
        setPage(targetPage)
      })
    } else {
      setPage(targetPage)
    }
  }

  const totalCount =
    query.data?.pages.reduce(
      (acc: number, page: any) => acc + page.data.length,
      0
    ) ?? 0
  const lastPageidx = (query.data?.pages.length ?? 1) - 1
  const hasNextPage = query.data?.pages[lastPageidx]?.hasNextPage ?? false

  return (
    <>
      <CreateButtonFlexEnd
        handleClick={() => navigate(`new`)}
        buttonType={tableType}
      />
      {query?.isSuccess && records?.length ? (
        <TableContainer component={Paper}>
          <Grid container spacing={2}>
            {!isTablet ? (
              <>
                {records?.map((row: any) => (
                  <Grid item xs={12} key={row.id}>
                    <Paper>
                      <CardContent>
                        <TableContainer>
                          <Table aria-label={labelorderDetails} size="small">
                            <TableBody>
                              {columns.map((column) => {
                                const value = row[column.id]
                                return (
                                  <TableRow
                                    key={column.id}
                                    onClick={() =>
                                      navigate(
                                        `${row.id}?query=${
                                          filters?.query ? filters.query : ''
                                        }`
                                      )
                                    }
                                  >
                                    <TableCell
                                      scope="row"
                                      style={{ fontWeight: 'bold' }}
                                    >
                                      {column.label}
                                    </TableCell>
                                    <TableCell align="left">
                                      {column.component
                                        ? column.component(row)
                                        : value}
                                    </TableCell>
                                  </TableRow>
                                )
                              })}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </CardContent>
                    </Paper>
                  </Grid>
                ))}
                {query.hasNextPage && (
                  <Box display="flex" justifyContent="center" my={2}>
                    <TablePagination
                      component={'td'}
                      colSpan={8}
                      page={page}
                      rowsPerPage={rowsPerPage}
                      count={
                        hasNextPage ? totalCount + rowsPerPage : totalCount
                      }
                      rowsPerPageOptions={[25]}
                      onPageChange={onPagePage}
                      onRowsPerPageChange={(event) =>
                        setRowsPerPage(+event.target.value)
                      }
                    />
                  </Box>
                )}
              </>
            ) : (
              <Grid item xs={12}>
                <Paper>
                  <TableContainer sx={{ maxHeight: 625, overflow: 'auto' }}>
                    <Table stickyHeader aria-label={labelorderDetails}>
                      <TableHead>
                        <TableRow>
                          {columns.map((column, index) => (
                            <TableCell
                              key={index}
                              align={column.align}
                              padding={column.padding}
                              style={{
                                minWidth: column.minWidth,
                                fontWeight: 'bold',
                              }}
                            >
                              {column.label}
                            </TableCell>
                          ))}
                          <TableCell>Action</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {records?.map((row: any) => (
                          <TableRow
                            key={row.id}
                            hover
                            sx={{
                              cursor: 'pointer',
                              '&:hover': { backgroundColor: '#f5f5f5' },
                            }}
                            onClick={() =>
                              tableType === 'account'
                                ? navigate(
                                    `${row.id}?query=${
                                      filters?.query ? filters.query : ''
                                    }`
                                  )
                                : // : tableType === 'location'
                                  // ? navigate(`${row.id}/apps?query=${filters?.query} ? filters.query : ''`)
                                  navigate(
                                    `${row.id}/edit?query=${
                                      filters?.query ? filters.query : ''
                                    }`
                                  )
                            }
                          >
                            {columns.map((column) => {
                              const value = row[column.id]
                              return (
                                <TableCell key={column.id} align={column.align}>
                                  {column.component
                                    ? column.component(row)
                                    : value}
                                </TableCell>
                              )
                            })}
                            <TableCell onClick={(e) => e.stopPropagation()}>
                              <Box sx={{ display: 'flex' }}>
                                <ClickableIcon
                                  handleNavigate={() =>
                                    tableType === 'account'
                                      ? navigate(
                                          `${row.id}/edit?from=account&query=${
                                            filters?.query ? filters.query : ''
                                          }`
                                        )
                                      : navigate(
                                          `${row.id}/edit?query=${
                                            filters?.query ? filters.query : ''
                                          }`
                                        )
                                  }
                                  children={<EditOutlined />}
                                />
                                {enableDelete ? (
                                  <ClickableIcon
                                    handleNavigate={() =>
                                      handleDelete && handleDelete(row)
                                    }
                                    children={<DeleteOutline />}
                                  />
                                ) : null}
                              </Box>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                      <TableFooter>
                        {query.hasNextPage && (
                          <TablePagination
                            component={'td'}
                            colSpan={8}
                            page={page}
                            rowsPerPage={rowsPerPage}
                            count={
                              hasNextPage
                                ? totalCount + rowsPerPage
                                : totalCount
                            }
                            rowsPerPageOptions={[25]}
                            onPageChange={onPagePage}
                            onRowsPerPageChange={(event) =>
                              setRowsPerPage(+event.target.value)
                            }
                          />
                        )}
                      </TableFooter>
                    </Table>
                  </TableContainer>
                </Paper>
              </Grid>
            )}
          </Grid>
        </TableContainer>
      ) : (
        <Paper sx={{ marginTop: 5 }}>
          <CardContent>
            <Typography
              component="span"
              align="center"
              style={{ marginTop: 10 }}
              id="id_no_data_message"
            >
              {noDataMessage}
            </Typography>
          </CardContent>
        </Paper>
      )}
    </>
  )
}
