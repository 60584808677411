import {
  UseQueryOptions,
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
} from 'react-query'
import { api } from '../services/api'
import { useAuthStore } from './useAuthStore'
import { useAccessToken } from './useAccessToken'
import { signalToCancellationToken } from './utils'
import {
  ErrorResponse,
  GetAccountMenuItemByIdResponse,
  ListAccountMenuItemsResponse,
  MenuItemCreateEntity,
  MenuItemPatchEntity,
} from '../types'
import { toast } from 'react-toastify'

export interface UseItemByIdProps {
  refetchOnMount?: boolean | 'always'
}

export interface UseItemByIdProps<
  TData = GetAccountMenuItemByIdResponse,
  TError = ErrorResponse
> extends UseQueryOptions<TData, TError> {}

export const useItems = (locationId: string, menuId: string, options?: any) => {
  const accountId = useAuthStore((t) => t.accountId)
  const token = useAccessToken()

  return useInfiniteQuery<ListAccountMenuItemsResponse>(
    ['accounts', accountId, 'locations', locationId, 'menu', menuId, 'items'],
    ({ pageParam }) =>
      api
        .get(
          `/v1/accounts/${accountId}/locations/${locationId}/menus/${menuId}/items`,
          {
            params: {
              limit: 100,
              nextPageKey: pageParam,
            },
            headers: { Authorization: `Bearer ${token?.access_token}` },
            cancelToken: signalToCancellationToken()?.token,
          }
        )
        .then(({ data }) => data),
    {
      getNextPageParam: (lastPage: any) => lastPage.nextPageKey,
      keepPreviousData: true,
      enabled: !!token?.access_token && !!accountId && !!locationId && !!menuId,
      ...options,
    }
  )
}

export const useMenuItems = (
  locationId: string,
  menuId: string,
  options?: any
) => {
  const accountId = useAuthStore((t) => t.accountId)
  const token = useAccessToken()

  return useInfiniteQuery<ListAccountMenuItemsResponse, ErrorResponse>(
    ['accounts', accountId, 'locations', locationId, 'menu', menuId, 'items'],
    ({ pageParam }) =>
      api
        .get(
          `/v1/accounts/${accountId}/locations/${locationId}/menus/${menuId}/items`,
          {
            params: { limit: 100, nextPageKey: pageParam },
            headers: { Authorization: `Bearer ${token?.access_token}` },
            cancelToken: signalToCancellationToken()?.token,
          }
        )
        .then(({ data }) => data),
    {
      getNextPageParam: (lastPage: any) => lastPage.nextPageKey,
      keepPreviousData: true,
      staleTime: 3600 * 60,
      enabled: !!token?.access_token && !!accountId && !!locationId && !!menuId,
      ...options,
    }
  )
}

export const useItemCreate = (locationId: any, menuId: any) => {
  const queryClient = useQueryClient()
  const [accountId] = useAuthStore((t) => [t.accountId])
  const token = useAccessToken()

  return useMutation<
    GetAccountMenuItemByIdResponse,
    ErrorResponse,
    MenuItemCreateEntity
  >(
    (item) =>
      api
        .post<GetAccountMenuItemByIdResponse>(
          `/v1/accounts/${accountId}/locations/${locationId}/menus/${menuId}/items`,
          item,
          {
            headers: { Authorization: `Bearer ${token?.access_token}` },
          }
        )
        .then(({ data }) => data),
    {
      onSuccess: () => {
        void queryClient.refetchQueries([
          'accounts',
          accountId,
          'locations',
          locationId,
          'menu',
          menuId,
          'items',
        ])
      },
      onError: (error: any) => {
        if (error.response && error.response.data) {
          error.response.data.error
            ? toast.error(JSON.stringify(error.response.data.error))
            : toast.error(JSON.stringify(error.response.data.data))
        } else {
          toast.error(JSON.stringify(error.message))
        }
      },
    }
  )
}

export const useItemUpdate = (
  locationId: string,
  menuId: string,
  itemId: string
) => {
  const [accountId] = useAuthStore((t) => [t.accountId])
  const token = useAccessToken()
  const queryClient = useQueryClient()

  return useMutation<
    GetAccountMenuItemByIdResponse,
    ErrorResponse,
    MenuItemPatchEntity
  >(
    (item) =>
      api
        .patch<GetAccountMenuItemByIdResponse>(
          `/v1/accounts/${accountId}/locations/${locationId}/menus/${menuId}/items/${itemId}`,
          item,
          {
            headers: { Authorization: `Bearer ${token?.access_token}` },
          }
        )
        .then(({ data }) => data),
    {
      onSuccess: () => {
        void queryClient.refetchQueries([
          'accounts',
          accountId,
          'locations',
          locationId,
          'menu',
          menuId,
          'items',
        ])
      },
      onError: (error: any) => {
        if (error?.response && error.response.data) {
          error.response.data.error
            ? toast.error(JSON.stringify(error.response.data.error))
            : toast.error(JSON.stringify(error.response.data.data))
        } else {
          toast.error(JSON.stringify(error.message))
        }
      },
    }
  )
}

interface useItemsUpdateWhileUpdateMenuProps {
  itemData: MenuItemPatchEntity
  itemId: string
}
export const useItemsUpdateWhileUpdateMenu = (
  locationId: string,
  menuId: string
) => {
  const queryClient = useQueryClient()
  const [accountId] = useAuthStore((t) => [t.accountId])
  const token = useAccessToken()

  return useMutation<
    GetAccountMenuItemByIdResponse,
    ErrorResponse,
    useItemsUpdateWhileUpdateMenuProps
  >(
    (options: any) =>
      api
        .patch<GetAccountMenuItemByIdResponse>(
          `/v1/accounts/${accountId}/locations/${locationId}/menus/${menuId}/items/${options.itemId}`,
          {
            ...options.itemData,
          },
          {
            headers: { Authorization: `Bearer ${token?.access_token}` },
          }
        )
        .then(({ data }) => data),
    {
      onSuccess: () => {
        void queryClient.refetchQueries([
          'accounts',
          accountId,
          'locations',
          locationId,
          'menu',
          menuId,
          'items',
        ])
      },
      onError: (error: any) => {
        if (error?.response && error.response.data) {
          error.response.data.error
            ? toast.error(JSON.stringify(error.response.data.error))
            : toast.error(JSON.stringify(error.response.data.data))
        } else {
          toast.error(JSON.stringify(error.message))
        }
      },
    }
  )
}

export const useItemById = (
  locationId?: string,
  menuId?: string,
  itemId?: string,
  props?: UseItemByIdProps
) => {
  const [accountId] = useAuthStore((t) => [t.accountId])
  const token = useAccessToken()
  return useQuery<GetAccountMenuItemByIdResponse, ErrorResponse>(
    [
      'accounts',
      accountId,
      'locations',
      locationId,
      'menus',
      menuId,
      'items',
      itemId,
    ],
    ({ signal }) =>
      api
        .get(
          `/v1/accounts/${accountId}/locations/${locationId}/menus/${menuId}/items/${itemId}`,
          {
            headers: { Authorization: `Bearer ${token?.access_token}` },
            cancelToken: signalToCancellationToken(signal)?.token,
          }
        )
        .then(({ data }) => data),
    {
      staleTime: 3600 * 60,
      refetchOnMount: 'always',
      enabled: !!token?.access_token && !!accountId && !!menuId && !!itemId,
      ...props,
    }
  )
}

export const useItemDelete = (locationId: string, menuId: string) => {
  const [accountId] = useAuthStore((t) => [t.accountId])
  const token = useAccessToken()
  const menuItems = useMenuItems(locationId!, menuId!)

  return useMutation(
    (itemId: any) =>
      api
        .delete(
          `/v1/accounts/${accountId}/locations/${locationId}/menus/${menuId}/items/${itemId}`,
          {
            headers: { Authorization: `Bearer ${token?.access_token}` },
          }
        )
        .then(({ data }) => data),
    {
      onSuccess: () => {
        void menuItems.refetch()
        toast.success('Item Deleted')
      },
      onError: (error: any) => {
        if (error.response && error.response.data) {
          error.response.data.error
            ? toast.error(JSON.stringify(error.response.data.error))
            : toast.error(JSON.stringify(error.response.data.data))
        } else {
          toast.error(JSON.stringify(error.message))
        }
      },
    }
  )
}
