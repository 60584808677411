import {
  Box,
  Grid,
  Typography,
  Autocomplete,
  FormControlLabel,
  Checkbox,
  TextField,
  Button,
} from '@mui/material'
import { CommonTextField } from '../CommonTextField'
import { useIntl } from 'react-intl'
import { ChangeEvent, useEffect, useState } from 'react'
import { RenderListView } from '../../pages/MenuV2/dialogs/ListView'
import { useNavigate, useParams } from 'react-router-dom'
import { validationCheckUpdateforModifierGroup } from '../../pages/Locations/CardSelect/Menu/utils'
import MinMaxField from '../MinMaxFields'

export const ModifierGroupEditForm = (props: any) => {
  const {
    modifierGroupState,
    modifierOptions,
    handleModifierGroupUpdate,
    handleNavigation,
    catalogProductData,
    handleModifierChangeMutation,
    handleChangeSequence,
  } = props

  const intl = useIntl()
  const { itemId, modifierGroupId } = useParams()
  const [updatedState, setUpdatedState] = useState<any>({})
  const navigate = useNavigate()
  const [valid, setValid] = useState(false)
  const [modifiersListState, setModifiersListState] = useState<any[]>(
    modifierOptions || []
  )
  const [hasSequenceChanged, setHasSequenceChanged] = useState<boolean>(false)
  const [userModifiedModifiers, setUserModifiedModifiers] =
    useState<boolean>(false)
  useEffect(() => {
    if (userModifiedModifiers || hasSequenceChanged) {
      return
    } else if (Array.isArray(modifierOptions)) {
      if (modifierGroupState?.selections?.length) {
        const filteredValues = modifierOptions
          .filter((option: any) => {
            const isInUpdatedStateModifiers = updatedState?.modifiers?.includes(
              option.id
            )
            const isInSelections = (list: any[], key: string) =>
              list?.some((modifierId: any) => modifierId === option.id)

            // Check if the option is selected using `updatedState` or `modifierGroupState`
            const isSelected =
              isInUpdatedStateModifiers ||
              isInSelections(
                modifierGroupState.selections
                  ?.find(
                    (group: any) =>
                      modifierGroupId === group.menuModifierGroupId
                  )
                  ?.modifiers?.map(
                    (modifier: any) => modifier.menuModifierId
                  ) || [],
                'id'
              )
            return isSelected
          })
          .map((option: any) => {
            // Find the corresponding modifier in `modifierGroupState.selections`
            const matchingModifier = modifierGroupState.selections
              ?.find(
                (group: any) => modifierGroupId === group.menuModifierGroupId
              )
              ?.modifiers?.find(
                (modifier: any) => modifier.menuModifierId === option.id
              )

            // If `matchingModifier` is found, update properties
            if (matchingModifier) {
              return {
                ...option,
                minPermitted:
                  matchingModifier?.minPermitted ?? option.minPermitted,
                maxPermitted:
                  matchingModifier?.maxPermitted ?? option.maxPermitted,
                price: matchingModifier?.price / 100 || option.price,
                showOnline: matchingModifier?.showOnline ?? option.showOnline,
              }
            }

            // Return the original `option` if no match is found
            return option
          })
        // Check for nested modifiers and set the `isNested` flag
        const filteredValuesWithNested = filteredValues.map((modifier: any) => {
          const isNested = modifierGroupState.selections
            ?.find(
              (group: any) => modifierGroupId === group.menuModifierGroupId
            )
            ?.modifiers?.find(
              (group: any) => group.menuModifierId === modifier.id
            )?.selections?.length
            ? true
            : false
          return { ...modifier, isNested }
        })
        setModifiersListState(filteredValuesWithNested) // Set the filtered values in state
      } else {
        // Handle the case where there are no selections in `modifierGroupState`
        const filteredValues = modifierOptions.filter((option: any) => {
          const isSelected =
            updatedState?.modifiers?.includes(option.id) ||
            modifierGroupState?.modifiers?.includes(option.id) ||
            false
          return isSelected
        })
        setModifiersListState(filteredValues)
      }
    }
  }, [modifierGroupState, modifierOptions])

  function validationCheckUpdate(object: any) {
    const validationResults = validationCheckUpdateforModifierGroup(object)
    const validMinAndMax =
      object?.maxPermitted === 0 ||
      (object?.minPermitted || 0) <= (object?.maxPermitted || 0)
    // Check if all the validations pass
    const isValid =
      Object.values(validationResults).every((value) => value) && validMinAndMax
    setValid(isValid)
  }
  const handleMinMaxChange = (data: any) => {
    if (catalogProductData?.selections?.length > 0) {
      const updatedCatalogProductData = { ...catalogProductData }
      updatedCatalogProductData.selections = catalogProductData.selections.map(
        (selection: any) => {
          if (selection.menuModifierGroupId === modifierGroupId) {
            selection.minPermitted = data.minPermitted
            selection.maxPermitted = data.maxPermitted
          }
          return selection
        }
      )
      updatedState.selections = updatedCatalogProductData.selections
    } else {
      updatedState.minPermitted = data.minPermitted
      updatedState.maxPermitted = data.maxPermitted
    }
    // Apply validation and update the main state
    validateAndSet(updatedState)
  }
  function validateAndSet(accountData: any) {
    var newObject = JSON.parse(JSON.stringify(accountData))
    setUpdatedState({ ...updatedState, ...newObject })
    validationCheckUpdate({ ...updatedState, ...newObject })
  }
  const handleNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    updatedState.name = event.target.value
    validateAndSet(updatedState)
  }
  const handleDescriptionChange = (event: ChangeEvent<HTMLInputElement>) => {
    updatedState.description = event.target.value
    validateAndSet(updatedState)
  }

  const handleModifierChange = (_: any, newValues: any) => {
    // Update the local state of selected modifier groups
    setModifiersListState(newValues)
    updatedState.modifiers = newValues?.map((newValue: any) => newValue.id)
    if (catalogProductData?.selections?.length > 0) {
      const updatedCatalogProductData = { ...catalogProductData }
      updatedCatalogProductData.selections = catalogProductData.selections.map(
        (selection: any) => {
          // Filter modifiers based on newValues
          const updatedModifiers = selection.modifiers.filter((modifier: any) =>
            newValues.find((value: any) => value.id === modifier.menuModifierId)
          )

          // Return updated modifier group with filtered modifiers
          return {
            ...selection,
            modifiers: updatedModifiers,
          }
        }
      )
      updatedState.selections = updatedCatalogProductData.selections
    }

    // Apply validation and update the main state
    validateAndSet(updatedState)
  }

  const handleModifierNavigation = (id: string) => {
    const currentUrl = location.pathname // Get the current URL path
    let newUrl = `${currentUrl}/modifier/${id}`
    // Use navigate to change the URL
    navigate(newUrl)
  }
  const handleSequenceChangeMiddleware = (result: any) => {
    setHasSequenceChanged(true)

    if (result?.modifiers) {
      setModifiersListState((prevState: any) =>
        result.modifiers.map((modifier: any) => {
          const updatedModifier = prevState.find(
            (updatedModifier: any) => updatedModifier.id === modifier
          )
          return updatedModifier || modifier
        })
      )
    }
    handleChangeSequence(result)
      .then(() => setHasSequenceChanged(false))
      .catch((error: any) => console.error('Sequence update failed:', error))
  }
  return (
    <>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Typography variant="h6" id="id_modifier_group_edit_form">
          {modifierGroupId
            ? `${intl.formatMessage({ id: 'action_update' })}`
            : `${intl.formatMessage({ id: 'action_create' })}`}{' '}
          {intl.formatMessage({ id: 'label_modifier_group' })}
        </Typography>
        <Box>
          <Button
            onClick={() => handleNavigation()}
            sx={{ mr: 1 }}
            id="id_cancel_btn"
          >
            {intl.formatMessage({ id: 'action_cancel' })}
          </Button>
          <Button
            onClick={() => handleModifierGroupUpdate(updatedState)}
            disabled={!valid}
            color="success"
            id="id_save_btn"
          >
            {modifierGroupId
              ? `${intl.formatMessage({ id: 'action_update' })}`
              : `${intl.formatMessage({ id: 'action_create' })}`}
          </Button>
        </Box>
      </Box>
      <Box sx={{ mt: 2 }}>
        <Grid container spacing={2} sx={{ marginTop: 1 }}>
          <Grid item xs={12} sm={6}>
            <CommonTextField
              id="outlined-basic"
              label={`${intl.formatMessage({
                id: 'label_name',
              })}${' *'}`}
              onChange={handleNameChange}
              value={updatedState?.name ?? modifierGroupState?.name}
              autoFocus={true}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Autocomplete
              multiple
              size="small"
              limitTags={2}
              disableCloseOnSelect
              options={modifierOptions ? modifierOptions : []}
              value={modifiersListState}
              onChange={handleModifierChange}
              isOptionEqualToValue={(option, value) => option.id === value.id} // Compare by unique property
              getOptionLabel={(option) => option.name}
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <FormControlLabel
                    control={<Checkbox checked={selected} />}
                    label={option.name}
                  />
                </li>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={`${intl.formatMessage({
                    id: 'label_modifiers',
                  })}`}
                  variant="outlined"
                />
              )}
            />
          </Grid>
          <MinMaxField
            size={{ xs: 12, sm: 6 }}
            minValue={
              updatedState?.minPermitted ??
              (modifierGroupState?.minPermitted || 0)
            }
            maxValue={
              updatedState?.maxPermitted ??
              (modifierGroupState?.maxPermitted || 0)
            }
            onChange={handleMinMaxChange}
          />
          <Grid item xs={12} sm={12}>
            <CommonTextField
              id="outlined-multiline-static"
              label={intl.formatMessage({
                id: 'label_description',
              })}
              onChange={handleDescriptionChange}
              value={
                updatedState?.description ?? modifierGroupState?.description
              }
              rows={2}
              multiline
            />
          </Grid>
          <RenderModifiers
            updatedState={updatedState}
            modifierGroupState={modifierGroupState}
            modifierOptions={modifierOptions}
            itemId={itemId}
            modifiersListState={modifiersListState}
            setModifiersListState={setModifiersListState}
            handleModifierNavigation={handleModifierNavigation}
            handleModifierChangeMutation={handleModifierChangeMutation}
            setUserModifiedModifiers={setUserModifiedModifiers}
            handleChangeSequence={handleSequenceChangeMiddleware}
            hasSequenceChanged={hasSequenceChanged}
            setHasSequenceChanged={setHasSequenceChanged}
          />
        </Grid>
      </Box>
    </>
  )
}
const RenderModifiers = (props: any) => {
  const intl = useIntl()
  const {
    updatedState,
    modifierGroupState,
    modifierOptions,
    itemId,
    modifiersListState,
    handleModifierNavigation,
    handleModifierChangeMutation,
    setModifiersListState,
    setUserModifiedModifiers,
    handleChangeSequence,
    hasSequenceChanged,
    setHasSequenceChanged,
  } = props
  if (
    !itemId ||
    !(
      updatedState?.modifiers?.length ||
      modifierGroupState?.modifiers?.length ||
      modifierGroupState?.selections?.length ||
      updatedState?.selections?.length
    ) ||
    !modifierOptions?.length
  ) {
    return null
  }
  const handleModifierNameChange = (value: any, id: any) => {
    setUserModifiedModifiers(true)
    const updatedState = modifiersListState.map((modifier: any) => {
      if (modifier.id === id) {
        return {
          ...modifier,
          name: value, // Update the name for the matching group
        }
      }
      return modifier // Return other groups unchanged
    })

    setModifiersListState(updatedState)
  }

  const handleMinMaxChange = (data: any, id: string) => {
    // Update the modifier group state
    const updatedState = modifiersListState.map((modifierGroup: any) => {
      if (modifierGroup.id === id) {
        const updatedModifierGroup = {
          ...modifierGroup,
          ...data, // Update the specific field (minPermitted or maxPermitted)
        }

        return updatedModifierGroup
      }
      return modifierGroup // Return other groups unchanged
    })
    setModifiersListState(updatedState)
  }
  const handleModifierPriceChange = (data: any, id: string) => {
    setModifiersListState((prevState: any) =>
      prevState.map(
        (modifier: any) =>
          modifier.id === id
            ? { ...modifier, price: data } // Update the price for the matching modifier
            : modifier // Keep other modifiers unchanged
      )
    )
  }

  const handleModifierShowOnlineChange = (value: boolean, id: string) => {
    setModifiersListState((prevState: any) =>
      prevState.map(
        (modifier: any) =>
          modifier.id === id
            ? { ...modifier, showOnline: value } // Update the showOnline for the matching modifier
            : modifier // Keep other modifiers unchanged
      )
    )
  }

  const handleModifierSaveIconClick = (id: any) => {
    setUserModifiedModifiers(true)
    const modifier = modifiersListState.find(
      (modifierGroup: any) => modifierGroup.id === id
    )
    if (modifier) {
      handleModifierChangeMutation(modifier)
    }
  }
  return (
    <RenderListView
      listOptions={modifiersListState.slice()}
      handleNavigation={handleModifierNavigation}
      title={intl.formatMessage({
        id: 'label_modifiers',
      })}
      handleNameChange={handleModifierNameChange}
      handleSaveIconClick={handleModifierSaveIconClick}
      handlePriceChange={handleModifierPriceChange}
      handleShowOnlineChange={handleModifierShowOnlineChange}
      handleMinMaxChange={handleMinMaxChange}
      handleChangeSequence={handleChangeSequence}
      hasSequenceChanged={hasSequenceChanged}
      setHasSequenceChanged={setHasSequenceChanged}
    />
  )
}
