import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import {
  getLocationQueryById,
  useItemById,
  useItemUpdate,
  useMenuById,
  useMenuModifierById,
  useMenuModifierGroupById,
  useMenuModifierGroups,
  useMenuModifiers,
  useMenuModifierUpdate,
} from '../../../hooks'
import { defaultMenuModifierState } from '../../Locations/CardSelect/Menu/utils'
import {
  MenuModifierCreateEntity,
  MenuModifierPatchEntity,
} from '../../../types'
import { ModifierEditForm } from '../../../components/ItemEditForm/ModifierEditForm'
import { Box } from '@mui/material'
import { updateSelections } from '../../util'
import { PageContainer } from '../../../components/Page/PageContainer'

export function MenuItemModifierGroupModifierEdit() {
  const isMenuV2 = window.location.pathname.includes('menus')
  const urlMenu = isMenuV2 ? 'menus' : 'menu'
  const urlItem = isMenuV2 ? 'items' : 'item'
  const navigate = useNavigate()
  const { locationId, menuId, itemId, modifierGroupId, modifierId } =
    useParams()
  const [modifierState, setMenuModifierState] = useState<
    MenuModifierCreateEntity | MenuModifierPatchEntity
  >(defaultMenuModifierState)
  const menuData = useMenuById(locationId!, menuId!, {
    refetchOnMount: 'always',
  })
  const setDataToTheState = (itemData: any, modifierData: any) => {
    if (itemData?.selections?.length) {
      const selection = itemData.selections.find(
        (group: any) => group.menuModifierGroupId === modifierGroupId
      )
      if (selection?.modifiers?.length) {
        const modifier = selection.modifiers.find(
          (mod: any) => mod.menuModifierId === modifierId
        )
        if (modifier) {
          setMenuModifierState({
            ...modifierData,
            ...(modifier?.maxPermitted
              ? { maxPermitted: modifier.maxPermitted }
              : {}),
            ...(modifier?.minPermitted
              ? { minPermitted: modifier.minPermitted }
              : {}),
            ...(modifier?.price ? { price: modifier.price / 100 } : {}),
            ...(modifier?.inStorePrice
              ? { inStorePrice: modifier.inStorePrice / 100 }
              : {}),
            ...(modifier?.showOnline !== undefined
              ? { showOnline: modifier.showOnline }
              : {}),
          })
        }
      }
    } else {
      setMenuModifierState(modifierData)
    }
  }
  const itemData = useItemById(locationId!, menuId!, itemId!, {
    refetchOnMount: 'always',
    onSuccess: (data) => {
      setDataToTheState(data?.data, modifierState)
    },
  })
  const modifiersList = useMenuModifiers(locationId!, menuId!, {
    onSuccess(data: any) {
      const d = data.pages.length
      if (data.pages[d - 1].hasNextPage) {
        void modifiersList.fetchNextPage()
      }
    },
  })
  const modifierGroupList = useMenuModifierGroups(locationId!, menuId!, {
    onSuccess(data: any) {
      const d = data.pages.length
      if (data.pages[d - 1].hasNextPage) {
        void modifierGroupList.fetchNextPage()
      }
    },
  })
  const modifiersListData = modifiersList.data?.pages
    .map((page: any) => page.data)
    .flat()
  const modifierGroupListData = modifierGroupList.data?.pages
    .map((page: any) => page.data)
    .flat()
  const itemUpdateMutation = useItemUpdate(locationId!, menuId!, itemId!)
  //  fetch Location data
  const locations = getLocationQueryById(locationId)
  const findModifierGroupFromSelections: any =
    itemData.data?.data?.selections?.find(
      (group: any) => modifierGroupId === group.menuModifierGroupId
    ) || {}
  const [isNestedModifiers, setIsNestedModifiers] = useState(false)
  useEffect(() => {
    const isNested =
      findModifierGroupFromSelections?.modifiers?.find(
        (mod: any) => mod.menuModifierId === modifierId
      )?.selections?.length > 0
    setIsNestedModifiers(isNested)
  }, [findModifierGroupFromSelections])

  const modifier = useMenuModifierById(locationId!, menuId!, modifierId!, {
    refetchOnMount: 'always',
    onSuccess: (data) => {
      if (data?.data) {
        let existingData = data.data
        existingData['price'] = data.data?.price! / 100
        setDataToTheState(itemData.data?.data, existingData)
      }
    },
  })
  const modifierUpdate = useMenuModifierUpdate(
    locationId!,
    menuId!,
    modifierId!
  )
  // Get the remaining modifier groups except the one we are updating
  const remainingModifierGroupsFromSelections =
    itemData.data?.data?.selections?.filter(
      (group: any) => modifierGroupId !== group.menuModifierGroupId
    ) || []
  const updatedSelections = updateSelections(
    itemData.data?.data?.selections!,
    modifiersListData!,
    modifierGroupListData!
  )
  const handleNavigation = () => {
    itemId && modifierGroupId
      ? navigate(
          `/app/locations/${locationId}/${urlMenu}/${menuId}/${urlItem}/${itemId}/modifier-groups/${modifierGroupId}`
        )
      : navigate(`/app/locations/${locationId}/menu/${menuId}/modifiers`)
  }
  const handleSubmitChange = async (data: any) => {
    const updatedState = { ...data }

    // Handle price conversion with rounding
    if (data.price) {
      updatedState.price = parseFloat((data.price * 100).toFixed(2))
    }

    if (data.inStorePrice) {
      updatedState.inStorePrice = parseFloat((data.price * 100).toFixed(2))
    }
    modifierUpdate.mutate(updatedState, {
      onSuccess: () => handleNavigation(),
    })
  }
  const handleSubmitNestedModifierChange = async (data: any) => {
    if (!(updatedSelections?.length > 0) && !data?.selections) {
      void handleSubmitChange(data)
    } else {
      let isNameChanged = false
      let isDescriptionChanged = false

      // Find and update the specific modifier by modifierId
      const updatedModifiers = findModifierGroupFromSelections?.modifiers?.map(
        (modifier: any) => {
          if (modifier.menuModifierId === modifierId) {
            // Check if the name has changed
            if (data.name !== undefined && data.name !== modifier.name) {
              isNameChanged = true // Flag the name change
            }

            // Check if the description has changed
            if (
              data.description !== undefined &&
              data.description !== modifier.description
            ) {
              isDescriptionChanged = true
            }

            // Construct a dynamic object with only the updated fields, excluding name
            const updatedFields: any = {}

            if (data.minPermitted !== undefined) {
              updatedFields.minPermitted = data.minPermitted
            }

            if (data.maxPermitted !== undefined) {
              updatedFields.maxPermitted = data.maxPermitted
            }
            if (data.showOnline !== undefined) {
              updatedFields.showOnline = data.showOnline
            }

            if (data.price !== undefined) {
              updatedFields.price = Math.round(parseFloat(data.price) * 100)
            }

            if (data.inStorePrice !== undefined) {
              updatedFields.inStorePrice = Math.round(
                parseFloat(data.inStorePrice) * 100
              )
            }
            if (data?.selections !== undefined) {
              updatedFields.selections = [...data.selections]
            }
            // Return the merged object with updated fields if any
            return Object.keys(updatedFields).length > 0
              ? { ...modifier, ...updatedFields }
              : modifier
          }
          return modifier // Return unmodified if not the target modifier
        }
      )

      // Check if there are any modifications
      const isModified = updatedModifiers.some(
        (modifier: any, index: number) =>
          modifier !== findModifierGroupFromSelections?.modifiers[index]
      )
      if (isModified) {
        // Construct the updated state for selections, excluding name updates
        const updatedState = {
          selections: [
            ...remainingModifierGroupsFromSelections,
            {
              ...findModifierGroupFromSelections,
              modifiers: updatedModifiers,
            },
          ],
        }
        itemUpdateMutation.mutate(updatedState, {
          onSuccess: () => {
            handleNavigation()
          },
        })
      }

      // If the name has changed, call a separate mutation
      if (isNameChanged || isDescriptionChanged) {
        const updatedState = {
          ...(data.name ? { name: data.name } : {}),
          ...(data.description ? { description: data.description } : {}),
        }

        modifierUpdate.mutate(updatedState, {
          onSuccess: () => {
            void modifier.refetch()
            handleNavigation()
          },
        })
      }
    }
  }

  const modifierGroupData = useMenuModifierGroupById(
    locationId!,
    menuId!,
    modifierGroupId!
  )
  // PageNavigation View constants
  const locationName = locations.data?.data?.name
  const menuName = menuData.data?.data?.name
  const itemName = itemData.data?.data?.name
  const modifierGroupName = modifierGroupData.data?.data?.name
  let breadcrumbs: any = [
    { title: 'Locations', path: '/app/locations' },
    { title: locationName, path: `/app/locations/${locationId}/analytics` },
    { title: 'Menus', path: `/app/locations/${locationId}/menus` },
    {
      title: menuName,
      path: isMenuV2
        ? `/app/locations/${locationId}/menus/${menuId}/`
        : `/app/locations/${locationId}/menu/${menuId}/items`,
    },
    {
      title: itemName,
      path: isMenuV2
        ? `/app/locations/${locationId}/${urlMenu}/${menuId}/${urlItem}/${itemId}/edit`
        : `/app/locations/${locationId}/${urlMenu}/${menuId}/${urlItem}/${itemId}`,
    },
    {
      title: modifierGroupName,
      path: `/app/locations/${locationId}/${urlMenu}/${menuId}/${urlItem}/${itemId}/modifier-groups/${modifierGroupId}`,
    },
  ]
  return (
    <PageContainer title={modifierState?.name} breadcrumbs={breadcrumbs}>
      <Box sx={{ p: 3 }}>
        <ModifierEditForm
          modifierState={modifierState}
          handleModifierUpdate={
            updatedSelections?.length > 0
              ? handleSubmitNestedModifierChange
              : handleSubmitChange
          }
          handleNavigation={handleNavigation}
          isNestedModifiers={isNestedModifiers}
          updatedSelections={updatedSelections}
        />
      </Box>
    </PageContainer>
  )
}
