import { useState } from 'react'
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Stack,
  Tooltip,
  Typography,
  Link as MuiLink,
  Avatar,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Grid,
} from '@mui/material'
import { Info as InfoIcon, WarningRounded } from '@mui/icons-material'
import { Navigate, useSearchParams } from 'react-router-dom'
import { useAuthStore, useUserAccounts } from '../../hooks'
import { useApplicationById } from '../../hooks'
import { AppLoader } from '../../components'
import { CenteredBox } from '../../components/CenteredBox'
import posHubLogo from '../../assets/logo.png'
import { AccountsPicker, LocationsPicker } from '../../components/Pickers'
import { useAccountLocations } from '../../hooks/useAccountLocations'
import { useBootstrapLogo } from '../../hooks/useBootstrap'

export const createAllowedAuthorizeUrl = (
  searchParams: URLSearchParams,
  token: string,
  accountId: string,
  locationId: string
) => {
  searchParams.set('approve', 'true')
  searchParams.set('token', token)
  searchParams.set('account_id', accountId)
  searchParams.set('location_id', locationId)

  return `${API_ENDPOINT}/oauth2/authorize?${searchParams.toString()}`
}

interface ScopeInfo {
  friendlyName: string
  description: string
  icon?: React.ReactNode
}

const SCOPE_INFO: Record<string, ScopeInfo> = {
  'applications.read': {
    friendlyName: 'Read Applications',
    description: 'Allows reading the list of applications and their details.',
    icon: <InfoIcon fontSize="small" />,
  },
  'applications.write': {
    friendlyName: 'Write Applications',
    description: 'Allows creating or editing applications.',
    icon: <InfoIcon fontSize="small" />,
  },
  'user.read': {
    friendlyName: 'Read User Info',
    description: 'Allows viewing user profile information.',
    icon: <InfoIcon fontSize="small" />,
  },
  'user.write': {
    friendlyName: 'Write User Info',
    description: 'Allows editing user profile information.',
    icon: <InfoIcon fontSize="small" />,
  },
  'accounts.read': {
    friendlyName: 'Read Accounts',
    description: 'Allows reading account data.',
    icon: <InfoIcon fontSize="small" />,
  },
  'accounts.write': {
    friendlyName: 'Write Accounts',
    description: 'Allows creating or modifying account data.',
    icon: <InfoIcon fontSize="small" />,
  },
  'locations.read': {
    friendlyName: 'Read Locations',
    description: 'Allows reading location data.',
    icon: <InfoIcon fontSize="small" />,
  },
  'locations.write': {
    friendlyName: 'Write Locations',
    description: 'Allows creating or editing location data.',
    icon: <InfoIcon fontSize="small" />,
  },
  'catalogs.read': {
    friendlyName: 'Read Catalogs',
    description: 'Allows reading catalog information (products, SKUs, etc.).',
    icon: <InfoIcon fontSize="small" />,
  },
  'catalogs.write': {
    friendlyName: 'Write Catalogs',
    description: 'Allows creating or modifying catalog information.',
    icon: <InfoIcon fontSize="small" />,
  },

  // New single/plural variations
  'catalog.read': {
    friendlyName: 'Read Catalog',
    description:
      'Allows reading a single catalog data set (products, SKUs, etc.).',
    icon: <InfoIcon fontSize="small" />,
  },
  'catalog.write': {
    friendlyName: 'Write Catalog',
    description: 'Allows creating or modifying data in a single catalog.',
    icon: <InfoIcon fontSize="small" />,
  },

  'menus.read': {
    friendlyName: 'Read Menus',
    description: 'Allows reading menu data (e.g., for a restaurant).',
    icon: <InfoIcon fontSize="small" />,
  },
  'menus.write': {
    friendlyName: 'Write Menus',
    description: 'Allows creating or updating menu data.',
    icon: <InfoIcon fontSize="small" />,
  },
  'orders.read': {
    friendlyName: 'Read Orders',
    description: 'Allows reading order data (items, statuses, etc.).',
    icon: <InfoIcon fontSize="small" />,
  },
  'orders.write': {
    friendlyName: 'Write Orders',
    description: 'Allows creating or modifying orders.',
    icon: <InfoIcon fontSize="small" />,
  },
  'resellers.read': {
    friendlyName: 'Read Resellers',
    description: 'Allows reading reseller data.',
    icon: <InfoIcon fontSize="small" />,
  },
  'resellers.write': {
    friendlyName: 'Write Resellers',
    description: 'Allows creating or editing reseller data.',
    icon: <InfoIcon fontSize="small" />,
  },
}

function AuthorizeScopes({
  scopes,
  applicationName,
}: {
  scopes?: string[]
  applicationName?: string
}) {
  if (!scopes || scopes.length === 0) {
    return (
      <Typography textAlign="center" color="textSecondary" mb={2}>
        No scopes requested.
      </Typography>
    )
  }

  return (
    <Box mb={2} ml={2}>
      <Typography variant="subtitle1" fontWeight={500} gutterBottom>
        {applicationName || 'The app'} is requesting the following permissions:
      </Typography>

      <List disablePadding>
        {scopes.map((scope) => {
          const { friendlyName, description } = SCOPE_INFO[scope] || {
            friendlyName: scope,
            description: 'No description available.',
            icon: <InfoIcon fontSize="small" />,
          }

          return (
            <ListItem key={scope} disableGutters disablePadding sx={{}}>
              <ListItemText
                primary={friendlyName}
                secondary={description}
                primaryTypographyProps={{ variant: 'body2' }}
              />
            </ListItem>
          )
        })}
      </List>
    </Box>
  )
}

export const NavigateToLoginWithBackLink = () => (
  <Navigate
    to={{
      pathname: '/',
      search: new URLSearchParams({ back: window.location.href }).toString(),
    }}
  />
)

/* --------------------------------------------------------------------------
   MAIN AUTHORIZE COMPONENT
   -------------------------------------------------------------------------- */
export const Authorize = () => {
  const [searchParams] = useSearchParams()
  const isLoggedIn = useAuthStore((state) => state.isLoggedIn)
  const token = useAuthStore((state) => state.token)

  // State for the selected account and location IDs
  const [accountId, setAccountId] = useState<string | null>(null)
  const [locationId, setLocationId] = useState<string | null>(null)

  // Extract the earch params required
  const accountIdParam = searchParams.get('account_id')
  const locationIdParam = searchParams.get('location_id')
  const errorMessage = searchParams.get('errorMessage')

  const application = useApplicationById(searchParams.get('client_id')!)
  const accounts = useUserAccounts()
  const locations = useAccountLocations(accountId!)
  const logo = useBootstrapLogo()

  const [deny, setDeny] = useState(false)
  const [loading, setLoading] = useState(false)

  /**
   * The button should be enabled if the user has set the accountId and locationId, or the URL has the parameters
   * accountIdParam and locationIdParam are set in the URL
   */
  const isParamsSet = accountIdParam && locationIdParam
  const isSelectedIdsSet = accountId && locationId
  const isAllowDisabled = !(isParamsSet || isSelectedIdsSet)

  // Handle authentication or error states
  if (!isLoggedIn) {
    return <NavigateToLoginWithBackLink />
  }

  if (errorMessage) {
    return (
      <CenteredBox>
        <Alert severity="error">{errorMessage}</Alert>
      </CenteredBox>
    )
  }
  if (application.isLoading || loading) {
    return <AppLoader />
  }

  if (application.isError || deny) {
    return (
      <CenteredBox>
        <Alert severity="error">
          <Box display="flex" alignItems="center">
            <WarningRounded sx={{ mr: 1 }} />
            We’re sorry, but something went wrong.
          </Box>
        </Alert>
      </CenteredBox>
    )
  }

  // Extract application data
  const appData = application?.data?.data
  const { imageUrl, name, scopes, description } = appData || {}

  const onDeny = () => setDeny(true)

  const onAllow = async () => {
    try {
      setLoading(true)
      await new Promise((resolve) => setTimeout(resolve, 500)) // Simulate async
      window.location.href = createAllowedAuthorizeUrl(
        searchParams,
        token?.access_token!,
        accountId! || accountIdParam!,
        locationId! || locationIdParam!
      )
    } catch (error) {
      console.error('Error while allowing:', error)
      setLoading(false)
    }
  }

  return (
    <Box
      minHeight="90vh"
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
    >
      {/* POS Hub Logo */}
      <Box mb={2}>
        <img src={logo || posHubLogo} alt="POS Hub Logo" width={180} />
      </Box>

      {/* Card */}
      <Card variant="outlined" sx={{ width: '100%', maxWidth: 600, p: 2 }}>
        <CardHeader
          title="Authorizion Required"
          subheader={`${
            name || 'This application'
          } is requesting access to one or more of your stores`}
          avatar={<Avatar src={imageUrl} alt="Application logo" />}
        />

        <CardContent>
          <AuthorizeScopes scopes={scopes} applicationName={name} />

          {/* Account Selector */}
          {(!accountIdParam || !locationIdParam) && (
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="subtitle1" fontWeight={500} gutterBottom>
                  Select the account and location to authorize:
                </Typography>
                <Divider />
              </Grid>

              {/* Accounts Picker */}
              <Grid item xs={12}>
                <AccountsPicker
                  query={accounts}
                  multiple={false}
                  disableClearable
                  onChange={(_, value) => setAccountId(value.id)}
                />
              </Grid>

              {/* Locations Picker */}
              <Grid item xs={12}>
                <LocationsPicker
                  key={`${accountId}-locations`}
                  query={locations}
                  disabled={locations.isLoading || !accountId}
                  multiple={false}
                  disableClearable
                  onChange={(_, value) => setLocationId(value.id)}
                />
              </Grid>
            </Grid>
          )}
        </CardContent>
        <CardActions sx={{ justifyContent: 'flex-end' }}>
          <Button onClick={onDeny}>Cancel</Button>
          <Button
            variant="contained"
            color="success"
            onClick={onAllow}
            disabled={isAllowDisabled}
            sx={{ ml: 1 }}
          >
            Allow
          </Button>
        </CardActions>
      </Card>
    </Box>
  )
}
