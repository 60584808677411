import { FC, useState, useMemo, useEffect, Fragment, useRef } from 'react'
import {
  Box,
  Grid,
  IconButton,
  Typography,
  Tooltip,
  Collapse,
} from '@mui/material'
import {
  EditOutlined,
  Delete as RemoveIcon,
  ExpandLess as ExpandLessIcon,
  ExpandMore as ExpandMoreIcon,
} from '@mui/icons-material'
import { useItemsUpdateWhileUpdateMenu } from '../../hooks'
import { TruncateName } from './components/TruncateName'
import { ConfirmationDialog } from './dialogs/ConfirmationDialog'
import { MenuCategoryItems } from './menuCategoryItems'
import { StyledGridContainer } from './components'
import { useMenuRearrange } from '../../hooks/useMenuRearrange'
import { ShowOnlinePicker } from '../../components/Pickers'

interface MenuCategoriesProps {
  locationId: string
  menuId: string
  onItemAdd: (categoryId: string) => any
  onEditItemById: (itemId: string, categoryId: string) => any
  onDeleteCategoryById: (categoryId: string) => any
  onEditCategoryById: (categoryId: string) => any
  setSelectedCatId?: any
  categoryUpdateMutation: any
  selectedCatId?: string | null
  onDeleteItemById: (itemId: string) => any
  categoriesState: any
  setSelectedProduct: (val: any) => void
  itemsState: any
  highlightStyle: (prod: any, index: any, type: any) => any
  showUndoSnackbar: any
  performDeletion: any
  setRearrangeState: (prev: any) => void
  scrollToCategoryId?: string | null
  isExpandedCategories: boolean
  expandedCategories: any
  setExpandedCategories: (val: any) => void
}
export const MenuCategories: FC<MenuCategoriesProps> = (props: any) => {
  const {
    locationId,
    menuId,
    onEditItemById,
    onItemAdd,
    onDeleteCategoryById,
    categoryUpdateMutation,
    setSelectedCatId,
    selectedCatId,
    onEditCategoryById,
    onDeleteItemById,
    categoriesState,
    setSelectedProduct,
    itemsState,
    highlightStyle,
    showUndoSnackbar,
    performDeletion,
    scrollToCategoryId,
    isExpandedCategories,
    expandedCategories,
    setExpandedCategories,
  } = props
  const itemUpdateMutation = useItemsUpdateWhileUpdateMenu(locationId!, menuId!)
  const menuRearrange = useMenuRearrange(locationId, menuId)
  const [categoryUpdateState, setCategoryUpdateState] = useState<any>({})
  const [showConfirmDialog, setShowConfirmDialog] = useState(false)
  const [isConfirmed, setIsConfirmed] = useState(false)
  const handleToggleCategory = (categoryId: number) => {
    setExpandedCategories((prevState: any) => {
      const newState = { ...prevState }
      newState[categoryId] = !newState[categoryId] // Toggle state
      return newState
    })
  }

  const sortedCategories = useMemo(() => {
    const categories =
      Object.keys(categoryUpdateState).length > 1
        ? categoryUpdateState
        : categoriesState
    return categories?.slice().sort((a: any, b: any) => a.position - b.position) // Avoid modifying original array
  }, [categoriesState, categoryUpdateState])
  const selectedCategoryItems = useMemo(
    () =>
      itemsState?.filter((item: any) =>
        item?.categories?.includes(selectedCatId)
      ),
    [selectedCatId, itemsState]
  )

  const handleChangeSequence = (result: any) => {
    menuRearrange.mutate(result)
  }

  useEffect(() => {
    if (isConfirmed && selectedCategoryItems.length > 0) {
      selectedCategoryItems.forEach((item: any) => {
        itemUpdateMutation.mutate(
          {
            itemData: { showOnline: categoryUpdateState.showOnline },
            itemId: item.id,
          },
          {
            onSuccess: () => {
              setShowConfirmDialog(false)
              setIsConfirmed(false)
              setSelectedCatId(null)
            },
          }
        )
      })
    }
  }, [isConfirmed])

  useEffect(() => {
    if (selectedCatId && categoryUpdateState.showOnline !== undefined) {
      categoryUpdateMutation.mutate(categoryUpdateState)
    }
  }, [selectedCatId, categoryUpdateState.showOnline]) // Only trigger when showOnline changes

  const handleShowOnline = (showOnline: boolean, catId: string) => {
    setShowConfirmDialog(true)
    setSelectedCatId(catId)
    setCategoryUpdateState({ showOnline })
  }

  return (
    <Box sx={{ pt: 1 }}>
      {sortedCategories?.map((category: any, index: any) => {
        return (
          <StyledGridContainer
            container
            key={category.id}
            pb={4}
            sx={highlightStyle(category, index, 'category')}
          >
            <RenderCategory
              category={category}
              handleShowOnline={handleShowOnline}
              onEditCategoryById={onEditCategoryById}
              onDeleteCategoryById={onDeleteCategoryById}
              setSelectedProduct={setSelectedProduct}
              onItemAdd={onItemAdd}
              itemsState={itemsState}
              highlightStyle={highlightStyle}
              showUndoSnackbar={showUndoSnackbar}
              performDeletion={performDeletion}
              onEditItemById={onEditItemById}
              onDeleteItemById={onDeleteItemById}
              scrollToCategoryId={scrollToCategoryId}
              isExpandedCategories={isExpandedCategories}
              expandedCategories={expandedCategories}
              handleToggleCategory={handleToggleCategory}
              handleChangeSequence={handleChangeSequence}
            />
          </StyledGridContainer>
        )
      })}
      {showConfirmDialog && (
        <ConfirmationDialog
          open
          title="Confirmation"
          message="Would you like to change the show online for all items in this category?"
          updateNewState={setIsConfirmed}
          setShowConfirmDailog={setShowConfirmDialog}
        />
      )}
    </Box>
  )
}
const RenderCategory = (props: any) => {
  const {
    category,
    handleShowOnline,
    onEditCategoryById,
    onDeleteCategoryById,
    setSelectedProduct,
    onItemAdd,
    itemsState,
    highlightStyle,
    showUndoSnackbar,
    performDeletion,
    onEditItemById,
    onDeleteItemById,
    scrollToCategoryId,
    isExpandedCategories,
    expandedCategories,
    handleToggleCategory,
    handleChangeSequence,
  } = props
  const categoryRef = useRef<HTMLDivElement>(null)
  const isExpanded = expandedCategories?.[category.id] ?? isExpandedCategories
  useEffect(() => {
    if (
      scrollToCategoryId &&
      category.id === scrollToCategoryId &&
      categoryRef.current
    ) {
      setTimeout(() => {
        categoryRef.current?.scrollIntoView({
          behavior: 'auto',
          block: 'start',
        })
      }, 300) // Adjust delay if necessary
    }
  }, [scrollToCategoryId, category.id])
  return (
    <Fragment>
      <div ref={categoryRef} style={{ width: '100%' }}>
        <Grid item xs={12} pb={2}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              cursor: 'pointer',
            }}
            onClick={() => handleToggleCategory(category.id)}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Typography variant="body1">
                {TruncateName(category.name)}
              </Typography>
            </Box>
            <Box sx={{ display: 'flex' }} onClick={(e) => e.stopPropagation()}>
              <Box width={150} mx={1}>
                <ShowOnlinePicker
                  value={category.showOnline}
                  onChange={(value) => handleShowOnline(value, category.id)}
                />
              </Box>
              <Tooltip title="Edit Category">
                <IconButton onClick={() => onEditCategoryById(category.id)}>
                  <EditOutlined fontSize="small" />
                </IconButton>
              </Tooltip>
              <Tooltip title="Delete Category">
                <IconButton
                  onClick={() => {
                    onDeleteCategoryById(category.id)
                    setSelectedProduct(category)
                  }}
                >
                  <RemoveIcon fontSize="small" />
                </IconButton>
              </Tooltip>
              <IconButton onClick={() => handleToggleCategory(category.id)}>
                {isExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
              </IconButton>
            </Box>
          </Box>
        </Grid>
        <Collapse
          in={isExpanded}
          timeout="auto"
          unmountOnExit
          sx={{ width: '100%' }}
        >
          <MenuCategoryItems
            category={category}
            onItemAdd={onItemAdd}
            items={itemsState}
            onDeleteItem={(item: any) => onDeleteItemById(item.id)}
            setSelectedProduct={setSelectedProduct}
            highlightStyle={highlightStyle}
            showUndoSnackbar={showUndoSnackbar}
            performDeletion={performDeletion}
            onEditItemById={onEditItemById}
            handleChangeSequence={handleChangeSequence}
          />
        </Collapse>
      </div>
    </Fragment>
  )
}
