import {
  createTheme,
  ThemeOptions,
  lighten,
  darken,
  responsiveFontSizes,
} from '@mui/material/styles'

/**
 * Global Default Constants
 */
const fontFamily = 'Lato, sans-serif'
const htmlFontSize = 16

/**
 * Theme that applies high level density to all components
 */
export const denseTheme = createTheme({
  components: {
    MuiButton: {
      defaultProps: { size: 'small' },
      styleOverrides: {
        root: {
          fontSize: '12px',
        },
      },
    },
    MuiCardActions: { defaultProps: { disableSpacing: true } },
    MuiButtonGroup: { defaultProps: { size: 'small' } },
    MuiFilledInput: { defaultProps: { size: 'small' } },
    MuiFormControl: { defaultProps: { size: 'small' } },
    MuiFormHelperText: { defaultProps: { margin: 'dense' } },
    MuiIconButton: { defaultProps: { size: 'small' } },
    MuiInputBase: { defaultProps: { size: 'small' } },
    MuiInputLabel: { defaultProps: { margin: 'dense' } },
    MuiList: { defaultProps: { dense: true } },
    MuiListItem: { defaultProps: { dense: true } },
    MuiOutlinedInput: { defaultProps: { size: 'small' } },
    MuiFab: { defaultProps: { size: 'small' } },
    MuiTable: { defaultProps: { size: 'small' } },
    MuiTableCell: { defaultProps: { size: 'small' } },
    MuiTextField: { defaultProps: { size: 'small' } },
    MuiToolbar: { defaultProps: { variant: 'dense' } },
    MuiChip: { defaultProps: { size: 'small' } },
  },
})

/**
 * Theme that applies outlined variant to all components
 */
export const outlinedTheme = createTheme({
  components: {
    MuiPaper: { defaultProps: { variant: 'outlined' } },
    MuiCard: { defaultProps: { variant: 'outlined' } },
    MuiButton: { defaultProps: { variant: 'outlined' } },
    MuiFormControl: { defaultProps: { variant: 'outlined' } },
    MuiFormHelperText: { defaultProps: { variant: 'outlined' } },
    MuiInputLabel: { defaultProps: { variant: 'outlined' } },
    MuiTextField: { defaultProps: { variant: 'outlined' } },
  },
})

/**
 * Base Theme - Contains all non-light/dark-related changes
 */
export const baseTheme: ThemeOptions = responsiveFontSizes(
  createTheme(denseTheme, outlinedTheme, {
    /**
     * To ensure consistency across all components, we set the border radius to 8px for all components
     */
    shape: { borderRadius: 8 },
    typography: { fontFamily, htmlFontSize },
    components: {
      MuiInputBase: {},
      MuiOutlinedInput: {
        styleOverrides: {
          input: {
            '&:-webkit-autofill': {
              WebkitBoxShadow: 'none',
            },
            '&:-internal-autofill-selected': {
              backgroundColor: 'transparent !important',
            },
          },
        },
      },
      MuiTypography: {
        defaultProps: { variant: 'body2' },
        styleOverrides: {
          root: {
            '&.MuiTypography-body2': {
              fontSize: '12px',
            },
            '&.MuiTypography-subtitle2': {
              fontSize: '12px',
              fontWeight: 'bold',
            },
          },
        },
      },
      MuiCard: {},
      MuiCardHeader: {},
      MuiCardContent: {},
      MuiCardActions: {},
      MuiTextField: {
        styleOverrides: {
          root: {
            backgroundColor: 'inherit',
            '& .MuiInputBase-root': {
              backgroundColor: '#fff',
              fontSize: '0.8rem',
            },
            '& .MuiFormHelperText-root': {
              backgroundColor: 'inherit',
              margin: 0,
            },
            '& .MuiInputLabel-root': {
              fontSize: '0.8rem',
              lineHeight: '1rem',
            },
            '& .MuiInputLabel-shrink': {
              lineHeight: '1.4rem',
            },
            '& .MuiOutlinedInput-root': { paddingLeft: 0 },
            '& .MuiOutlinedInput-input': {
              padding: 8,
            },
            '& .MuiInputBase-multiline': { paddingLeft: 12 },
          },
        },
      },
      MuiListItemButton: {
        styleOverrides: {
          root: {
            '&.Mui-selected': {
              position: 'relative',
              '&::before': {
                content: '""',
                position: 'absolute',
                left: 0,
                top: 0,
                bottom: 0,
                width: '4px',
                backgroundColor: '#D82927',
              },
              color: '#D82927',
              '& .MuiListItemIcon-root': { color: '#D82927' },
            },
            '&.Mui-nested': {
              paddingLeft: '16px',
              '&.Mui-selected::before': {
                left: '0px',
              },
            },
            '&.Mui-disabled': {
              color: 'rgba(0, 0, 0, 0.38)',
              '& .MuiListItemIcon-root': {
                color: 'rgba(0, 0, 0, 0.38)',
              },
            },
          },
        },
      },
      MuiListItemIcon: { styleOverrides: { root: { minWidth: '40px' } } },
      MuiTableHead: {
        styleOverrides: {
          root: {
            '& .MuiTableRow-root': {
              '& .MuiTableCell-root': {
                fontWeight: 'bold',
                borderBottom: '1px solid rgba(224, 224, 224, 1)',
              },
            },
          },
        },
      },
      MuiTableRow: {
        defaultProps: { hover: true },
        styleOverrides: {
          root: {
            cursor: 'pointer',
            '&:last-child': {
              '& .MuiTableCell-root': { borderBottom: 'none' },
            },
          },
        },
      },
      MuiTableCell: {
        // defaultProps: { size: 'small' },
        styleOverrides: {
          root: {
            '&.MuiSvgIcon-root': { cursor: 'pointer' },
          },
        },
      },
      MuiDialog: {},
      MuiDialogTitle: {
        defaultProps: { variant: 'subtitle2' },
      },
      MuiDivider: {
        styleOverrides: {
          root: {
            '&.MuiDivider-textAlignLeft::before': {
              width: '2% !important',
            },
            '&.MuiDivider-textAlignLeft::after': {
              width: '98% !important',
            },
          },
        },
      },
      MuiButton: {},
      MuiGrid: {
        styleOverrides: {
          root: {
            '& .react-flow__attribution': {
              background: 'none',
            },
          },
        },
      },
      MuiLink: {
        defaultProps: { color: '#1976d2', fontSize: 'inherit' },
        styleOverrides: {
          root: { padding: 8, '&:hover': { cursor: 'pointer' } },
        },
      },
      MuiAutocomplete: {
        styleOverrides: {
          root: {
            '&.MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon .MuiOutlinedInput-root':
              { paddingRight: '50px' },
          },
        },
      },
      MuiFormControl: {
        styleOverrides: {
          root: {
            '& .MuiFormLabel-root.MuiFormLabel-filled': {
              backgroundColor: 'transparent',
            },
            '& .MuiFormLabel-root.Mui-focused': {
              color: '#1976d2',
            },
            '& .MuiOutlinedInput-root .MuiInputAdornment-root': {
              padding: 8,
              margin: 0,
            },
            '& .MuiOutlinedInput-root.Mui-disabled .MuiInputAdornment-root .MuiTypography-root':
              {
                color: 'rgba(0, 0, 0, 0.38)',
              },
            '& .MuiInputBase-adornedEnd': {
              paddingRight: 0,
            },
            '& .MuiOutlinedInput-root.Mui-focused': {
              '& .MuiOutlinedInput-notchedOutline': {
                borderColor: '#1976d2',
              },
            },
          },
        },
      },
    },
  } as ThemeOptions)
)

/**
 * Light Theme - Extends the Base Theme with Light-Specific Styles
 */
export const lightTheme: ThemeOptions = responsiveFontSizes(
  createTheme(baseTheme, {
    palette: {
      mode: 'light',
      background: { default: '#f5f5f5', paper: '#ffffff' },
      primary: {
        main: '#D82927',
        light: lighten('#D82927', 0.1),
        dark: darken('#D82927', 0.4),
      },
      secondary: {
        main: '#888888',
        light: lighten('#888888', 0.1),
        dark: darken('#888888', 0.4),
        disabled: '#88888833',
      },
      error: {
        main: '#D82927',
        light: lighten('#D82927', 0.1),
        dark: darken('#D82927', 0.4),
      },
      info: {
        main: '#1976d2',
        light: lighten('#1976d2', 0.1),
      },
      success: {
        main: '#5bae75',
        light: lighten('#5bae75', 0.1),
        dark: darken('#5bae75', 0.4),
      },
    },
  })
)

/**
 * Darker Variant of Dark Theme
 */
export const darkTheme: ThemeOptions = responsiveFontSizes(
  createTheme(baseTheme, {
    palette: {
      mode: 'dark',
      divider: 'rgba(255, 255, 255, 0.1)',
      background: { default: '#1E1E1E', paper: '#1E1E1E' },
      primary: {
        main: '#D82927',
      },
      secondary: {
        main: '#888888',
        light: lighten('#888888', 0.1),
        dark: darken('#888888', 0.4),
        disabled: '#888888a1',
      },
      text: {
        primary: '#F5F5F5',
        secondary: '#B3B3B3',
        disabled: 'rgba(255, 255, 255, 0.4)',
      },
      action: {
        active: '#FFFFFF',
        hover: 'rgba(255, 255, 255, 0.08)',
        selected: 'rgba(255, 255, 255, 0.30)',
        disabled: 'rgba(255, 255, 255, 0.3)',
        disabledBackground: 'rgba(255, 255, 255, 0.12)',
        focus: 'rgba(255, 255, 255, 0.12)',
      },
    },
    components: {
      MuiSvgIcon: { styleOverrides: { root: { color: '#F5F5F5' } } },
      MuiTextField: {
        styleOverrides: {
          root: {
            '& .MuiInputBase-root': {
              backgroundColor: '#333333',
            },
          },
        },
      },
      MuiDialog: {
        styleOverrides: {
          root: {
            '& .MuiDialogTitle-root, & .MuiDialogContent-root, & .MuiDialogActions-root':
              { backgroundColor: 'background' },
          },
        },
      },
      MuiListSubheader: {
        styleOverrides: { root: { backgroundColor: 'inherit' } },
      },
      MuiListItemButton: {},
      MuiTableContainer: {
        styleOverrides: {
          root: { border: '1px solid', borderColor: '#333333' },
        },
      },
      MuiDrawer: {
        styleOverrides: { paper: { backgroundColor: 'background' } },
      },
      MuiAppBar: {
        styleOverrides: { root: { backgroundColor: 'background' } },
      },
      MuiTabs: {
        styleOverrides: {
          root: { '& .MuiTabs-indicator': { backgroundColor: '#D82927' } },
        },
      },
      MuiTab: {
        styleOverrides: {
          root: {
            '&.Mui-selected': { color: '#D82927' },
          },
        },
      },
      MuiSwitch: {
        defaultProps: { color: 'primary' },
        styleOverrides: { root: {} },
      },
    },
  } as ThemeOptions)
)
