import { useTheme } from '@mui/material'
import { BaseEdge, type EdgeProps, getBezierPath } from '@xyflow/react'

export function ApplicationEdge({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  animated,
  data,
}: EdgeProps) {
  const theme = useTheme()
  const [edgePath] = getBezierPath({
    sourceX,
    sourceY,
    targetX,
    targetY,
    sourcePosition,
    targetPosition,
  })

  return (
    <>
      <BaseEdge
        id={id}
        path={edgePath}
        color="#B3B3B3"
        opacity={theme.palette.mode === 'dark' ? 0.8 : 1}
      />
      {animated && (
        <circle
          r="2"
          fill={theme.palette.primary.main}
          opacity={theme.palette.mode === 'dark' ? 0.8 : 1}
        >
          <animateMotion
            dur={(data?.animationDuration ?? '2s') as any}
            offset={(data?.animationOffset ?? '0s') as any}
            repeatCount="indefinite"
            path={edgePath}
          />
        </circle>
      )}
    </>
  )
}
