import { FC } from 'react'
import { Handle, NodeProps, Position } from '@xyflow/react'
import { Avatar, Box, Tooltip, useTheme } from '@mui/material'

export interface ApplicationNodeData {
  label: string
  src: string
}

const handleStyles: React.CSSProperties = {
  background: '#333',
  visibility: 'hidden',
}

/**
 * ApplicationNode component
 */
export const ApplicationNode: FC<NodeProps> = ({ data }) => {
  const theme = useTheme()
  const { label, src } = data as unknown as ApplicationNodeData
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      {/* Logo */}
      <Tooltip title={label} placement="top">
        <Avatar
          src={src}
          alt={label}
          sx={{
            width: 32,
            height: 32,
            background: theme.palette.common.white,
            // Dim the logo if dark mode is enabled to take the edge off the brightness
            // filter: theme.palette.mode === 'dark' ? 'brightness(0.9)' : 'none',

            // Add a border to the logo
            // border: '1px solid',
          }}
          variant="rounded"
        />
      </Tooltip>
      {/* <img src={src} alt={label} style={{ width: 24, height: 24 }} /> */}
      {/* Hide handles visually if you only want lines from the hub */}
      <Handle
        id="source-right"
        type="source"
        position={Position.Right}
        style={handleStyles}
      />
      <Handle
        id="source-left"
        type="source"
        position={Position.Left}
        style={handleStyles}
      />
      <Handle
        id="source-top"
        type="source"
        position={Position.Top}
        style={handleStyles}
      />
      <Handle
        id="source-bottom"
        type="source"
        position={Position.Bottom}
        style={handleStyles}
      />
      <Handle
        id="target-left"
        type="target"
        position={Position.Left}
        style={handleStyles}
      />
      <Handle
        id="target-right"
        type="target"
        position={Position.Right}
        style={handleStyles}
      />
      <Handle
        id="target-top"
        type="target"
        position={Position.Top}
        style={handleStyles}
      />
      <Handle
        id="target-bottom"
        type="target"
        position={Position.Bottom}
        style={handleStyles}
      />
    </Box>
  )
}
