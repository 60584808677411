import { useEffect, useMemo, useState } from 'react'
import { defaultItemPatchState } from '../../Locations/CardSelect/Menu/utils'
import { MenuItemCreateEntity, MenuItemPatchEntity } from '../../../types'
import { ItemEditForm } from '../../../components'
import {
  getLocationQueryById,
  useCategory,
  useItemById,
  useItemUpdate,
  useMenuById,
  useMenuModifierGroups,
  useMenuModifierGroupUpdateForItemEdit,
  useMenuModifiers,
  useProducts,
} from '../../../hooks'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { Box } from '@mui/material'
import { selectionsMenuIdsMap } from '../../util'
import { useMenuRearrange } from '../../../hooks/useMenuRearrange'
import { PageContainer } from '../../../components/Page/PageContainer'

/**
 * Dialog for editing an item in the menu.
 */
export function EditItemMenuV2() {
  const navigate = useNavigate()
  const location = useLocation()
  const { locationId, menuId, itemId } = useParams()
  const menuRearrange = useMenuRearrange(locationId!, menuId!)
  const isMenuV2 = window.location.pathname.includes('menus')
  //  fetch Location data
  const locations = getLocationQueryById(locationId)
  const itemData = useItemById(locationId!, menuId!, itemId!, {
    refetchOnMount: 'always',
    onSuccess: (data: any) => {
      if (
        (data?.data?.fulfillmentTypes && data.data.fulfillmentTypes?.length) ||
        (data?.data?.serviceAvailability &&
          data.data.serviceAvailability?.length)
      ) {
        setItemSpecificAvailability(true)
      }
    },
  })
  const itemUpdateMutation = useItemUpdate(locationId!, menuId!, itemId!)
  const modiferGroupUpdateMutation = useMenuModifierGroupUpdateForItemEdit(
    locationId!,
    menuId!
  )
  const menuData = useMenuById(locationId!, menuId!, {
    refetchOnMount: 'always',
  })
  const menuDetails = menuData.data?.data
  const [menuItemState, setMenuItemState] = useState<
    MenuItemCreateEntity | MenuItemPatchEntity
  >(defaultItemPatchState)
  const [updatedCatalogProduct, setUpdatedCatalogProduct] = useState<any>(null)
  const catalogProducts = useProducts(locationId!, {
    onSuccess(data: any) {
      const item = data.pages.map((page: any) => page.data).flat()
      fetchItemData(item)
      const d = data.pages.length
      if (data.pages[d - 1].hasNextPage) {
        void catalogProducts.fetchNextPage()
      }
    },
  })
  const [catalogProductData, setCatalogProductData] = useState<any>(null)
  const fetchItemData = (catalogProducts: any) => {
    const item = catalogProducts?.find(
      (item: any) => item.id === itemData?.data?.data?.catalogProductId
    )
    setCatalogProductData(item)
    updateCatalogProductData(
      modifiers?.data?.pages.map((page: any) => page.data).flat()!,
      modifierGroups?.data?.pages.map((page: any) => page.data).flat()!,
      item
    )
  }
  useEffect(() => {
    catalogProducts?.data &&
      fetchItemData(
        catalogProducts?.data?.pages.map((page: any) => page.data).flat()
      )
    if (itemData?.data?.data) {
      setMenuItemState({
        ...itemData?.data?.data,
        price: itemData?.data?.data.price / 100,
        fulfillmentTypes: itemData?.data?.data?.fulfillmentTypes?.length
          ? itemData.data.data.fulfillmentTypes
          : menuDetails?.fulfillmentTypes,
        serviceAvailability: itemData?.data?.data?.serviceAvailability?.length
          ? itemData.data.data.serviceAvailability
          : menuDetails?.serviceAvailability,
        isBikeFriendly: itemData?.data?.data?.isBikeFriendly || true,
        ...(itemData?.data?.data?.inStorePrice
          ? { inStorePrice: itemData.data.data.inStorePrice! / 100 }
          : {}),
      })
    }
  }, [itemData?.data?.data, menuData.data?.data])
  const categories = useCategory(locationId!, menuId!, {
    onSuccess(data: any) {
      const d = data.pages.length
      if (data.pages[d - 1].hasNextPage) {
        void categories.fetchNextPage()
      }
    },
  })
  const categoriesOptions = categories.data?.pages
    .map((value: any) => value.data)
    .flat()
    ?.map((record) => record)
  const updateCatalogProductData = (
    modifiers: any[],
    modifierGroups: any[],
    catalogProduct: any
  ) => {
    setUpdatedCatalogProduct({
      ...catalogProduct,
      ...(catalogProduct?.selections?.length
        ? {
            selections: selectionsMenuIdsMap(
              modifierGroups,
              modifiers,
              catalogProduct?.selections
            ),
          }
        : {}),
    })
  }
  const modifierGroups = useMenuModifierGroups(locationId!, menuId!, {
    refetchOnMount: 'always',
    onSuccess(data: any) {
      const d = data.pages.length
      if (data.pages[d - 1].hasNextPage) {
        void modifierGroups.fetchNextPage()
      }
      updateCatalogProductData(
        modifiers?.data?.pages.map((page: any) => page.data).flat()!,
        data.pages.map((page: any) => page.data).flat(),
        catalogProductData
      )
    },
  })
  const modifiers = useMenuModifiers(locationId!, menuId!, {
    refetchOnMount: 'always',
    onSuccess(data: any) {
      const d = data.pages.length
      if (data.pages[d - 1].hasNextPage) {
        void modifiers.fetchNextPage()
      }
      updateCatalogProductData(
        data.pages.map((page: any) => page.data).flat(),
        modifierGroups?.data?.pages.map((page: any) => page.data).flat()!,
        catalogProductData
      )
    },
  })
  const [itemSpecificAvailability, setItemSpecificAvailability] =
    useState<boolean>(false)
  const modifierGroupsData: any[] = useMemo(() => {
    if (!modifierGroups?.data?.pages) return [] // Handle undefined safely
    return modifierGroups.data.pages.flatMap((page: any) => page.data) || []
  }, [modifierGroups?.data?.pages]) // Only depend on `pages`, not the whole object!

  const stableCatalogProductData = useMemo(
    () => ({
      selections:
        catalogProductData?.selections?.map(
          (s: any) => s.catalogModifierGroupId
        ) || [],
      modifierGroups: catalogProductData?.modifierGroups || [],
    }),
    [
      JSON.stringify(catalogProductData?.selections),
      JSON.stringify(catalogProductData?.modifierGroups),
    ]
  )

  const modifierGroupOptions = useMemo(() => {
    if (modifierGroupsData.length === 0) {
      return []
    }
    const sortingSource =
      (menuItemState?.selections?.length ?? 0) > 0 ||
      (menuItemState?.modifierGroups?.length ?? 0) > 0
        ? menuItemState
        : stableCatalogProductData
    const selectedGroups = [
      ...(sortingSource?.selections?.map(
        (selection: any) => selection.menuModifierGroupId
      ) ?? []),
      ...(sortingSource?.modifierGroups ?? []),
    ]
    // Remove duplicate IDs
    const uniqueSelectedGroups = [...new Set(selectedGroups)]
    const options =
      modifierGroupsData.filter((group: any) => {
        const isIncluded = uniqueSelectedGroups.includes(group.id)
        return isIncluded
      }) || []
    return options.sort((a: any, b: any) => {
      const indexA = selectedGroups.indexOf(a.catalogModifierGroupId)
      const indexB = selectedGroups.indexOf(b.catalogModifierGroupId)

      if (indexA === -1) return 1
      if (indexB === -1) return -1
      return indexA - indexB
    })
  }, [modifierGroupsData, stableCatalogProductData, menuItemState])

  // PageNavigation View constants
  const locationName = locations.data?.data?.name
  const menuName = menuData.data?.data?.name
  let breadcrumbs: any = [
    { title: 'Locations', path: '/app/locations' },
    { title: locationName, path: `/app/locations/${locationId}/analytics` },
    { title: 'Menus', path: `/app/locations/${locationId}/menus` },
    {
      title: menuName,
      path: isMenuV2
        ? `/app/locations/${locationId}/menus/${menuId}`
        : `/app/locations/${locationId}/menu/${menuId}/items`,
    },
  ]
  const handleOnclickBack = () => {
    navigate(location.state.back ?? -1)
  }
  const handleNavigation = () => {
    if (isMenuV2) {
      location.state
        ? handleOnclickBack()
        : navigate(`/app/locations/${locationId}/menus/${menuId}`)
    } else navigate(`/app/locations/${locationId}/menu/${menuId}/items`)
  }
  const areArraysEqual = (arr1: string[], arr2: string[]) => {
    // Check if both arrays exist and have the same length
    if (!arr1 || !arr2 || arr1.length !== arr2.length) return false

    // Sort both arrays to ensure they can be compared
    const sortedArr1 = [...arr1].sort()
    const sortedArr2 = [...arr2].sort()

    // Compare each element of the sorted arrays
    return sortedArr1.every((value, index) => value === sortedArr2[index])
  }
  const areTimePeriodsEqual = (arr1: any[], arr2: any[]) => {
    // Check if both arrays exist and have the same length
    if (!arr1 || !arr2 || arr1.length !== arr2.length) return false

    // Sort both arrays to ensure they can be compared
    const sortedArr1 = [...arr1].sort((a, b) =>
      a.startTime.localeCompare(b.startTime)
    )
    const sortedArr2 = [...arr2].sort((a, b) =>
      a.startTime.localeCompare(b.startTime)
    )

    // Compare each element of the sorted arrays
    return sortedArr1.every((item, index) => {
      const correspondingItem = sortedArr2[index]
      return (
        item.startTime === correspondingItem.startTime &&
        item.endTime === correspondingItem.endTime
      )
    })
  }

  const areServiceAvailabilityEqual = (arr1: any[], arr2: any[]) => {
    // Check if both arrays exist and have the same length
    if (!arr1 || !arr2 || arr1.length !== arr2.length) return false

    // Sort both arrays by weekday
    const sortedArr1 = [...arr1].sort((a, b) =>
      a.weekday.localeCompare(b.weekday)
    )
    const sortedArr2 = [...arr2].sort((a, b) =>
      a.weekday.localeCompare(b.weekday)
    )

    // Compare each item in the sorted arrays
    return sortedArr1.every((item, index) => {
      const correspondingItem = sortedArr2[index]

      // Check if the weekdays are the same
      if (item.weekday !== correspondingItem.weekday) return false

      // Check if the timePeriods are equal using the helper function
      return areTimePeriodsEqual(
        item.timePeriods,
        correspondingItem.timePeriods
      )
    })
  }
  const handleModifierGroupChange = (data: any) => {
    const { id: modifierGroupId, ...modifierGroup } = data // Extract modifierGroupId from data
    const foundModifierGroup: any = modifierGroupOptions?.find(
      (group: any) => group.id === modifierGroupId
    )
    const isNameChanged = modifierGroup.name !== foundModifierGroup?.name
    if (itemData?.data?.data?.selections?.length) {
      const updatedSelections = itemData?.data?.data.selections?.map(
        (selection: any) => {
          if (selection.menuModifierGroupId === modifierGroupId) {
            return {
              ...selection,
              minPermitted: modifierGroup.minPermitted,
              maxPermitted: modifierGroup.maxPermitted,
            }
          }
          return selection
        }
      )
      itemUpdateMutation.mutate(
        { selections: [...updatedSelections] },
        {
          onSuccess: (data: any) => {
            void itemData.refetch()
            setMenuItemState({
              ...data.data,
              price: data.data.price / 100,
              fulfillmentTypes: data.data?.fulfillmentTypes?.length
                ? data.data.fulfillmentTypes
                : menuDetails?.fulfillmentTypes,
              serviceAvailability: data.data?.serviceAvailability?.length
                ? data.data.serviceAvailability
                : menuDetails?.serviceAvailability,
              isBikeFriendly: data.data?.isBikeFriendly || true,
              inStorePrice: data.data?.inStorePrice! / 100,
            })
          },
        }
      )
      if (isNameChanged) {
        modiferGroupUpdateMutation.mutate({
          modifierGroupId,
          modifierGroup: {
            name: modifierGroup.name,
          },
        })
      }
    } else {
      modiferGroupUpdateMutation.mutate({
        modifierGroupId,
        modifierGroup: {
          name: modifierGroup.name,
          minPermitted: modifierGroup.minPermitted,
          maxPermitted: modifierGroup.maxPermitted,
        },
      })
    }
  }
  const handleSubmitChange = (data: any) => {
    const updatedState = { ...data }
    // Handle price conversion with rounding
    if (data.price) {
      updatedState.price = Math.round(parseFloat(data.price) * 100)
    }

    if (data.inStorePrice) {
      updatedState.inStorePrice = Math.round(
        parseFloat(data.inStorePrice) * 100
      )
    }

    // Handle fulfillment types update
    if (data.fulfillmentTypes) {
      const isEqual = areArraysEqual(
        data.fulfillmentTypes,
        menuDetails?.fulfillmentTypes || []
      )
      updatedState.fulfillmentTypes = isEqual ? [] : data.fulfillmentTypes
    }

    // Handle service availability update
    if (data.serviceAvailability) {
      const serviceAvailabilityEqual = areServiceAvailabilityEqual(
        data.serviceAvailability,
        menuDetails?.serviceAvailability || []
      )
      updatedState.serviceAvailability = serviceAvailabilityEqual
        ? []
        : data.serviceAvailability
    }
    // Perform the mutation
    itemUpdateMutation.mutate(updatedState, {
      onSuccess: () => {
        toast.success('Item Updated')
        handleNavigation()
      },
    })
  }

  const handleChangeSequence = (result: any): Promise<void> => {
    return new Promise((resolve, reject) => {
      let updatedSelections = []

      if (menuItemState?.selections?.length) {
        updatedSelections = result.modifierGroups.map((id: string) => {
          return menuItemState.selections?.find(
            (selection: any) => selection.menuModifierGroupId === id
          )
        })
      }

      // First mutation: itemUpdateMutation
      itemUpdateMutation.mutate(
        {
          ...result,
          ...(updatedSelections?.length
            ? { selections: updatedSelections }
            : {}),
        },
        {
          onSuccess: () => {
            // Second mutation: menuRearrange
            menuRearrange.mutate(result, {
              onSuccess: () => {
                void modifierGroups.refetch()
                void itemData.refetch()
                resolve() // Resolve the promise when all updates succeed
              },
              onError: reject, // Reject the promise if menuRearrange fails
            })
          },
          onError: reject, // Reject the promise if itemUpdateMutation fails
        }
      )
    })
  }
  return (
    <PageContainer title={itemData?.data?.data?.name} breadcrumbs={breadcrumbs}>
      <Box sx={{ p: 3 }}>
        <ItemEditForm
          menuItemState={menuItemState}
          categoriesOptions={categoriesOptions}
          modifierGroupOptions={modifierGroupOptions}
          itemSpecificAvailability={itemSpecificAvailability}
          setItemSpecificAvailability={setItemSpecificAvailability}
          handleSave={handleSubmitChange}
          handleModifierGroupChangeMutation={handleModifierGroupChange}
          handleNavigation={handleNavigation}
          catalogProductData={updatedCatalogProduct}
          handleChangeSequence={handleChangeSequence}
        />
      </Box>
    </PageContainer>
  )
}
