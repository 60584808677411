import { FC } from 'react'
import { QueryPicker, QueryPickerProps } from '../QueryPicker'
import { Box, Checkbox, TextField, Typography } from '@mui/material'
import { CheckBox, CheckBoxOutlineBlank } from '@mui/icons-material'
import { useIntl } from 'react-intl'

export interface AccountsPickerProps
  extends QueryPickerProps<any, boolean, boolean, boolean> {}

/**
 * Accounts picker component
 * @returns
 */
export const AccountsPicker: FC<AccountsPickerProps> = (props) => {
  const intl = useIntl()
  return (
    <QueryPicker
      {...props}
      renderOption={(props, option, { selected }) => (
        <li {...props} style={{ padding: 0 }}>
          <Checkbox
            size="small"
            icon={<CheckBoxOutlineBlank fontSize="small" />}
            checkedIcon={<CheckBox fontSize="small" />}
            sx={{ marginRight: 2, mb: 2 }}
            checked={selected}
          />
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <Typography variant="body1">{option.name}</Typography>
            <Typography variant="body2" color="textSecondary">
              {option.address.address}
            </Typography>
          </Box>
        </li>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          label={intl.formatMessage({
            id: 'action_select_account',
            defaultMessage: 'Select Account',
          })}
        />
      )}
    />
  )
}
