import { FC, PropsWithChildren } from 'react'
import {
  Box,
  Container,
  Divider,
  Grid,
  Link,
  Typography,
  Stack,
  IconButton,
} from '@mui/material'
import TwitterIcon from '@mui/icons-material/Twitter'
import FacebookIcon from '@mui/icons-material/Facebook'
import InstagramIcon from '@mui/icons-material/Instagram'

export interface WebsiteFooterProps {}

export const WebsiteFooter: FC<PropsWithChildren<WebsiteFooterProps>> = () => {
  return (
    <Box
      component="footer"
      sx={{
        mt: 'auto',
        py: 4,
        backgroundColor: (theme) => theme.palette.background.default,
      }}
    >
      <Container maxWidth="lg">
        <Grid container spacing={4}>
          {/* Brand / Title */}
          <Grid item xs={12} md={4}>
            <Typography variant="h6" gutterBottom>
              POS Hub
            </Typography>
            <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
              A Foodhub Platform
            </Typography>
            <Typography variant="caption" color="text.secondary">
              &copy; {new Date().getFullYear()} Foodhub
            </Typography>
          </Grid>

          {/* Quick Links */}
          <Grid item xs={12} sm={6} md={2}>
            <Typography variant="subtitle1" gutterBottom>
              Developer
            </Typography>
            <Link
              href={`${API_ENDPOINT}/docs/index.html`}
              color="inherit"
              underline="none"
              display="block"
              sx={{ mb: 1 }}
            >
              Documentation
            </Link>
          </Grid>

          {/* Products or Services */}
          <Grid item xs={12} sm={6} md={3}></Grid>

          {/* Social Links */}
          <Grid item xs={12} md={3}>
            <Typography variant="subtitle1" gutterBottom>
              Follow Us
            </Typography>
            <Stack direction="row" spacing={1}>
              <IconButton
                component="a"
                href="#"
                aria-label="Twitter"
                color="primary"
              >
                <TwitterIcon />
              </IconButton>
              <IconButton
                component="a"
                href="#"
                aria-label="Facebook"
                sx={{ color: '#1976d2' }}
              >
                <FacebookIcon />
              </IconButton>
              <IconButton
                component="a"
                href="#"
                aria-label="Instagram"
                sx={{ color: '#e1306c' }}
              >
                <InstagramIcon />
              </IconButton>
            </Stack>
          </Grid>
        </Grid>

        <Divider sx={{ my: 3 }} />
      </Container>
    </Box>
  )
}
