import {
  Autocomplete,
  Box,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material'
import { SwitchWithLabel } from '..'
import { useIntl } from 'react-intl'
import { useEffect, useState } from 'react'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import {
  additiveOptions,
  allergensOptions,
  dietaryRestrictionOptions,
  spicinessOptions,
} from './util'

export function MenuAdvancedOptions(props: any) {
  const intl = useIntl()
  const { menuItemState, updatedState, validateAndSet } = props
  const [nutrition, setNutrition] = useState<Nutrition>(
    menuItemState.nutritionalInfo
  )
  useEffect(() => {
    setNutrition(menuItemState.nutritionalInfo)
  }, [menuItemState.nutritionalInfo])
  const handleNutritionChange = (field: keyof Nutrition, value: any) => {
    setNutrition((prev) => ({
      ...prev,
      [field]: value,
    }))
    updatedState.nutritionalInfo = {
      ...nutrition,
      [field]: value,
    }
    validateAndSet(updatedState)
  }
  const handleAlcoholChange = (event: any) => {
    updatedState.containsAlcohol = event.target.checked
    validateAndSet(updatedState)
  }
  const handleTobaccoChange = (event: any) => {
    updatedState.containsTobacco = event.target.checked
    validateAndSet(updatedState)
  }
  const handleBikeFriendlyChange = (event: any) => {
    updatedState.isBikeFriendly = event.target.checked
    validateAndSet(updatedState)
  }
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <Grid container spacing={2}>
        <Grid item xs={6} md={3}>
          <SwitchWithLabel
            show={updatedState.containsAlcohol ?? menuItemState.containsAlcohol}
            label={intl.formatMessage({ id: 'label_contains_alcohol' })}
            handleSwitchChange={(e: any) => handleAlcoholChange(e)}
            size="medium"
          />
        </Grid>
        <Grid item xs={6} md={3}>
          <SwitchWithLabel
            show={updatedState.containsTobacco ?? menuItemState.containsTobacco}
            label={intl.formatMessage({ id: 'label_contains_tobacco' })}
            handleSwitchChange={(e: any) => handleTobaccoChange(e)}
            size="medium"
          />
        </Grid>
        <Grid item xs={6} md={3}>
          <SwitchWithLabel
            show={
              updatedState.isBikeFriendly ??
              menuItemState.isBikeFriendly ??
              true
            }
            label={intl.formatMessage({ id: 'label_is_bike_friendly' })}
            handleSwitchChange={(e: any) => handleBikeFriendlyChange(e)}
            size="medium"
          />
        </Grid>
      </Grid>
      <Grid container spacing={2} mt={1}>
        <Grid item xs={12} md={12}>
          <Typography
            variant="subtitle1"
            component="h6"
            fontWeight={600}
            id="id_nutritional_info"
          >
            {intl.formatMessage({ id: 'label_nutritional_info' })}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <EditableNutritionInfoComponent
              nutrition={nutrition}
              onNutritionChange={handleNutritionChange}
            />
          </Grid>
        </Grid>
      </Grid>
    </Box>
  )
}
interface NutritionRange {
  upperRange: number
  lowerRange: number
}
interface Nutrition {
  kilojoules?: NutritionRange
  carbohydrates?: NutritionRange
  salt?: NutritionRange
  spiciness?: string
  protein?: NutritionRange
  dietaryRestriction?: string
  calories?: NutritionRange
  saturatedFat?: NutritionRange
  sugar?: NutritionRange
  additive?: string[]
  allergens?: string[]
}

const renderAutocomplete = (
  label: string,
  options: string[],
  value: string | string[] | null,
  onChange: (event: any, newValue: string | string[] | null) => void,
  multiple: boolean = false
) => {
  // Sort the options so that selected ones come first
  const sortedOptions = [...options].sort((a, b) => {
    if (value && Array.isArray(value)) {
      const aSelected = value.includes(a)
      const bSelected = value.includes(b)
      return aSelected === bSelected ? 0 : aSelected ? -1 : 1
    }
    return 0
  })

  return (
    <Grid item xs={12} md={6}>
      <Autocomplete
        size="small"
        multiple={multiple}
        limitTags={2}
        options={sortedOptions}
        disableCloseOnSelect={multiple ? true : false}
        value={value || null} // Handle empty value
        onChange={onChange}
        renderInput={(params) => (
          <TextField {...params} label={label} variant="outlined" />
        )}
        isOptionEqualToValue={(option, value) => option === value} // Custom equality
        fullWidth
      />
    </Grid>
  )
}

const renderEditableNutritionInfo = (
  label: string,
  values: NutritionRange | string | string[] | undefined,
  onChange: (newValue: any) => void
) => {
  const [inputValue, setInputValue] = useState('')

  useEffect(() => {
    if (
      typeof values === 'object' &&
      'lowerRange' in values &&
      'upperRange' in values
    ) {
      setInputValue(
        values.lowerRange === values.upperRange
          ? `${values.lowerRange}`
          : `${values.lowerRange} - ${values.upperRange}`
      )
    } else if (typeof values === 'string') {
      setInputValue(values)
    } else {
      setInputValue('')
    }
  }, [values])

  const handleBlur = () => {
    const rangePattern = /(\d+)\s*[-to]*\s*(\d+)?/i
    const match = inputValue.match(rangePattern)
    if (match) {
      const firstValue = parseInt(match[1], 10)
      const secondValue = match[2] ? parseInt(match[2], 10) : firstValue
      const minValue = Math.min(firstValue, secondValue)
      const maxValue = Math.max(firstValue, secondValue)

      onChange({ lowerRange: minValue, upperRange: maxValue })
    } else if (!isNaN(parseInt(inputValue, 10))) {
      const singleValue = parseInt(inputValue, 10)
      onChange({ lowerRange: singleValue, upperRange: singleValue })
    } else {
      onChange({}) // Clear the value if it's invalid
    }
  }

  const handleChange = (e: any) => {
    setInputValue(e.target.value) // Only update the input value without parsing immediately
  }

  return (
    <Grid item xs={12} md={6}>
      <TextField
        label={label}
        value={inputValue}
        onChange={handleChange}
        onBlur={handleBlur} // Parse and validate on blur
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <Tooltip title="You can either enter a single value, such as 340, or a range of values, such as 340 to 450 or 340 - 450.">
                <IconButton id="id_nutritional_info_icon">
                  <InfoOutlinedIcon />
                </IconButton>
              </Tooltip>
            </InputAdornment>
          ),
        }}
        fullWidth
      />
    </Grid>
  )
}

const EditableNutritionInfoComponent = ({
  nutrition,
  onNutritionChange,
}: {
  nutrition: Nutrition
  onNutritionChange: (field: keyof Nutrition, value: any) => void
}) => {
  return (
    <>
      {renderEditableNutritionInfo(
        'Kilojoules',
        nutrition?.kilojoules,
        (newValue) => onNutritionChange('kilojoules', newValue)
      )}
      {renderEditableNutritionInfo(
        'Carbohydrates',
        nutrition?.carbohydrates,
        (newValue) => onNutritionChange('carbohydrates', newValue)
      )}
      {renderEditableNutritionInfo('Salt', nutrition?.salt, (newValue) =>
        onNutritionChange('salt', newValue)
      )}
      {renderAutocomplete(
        'Spiciness',
        spicinessOptions,
        nutrition?.spiciness || '',
        (event, newValue) => onNutritionChange('spiciness', newValue)
      )}
      {renderEditableNutritionInfo('Protein', nutrition?.protein, (newValue) =>
        onNutritionChange('protein', newValue)
      )}
      {renderAutocomplete(
        'Dietary Restriction',
        dietaryRestrictionOptions,
        nutrition?.dietaryRestriction || '',
        (event, newValue) => onNutritionChange('dietaryRestriction', newValue)
      )}
      {renderEditableNutritionInfo(
        'Calories',
        nutrition?.calories,
        (newValue) => onNutritionChange('calories', newValue)
      )}
      {renderEditableNutritionInfo(
        'Saturated Fat',
        nutrition?.saturatedFat,
        (newValue) => onNutritionChange('saturatedFat', newValue)
      )}
      {renderEditableNutritionInfo('Sugar', nutrition?.sugar, (newValue) =>
        onNutritionChange('sugar', newValue)
      )}
      {renderAutocomplete(
        'Additives',
        additiveOptions,
        nutrition?.additive || [],
        (event, newValue) => onNutritionChange('additive', newValue),
        true
      )}
      {renderAutocomplete(
        'Allergens',
        allergensOptions,
        nutrition?.allergens || [],
        (event, newValue) => onNutritionChange('allergens', newValue),
        true
      )}
    </>
  )
}
