import { Box, Button, Grid, Typography } from '@mui/material'
import { Navigate, useNavigate, useParams } from 'react-router-dom'
import { ChangeEvent, useMemo, useState } from 'react'
import {
  CatalogCategoryCreateEntity,
  CatalogCategoryPatchEntity,
} from '../../../../../../types'
import {
  getLocationQueryById,
  useCatalogCategoryById,
  useCatalogCategoryCreate,
  useCatalogCategoryUpdate,
} from '../../../../../../hooks'
import { AppLoader, CommonTextField } from '../../../../../../components'
import { useIntl } from 'react-intl'
import {
  defaultCatalogCategoryState,
  extractCatalogCategoryCreatePayload,
  extractCatalogCategoryPatchPayload,
  validateCatalogCategoryEntity,
} from '../../../../../util'
import { PageContainer } from '../../../../../../components/Page/PageContainer'

export function CatalogCategoryUpsert() {
  const navigate = useNavigate()
  const intl = useIntl()
  const { locationId, categoryId } = useParams()

  // fetching the location by id
  const locations = getLocationQueryById(locationId)
  const locationName = locations.data?.data?.name
  let breadcrumbs: any = [
    { title: 'Locations', path: '/app/locations' },
    { title: locationName, path: `/app/locations/${locationId}/analytics` },
    {
      title: 'Catalog',
      path: `/app/locations/${locationId}/catalog/categories`,
    },
    {
      title: 'Categories',
      path: `/app/locations/${locationId}/catalog/categories`,
    },
  ]
  const categoryCreate = useCatalogCategoryCreate(locationId!)
  const categoryUpdate = useCatalogCategoryUpdate(locationId!, categoryId!)
  const category = useCatalogCategoryById(locationId, categoryId, {
    refetchOnMount: 'always',
    onSuccess: (data) => {
      data?.data && setCatalogCategoryState(data.data)
    },
  })

  const handleNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    setCatalogCategoryState((state) => ({
      ...state,
      name: event.target.value,
    }))
  }
  const handleDescriptionChange = (event: ChangeEvent<HTMLInputElement>) => {
    setCatalogCategoryState((state) => ({
      ...state,
      description: event.target.value,
    }))
  }

  const handleSubmitChange = async () => {
    categoryId
      ? categoryUpdate.mutate(
          extractCatalogCategoryPatchPayload(catalogCategoryState)
        )
      : categoryCreate.mutate(
          extractCatalogCategoryCreatePayload(catalogCategoryState)
        )
  }

  const [catalogCategoryState, setCatalogCategoryState] = useState<
    CatalogCategoryCreateEntity | CatalogCategoryPatchEntity
  >(defaultCatalogCategoryState)

  const isValid = useMemo(
    () => validateCatalogCategoryEntity(catalogCategoryState),
    [catalogCategoryState]
  )

  if (
    categoryCreate.isLoading ||
    categoryUpdate.isLoading ||
    category.isFetching ||
    category.isLoading
  ) {
    return <AppLoader />
  }

  if (categoryCreate.isSuccess || categoryUpdate.isSuccess) {
    return (
      <Navigate
        to={`/app/locations/${locationId}/catalog/categories`}
        replace
      />
    )
  }
  return (
    <PageContainer title={''} breadcrumbs={breadcrumbs}>
      <Box sx={{ p: 3 }}>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12} sm={6} md={9}>
            <Box marginTop={1}>
              <Typography variant="h6" component="h1">
                {categoryId
                  ? `${intl.formatMessage({ id: 'action_update' })}`
                  : `${intl.formatMessage({ id: 'action_create' })}`}{' '}
                {intl.formatMessage({ id: 'label_category' })}
              </Typography>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            md={3}
            justifyContent="flex-end"
            display="flex"
          >
            <Button
              onClick={() =>
                navigate(`/app/locations/${locationId}/catalog/categories`)
              }
            >
              {intl.formatMessage({ id: 'action_cancel' })}
            </Button>
            <Button
              disabled={!isValid}
              onClick={handleSubmitChange}
              color="success"
              sx={{ ml: 2 }}
            >
              {categoryId
                ? `${intl.formatMessage({ id: 'action_update' })}`
                : `${intl.formatMessage({ id: 'action_create' })}`}
            </Button>
          </Grid>
        </Grid>
        <Grid container spacing={2} sx={{ marginTop: 1 }}>
          <Grid item xs={12}>
            <CommonTextField
              id="outlined-basic"
              label={`${intl.formatMessage({ id: 'label_name' })}${' *'}`}
              onChange={handleNameChange}
              value={catalogCategoryState.name || ''}
              autoFocus={true}
            />
          </Grid>
          <Grid item xs={12} mt={{ xs: 2, sm: 1 }}>
            <CommonTextField
              id="outlined-multiline-static"
              label={intl.formatMessage({ id: 'label_description' })}
              onChange={handleDescriptionChange}
              value={catalogCategoryState.description || ''}
              rows={4}
              multiline
            />
          </Grid>
        </Grid>
      </Box>
    </PageContainer>
  )
}
