import { CheckBoxOutlineBlank, CheckBox } from '@mui/icons-material'
import {
  Box,
  Grid,
  Autocomplete,
  FormControlLabel,
  Checkbox,
  TextField,
  Typography,
  Button,
  Divider,
} from '@mui/material'
import { MenuAdvancedOptions } from './MenuAdvancedOptions'
import { CollapsibleSection } from '../CollapsibleSection'
import { CommonPriceInputField } from '../CommonPriceField'
import { CommonTextField } from '../CommonTextField'
import { ServiceAvailabilityInput } from '../ServiceAvailabilityInput'
import { getCurrencySymbol } from '../../pages/util'
import { validationCheckUpdateforMenuItem } from '../../pages/Locations/CardSelect/Menu/utils'
import { useEffect, useMemo, useState } from 'react'
import { useIntl } from 'react-intl'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import { useNavigate, useParams } from 'react-router-dom'
import { RenderListView } from '../../pages/MenuV2/dialogs/ListView'
import { MenuItemPatchEntity } from '../../types'
import { ShowOnlinePicker } from '../Pickers'

const fulfillmentTypes: any = ['DELIVERY', 'PICKUP', 'DINE-IN']

export const ItemEditForm = (props: any) => {
  const {
    menuItemState,
    categoriesOptions,
    modifierGroupOptions,
    itemSpecificAvailability,
    setItemSpecificAvailability,
    handleModifierGroupChangeMutation,
    handleSave,
    handleNavigation,
    catalogProductData,
    handleChangeSequence,
  } = props
  const { itemId } = useParams()
  const currency = getCurrencySymbol()
  const intl = useIntl()
  const navigate = useNavigate()
  const [valid, setValid] = useState(false)
  const [showAdvancedOptions, setShowAdvancedOptions] = useState(false)
  const [updatedState, setUpdatedState] = useState<MenuItemPatchEntity>({})
  const [updatedModifierGroupState, setUpdatedModifierGroupState] = useState(
    modifierGroupOptions || {}
  )
  const [hasSequenceChanged, setHasSequenceChanged] = useState<boolean>(false)
  const [userUpdatedModifierGroups, setUserUpdatedModifierGroups] =
    useState(false)

  const selectedModifierGroup = useMemo(() => {
    return (
      updatedState?.modifierGroups ??
      menuItemState.modifierGroups ??
      []
    ).map((modifierGroupId: any) => {
      const selectedModifiers = modifierGroupOptions?.find(
        (option: any) => option.id === modifierGroupId
      )
      return selectedModifiers
    })
  }, [
    updatedState?.modifierGroups,
    menuItemState.modifierGroups,
    modifierGroupOptions,
  ])
  useEffect(() => {
    // Prevent overwriting when `updatedModifierGroupState` already has changes
    if (userUpdatedModifierGroups || hasSequenceChanged) {
      return
    }
    // Check if `menuItemState` has `selections`
    if (menuItemState?.selections?.length) {
      // Map over the selections to update the `modifierGroup` state
      const updatedModifierGroupState = (
        updatedState?.selections || menuItemState?.selections
      )
        ?.map((selection: any) => {
          // Find the matching modifier group
          const modifierGroup = modifierGroupOptions?.find(
            (option: any) => option.id === selection.menuModifierGroupId
          )

          // If a matching modifier group is found, return the updated group
          if (modifierGroup) {
            return {
              ...modifierGroup,
              minPermitted:
                selection.minPermitted ?? modifierGroup.minPermitted,
              maxPermitted:
                selection.maxPermitted ?? modifierGroup.maxPermitted,
            }
          }

          // Return null if no matching group is found (or handle it as needed)
          return null
        })
        .filter(Boolean) // Filter out any null values

      setUpdatedModifierGroupState(updatedModifierGroupState)
    } else {
      // Fallback to `selectedModifierGroup` if no selections are present
      setUpdatedModifierGroupState(selectedModifierGroup)
    }
  }, [selectedModifierGroup, menuItemState, modifierGroupOptions])

  function validationCheckUpdate(object: any) {
    const validationResults = validationCheckUpdateforMenuItem(object)
    // Check if all the validations pass
    const isValid = Object.values(validationResults).every((value) => value)
    setValid(isValid)
  }
  function validateAndSet(accountData: any) {
    var newObject = JSON.parse(JSON.stringify(accountData))
    setUpdatedState(newObject)
    validationCheckUpdate(newObject)
  }
  const handleAdvancedOptionsShow = () => {
    setShowAdvancedOptions(!showAdvancedOptions)
  }
  const handleShowOnlineChange = (value: boolean) => {
    updatedState.showOnline = value
    validateAndSet(updatedState)
  }
  const handleNameChange = (event: any) => {
    updatedState.name = event.target.value
    validateAndSet(updatedState)
  }
  const handleDescriptionChange = (event: any) => {
    updatedState.description = event.target.value
    validateAndSet(updatedState)
  }
  const handleFullFillmentType = (event: any, value: any) => {
    updatedState.fulfillmentTypes = value
    validateAndSet(updatedState)
  }
  const handlePriceChange = (data: any) => {
    updatedState.price = data
    validateAndSet(updatedState)
  }
  const handleinStoreChange = (data: any) => {
    updatedState.inStorePrice = data
    validateAndSet(updatedState)
  }
  const handleCategoriesChange = (_: any, newValues: any) => {
    updatedState.categories = newValues.map((newValue: any) => newValue.id)
    validateAndSet(updatedState)
  }
  const handleModifierGroupChange = (_: any, newValues: any) => {
    updatedState.modifierGroups = newValues.map((newValue: any) => newValue.id)
    // find the objects of the selected modifier groups and set the selections
    if (menuItemState?.selections?.length > 0) {
      updatedState.selections = newValues.map((newValue: any) => {
        const modifierGroup = catalogProductData.selections?.find(
          (option: any) =>
            option?.catalogModifierGroupId === newValue.catalogModifierGroupId
        )
        return modifierGroup
      })
    }
    validateAndSet(updatedState)
  }
  const onAvailabilityChange = (availability: any) => {
    const filteredAvailability = availability?.filter(
      (day: any) => day.timePeriods.length > 0
    )
    updatedState.serviceAvailability = filteredAvailability
    validateAndSet(updatedState)
  }
  const handleItemAvailability = () => {
    setItemSpecificAvailability(!itemSpecificAvailability)
  }
  const handleModifierGroupNameChange = (value: any, id: any) => {
    setUpdatedModifierGroupState((prevState: any) =>
      prevState.map((modifierGroup: any) =>
        modifierGroup.id === id
          ? { ...modifierGroup, name: value }
          : modifierGroup
      )
    )
  }

  const handleMinMaxChange = (data: any, id: string) => {
    // Update the modifier group state
    const updatedState = updatedModifierGroupState.map((modifierGroup: any) => {
      if (modifierGroup.id === id) {
        const updatedModifierGroup = {
          ...modifierGroup,
          ...data,
        }
        return updatedModifierGroup
      }
      return modifierGroup // Return other groups unchanged
    })
    setUpdatedModifierGroupState(updatedState)
  }
  const handleModifierGroupSaveIconClick = (id: any) => {
    setUserUpdatedModifierGroups(true)
    const modifierGroup = updatedModifierGroupState.find(
      (modifierGroup: any) => modifierGroup.id === id
    )
    if (modifierGroup) {
      handleModifierGroupChangeMutation(modifierGroup)
    }
  }
  const handleModifierGroupNavigation = (id: string) => {
    const currentUrl = location.pathname // Get the current URL path
    const basePath = currentUrl.includes('/edit')
      ? currentUrl.split('/edit')[0] // Correctly split the URL before '/edit'
      : currentUrl
    let newUrl = `${basePath}/modifier-groups/${id}`
    // Use navigate to change the URL
    navigate(newUrl)
  }
  const handleSequenceChangeMiddleware = (result: any) => {
    if (result.modifierGroups) {
      setUpdatedModifierGroupState((prevState: any) =>
        result.modifierGroups.map((modifierGroup: any) => {
          const updatedModifier = prevState.find(
            (updatedModifier: any) => updatedModifier.id === modifierGroup
          )
          return updatedModifier || modifierGroup
        })
      )
    }
    setHasSequenceChanged(true)
    handleChangeSequence(result)
      .then(() => setHasSequenceChanged(false))
      .catch((error: any) => console.error('Sequence update failed:', error))
  }
  return (
    <>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Typography variant="h6" id="id_item_edit_form">
          {itemId
            ? `${intl.formatMessage({ id: 'action_update' })}`
            : `${intl.formatMessage({ id: 'action_create' })}`}{' '}
          {intl.formatMessage({ id: 'label_item' })}
        </Typography>
        <Box>
          <Button
            onClick={() => handleNavigation()}
            sx={{ mr: 1 }}
            id="id_cancel_button"
          >
            {intl.formatMessage({ id: 'action_cancel' })}
          </Button>
          <Button
            onClick={() => handleSave(updatedState)}
            disabled={!valid}
            color="success"
            id="id_save_button"
          >
            {itemId
              ? `${intl.formatMessage({ id: 'action_update' })}`
              : `${intl.formatMessage({ id: 'action_create' })}`}
          </Button>
        </Box>
      </Box>
      <Box sx={{ mt: 2 }}>
        {/* Form to get the name and description of the cateogry */}
        <Grid container spacing={2}>
          {/* Name */}
          <Grid item xs={12} sm={10}>
            <CommonTextField
              id="outlined-basic"
              label={`${intl.formatMessage({ id: 'label_name' })}${' *'}`}
              onChange={handleNameChange}
              value={updatedState?.name ?? menuItemState?.name}
              autoFocus={true}
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <ShowOnlinePicker
              value={
                'showOnline' in updatedState
                  ? updatedState.showOnline
                  : menuItemState.showOnline
              }
              onChange={(value) => handleShowOnlineChange(value)}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <CommonPriceInputField
              label={`${intl.formatMessage({ id: 'label_price' })}${' *'}`}
              currency={currency}
              value={updatedState?.price ?? menuItemState.price!}
              onChange={handlePriceChange}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <CommonPriceInputField
              label={`${intl.formatMessage({
                id: 'label_inStorePrice',
              })}`}
              currency={currency}
              value={updatedState?.inStorePrice ?? menuItemState.inStorePrice!}
              onChange={handleinStoreChange}
            />
          </Grid>
          <Grid item xs={12} md={6} mt={{ xs: 2, sm: 1 }}>
            <Autocomplete
              multiple
              size="small"
              limitTags={4}
              disableCloseOnSelect
              options={modifierGroupOptions || []}
              value={(
                (updatedState?.selections?.length
                  ? updatedState?.selections?.map(
                      (sel: any) => sel.menuModifierGroupId
                    )
                  : updatedState?.modifierGroups) ||
                (menuItemState?.selections?.length
                  ? menuItemState?.selections?.map(
                      (sel: any) => sel.menuModifierGroupId
                    )
                  : menuItemState?.modifierGroups) ||
                []
              )
                .map(
                  (id: any) =>
                    modifierGroupOptions?.find(
                      (option: any) => option?.id === id
                    ) || null
                )
                .filter(Boolean)}
              onChange={handleModifierGroupChange}
              getOptionLabel={(option) => option.name}
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <FormControlLabel
                    control={<Checkbox checked={selected} />}
                    label={option.name}
                  />
                </li>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={`${intl.formatMessage({
                    id: 'label_modifier_group',
                  })}`}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={6} mt={{ xs: 2, sm: 1 }}>
            <Autocomplete
              size="small"
              multiple
              limitTags={4}
              disableCloseOnSelect
              options={categoriesOptions ? categoriesOptions : []}
              value={
                categoriesOptions?.filter((option: any) =>
                  updatedState.categories
                    ? updatedState.categories.includes(option.id)
                    : menuItemState.categories?.includes(option.id)
                ) || []
              }
              onChange={handleCategoriesChange}
              getOptionLabel={(option) => option.name}
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <FormControlLabel
                    control={<Checkbox checked={selected} />}
                    label={option.name}
                  />
                </li>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={`${intl.formatMessage({
                    id: 'label_categories',
                  })}`}
                />
              )}
            />
          </Grid>
          {/* Description */}
          <Grid item xs={12} md={12}>
            <CommonTextField
              id="outlined-basic"
              label={`${intl.formatMessage({
                id: 'label_description',
              })}`}
              onChange={handleDescriptionChange}
              value={updatedState?.description ?? menuItemState?.description}
              rows={2}
              multiline
            />
          </Grid>

          {(updatedState?.modifierGroups?.length !== 0 ||
            menuItemState?.modifierGroups?.length !== 0) &&
          modifierGroupOptions?.length ? (
            <RenderListView
              listOptions={updatedModifierGroupState.slice()}
              handleNavigation={handleModifierGroupNavigation}
              title={intl.formatMessage({
                id: 'label_modifier_groups',
              })}
              handleNameChange={handleModifierGroupNameChange}
              handleSaveIconClick={handleModifierGroupSaveIconClick}
              handleMinMaxChange={handleMinMaxChange}
              handleChangeSequence={handleSequenceChangeMiddleware}
              hasSequenceChanged={hasSequenceChanged}
              setHasSequenceChanged={setHasSequenceChanged}
            />
          ) : null}
          <Grid item xs={12}>
            <Box
              onClick={(e) => {
                e.stopPropagation()
                handleAdvancedOptionsShow()
              }}
              sx={{
                color: '#1976d2',
                cursor: 'pointer',
                display: 'flex',
                justifyContent: 'space-between',
                p: 1,
              }}
            >
              <Typography
                variant="body1"
                component="a"
                sx={{ color: 'inherit' }}
                id="id_advanced_options"
              >
                {intl.formatMessage({ id: 'label_advanced_options' })}
              </Typography>

              <KeyboardArrowDownIcon
                style={{
                  fontSize: '24px',
                  transform: showAdvancedOptions ? 'rotate(180deg)' : 'none',
                  transition: 'transform 0.5s ease-in',
                }}
              />
            </Box>
          </Grid>
          <Grid item xs={12} sx={{ pt: '0 !important' }}>
            <CollapsibleSection
              menuItemState={menuItemState}
              show={showAdvancedOptions}
              children={
                <MenuAdvancedOptions
                  menuItemState={menuItemState}
                  updatedState={updatedState}
                  validateAndSet={validateAndSet}
                />
              }
            />
            <Divider sx={{ mt: 1 }} />
          </Grid>
          <Grid item xs={12}>
            <Box
              onClick={(e) => {
                e.stopPropagation()
                handleItemAvailability()
              }}
              sx={{
                color: '#1976d2',
                cursor: 'pointer',
                display: 'flex',
                justifyContent: 'space-between',
                p: 1,
              }}
            >
              <Typography
                variant="body1"
                component="a"
                sx={{ color: 'inherit' }}
                id="id_item_specific_availability"
              >
                {intl.formatMessage({ id: 'label_item_specific_availability' })}
              </Typography>

              <KeyboardArrowDownIcon
                style={{
                  fontSize: '24px',
                  transform: itemSpecificAvailability
                    ? 'rotate(180deg)'
                    : 'none',
                  transition: 'transform 0.5s ease-in',
                }}
              />
            </Box>
          </Grid>
          <Grid item xs={12} sx={{ pt: '0 !important' }}>
            <CollapsibleSection
              menuItemState={menuItemState}
              show={itemSpecificAvailability}
              children={
                <>
                  {/* fulfilment */}
                  <Grid item xs={12} md={5}>
                    <Autocomplete
                      size="small"
                      multiple
                      limitTags={3}
                      id="checkboxes-tags-demos"
                      options={fulfillmentTypes ? fulfillmentTypes : []}
                      onChange={handleFullFillmentType}
                      value={
                        fulfillmentTypes?.filter((option: any) =>
                          updatedState?.fulfillmentTypes
                            ? updatedState.fulfillmentTypes.includes(option)
                            : menuItemState.fulfillmentTypes?.includes(option)
                        ) || []
                      }
                      disableCloseOnSelect
                      renderOption={(props, optionScope, { selected }) => (
                        <li {...props}>
                          <Checkbox
                            icon={<CheckBoxOutlineBlank fontSize="small" />}
                            checkedIcon={<CheckBox fontSize="small" />}
                            style={{ marginRight: 8 }}
                            checked={selected}
                          />
                          {optionScope}
                        </li>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          value={
                            updatedState?.fulfillmentTypes
                              ? updatedState.fulfillmentTypes?.join(', ')
                              : menuItemState?.fulfillmentTypes?.join(', ')
                          }
                          label={`${intl.formatMessage({
                            id: 'label_item',
                          })}${' '}${intl.formatMessage({
                            id: 'label_fulfilment_type',
                          })}`}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} md={8} sx={{ pt: 0 }}>
                    <ServiceAvailabilityInput
                      availabilities={
                        updatedState?.serviceAvailability ??
                        menuItemState.serviceAvailability
                      }
                      onChange={onAvailabilityChange}
                      isMandatory={false}
                      fullWidth={true}
                    />
                  </Grid>
                </>
              }
            />
          </Grid>
        </Grid>
      </Box>
    </>
  )
}
