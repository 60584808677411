import { Typography } from '@mui/material'

// Reusable component
export const CatalogViewDetails = (label: string, value: any) => {
  return (
    <Typography
      component="span"
      color="action"
      sx={{ fontSize: 'inherit' }}
      id="id_catalog_view_details"
    >
      <Typography
        component="span"
        color="grayText"
        sx={{ textTransform: 'capitalize', fontSize: 'inherit' }}
        id="id_label"
      >
        {label}
        {' :'}
      </Typography>
      <Typography
        component="span"
        sx={{ marginLeft: 1, fontSize: 'inherit' }}
        id="id_value"
      >
        {typeof value === 'boolean' ? (value ? 'Yes' : 'No') : value}
      </Typography>
    </Typography>
  )
}
