import { FC, useEffect, useState } from 'react'
import { Dialog, DialogProps } from '../../../components/Dialog'
import {
  useItemCreate,
  useMenuModifierCreate,
  useMenuModifierGroupCreate,
  useMenuModifierGroups,
  useMenuModifiers,
  useModifierGroups,
  useModifiers,
  useCategoryCreate,
  useCategory,
} from '../../../hooks'
import {
  Button,
  DialogContent,
  DialogTitle,
  Grid,
  List,
  Typography,
  DialogActions,
  ListItemButton,
  ListItemText,
  ListItem,
  ListSubheader,
  Checkbox,
  FormControlLabel,
} from '@mui/material'
import { FormattedNumber, useIntl } from 'react-intl'
import { MenuItemCreateEntity } from '../../../types'
import { LoadingButton } from '../../../components'
import { getCurrency, rebuildMenuItemData } from '../../util'
import { capitalizeFirstLetter } from '../../Locations/utils'
import { extractMenuItemCreatePayload } from '../../Locations/CardSelect/Menu/utils'

/**
 * Props for the ChooseCatalogItemsDialog component.
 */
export interface ChooseCatalogItemsDialogProps extends DialogProps {
  locationId: string
  menuId: string
  categoryId: string
  products: any
  categories: any
}

/**
 * Dialog for choosing an item from the catalog
 */
export const ChooseCatalogItemsDialog: FC<ChooseCatalogItemsDialogProps> = (
  props
) => {
  const intl = useIntl()
  const {
    locationId,
    menuId,
    categoryId,
    products,
    categories,
    ...dialogProps
  } = props

  const addMenuItem = useItemCreate(locationId, menuId)
  const createMenuModifier = useMenuModifierCreate(locationId!, menuId!)
  const createMenuModifierGroup = useMenuModifierGroupCreate(
    locationId!,
    menuId!
  )
  const createCategory = useCategoryCreate(locationId, menuId)

  const [productsData, setProductsData] = useState<any[] | undefined>(undefined)
  const [categoriesData, setCategoriesData] = useState<any[] | undefined>(
    undefined
  )
  const [selectedProductIds, setSelectedProductIds] = useState<string[]>([])
  const [selectedCatIds, setSelectedCatIds] = useState<string[]>([])

  const handleSelectAllForCategory = (
    categoryId: string,
    productIds: string[]
  ) => {
    const categoryProducts = productsData?.filter((product) =>
      product.categories?.includes(categoryId)
    )

    const areAllSelected = productIds.every((id) =>
      selectedProductIds.includes(id)
    )

    if (areAllSelected) {
      // Deselect all products in this category
      setSelectedProductIds((prev) =>
        prev.filter((id) => !productIds.includes(id))
      )

      // Update selected categories to remove this category if no products are selected
      const remainingProductCategories = selectedProductIds
        .filter((id) => !productIds.includes(id))
        .flatMap(
          (id) => productsData?.find((p) => p.id === id)?.categories || []
        )

      setSelectedCatIds((prev) =>
        prev.filter((catId) => remainingProductCategories.includes(catId))
      )
    } else {
      // Select all products in this category
      setSelectedProductIds((prev) => [...new Set([...prev, ...productIds])])

      // Ensure this category is marked as selected
      setSelectedCatIds((prev) =>
        prev.includes(categoryId) ? prev : [...prev, categoryId]
      )
    }
  }

  const handleProductSelect = (productId: string) => {
    const product = productsData?.find((product) => product.id === productId)
    const productCategories = product?.categories || []

    if (selectedProductIds.includes(productId)) {
      // If the product is already selected, remove it from selectedProductIds
      setSelectedProductIds((prev) => prev.filter((id) => id !== productId))

      // Remove categories that no longer have any selected products
      const remainingProductCategories = selectedProductIds
        .filter((id) => id !== productId)
        .flatMap(
          (id) => productsData?.find((p) => p.id === id)?.categories || []
        )

      setSelectedCatIds((prev) =>
        prev.filter((catId) => remainingProductCategories.includes(catId))
      )
    } else {
      // Add product to selectedProductIds
      setSelectedProductIds((prev) => [...prev, productId])

      // Add categories that have at least one selected product
      setSelectedCatIds((prev) => {
        const newCatIds = productCategories.filter(
          (catId: any) => !prev.includes(catId)
        )
        return [...prev, ...newCatIds]
      })
    }
  }

  const handleCategorySelect = (category: any) => {
    setSelectedCategoryId(category.id)

    // Ensure category selection reflects only if items are selected
    if (selectedCatIds.includes(category.id)) {
      const hasSelectedProducts = selectedProductIds.some((productId) =>
        productsData
          ?.find((product) => product.id === productId)
          ?.categories.includes(category.id)
      )

      if (!hasSelectedProducts) {
        // Remove the category if no products within it are selected
        setSelectedCatIds((prev) => prev.filter((id) => id !== category.id))
      }
    }
  }

  // Fetch the catalog modifiers and modifier groups data
  const catalogModifierGroups = useModifierGroups(locationId, {
    onSuccess(data: any) {
      const d = data.pages.length
      if (data.pages[d - 1].hasNextPage) {
        void catalogModifierGroups.fetchNextPage()
      }
    },
  })
  const catalogModifiers = useModifiers(locationId, {
    onSuccess(data: any) {
      const d = data.pages.length
      if (data.pages[d - 1].hasNextPage) {
        void catalogModifiers.fetchNextPage()
      }
    },
  })
  const menuCategories = useCategory(locationId, menuId, {
    refetchOnMount: 'always',
  })

  const catalogModifierGroupsData = catalogModifierGroups?.data?.pages
    ?.map((value: any) => value.data)
    .flat()
  const catalogModifiersData = catalogModifiers?.data?.pages
    ?.map((value) => value.data)
    .flat()
  const menuCategoryData = menuCategories?.data?.pages
    ?.map((value: any) => value.data)
    .flat()

  // Fetch the menu modifier groups
  const menuModifierGroups = useMenuModifierGroups(locationId, menuId, {
    onSuccess(data: any) {
      const d = data.pages.length
      if (data.pages[d - 1].hasNextPage) {
        void menuModifierGroups.fetchNextPage()
      }
    },
  })
  const menuModifierGroupsData = menuModifierGroups?.data?.pages
    ?.map((value) => value.data)
    .flat()

  // Fetch the menu Modifers
  const menuModifiers = useMenuModifiers(locationId!, menuId!, {
    onSuccess(data: any) {
      const d = data.pages.length
      if (data.pages[d - 1].hasNextPage) {
        void menuModifiers.fetchNextPage()
      }
    },
  })
  const menuModifiersData = menuModifiers?.data?.pages
    ?.map((value) => value.data)
    .flat()

  // Combine the catalog modifier groups and modifiers
  const combinedCatalogModifiers = catalogModifierGroupsData?.map(
    (catalogModifierGroup: any) => {
      // Find modifiers for the current catalogModifierGroup
      const modifiers = catalogModifiersData?.filter((modifier) =>
        catalogModifierGroup.modifiers.some(
          (catalogModifierId: any) => catalogModifierId.id === modifier.id
        )
      )
      return {
        ...catalogModifierGroup,
        modifiers: modifiers?.map(
          ({
            id,
            name,
            price,
            description,
            showOnline,
            imageUrl,
            position,
          }) => ({
            id,
            name,
            price,
            description,
            showOnline,
            imageUrl,
            position,
          })
        ),
      }
    }
  )
  useEffect(() => {
    const data = categories?.data?.pages.map((value: any) => value.data).flat()
    setCategoriesData(data)
    if (data && data.length && !selectedCategoryId) {
      setSelectedCategoryId(data[0].id)
    }
  }, [categories.data])
  useEffect(() => {
    const data = products?.data?.pages.map((value: any) => value.data).flat()
    setProductsData(data)
  }, [products.data])
  const [uploadModifierGroups, setUploadModifierGroups] = useState<any[]>([])
  const [isLoading, setIsLoading] = useState(false)

  /**  creating item manually from catalog 
  we will check the modifier group existed in the menu modifier group DB or not
  then we create the modifiers and modifier groups if they are not existed in the menu modifier group DB 
  */
  const handleCreateModifiers = async (modifier: any) => {
    try {
      const response = await createMenuModifier.mutateAsync({
        catalogModifierId: modifier.id,
        price: modifier.price,
        showOnline: modifier.showOnline || true,
        imageUrl: modifier.imageUrl,
        name: capitalizeFirstLetter(modifier.name),
        description: modifier.description,
        position: modifier.position,
      })
      return response.data // Return modifier ID
    } catch (error) {
      console.error('Error creating menu modifier:', error)
      throw error // Rethrow error to handle it outside
    }
  }

  // Function to create modifiers and modifier groups in the menu from catalog data
  const handleCreateModifierGroups = async (
    modifierGroupForSelectedProduct: any,
    catalogModifierGroupData: any,
    modifiersFound: any
  ) => {
    let createdModifierGroupIds: string[] = [] // Array to store created modifier group IDs
    try {
      // Exclude modifierGroupForSelectedProduct from catalogModifierGroupData
      const filteredCatalogModifierGroupData = catalogModifierGroupData.filter(
        (group: any) =>
          !modifierGroupForSelectedProduct.some(
            (selectedGroup: any) =>
              selectedGroup.catalogModifierGroupId === group.id
          )
      )
      for (const catalogModifierGroup of filteredCatalogModifierGroupData) {
        if (!catalogModifierGroup) continue // Skip if catalogModifierGroup is not found

        // Array to store promises returned by createMenuModifier.mutate
        const modifierPromises =
          catalogModifierGroup.modifiers?.map(async (modifier: any) => {
            if (modifiersFound.length) {
              const foundModifier = modifiersFound.find((group: any) =>
                group.find(
                  (mod: any) => mod?.catalogModifierId === modifier?.id
                )
              )
              if (!foundModifier) {
                // If no matching modifier is found, call handleCreateModifiers
                return handleCreateModifiers(modifier)
              } else {
                // Extract unique modifier ids from foundModifier array
                const modifierIds = foundModifier.map((mod: any) => mod.id)
                const uniqueModifierIds = Array.from(new Set(modifierIds))
                return uniqueModifierIds
              }
            } else {
              // If modifiersFound is empty, call handleCreateModifiers
              return handleCreateModifiers(modifier)
            }
          }) || []

        // Wait for all createMenuModifier.mutate calls to complete
        const resolvedModifierArray = await Promise.all(modifierPromises)

        // Flatten the array of arrays and remove duplicates
        const modifierIds = Array.from(
          new Set(
            resolvedModifierArray?.map((modifier: any) => modifier.id).flat()
          )
        )

        // Once all modifiers are created, proceed with createMenuModifierGroup.mutate
        const createdModifierGroup: any =
          await createMenuModifierGroup.mutateAsync({
            catalogModifierGroupId: catalogModifierGroup.id,
            name: capitalizeFirstLetter(catalogModifierGroup.name),
            description: catalogModifierGroup.description,
            maxPermitted: catalogModifierGroup.maxPermitted ?? 0,
            minPermitted: catalogModifierGroup.minPermitted ?? 0,
            position: catalogModifierGroup.position,
            modifiers: modifierIds,
          })
        createdModifierGroupIds.push(createdModifierGroup?.data) // Push the created modifier group ID
      }

      return createdModifierGroupIds // Return array of created modifier group IDs
    } catch (error) {
      console.log('Error in creating modifiers in menu:', error)
      throw error // Rethrow error to handle it outside
    }
  }

  useEffect(() => {
    const selectedProducts: any[] = selectedProductIds
      .map((id) => productsData?.find((product) => product.id === id))
      .filter((product) => product != null)
    const modifierGroupsFromSelectedProducts = selectedProducts
      .map((product) => product.modifierGroups)
      .flat()
      .filter((value, index, self) => self.indexOf(value) === index)
    setUploadModifierGroups(modifierGroupsFromSelectedProducts)

    const newCategoryIds = selectedProducts
      .flatMap((product) => product.categories)
      .filter((catId) => !selectedCatIds.includes(catId))

    if (newCategoryIds.length > 0) {
      setSelectedCatIds((prev) => [...prev, ...newCategoryIds])
    }
  }, [selectedProductIds])

  const [selectedCategoryId, setSelectedCategoryId] = useState<
    string | undefined
  >()

  const fetchModifierGroupData = async () => {
    let modiferGroupSelected: any[] = []
    if (uploadModifierGroups?.length) {
      const modifierGroupForSelectedProduct: any =
        menuModifierGroupsData?.filter((menuModifierGroup) =>
          uploadModifierGroups.includes(
            menuModifierGroup.catalogModifierGroupId
          )
        )
      const catalogModifierGroupData = uploadModifierGroups.map(
        (modifierGroupId: any) =>
          combinedCatalogModifiers?.find(
            (catalogModifierGroup) =>
              catalogModifierGroup.id === modifierGroupId
          )
      )
      const modifiersFound = catalogModifierGroupData.map((group: any) =>
        group.modifiers.map((modifier: any) => {
          const foundModifier: any = menuModifiersData?.find(
            (menuModifier) => menuModifier.catalogModifierId === modifier.id
          )
          return foundModifier
        })
      )
      const isAnyModiferMissingInMenu = modifiersFound.some((group: any) =>
        group.some((mod: any) => !mod)
      )
      if (
        modifierGroupForSelectedProduct?.length !==
        catalogModifierGroupData?.length
      ) {
        try {
          const res = await handleCreateModifierGroups(
            modifierGroupForSelectedProduct,
            catalogModifierGroupData,
            modifiersFound
          )
          modiferGroupSelected = [...res, ...modifierGroupForSelectedProduct]
        } catch (error) {
          console.error('Error while creating modifiers:', error)
        }
      } else {
        modiferGroupSelected = modifierGroupForSelectedProduct?.map(
          (modiferGroup: any) => modiferGroup
        )
      }
    }
    return modiferGroupSelected
  }

  const createOrUpdateCategories = async () => {
    const existingCategoryIds = menuCategoryData?.map(
      (cat) => cat.catalogCategoryId || []
    )
    const newCategories = selectedCatIds.filter(
      (id) => !existingCategoryIds?.includes(id)
    )

    const createCategoryPromises = newCategories.map(async (categoryId) => {
      const category = categoriesData?.find((cat) => cat.id === categoryId)
      if (category) {
        try {
          const newCategory = await createCategory.mutateAsync({
            name: capitalizeFirstLetter(category.name),
            description: category.description,
            catalogCategoryId: category.id,
            showOnline: category.showOnline ?? true,
            position: category.position,
            imageUrl: category.imageUrl,
          })
          return newCategory.data
        } catch (error) {
          console.error('Error creating category:', error)
          return null
        }
      }
    })

    const createdCategories = await Promise.all(createCategoryPromises)
    const validCreatedCategories = createdCategories.filter(
      (category) => category !== null
    )

    const currentMenuDataArray = [
      ...(menuCategoryData ? menuCategoryData : []),
      ...validCreatedCategories,
    ]

    return currentMenuDataArray
  }

  const save = async () => {
    setIsLoading(true)
    try {
      let currentMenuDataArray = await createOrUpdateCategories()
      const modifierGroupData = await fetchModifierGroupData()

      const itemsToAdd = selectedProductIds.map((productId) => {
        const product = productsData?.find(
          (product) => product.id === productId
        )
        const menuItemData = rebuildMenuItemData(product!)
        const productCategories = product?.categories || []
        const selectedMenuCategories = productCategories
          .filter((catId: any) => selectedCatIds.includes(catId))
          .map((catId: any) => {
            const menuCategory = currentMenuDataArray?.find(
              (menuCat: any) => menuCat.catalogCategoryId === catId
            )
            return menuCategory ? menuCategory.id : null
          })
          .filter((catId: any) => catId !== null)
        const modifierGroupSelected = modifierGroupData?.filter((group) =>
          product.modifierGroups.includes(group.catalogModifierGroupId)
        )
        return {
          ...menuItemData,
          categories:
            selectedMenuCategories.length > 0
              ? selectedMenuCategories
              : [categoryId], // Ensure menu categories are mapped correctly
          showOnline: menuItemData?.showOnline ?? true,
          modifierGroups: modifierGroupSelected?.map((group) => group.id) || [],
        }
      })
      // Add items to the menu
      itemsToAdd.forEach((item) => {
        addMenuItem.mutate(
          extractMenuItemCreatePayload({
            ...item,
            name: capitalizeFirstLetter(item.name),
          }) as MenuItemCreateEntity,
          {
            onSuccess: () => {
              setIsLoading(false)
              dialogProps.onClose?.({}, 'escapeKeyDown')
            },
            onError: (error) => {
              console.error('Error creating menu item:', error)
              setIsLoading(false)
            },
          }
        )
      })
    } catch (error) {
      console.error('Error saving categories and items:', error)
      setIsLoading(false)
    }
  }

  return (
    <Dialog
      {...dialogProps}
      fullWidth
      maxWidth={selectedCategoryId ? 'md' : 'xs'}
      sx={{ p: '0 !important' }}
    >
      <DialogTitle>
        Catalog
        {categoriesData?.length ? (
          <Typography variant="body2" component="p" sx={{ mb: 1 }}>
            Please select the category to add item from catalog.
          </Typography>
        ) : (
          <Typography variant="body2" component="p" sx={{ mb: 1 }}>
            No categories available in the catalog.
          </Typography>
        )}
      </DialogTitle>
      <DialogContent>
        {/* Grid that splits the UI into a sidebar of cateogries and a right side of products */}
        {categoriesData?.length ? (
          <Grid container spacing={2}>
            <Grid
              item
              xs={selectedCategoryId ? 4 : 12}
              sx={{ pl: 0, pt: 0 }}
              padding={0}
            >
              <ListSubheader sx={{ position: 'relative' }}>
                {intl.formatMessage({ id: 'label_categories' })}
              </ListSubheader>
              <List
                sx={{ backgroundColor: 'secondary', borderRadius: 2 }}
                disablePadding
              >
                <ListItem sx={{ display: 'block' }} disablePadding>
                  {/* List of categories */}
                  {categoriesData?.map((category: any) => (
                    <ListItemButton
                      sx={{
                        borderRadius: 2,
                        backgroundColor: selectedProductIds.some((productId) =>
                          productsData
                            ?.find((product) => product.id === productId)
                            ?.categories.includes(category.id)
                        )
                          ? 'rgba(237, 81, 91, 0.08)'
                          : 'inherit',
                      }}
                      divider
                      key={category.id}
                      title={category.description}
                      selected={category.id === selectedCategoryId}
                      onClick={() => handleCategorySelect(category)}
                    >
                      <ListItemText primary={category.name} />
                    </ListItemButton>
                  ))}
                </ListItem>
              </List>
            </Grid>
            <Grid item xs={8}>
              {/* List of products */}
              <CatalogProducts
                activeCategoryId={selectedCategoryId}
                products={productsData || []}
                selectedProductIds={selectedProductIds}
                onItemSelect={(productId) => handleProductSelect(productId)}
                onSelectAllForCategory={handleSelectAllForCategory}
              />
            </Grid>
          </Grid>
        ) : null}
      </DialogContent>
      <DialogActions>
        <Button onClick={() => dialogProps.onClose?.({}, 'escapeKeyDown')}>
          {intl.formatMessage({ id: 'action_cancel' })}
        </Button>
        <LoadingButton
          color="success"
          disabled={selectedProductIds.length === 0}
          loading={isLoading}
          onClick={save}
        >
          {`${intl.formatMessage({ id: 'action_add_items' })}${
            selectedProductIds.length > 0
              ? ` (${selectedProductIds.length})`
              : ''
          }`}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}

const CatalogProducts: FC<{
  activeCategoryId?: string
  products: any[]
  selectedProductIds: string[]
  onItemSelect: (productId: string) => void
  onSelectAllForCategory: (categoryId: string, productIds: string[]) => void
}> = (props) => {
  const {
    products,
    activeCategoryId,
    selectedProductIds,
    onItemSelect,
    onSelectAllForCategory,
  } = props
  const intl = useIntl()
  const currency = getCurrency()

  const entries =
    products?.filter(
      (product: any) =>
        product.categories && product.categories?.indexOf(activeCategoryId) > -1
    ) || []

  const allProductIdsInCategory = entries.map((product) => product.id)
  const areAllSelected = allProductIdsInCategory.every((id) =>
    selectedProductIds.includes(id)
  )

  if (activeCategoryId) {
    return (
      <>
        <ListSubheader
          sx={{
            position: 'relative',
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          {intl.formatMessage({ id: 'label_items' })}
          <FormControlLabel
            control={
              <Checkbox
                checked={areAllSelected}
                onChange={() =>
                  onSelectAllForCategory(
                    activeCategoryId,
                    allProductIdsInCategory
                  )
                }
                sx={{
                  '& .MuiSvgIcon-root': {
                    fontSize: '18px', // Adjust size of the checkbox icon
                  },
                }}
              />
            }
            label={intl.formatMessage({ id: 'action_select_all' })}
            sx={{
              marginRight: '8px', // Adjust spacing as needed
              '.MuiTypography-root': {
                fontSize: '12px', // Customize font size here
              },
            }}
          />
        </ListSubheader>
        <List
          sx={{ backgroundColor: 'secondary', borderRadius: 2 }}
          disablePadding
        >
          {entries.map((product) => (
            <ListItem disablePadding sx={{ display: 'block' }} key={product.id}>
              <ListItemButton
                divider
                sx={{ borderRadius: 2 }}
                alignItems="flex-start"
                onClick={() => onItemSelect(product.id)}
                selected={selectedProductIds.includes(product.id)}
              >
                <ListItemText
                  primary={
                    <>
                      <FormattedNumber
                        value={product.price / 100}
                        style="currency"
                        currency={currency}
                      />
                      {' - '}
                      {product.name}
                    </>
                  }
                  secondary={
                    <>
                      <Typography variant="body2" color="text.primary">
                        {product.description ?? (
                          <Typography variant="body2">
                            {product.description}
                          </Typography>
                        )}
                      </Typography>
                    </>
                  }
                />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </>
    )
  } else return null
}
