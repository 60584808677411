import { useState } from 'react'
import { useIntl } from 'react-intl'
import {
  Box,
  Button,
  Grid,
  List,
  ListItemText,
  Typography,
} from '@mui/material'
import { useNavigate, useParams } from 'react-router-dom'
import { getLocationQueryById, useModifierById } from '../../../../../../hooks'
import { defaultCatalogProductData } from '../../../../../util'
import {
  AppLoader,
  CatalogViewDetails,
  NutritionalInfoView,
} from '../../../../../../components'
import { CatalogModifierEntity } from '../../../../../../types'
import { getCurrencySymbol } from '../../../../../util'
import { PageContainer } from '../../../../../../components/Page/PageContainer'

export function ModifierView() {
  const navigate = useNavigate()
  const currency = getCurrencySymbol()
  const intl = useIntl()
  const { locationId, modifierId } = useParams()

  // fetching the location by id
  const locations = getLocationQueryById(locationId)
  const locationName = locations.data?.data?.name
  let breadcrumbs: any = [
    { title: 'Locations', path: '/app/locations' },
    { title: locationName, path: `/app/locations/${locationId}/analytics` },
    {
      title: 'Catalog',
      path: `/app/locations/${locationId}/catalog/categories`,
    },
    {
      title: 'Modifiers',
      path: `/app/locations/${locationId}/catalog/modifiers`,
    },
  ]
  const [price, setPriceData] = useState<any>()
  const product = useModifierById(locationId, modifierId, {
    refetchOnMount: 'always',
    onSuccess: (data) => {
      data?.data && setProductState(data.data)
      setPriceData(data.data?.price! / 100)
    },
  })

  const [productState, setProductState] = useState<CatalogModifierEntity>(
    defaultCatalogProductData
  )

  if (product.isError) {
    return (
      <Typography>{intl.formatMessage({ id: 'error_try_again' })}</Typography>
    )
  }
  if (product.isLoading || !productState?.id) {
    return <AppLoader />
  }

  return (
    <PageContainer title={''} breadcrumbs={breadcrumbs}>
      <Box sx={{ p: 3, display: 'flex', justifyContent: 'flex-end' }}>
        <Button
          onClick={() =>
            navigate(`/app/locations/${locationId}/catalog/modifiers`)
          }
        >
          {intl.formatMessage({ id: 'action_cancel' })}
        </Button>
      </Box>
      <Grid container spacing={2} sx={{ px: 3 }}>
        <Grid item xs={12} md={9} sm={12}>
          <List>
            <ListItemText sx={{ marginTop: 1 }}>
              {CatalogViewDetails(
                `${intl.formatMessage({
                  id: 'label_in_stock',
                })}`,
                productState?.showOnline
              )}
            </ListItemText>
            {productState.name && (
              <ListItemText sx={{ marginTop: 1 }}>
                {CatalogViewDetails(
                  `${intl.formatMessage({
                    id: 'label_name',
                  })}`,
                  productState.name
                )}
              </ListItemText>
            )}
            <ListItemText sx={{ marginTop: 1 }}>
              {CatalogViewDetails(
                `${intl.formatMessage({
                  id: 'label_price',
                })}`,
                `${currency} ${price}`
              )}
            </ListItemText>
            {productState.description && (
              <ListItemText sx={{ marginTop: 1 }}>
                {CatalogViewDetails(
                  `${intl.formatMessage({ id: 'label_description' })}`,
                  productState.description
                )}
              </ListItemText>
            )}
            {(productState.maxPermitted || productState.maxPermitted == 0) && (
              <ListItemText sx={{ marginTop: 1 }}>
                {CatalogViewDetails(
                  `${intl.formatMessage({
                    id: 'label_max_permitted_text',
                  })}`,
                  productState.maxPermitted
                )}
              </ListItemText>
            )}
            {(productState.minPermitted || productState.minPermitted == 0) && (
              <ListItemText sx={{ marginTop: 1 }}>
                {CatalogViewDetails(
                  `${intl.formatMessage({
                    id: 'label_min_permitted_text',
                  })}`,
                  productState.minPermitted
                )}
              </ListItemText>
            )}
            {productState.containsAlcohol && (
              <ListItemText sx={{ marginTop: 1 }}>
                {CatalogViewDetails(
                  `${intl.formatMessage({
                    id: 'label_contains_alcohol',
                  })}`,
                  productState.containsAlcohol
                )}
              </ListItemText>
            )}
            {productState.containsTobacco && (
              <ListItemText sx={{ marginTop: 1 }}>
                {CatalogViewDetails(
                  `${intl.formatMessage({
                    id: 'label_contains_tobacco',
                  })}`,
                  productState.containsTobacco
                )}
              </ListItemText>
            )}
          </List>
          <List>
            {productState.nutritionalInfo &&
            Object.keys(productState.nutritionalInfo).length > 0 ? (
              <ListItemText sx={{ marginTop: 1 }}>
                <b>
                  {intl.formatMessage({
                    id: 'lable_nutritional_info',
                  })}
                </b>
                <List>
                  {productState.nutritionalInfo &&
                    Object.entries(productState.nutritionalInfo).map(
                      ([key, value]) => (
                        <ListItemText>
                          <NutritionalInfoView
                            key={key}
                            label={key}
                            value={value}
                          />
                        </ListItemText>
                      )
                    )}
                </List>
              </ListItemText>
            ) : null}
          </List>
        </Grid>
        <Grid item xs={12} sm={12} md={3}>
          <Box sx={{ display: 'flex' }}>
            <img
              src={product.data?.data?.imageUrl}
              width="100%"
              style={{ borderRadius: '8px' }}
            />
          </Box>
        </Grid>
      </Grid>
    </PageContainer>
  )
}
