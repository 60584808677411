import { FC } from 'react'
import { Box, Divider, Toolbar } from '@mui/material'
import { WebsiteAppBar } from './components/WebsiteAppBar'
import { WebsiteFooter } from './components/WebsiteFooter'
import { applications } from './applications'
import {
  CoverageSection,
  FAQSection,
  FeaturesSection,
  // PricingSection,
  ScrollingApplicationsSection,
  HeroSection,
} from './sections'

export const WebsiteHome: FC = () => {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
      {/* Header / Navbar */}
      <WebsiteAppBar />

      {/* Shift down the content by the size of the toolbar */}
      <Toolbar disableGutters />

      {/* A grid that contains two rows of cards, each card being a specific icon and name */}
      <div id="top">
        <HeroSection applications={applications} />
      </div>

      {/* Features Section */}
      <div id="features">
        <FeaturesSection />
      </div>

      {/* Global Coverage Map */}
      <div id="coverage">
        <CoverageSection applications={applications} />
      </div>

      {/* Pricing */}
      {/* <div id="pricing">
        <PricingSection />
      </div> */}

      <div id="faq">
        <FAQSection />
      </div>

      <ScrollingApplicationsSection applications={applications} />

      {/* Optional Divider */}
      <Divider sx={{ mt: 4 }} />

      {/* Footer */}
      <WebsiteFooter />
    </Box>
  )
}
