import { FC, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useResellerAccountsById } from '../../../hooks/useResellerAccounts'
import { AppLoader, TabNavigation } from '../../../components'
import { useIntl } from 'react-intl'
import { ResellerAccountLocations } from './AccountLocations'
import { ResellerAccountSettings } from './AccountSettings'
import { ResellerAccountAudits } from './AccountAudits'
import { capitalizeFirstLetter } from '../../Locations/utils'
import { ResellerAccountDashboard } from './AccountsDashboard'
import {
  Breadcrumb,
  PageContainer,
} from '../../../components/Page/PageContainer'

export const ResellerAccountView: FC = () => {
  const { resellerId, accountId, data } = useParams()
  const navigate = useNavigate()
  const intl = useIntl()
  /**
   * Tabs for location view
   */
  const tabs = [
    intl.formatMessage({ id: 'label_analytics' }),
    intl.formatMessage({ id: 'label_locations' }),
    intl.formatMessage({ id: 'label_settings' }),
    intl.formatMessage({ id: 'label_audits' }),
  ] as const
  const [currentTab, setCurrentTab] = useState(0)
  const accountById = useResellerAccountsById(resellerId!, accountId!, {
    onSuccess: (data: any) => {
      setMyInstance(data)
    },
    refetchOnMount: 'always',
  })
  const [myInstance, setMyInstance] = useState<any>(accountById.data)
  let breadcrumbs: Breadcrumb[] = [
    { title: 'Reseller', path: `/app/resellers/${resellerId}/dashboard` },
    { title: 'Accounts', path: `/app/resellers/${resellerId}/accounts` },
    { title: capitalizeFirstLetter(data || 'analytics'), path: '' },
  ]
  useEffect(() => {
    if (data === 'locations') {
      setCurrentTab(1)
    } else if (data === 'settings') {
      setCurrentTab(2)
    } else if (data === 'audits') {
      setCurrentTab(3)
    } else {
      setCurrentTab(0)
    }
  }, [data])

  const handleTabChange = (newValue: any) => {
    setCurrentTab(newValue)
    switch (newValue) {
      case 0:
        setCurrentTab(0)
        navigate(`/app/resellers/${resellerId}/accounts/${accountId}`, {
          replace: true,
        })
        break
      case 1:
        setCurrentTab(1)
        navigate(
          `/app/resellers/${resellerId}/accounts/${accountId}/locations`,
          {
            replace: true,
          }
        )
        break
      case 2:
        setCurrentTab(2)
        navigate(
          `/app/resellers/${resellerId}/accounts/${accountId}/settings`,
          {
            replace: true,
          }
        )
        break
      case 3:
        setCurrentTab(3)
        navigate(`/app/resellers/${resellerId}/accounts/${accountId}/audits`, {
          replace: true,
        })
        break
      default:
        setCurrentTab(0)
        navigate(`/app/resellers/${resellerId}/accounts/${accountId}`, {
          replace: true,
        })
        break
    }
  }
  if (accountById.isLoading) return <AppLoader />
  return (
    <PageContainer title={myInstance.name} breadcrumbs={breadcrumbs}>
      <TabNavigation
        currentTab={currentTab}
        setCurrentTab={handleTabChange}
        navigate={navigate}
        path={`/app/resellers/${resellerId}/accounts`}
        labels={tabs}
      />
      {currentTab === 0 && (
        <ResellerAccountDashboard
          currencyValue={accountById?.data?.data?.currency}
        />
      )}
      {currentTab === 1 && <ResellerAccountLocations />}
      {currentTab === 2 && <ResellerAccountSettings />}
      {currentTab === 3 && <ResellerAccountAudits />}
    </PageContainer>
  )
}
