import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  InputAdornment,
  Typography,
} from '@mui/material'
import { Navigate, useNavigate, useParams } from 'react-router-dom'
import { ChangeEvent, useMemo, useState } from 'react'
import {
  CatalogModifierGroupCreateEntity,
  CatalogModifierGroupPatchEntity,
} from '../../../../../../types'
import {
  useModifiers,
  useModifierGroupById,
  useModifierGroupCreate,
  useModifierGroupUpdate,
  getLocationQueryById,
} from '../../../../../../hooks'
import {
  AppLoader,
  CommonTextField,
  CardWithImage,
  NoRecordFound,
} from '../../../../../../components'
import { useIntl } from 'react-intl'
import { Close, SearchOutlined } from '@mui/icons-material'
import {
  defaultModifierGroupState,
  extractModifierGroupCreatePayload,
  extractModifierGroupPatchPayload,
  validateModifierGroupEntity,
} from '../../../../../util'
import { PageContainer } from '../../../../../../components/Page/PageContainer'

export function ModifierGroupUpsert() {
  const navigate = useNavigate()
  const intl = useIntl()
  const { locationId, modifierGroupId } = useParams()

  // fetching the location by id
  const locations = getLocationQueryById(locationId)
  const locationName = locations.data?.data?.name
  let breadcrumbs: any = [
    { title: 'Locations', path: '/app/locations' },
    { title: locationName, path: `/app/locations/${locationId}/analytics` },
    {
      title: 'Catalog',
      path: `/app/locations/${locationId}/catalog/categories`,
    },
    {
      title: 'Modifier-group',
      path: `/app/locations/${locationId}/catalog/modifier-group`,
    },
  ]

  const modifiers = useModifiers(locationId!)
  const records = modifiers.data?.pages.map((value) => value.data).flat()
  const modifierGroupCreate = useModifierGroupCreate(locationId!)
  const modifierGroupUpdate = useModifierGroupUpdate(
    locationId!,
    modifierGroupId!
  )
  const modifierGroupById = useModifierGroupById(locationId, modifierGroupId, {
    refetchOnMount: 'always',
    onSuccess: (data) => {
      data?.data && setModifierGroupState(data.data)
    },
  })

  const [open, setOpen] = useState(false)
  const [searchedVal, setSearchedVal] = useState('')
  const [modifierGroupState, setModifierGroupState] = useState<
    CatalogModifierGroupCreateEntity | CatalogModifierGroupPatchEntity
  >(defaultModifierGroupState)
  const isValid = useMemo(
    () => validateModifierGroupEntity(modifierGroupState),
    [modifierGroupState]
  )

  const handleNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    setModifierGroupState((state) => ({
      ...state,
      name: event.target.value,
    }))
  }
  const handleDescriptionChange = (event: ChangeEvent<HTMLInputElement>) => {
    setModifierGroupState((state) => ({
      ...state,
      description: event.target.value,
    }))
  }
  const handleMinimumChange = (event: ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value
    if (/^\d+$/.test(inputValue) || inputValue === '') {
      setModifierGroupState((state: any) => ({
        ...state,
        minPermitted: inputValue !== '' ? parseInt(inputValue) : 0,
      }))
    }
  }
  const handleMaximumChange = (event: any) => {
    const inputValue = event.target.value
    if (/^\d+$/.test(inputValue) || inputValue === '') {
      setModifierGroupState((state: any) => ({
        ...state,
        maxPermitted: inputValue !== '' ? parseInt(inputValue) : 0,
      }))
    }
  }

  const handleChangeText = (event: any) => {
    if (event?.target?.value.trim() !== '') {
      setSearchedVal(event?.target?.value)
    } else {
      setSearchedVal('')
    }
  }

  const handleProductChange = async () => {
    modifierGroupId
      ? modifierGroupUpdate.mutate(
          extractModifierGroupPatchPayload(modifierGroupState)
        )
      : modifierGroupCreate.mutate(
          extractModifierGroupCreatePayload(modifierGroupState)
        )
  }

  const handleSelectedItem = (value: any, index: any, isSelected: any) => {
    if (isSelected) {
      // Check if the item with the same id already exists in items array
      const itemIndex = modifierGroupState.modifiers?.findIndex(
        (item) => item.id === value.id
      )
      if (itemIndex !== undefined && itemIndex !== -1) {
        // If item with the same id exists, update it
        const updatedItems = [...(modifierGroupState.modifiers || [])]
        updatedItems[itemIndex] = {
          id: value.id,
          name: value.name,
          price: parseFloat(value.price),
          // imageUrl: value.imageUrl,
        }
        setModifierGroupState((prevModifierState) => ({
          ...prevModifierState,
          modifiers: updatedItems,
        }))
      } else {
        // If item with the same id doesn't exist, add the new item
        setModifierGroupState((prevModifierState) => ({
          ...prevModifierState,
          modifiers: [
            ...(prevModifierState.modifiers || []),
            {
              id: value.id,
              name: value.name,
              price: parseFloat(value.price),
              // imageUrl: value.imageUrl,
            },
          ],
        }))
      }
    } else {
      // If the item is deselected, remove it from the items array
      setModifierGroupState((prevModifierState) => ({
        ...prevModifierState,
        modifiers: prevModifierState?.modifiers?.filter(
          (item) => item.id !== value.id
        ),
      }))
    }
  }

  if (
    modifierGroupCreate.isLoading ||
    modifierGroupUpdate.isLoading ||
    modifierGroupById.isFetching ||
    modifierGroupById.isLoading
  ) {
    return <AppLoader />
  }

  if (modifierGroupCreate.isSuccess || modifierGroupUpdate.isSuccess) {
    return (
      <Navigate
        to={`/app/locations/${locationId}/catalog/modifier-group`}
        replace
      />
    )
  }

  return (
    <PageContainer title={''} breadcrumbs={breadcrumbs}>
      <Box sx={{ p: 3 }}>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12} sm={6} md={9}>
            <Box marginTop={1}>
              <Typography variant="h6" component="h1">
                {modifierGroupId
                  ? `${intl.formatMessage({ id: 'action_update' })}`
                  : `${intl.formatMessage({ id: 'action_create' })}`}{' '}
                {intl.formatMessage({ id: 'label_modifier_group' })}
              </Typography>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            md={3}
            justifyContent="flex-end"
            display="flex"
          >
            <Button
              onClick={() =>
                navigate(`/app/locations/${locationId}/catalog/modifier-group`)
              }
            >
              {intl.formatMessage({ id: 'action_cancel' })}
            </Button>
            <Button
              disabled={!isValid}
              onClick={handleProductChange}
              color="success"
              sx={{ ml: 2 }}
            >
              {modifierGroupId
                ? `${intl.formatMessage({ id: 'action_update' })}`
                : `${intl.formatMessage({ id: 'action_create' })}`}
            </Button>
          </Grid>
        </Grid>
        <Grid container spacing={2} sx={{ marginTop: 1 }}>
          <Grid item xs={12}>
            <CommonTextField
              id="outlined-basic"
              label={`${intl.formatMessage({ id: 'label_name' })}${' *'}`}
              onChange={handleNameChange}
              value={modifierGroupState.name || ''}
              autoFocus={true}
            />
          </Grid>
          <Grid item mt={{ xs: 2, sm: 1 }} xs={12}>
            <CommonTextField
              id="outlined-multiline-static"
              label={intl.formatMessage({ id: 'label_description' })}
              onChange={handleDescriptionChange}
              value={modifierGroupState.description || ''}
              rows={4}
              multiline
            />
          </Grid>
          <Grid item xs={12} sm={5.9} mt={2} mr={{ md: 2, sm: 1.2, xs: 0 }}>
            <CommonTextField
              id="outlined-basic"
              label={intl.formatMessage({ id: 'label_min_required' })}
              onChange={handleMinimumChange}
              value={modifierGroupState.minPermitted || 0}
            />
          </Grid>
          <Grid item xs={12} sm={5.9} mt={2}>
            <CommonTextField
              id="outlined-basic"
              label={intl.formatMessage({ id: 'label_max_permitted' })}
              onChange={handleMaximumChange}
              value={modifierGroupState.maxPermitted || 0}
            />
          </Grid>
          <Grid item xs={12} mt={2}>
            <Button onClick={() => setOpen(true)}>
              {intl.formatMessage({ id: 'action_add_items' })}
            </Button>
          </Grid>
          <Grid container sx={{ marginTop: 2 }}>
            {modifierGroupState?.modifiers?.map((obj: any, index: any) => (
              <Grid item xs={12} sm={6} md={4} xl={3} key={index}>
                <CardWithImage
                  selectedItem={modifierGroupState.modifiers}
                  onClickCard={handleSelectedItem}
                  isRedirect={false}
                  data={obj}
                  onDelete={() =>
                    setModifierGroupState((prevModifierState) => ({
                      ...prevModifierState,
                      modifiers: prevModifierState?.modifiers?.filter(
                        (item) => item.id !== obj.id
                      ),
                    }))
                  }
                  isEditable={true}
                  index={index}
                  multiSelectItems={false}
                />
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Box>
      <Dialog
        open={open}
        fullWidth
        maxWidth={'sm'}
        onClose={() => setOpen(false)}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="customized-dialog-title">
          {intl.formatMessage({ id: 'action_add_items' })}
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={() => setOpen(false)}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <Close />
        </IconButton>
        <DialogContent dividers>
          <CommonTextField
            id="outlined-basic"
            size="medium"
            autoFocus={true}
            onChange={handleChangeText}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <SearchOutlined />
                </InputAdornment>
              ),
            }}
          />
          <Grid container sx={{ marginTop: 1 }}>
            {records && records.length > 0 ? (
              records
                .filter(
                  (row) =>
                    !searchedVal.length ||
                    (row as any)?.name
                      .toString()
                      .toLowerCase()
                      .includes(searchedVal.toString().toLowerCase())
                )
                .map((item, index) => (
                  <Grid item key={index} xs={12}>
                    <CardWithImage
                      onDelete={() => {
                        setModifierGroupState((prevModifierState) => ({
                          ...prevModifierState,
                          modifiers: prevModifierState?.modifiers?.filter(
                            (item) => item.id !== item.id
                          ),
                        }))
                      }}
                      selectedItem={modifierGroupState.modifiers || []}
                      onClickCard={handleSelectedItem}
                      data={item}
                      isRedirect={false}
                      isEditable={false}
                      index={index}
                      multiSelectItems={true}
                    />
                  </Grid>
                ))
            ) : (
              <NoRecordFound title="Modifiers" />
            )}
          </Grid>
        </DialogContent>
      </Dialog>
    </PageContainer>
  )
}
