export const dietaryRestrictionOptions = ['VEGAN', 'VEGETARIAN']
export const spicinessOptions = ['MILD', 'MEDIUM', 'HOT', 'UNKNOWN', 'VERY_HOT']
export const additiveOptions = [
  'UNSPECIFIED',
  'COLORANT_UNSPECIFIED',
  'COLORANT_ADVERSE_EFFECT',
  'PRESERVATIVES_UNSPECIFIED',
  'PRESERVATIVES_NITRITE_CURING_SALT',
  'PRESERVATIVES_NITRATE',
  'PRESERVATIVES_NITRATE_CURING_SALT_AND_NITRATE',
  'ANTIOXIDANT',
  'FLAVOURE_ENHANCER',
  'SULFITES',
  'BLACKENED',
  'WAXED',
  'PHOSPHATE',
  'SWEETENER_UNSPECIFIED',
  'SWEETENER_PHENYLALANINE',
  'SWEETENER_LAXATIVE',
  'ACIDIFIERS',
  'CAFFEINE_UNSPECIFIED',
  'CAFFEINE_HIGH_CAFFEINE',
  'PROTEIN_STARCH',
  'CAFFEINE_CAFFEINE',
  'QUININE',
  'GENETICALLY_MODIFIED',
  'STABILISERS',
  'PROTEIN_UNSPECIFIED',
  'PROTEIN_MILK_PROTEIN',
  'PROTEIN_EGG_WHITE',
]
export const allergensOptions = [
  'NO ALLERGENS',
  'GLUTEN',
  'CEREAL_UNSPECIFIED',
  'CEREAL_WHEAT',
  'CEREAL_RYE',
  'CEREAL_BARLEY',
  'CEREAL_OATS',
  'CEREAL_SPELT',
  'CEREAL_KAMUT',
  'CRUSTACEANS',
  'EGGS',
  'FISH',
  'PEANUTS',
  'SOYBEANS',
  'MILK',
  'NUTS_UNSPECIFIED',
  'NUTS_ALMONDS',
  'NUTS_HAZELNUTS',
  'NUTS_WALNUTS',
  'NUTS_CASHEWS',
  'NUTS_PECAN',
  'NUTS_BRAZIL',
  'NUTS_PISTACHIO',
  'NUTS_MACADAMIA',
  'NUTS_QUEENSLAND',
  'CELERY',
  'MUSTARD',
  'SESAME_SEEDS',
  'SULPHUR_DIOXIDE_SULPHITES',
  'LUPIN',
  'MOLLUSCS',
]
export const updateSelectionsWithMenuModifierIds = (
  catalogProductData: any,
  menuModifiers: any[],
  menuModifierGroups: any[]
) => {
  const findMenuModifier = (
    catalogModifierId: string,
    menuModifiers: any[]
  ) => {
    return menuModifiers?.find(
      (modifier: any) => modifier.catalogModifierId === catalogModifierId
    )
  }

  const findMenuModifierGroup = (
    catalogModifierGroupId: string,
    menuModifierGroups: any[]
  ) => {
    const group = menuModifierGroups?.find(
      (group: any) => group.catalogModifierGroupId === catalogModifierGroupId
    )
    return group
  }

  const processModifiers = (
    modifiers: any[],
    menuModifiers: any[],
    menuModifierGroups: any[]
  ): any[] => {
    return modifiers?.map((modifier: any) => {
      // Recursively process selections under each modifier, if any exist
      const updatedSelections =
        modifier.selections?.length > 0
          ? modifier.selections?.map((selection: any) => {
              const menuModifierGroup = findMenuModifierGroup(
                selection.catalogModifierGroupId,
                menuModifierGroups
              )

              // Recursively process modifiers inside selections
              return {
                ...selection,
                modifiers: processModifiers(
                  selection.modifiers || [],
                  menuModifiers,
                  menuModifierGroups
                ),
                menuModifierGroupId: menuModifierGroup?.id || null,
              }
            })
          : []

      // Find the corresponding menuModifier for this modifier
      const menuModifier = findMenuModifier(
        modifier.catalogModifierId,
        menuModifiers
      )

      return {
        ...modifier,
        selections: updatedSelections, // Update the nested selections
        menuModifierId: menuModifier?.id || null, // Update menuModifierId
      }
    })
  }

  const processSelections = (selections: any[]) => {
    return selections?.map((selection: any) => {
      const menuModifierGroup = findMenuModifierGroup(
        selection.catalogModifierGroupId,
        menuModifierGroups
      )

      return {
        ...selection,
        modifiers: processModifiers(
          selection.modifiers || [],
          menuModifiers,
          menuModifierGroups
        ),
        menuModifierGroupId: menuModifierGroup?.id || null,
      }
    })
  }

  const updatedSelections = processSelections(catalogProductData?.selections)

  return {
    ...catalogProductData,
    selections: updatedSelections,
  }
}
