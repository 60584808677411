import { FC, useState } from 'react'
import { Box, Button, Divider, Switch, TextField } from '@mui/material'
import { useIntl } from 'react-intl'
import {
  UseInfiniteQueryResult,
  UseMutationResult,
  UseQueryResult,
} from 'react-query'
import { ApplicationPicker } from '../Pickers'
import { SettingsItem } from './SettingsItem'
import { BasePicker } from '../Pickers/BasePicker'

// ------------------------------------------------------------------
// A small interface for your overall account props
// ------------------------------------------------------------------
export interface DeliverySettingsProps {
  location: UseQueryResult<any, any>
  updateLocation: UseMutationResult<any, any>
  applications: UseInfiniteQueryResult<any, any>
}

// ------------------------------------------------------------------
// The main CompanyDetails component
// ------------------------------------------------------------------
export const DeliverySettings: FC<DeliverySettingsProps> = (props) => {
  const intl = useIntl()
  const [updates, setUpdates] = useState<any>({})

  const handleSaveChanges = () => {
    props.updateLocation.mutate(updates)
  }

  const isUpdatesPending = Object.keys(updates).length > 0

  return (
    <Box>
      {/* Account Name */}
      <SettingsItem
        label="Auto Create Deliveries"
        description="When enabled, deliveries automatically created will be dispatched
              based on the dispatch settings, if an delivery is being created
              manually, it will pre-enable the dispatch option within the create
              delivery form."
      >
        <Switch
          disabled={props.location.isLoading || props.updateLocation.isLoading}
          defaultChecked={
            props.location.data?.data.autoCreateDeliveryOnDeliveryOrder
          }
          checked={updates.autoCreateDeliveryOnDeliveryOrder}
          onChange={(e) =>
            setUpdates((updates: any) => ({
              ...updates,
              autoCreateDeliveryOnDeliveryOrder: e.target.checked,
            }))
          }
        />
      </SettingsItem>
      <Divider />

      {/* Auto Dispatch Deliveries */}
      <SettingsItem
        label="Auto Dispatch Deliveries"
        description="When enabled, all deliveries will be dispatched automatically."
      >
        <Switch
          disabled={props.location.isLoading || props.updateLocation.isLoading}
          checked={props.location.data?.data.deliveryAutoDispatchEnabled}
          onChange={(e) =>
            setUpdates((updates: any) => ({
              ...updates,
              deliveryAutoDispatchEnabled: e.target.checked,
            }))
          }
        />
      </SettingsItem>
      <Divider />

      {/* Default Failure Action */}
      <SettingsItem
        label="Default Failure Action"
        description="The default action to take when a driver is unable to deliver the
              package, by default we request the driver dispose of the package,
              if this option is enabled, the driver will be requested to return
              the package to the location, this may occur additional changes."
      >
        <BasePicker
          fullWidth
          options={['DISPOSE', 'RETURN']}
          defaultValue={'DISPOSE'}
          value={props.location.data?.data.deliveryDefaultFailureAction}
          onChange={(e, value) =>
            setUpdates((updates: any) => ({
              ...updates,
              deliveryDefaultFailureAction: value,
            }))
          }
          renderInput={(params) => (
            <TextField {...params} label="Default Failure Action" />
          )}
        />
      </SettingsItem>
      <Divider />

      {/* Quote Selection Strategy */}
      <SettingsItem
        label="Quote Selection Strategy"
        description="When enabled, the delivery selection will be based on the price of
              the delivery, the lowest price delivery will be selected first."
      >
        <BasePicker
          fullWidth
          disableClearable
          options={['RANK', 'PRICE']}
          defaultChecked={
            props.location.data?.data.deliveryAutoDispatchSelectionStrategy
          }
          onChange={(e, value) =>
            setUpdates((updates: any) => ({
              ...updates,
              deliveryAutoDispatchSelectionStrategy: value,
            }))
          }
          defaultValue={'PRICE'}
          renderInput={(params) => (
            <TextField {...params} label="Delivery Selection Strategy" />
          )}
        />
      </SettingsItem>
      <Divider />

      {/* Provider Rank */}
      <SettingsItem
        label="Provider Rank"
        description="When the delivery selection strategy is set to RANK, the provider rank will be used to determine the which provider will be auto selected when creating a delivery."
      >
        <ApplicationPicker
          query={props.applications}
          filterOptions={(options) =>
            options.filter(
              (option) => option.category === 'DELIVERY' && option.isEnabled
            )
          }
          noOptionsText="No applications installed"
          fullWidth
          disableClearable
          disableCloseOnSelect
          multiple={true}
          defaultValue={props.applications.data?.pages
            .map((page) =>
              page.data?.filter((app: any) =>
                props.location.data?.data.deliveryAutoDispatchSelectionRanking?.includes(
                  app.id
                )
              )
            )
            .flat()}
          disabled={
            // If the {deliveryAutoDispatchSelectionStrategy} on the server is not 'RANK' or
            // there is a  {deliveryAutoDispatchSelectionStrategy} property in the {updates} object
            // that shows the user has switched to a different strategy
            (updates.deliveryAutoDispatchSelectionStrategy &&
              updates.deliveryAutoDispatchSelectionStrategy !== 'RANK') ||
            (props.location.data?.data.deliveryAutoDispatchSelectionStrategy !==
              'RANK' &&
              !updates.deliveryAutoDispatchSelectionStrategy) ||
            props.location.isLoading ||
            props.updateLocation.isLoading ||
            props.applications.isLoading
          }
          onChange={(e, value) => {
            setUpdates((updates: any) => ({
              ...updates,
              deliveryAutoDispatchSelectionRanking: value.map(
                ({ id }: any) => id
              ),
            }))
          }}
          renderInput={(params) => (
            <TextField {...params} label="Provider Rank" />
          )}
        />
      </SettingsItem>
      <Divider />

      {/* Single Save/Update Button at the bottom */}
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', p: 2 }}>
        <Button
          variant="contained"
          color="primary"
          onClick={handleSaveChanges}
          disabled={!isUpdatesPending || props.updateLocation.isLoading}
        >
          Save Changes
        </Button>
      </Box>
    </Box>
  )
}
