import { Box, Drawer, IconButton, Toolbar, useMediaQuery } from '@mui/material'
import { Outlet } from 'react-router'
import { Navigate } from 'react-router-dom'
import { useAccounts, useAuthStore } from '../hooks'
import { FC, useState } from 'react'
import { AppSideBar, AppBar, AppLoader } from '../components'
import { Close } from '@mui/icons-material'

/**
 * ApplicationRouteProps interface
 */
export interface ApplicationRouteProps {}

export const ApplicationRoute: FC<ApplicationRouteProps> = () => {
  const accounts = useAccounts()
  const isLoggedIn = useAuthStore((state) => state.isLoggedIn)
  const isDesktop = useMediaQuery((theme: any) => theme.breakpoints.up('md'))
  const [isDrawerOpen, setIsDrawerOpen] = useState(false)

  /**
   * This route should only be accessible if the user is logged in
   */
  if (!isLoggedIn) {
    const back = window.location.href
    const search = new URLSearchParams({ back }).toString()
    return <Navigate to={{ pathname: '/signin', search }} />
  }

  /**
   *
   */
  if (accounts.isLoading) {
    return <AppLoader />
  }

  return (
    <Box sx={{ display: 'flex' }}>
      <AppBar status={isDrawerOpen} setSideBar={setIsDrawerOpen} />
      {isDesktop && <AppSideBar />}
      {!isDesktop && (
        <Drawer
          anchor="left"
          open={isDrawerOpen}
          variant="temporary"
          onClose={() => setIsDrawerOpen(false)}
        >
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', p: 1 }}>
            <IconButton>
              <Close />
            </IconButton>
          </Box>
          <AppSideBar
            setIsDrawerOpen={setIsDrawerOpen}
            isDrawerOpen={isDrawerOpen}
          />
        </Drawer>
      )}
      <Box component="main" sx={{ flexGrow: 1, p: 1 }}>
        <Toolbar />
        <Outlet />
      </Box>
    </Box>
  )
}
