import { Box, Typography, List, ListItem, Divider, Grid } from '@mui/material'
import { getCurrency } from '../../pages/util'
import { FormattedMoney } from '../FormattedMoney'
import { Fragment } from 'react'

export interface OrderViewFooterProps {
  order: any
}

export const OrderViewFooter: React.FC<OrderViewFooterProps> = ({ order }) => {
  const currencyValue = getCurrency()
  return (
    <Box sx={{ mt: 2 }}>
      <Grid container sx={{ justifyContent: 'center', alignItems: 'center' }}>
        <Grid item xs={12} md={6}></Grid>

        {/* Charges Section */}
        <Grid
          item
          xs={12}
          md={6}
          sx={{ border: '1px solid #ccc', borderRadius: 2, my: 2, p: 0 }}
        >
          <List sx={{ p: 0 }}>
            {/* Subtotal */}
            <ListItem sx={{ justifyContent: 'space-between' }}>
              <Typography variant="body2" component="span" id="id_subtotal">
                Subtotal
              </Typography>
              <Typography
                variant="body2"
                component="span"
                id="id_subtotal_value"
              >
                <FormattedMoney
                  value={(order?.subTotal || 0) / 100}
                  currency={currencyValue}
                  decimalValue={true}
                />
              </Typography>
            </ListItem>
            <Divider />
            {/* Total Tax */}
            {order?.totalTax !== 0 && (
              <ListItem sx={{ justifyContent: 'space-between' }}>
                <Typography variant="body2" component="span" id="id_tax">
                  {'Tax'}
                </Typography>
                <Typography variant="body2" component="span" id="id_tax_value">
                  <FormattedMoney
                    value={order?.totalTax / 100}
                    currency={currencyValue}
                    decimalValue={true}
                  />
                </Typography>
              </ListItem>
            )}
            {/* Charges */}
            {order?.charges?.length > 0 &&
              order?.charges?.map((charge: any, index: number) => {
                if (charge.amount !== 0) {
                  return (
                    <ListItem
                      key={index}
                      sx={{ justifyContent: 'space-between' }}
                    >
                      <Typography
                        variant="body2"
                        component="span"
                        id={`id_charge_${charge.displayName || charge.name}`}
                      >
                        {charge.displayName || charge.name}
                      </Typography>
                      <Typography
                        variant="body2"
                        component="span"
                        id={`id_charge_${
                          charge.displayName || charge.name
                        }_value`}
                      >
                        <FormattedMoney
                          value={charge.amount / 100}
                          currency={currencyValue}
                          decimalValue={true}
                        />
                      </Typography>
                    </ListItem>
                  )
                }
                return null
              })}
            <Divider />
            {/* Discounts */}
            {order.discounts?.length > 0 &&
              order.discounts.map((discount: any, index: number) => (
                <Fragment key={index}>
                  <ListItem
                    key={index}
                    sx={{ color: 'green', justifyContent: 'space-between' }}
                  >
                    <Typography
                      variant="body2"
                      component="span"
                      id={`id_discount_${discount.displayName}`}
                    >
                      {discount.displayName}
                    </Typography>
                    <Typography
                      variant="body2"
                      component="span"
                      id={`id_discount_${discount.displayName}_value`}
                    >
                      <FormattedMoney
                        value={-(discount.amount / 100)}
                        currency={currencyValue}
                        decimalValue={true}
                      />
                    </Typography>
                  </ListItem>
                  <Divider />
                </Fragment>
              ))}
            {/* Total */}
            <ListItem sx={{ justifyContent: 'space-between' }}>
              <Typography variant="h6" component="span" id="id_total">
                Total
              </Typography>
              <Typography variant="h6" component="span" id="id_total_value">
                <FormattedMoney
                  value={(order?.total || 0) / 100}
                  currency={currencyValue}
                  decimalValue={true}
                />
              </Typography>
            </ListItem>
          </List>
        </Grid>
      </Grid>
    </Box>
  )
}
