import { FC } from 'react'
import { Grid } from '@mui/material'
import { AvgOrderValueWidget } from './widgets/AvgOrderValueWidget'
import { OrderVolumeByLocationWidget } from './widgets/OrderVolumeByLocationWidget'
import { RevenueBySourceWidget } from './widgets/RevenueBySourceWidget'
import { TotalOrderValueByFulfillmentTypes } from './widgets/TotalOrderValueByFulfillmentTypes'
import { TotalValueWidget } from './widgets/TotalValueWidget'
import { TotalOrderCountWidget } from './widgets/TotalOrdersCountWidget'
import { CompletedOrdersWidget } from './widgets/CompletedOrdersWidget'
import { RejectedOrdersWidget } from './widgets/RejectedOrdersWidget'
import { CancelledOrdersWidget } from './widgets/CancelledOrdersWidget'

/**
 * DashboardView Component, renders the dashboard view which is made up of a grid of widgets
 * @param props the props for the dashboard view, which include the metrics data returned from the API
 */
export const DashboardView: FC<{
  totalOrders: any
  totalOrderValue: any
  averageOrderValue: any
  orderStatusVolume: any
  formatLabelAsMoney: any
  foramtTickAsMoney: any
  totalOrderValueByOrderSource: any
  orderByLocationHistogram: any
  totalOrderValueByFulfillemtType: any
}> = (props) => {
  return (
    <Grid container spacing={{ xs: 1, sm: 2, md: 2, lg: 2 }}>
      <Grid item xs={12} md={4} lg={4}>
        <TotalOrderCountWidget value={props.totalOrders?.value} />
      </Grid>
      <Grid item xs={12} md={4} lg={4}>
        <TotalValueWidget value={props.totalOrderValue?.value / 100} />
      </Grid>
      <Grid item xs={12} md={4} lg={4}>
        <AvgOrderValueWidget value={props.averageOrderValue?.value / 100} />
      </Grid>

      {/* Create a stat box for COMPLETED, REJECTED and CANCELLED orders */}
      <Grid item xs={12} md={4} lg={4} sx={{ display: 'flex' }}>
        <CompletedOrdersWidget buckets={props.orderStatusVolume?.buckets} />
      </Grid>
      <Grid item xs={12} md={4} lg={4} sx={{ display: 'flex' }}>
        <RejectedOrdersWidget buckets={props.orderStatusVolume?.buckets} />
      </Grid>
      <Grid item xs={12} md={4} lg={4} sx={{ display: 'flex' }}>
        <CancelledOrdersWidget buckets={props.orderStatusVolume?.buckets} />
      </Grid>

      <Grid item xs={12} md={12} lg={12}>
        <OrderVolumeByLocationWidget
          orderByLocationHistogram={props.orderByLocationHistogram}
        />
      </Grid>
      <Grid item xs={12} md={12} lg={6}>
        <RevenueBySourceWidget
          totalOrderValueByOrderSource={props.totalOrderValueByOrderSource}
        />
      </Grid>
      <Grid item xs={12} md={12} lg={6}>
        <TotalOrderValueByFulfillmentTypes
          totalOrderValueByFulfillemtType={
            props.totalOrderValueByFulfillemtType
          }
        />
      </Grid>
    </Grid>
  )
}
