import { FC, useState } from 'react'
import { Grid, Typography } from '@mui/material'
import { UseQueryResult } from 'react-query/types'
import { useUserApplicationMetrics } from '../../../hooks'
import { GenericCard } from '../../../components'
import { RangeSelector } from '../../../components/RangeSelector'
import { BaseChart } from '../../../components/BaseChart'
import { useIntl } from 'react-intl'
import { getCurrency } from '../../util'

export interface UserApplicationAnalyticsProps {
  application: UseQueryResult<any, unknown>
}

export const UserApplicationAnalytics: FC<UserApplicationAnalyticsProps> = ({
  application,
}) => {
  const { formatNumber } = useIntl()
  const currency = getCurrency()
  const [seelctedTimeRange, setSelectedTimeRange] = useState('1m')
  const metrics = useUserApplicationMetrics(application.data.id, {
    range: seelctedTimeRange,
  })

  /**
   * If the metrics are still loading, show a loading spinner
   */
  if (metrics.isLoading) {
    return (
      <Grid container justifyContent="center" alignItems="center">
        <Typography variant="overline">Loading Metrics</Typography>
      </Grid>
    )
  }

  /**
   * if there was an error loading the metrics, show an error message
   */
  if (metrics.isError || !metrics.isSuccess) {
    return (
      <Grid container justifyContent="center" alignItems="center">
        <Typography variant="overline">Error Loading Metrics</Typography>
      </Grid>
    )
  }

  const {
    averageOrderValue,
    orderHistogram,
    totalOrderValue,
    totalOrderValueByOrderSource,
    totalOrders,
    totalOrdersByFulfillemtType,
    webhookRequests,
  } = metrics.data?.data.aggregations || {}

  /**
   * Load the metrics data
   */
  return (
    <Grid container spacing={{ xs: 1, sm: 2, md: 2, lg: 2 }}>
      <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
        <RangeSelector
          options={['6h', '1d', '3d', '1w', '1m', '3m', '6m']}
          selected={seelctedTimeRange}
          onChange={(newValue) => setSelectedTimeRange(newValue)}
        />
      </Grid>

      <Grid item xs={12} md={12} lg={12}>
        <Typography variant="h5">Webhook Metrics</Typography>
      </Grid>
      <Grid item xs={12} md={12} lg={4}>
        <GenericCard
          title="Total Webhook Requests"
          subtitle="The total number of webhook events requested"
          helpText="This is the total number of webhook events dispatched to this application, including successful, failed and retried events"
        >
          <Typography variant="h4">
            {formatNumber(webhookRequests?.doc_count)}
          </Typography>
        </GenericCard>
      </Grid>

      {/* Webhook response times, card that shows the stats, such as min, max, sum */}
      <Grid item xs={12} md={12} lg={4}>
        <GenericCard
          title="Average Webhook Response Time"
          subtitle="The average time taken to respond to webhook events"
          helpText="This is the average time taken to respond to webhook events, including successful, failed and retried events"
        >
          <Typography variant="h4">
            {formatNumber(webhookRequests?.responseTimeStats.avg, {
              style: 'unit',
              unit: 'millisecond',
            })}
          </Typography>
        </GenericCard>
      </Grid>

      {/* Max response time */}
      <Grid item xs={12} md={12} lg={4}>
        <GenericCard
          title="Max Webhook Response Time"
          subtitle="The maximum time taken to respond to webhook events"
          helpText="This is the maximum time taken to respond to webhook events, including successful, failed and retried events"
        >
          <Typography variant="h4">
            {formatNumber(webhookRequests?.responseTimeStats.max, {
              style: 'unit',
              unit: 'millisecond',
            })}
          </Typography>
        </GenericCard>
      </Grid>

      {/* Full width chart showing webhookRequests.histograms */}
      <Grid item xs={12} md={12} lg={12}>
        <GenericCard
          title="Webhook Response Time"
          subtitle="Displays the response time of webhook events"
        >
          <BaseChart
            options={{
              plugins: { legend: { position: 'right', align: 'start' } },
              responsive: true,
              scales: {
                x: {
                  type: 'timeseries',
                  stacked: true,
                },
                y: {
                  title: { text: 'Response Time' },
                  stacked: true,
                  beginAtZero: true,
                  // Format the axis is milliseconds
                  ticks: {
                    callback: (value: any) =>
                      formatNumber(value > 1000 ? value / 1000 : value, {
                        style: 'unit',
                        unit: value > 1000 ? 'second' : 'millisecond',
                      }),
                  },
                },
              },
            }}
            data={{
              labels: webhookRequests?.histograms.buckets.map(
                (bucket: any) => bucket.key
              ),
              datasets: [
                {
                  label: 'Response Time',
                  data: webhookRequests?.histograms.buckets.map(
                    (bucket: any) => bucket.responseTime.avg
                  ),
                },
              ],
            }}
            height={50}
            type="line"
          ></BaseChart>
        </GenericCard>
      </Grid>

      {/* Bar chart that uses the same histograms as above, but instead of responseTime it uses responseCode */}
      <Grid item xs={12} md={12} lg={12}>
        <GenericCard
          title="Webhook Response Code"
          subtitle="Displays the response code of webhook events"
        >
          <BaseChart
            options={{
              plugins: { legend: { position: 'right', align: 'start' } },
              responsive: true,
              scales: {
                x: {
                  type: 'timeseries',
                  stacked: true,
                },
                y: { title: { text: 'Response Code' } },
              },
            }}
            data={{
              labels: webhookRequests?.histograms.buckets.map(
                (bucket: any) => bucket.key
              ),
              datasets: [
                {
                  label: '200',
                  data: webhookRequests?.histograms.buckets.map((b: any) =>
                    b.responseCode.buckets.reduce(
                      (acc: number, c: any) =>
                        c.key === 200 ? c.doc_count : acc,
                      0
                    )
                  ),
                },
                {
                  label: '400',
                  data: webhookRequests?.histograms.buckets.map(
                    (b: any) =>
                      b.responseCode.buckets.find((c: any) => c.key === 400)
                        ?.doc_count
                  ),
                },
                {
                  label: '500',
                  data: webhookRequests?.histograms.buckets.map(
                    (b: any) =>
                      b.responseCode.buckets.find((c: any) => c.key === 500)
                        ?.doc_count
                  ),
                },
              ],
            }}
            height={50}
            type="line"
          ></BaseChart>
        </GenericCard>
      </Grid>

      {/* If the application category is a MARKETPLACE, we should show the order stats */}
      {application.data.category === 'MARKETPLACE' && (
        <>
          {/* Title */}
          <Grid item xs={12} md={12} lg={12}>
            <Typography variant="h5">Order Metrics</Typography>
            <Typography variant="body2">
              These metrics are only available for marketplace applications
            </Typography>
          </Grid>
          <Grid item xs={12} md={12} lg={4}>
            <GenericCard
              title="Total Created Orders"
              subtitle="Total orders created by this application"
              helpText="This is the total number of orders created accross all locations that have installed this application"
            >
              <Typography variant="h4">
                {formatNumber(totalOrders?.value)}
              </Typography>
            </GenericCard>
          </Grid>
          <Grid item xs={12} md={12} lg={4}>
            <GenericCard
              title="Total Order Value"
              subtitle="Total value of all orders"
              helpText="The total value of all orders placed by users"
            >
              <Typography variant="h4">
                {formatNumber(totalOrderValue?.value / 100, { currency })}
              </Typography>
            </GenericCard>
          </Grid>
          <Grid item xs={12} md={12} lg={4}>
            <GenericCard
              title="Average Order Value"
              subtitle="Average value of all orders"
              helpText="The average value of all orders placed by users"
            >
              <Typography variant="h4">
                {formatNumber(averageOrderValue?.value / 100, { currency })}
              </Typography>
            </GenericCard>
          </Grid>
          <Grid item xs={12} md={12} lg={12}>
            <GenericCard
              title="Order Volume"
              subtitle="Displays the volume of orders received for any given time period"
            >
              <BaseChart
                options={{
                  plugins: { legend: { position: 'right', align: 'start' } },
                  responsive: true,
                  scales: {
                    x: {
                      type: 'timeseries',
                      stacked: true,
                    },
                    y: { title: { text: 'Order Volume' } },
                  },
                }}
                data={{
                  labels: orderHistogram?.buckets.map(
                    (bucket: any) => bucket.key
                  ),
                  datasets: [
                    {
                      label: 'Orders',
                      data: orderHistogram?.buckets.map(
                        (bucket: any) => bucket.doc_count
                      ),
                    },
                  ],
                }}
                height={50}
                type="line"
              ></BaseChart>
            </GenericCard>
          </Grid>

          {/* Full width chart */}
          <Grid item xs={12} md={12} lg={12}>
            <GenericCard
              title="Total Orders by Fulfillment Type"
              subtitle="Displays the volume of orders received for any given fulfillment type"
            >
              <BaseChart
                options={{
                  plugins: {
                    legend: {
                      position: 'right',
                      align: 'start',
                    },
                  },
                  responsive: true,
                  scales: {
                    x: { title: { text: 'Revenue' } },
                    y: {
                      type: 'linear',
                      ticks: {
                        callback: (tickValue: any) =>
                          formatNumber(+tickValue, {
                            currency: currency,
                            style: 'currency',
                          }),
                      },
                    },
                  },
                }}
                data={{
                  labels: ['Revenue'],
                  datasets: totalOrdersByFulfillemtType?.buckets.map(
                    (bucket: any) => ({
                      label: bucket.key,
                      data: [bucket.sum.value / 100],
                    })
                  ),
                }}
                height={50}
                type="bar"
              ></BaseChart>
            </GenericCard>
          </Grid>
        </>
      )}
    </Grid>
  )
}
