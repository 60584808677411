import { FC, useState } from 'react'
import {
  Box,
  Button,
  Divider,
  Grid,
  TextField,
  Typography,
} from '@mui/material'
import { MuiTelInput } from 'mui-tel-input'
import { useIntl } from 'react-intl'
import { UseMutationResult, UseQueryResult } from 'react-query'
import {
  CountryPicker,
  getAddressComponentsFromPlace,
  getDefaultCountryValueFromCode,
  GoogleAddressPicker,
} from '../../components/Pickers'
import {
  getDefaultTimezoneValueFromCode,
  TimezonePicker,
} from '../../components/Pickers/TimezonePicker'
import {
  CurrencyPicker,
  getDefaultCurrencyValueFromCode,
} from '../../components/Pickers/CurrencyPicker'

// ------------------------------------------------------------------
// A small interface for your overall account props
// ------------------------------------------------------------------
export interface CompanyDetailsProps {
  account: UseQueryResult<any, unknown>
  updateAccount: UseMutationResult<any, unknown>
}

// ------------------------------------------------------------------
// Generic sub-component to standardize the layout of each Setting row
// ------------------------------------------------------------------
interface SettingsItemProps {
  label: string
  description: string
  children: React.ReactNode
}

const SettingsItem: FC<SettingsItemProps> = ({
  label,
  description,
  children,
}) => {
  return (
    <Grid container spacing={2} sx={{ paddingX: 3, paddingY: 2 }}>
      {/* Left side: label & description */}
      <Grid item xs={12} md={4}>
        <Typography variant="body1">{label}</Typography>
        <Typography variant="body2" color="textSecondary">
          {description}
        </Typography>
      </Grid>

      {/* Right side: the actual input or info */}
      <Grid item xs={12} md={8} sx={{ display: 'flex', alignItems: 'center' }}>
        {children}
      </Grid>
    </Grid>
  )
}

// ------------------------------------------------------------------
// The main CompanyDetails component
// ------------------------------------------------------------------
export const CompanyDetails: FC<CompanyDetailsProps> = (props) => {
  const intl = useIntl()
  const [updates, setUpdates] = useState<any>({})

  // Called whenever the address picker changes
  const onPlaceSelected = (place: google.maps.places.PlaceResult) => {
    setUpdates((prev: any) => ({
      ...prev,
      address: {
        address: place.formatted_address,
        googlePlaceId: place.place_id,
        latitude: place.geometry?.location?.lat(),
        longitude: place.geometry?.location?.lng(),
        addressComponents: getAddressComponentsFromPlace(place),
      },
    }))
  }

  const handleSaveChanges = () => {
    props.updateAccount.mutate(updates)
  }

  const isUpdatesPending = Object.keys(updates).length > 0

  return (
    <Box>
      {/* Account Name */}
      <SettingsItem
        label="Name"
        description="The name of the business or account that will be used for reports, billing and notifications"
      >
        <TextField
          disabled={props.account.isLoading || props.updateAccount.isLoading}
          defaultValue={props.account.data?.data.name}
          onChange={(e) =>
            setUpdates((updates: any) => ({ ...updates, name: e.target.value }))
          }
          fullWidth
        />
      </SettingsItem>

      <Divider />

      {/* Account Billing Address */}
      <SettingsItem
        label="Address"
        description="The address used for reporting and billing purposes, this should be the primary business address"
      >
        <GoogleAddressPicker
          disabled={props.account.isLoading || props.updateAccount.isLoading}
          placeholder={props.account.data?.data.address.address}
          textFieldProps={{ label: props.account.data?.data.address.address }}
          onPlaceSelected={onPlaceSelected}
          fullWidth
        />
      </SettingsItem>

      <Divider />

      {/* Country / Region (using <CountryPicker>) */}
      <SettingsItem
        label="Country"
        description="The country or region where the business is located"
      >
        <CountryPicker
          disabled={props.account.isLoading || props.updateAccount.isLoading}
          defaultValue={getDefaultCountryValueFromCode(
            props.account.data?.data.countryCode
          )}
          onChange={(newValue: any, country) =>
            setUpdates((prev: any) => ({ ...prev, countryCode: country?.id }))
          }
          fullWidth
        />
      </SettingsItem>

      {/* Account Phone Number */}
      <SettingsItem
        label="Phone Number"
        description="The phone number of the business or account that will be used for notifications"
      >
        <MuiTelInput
          disabled={props.account.isLoading || props.updateAccount.isLoading}
          defaultCountry={props.account.data?.data.countryCode}
          value={updates.phoneNumber ?? props.account.data?.data.phoneNumber}
          onChange={(_: any, info: any) =>
            setUpdates((prev: any) => ({
              ...prev,
              phoneNumber: info.numberValue,
            }))
          }
          fullWidth
        />
      </SettingsItem>

      <Divider />

      {/* Account Currency */}
      <SettingsItem
        label="Currency"
        description="The currency used for billing and transactions, this cannot be changed after the account is created"
      >
        {/* If you want to keep it read-only, a simple Typography might be enough */}
        <CurrencyPicker
          fullWidth
          disabled={props.account.isLoading || props.updateAccount.isLoading}
          defaultValue={getDefaultCurrencyValueFromCode(
            props.account.data?.data.currency
          )}
          onChange={(newValue: any, currency) =>
            setUpdates((prev: any) => ({ ...prev, currency: currency?.code }))
          }
        />
      </SettingsItem>

      <Divider />

      {/* Time Zone */}
      <SettingsItem
        label="Time Zone"
        description="The time zone used for scheduling and notifications regarding the account, such as date/time within reports/invoices"
      >
        <TimezonePicker
          disabled={props.account.isLoading || props.updateAccount.isLoading}
          defaultValue={getDefaultTimezoneValueFromCode(
            props.account.data?.data.timezone
          )}
          fullWidth
          onChange={(newValue: any, timezone) =>
            setUpdates((prev: any) => ({ ...prev, timezone: timezone?.name }))
          }
        />
      </SettingsItem>

      <Divider />

      {/* Single Save/Update Button at the bottom */}
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', p: 2 }}>
        <Button
          variant="contained"
          color="primary"
          onClick={handleSaveChanges}
          disabled={!isUpdatesPending || props.updateAccount.isLoading}
        >
          Save Changes
        </Button>
      </Box>
    </Box>
  )
}
