import { InputAdornment, TextField } from '@mui/material'
import { useEffect, useState } from 'react'

interface Props {
  label?: any
  currency: string
  value: number
  onChange: (data: any) => void
}
export const CommonPriceInputField: React.FC<Props> = ({
  label,
  currency,
  value,
  onChange,
}) => {
  const [price, setPrice] = useState(value)
  useEffect(() => {
    setPrice(value)
  }, [value])

  const handlePriceChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let inputValue = event.target.value

    // Remove leading zero for non-decimal values
    if (
      inputValue.startsWith('0') &&
      inputValue.length > 1 &&
      inputValue[1] !== '.'
    ) {
      inputValue = inputValue.substring(1)
    }

    // Allow empty input to be converted to 0
    if (inputValue === '') {
      setPrice(0)
      onChange(0)
      return
    }

    // Validate input for up to two decimal places
    if (/^\d*\.?\d{0,2}$/.test(inputValue)) {
      const numericValue = parseFloat(inputValue) || 0 // Convert to a number
      setPrice(numericValue)
      onChange(numericValue)
    }
  }

  return (
    <TextField
      size="small"
      id="outlined-basic"
      label={label}
      onChange={handlePriceChange}
      type="number"
      value={price}
      inputProps={{
        step: '0.01',
        maxLength: 10,
        inputMode: 'decimal', // Helps to indicate that decimal input is expected
        style: {
          // For Chrome, Safari, Edge, and Opera
          WebkitAppearance: 'none',
          MozAppearance: 'textfield',
          // For Firefox
          appearance: 'textfield',
        },
      }}
      fullWidth
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">{currency}</InputAdornment>
        ),
      }}
      sx={{
        // Specifically target number inputs to remove the arrows
        '& input[type=number]': {
          MozAppearance: 'textfield',
          WebkitAppearance: 'none',
          appearance: 'none',
          margin: 0,
          paddingLeft: '0px !important',
        },
        // Additional styling to ensure cross-browser compatibility
        '& input[type=number]::-webkit-outer-spin-button, & input[type=number]::-webkit-inner-spin-button':
          {
            WebkitAppearance: 'none',
            margin: 0,
          },
        '& input[type=number]::-moz-appearance': {
          MozAppearance: 'textfield',
        },
      }}
    />
  )
}
