import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from '@mui/material'
import { FC, useMemo, useState } from 'react'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import { StyledGridContainer } from '../../pages/MenuV2/components'
import { handleDragEndGeneric } from '../../pages/util'
import { TruncateName } from '../../pages/MenuV2/components/TruncateName'
import DragIndicatorOutlinedIcon from '@mui/icons-material/DragIndicatorOutlined'

interface SortCategoryDialogProps {
  categories: any
  setRearrangeState: (prev: any) => void
  onCancel: () => any
  onSave: () => any
}
export const SortCategoryDialog: FC<SortCategoryDialogProps> = (props: any) => {
  const { categories, setRearrangeState, onCancel, onSave } = props
  const [categoryUpdateState, setCategoryUpdateState] = useState({})
  const sortedCategories = useMemo(
    () =>
      (Object.keys(categoryUpdateState)?.length > 1
        ? categoryUpdateState
        : categories.map((category: any) => ({ ...category }))
      )?.sort((a: any, b: any) => a.position - b.position),
    [categories, categoryUpdateState]
  )

  const handleDragEnd = (result: any) => {
    handleDragEndGeneric({
      result: result, // or simply result,
      sortedList: sortedCategories.map((category: any) => ({ ...category })), // pass sortedCategories as sortedList
      rearrangeKey: 'categories', // string indicating the key to update in rearrange state
      setRearrangeState: setRearrangeState,
      setSortedList: setCategoryUpdateState,
      mergeExisting: false, // optional; for categories, usually false
    })
  }
  return (
    <Dialog open fullWidth>
      <DialogTitle>Sort Category</DialogTitle>
      <DialogContent>
        <DragDropContext onDragEnd={handleDragEnd}>
          <Droppable droppableId="categories" direction="vertical">
            {(provided: any) => (
              <Box
                ref={provided.innerRef}
                {...provided.droppableProps}
                sx={{ width: '100%' }}
              >
                {sortedCategories?.map((category: any, index: number) => (
                  <Draggable
                    key={category.id}
                    draggableId={category.id}
                    index={index}
                  >
                    {(provided: any) => (
                      <StyledGridContainer
                        container
                        key={category.id}
                        pb={4}
                        // sx={highlightStyle(category, index, 'category')}
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                      >
                        <Grid item xs={12} pb={2}>
                          <Box
                            sx={{
                              display: 'flex',
                              flexDirection: 'row',
                              justifyContent: 'flex-start',
                              alignItems: 'center',
                            }}
                            {...provided.dragHandleProps}
                          >
                            <DragIndicatorOutlinedIcon
                              sx={{ color: 'gray', mr: 1 }}
                            />
                            <Typography variant="body1">
                              {TruncateName(category.name)}
                            </Typography>
                          </Box>
                        </Grid>
                      </StyledGridContainer>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </Box>
            )}
          </Droppable>
        </DragDropContext>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel}>Cancel</Button>
        <Button onClick={onSave}>Save</Button>
      </DialogActions>
    </Dialog>
  )
}
