import CardContent from '@mui/material/CardContent'
import Typography from '@mui/material/Typography'
import {
  Alert,
  Box,
  Button,
  CardActions,
  IconButton,
  Stack,
} from '@mui/material'
import { CheckCircleOutline, Delete } from '@mui/icons-material'
import DangerousIcon from '@mui/icons-material/Dangerous'
import PendingIcon from '@mui/icons-material/Pending'
import { FC, useState } from 'react'
import { createAllowedAuthorizeUrl } from '../../pages/Authorize'
import { useIntl } from 'react-intl'
import { ApplicationEntity } from '../../types'
import { CardAvatar } from './CardAvatar'
import { BaseCard } from './BaseCard'
import { SyncCatalogDialog } from '../SyncCatalogDialog'
import { useThemeStore } from '../../theme/ThemeProvider'

export interface ApplicationCardProps {
  app: ApplicationEntity & {
    isEnabled: boolean
    connected: boolean
    isCatalogSyncEnabled: boolean
    status: 'PENDING' | 'COMPLETED' | 'FAILED' | undefined
  }
  locationId: string
  isDelete?: boolean
  handleDeleteApp?: any
  disabled?: boolean
  token: any
  accountId: string | undefined
}

const createAuthorizeUrl = (
  token: string,
  props: ApplicationCardProps,
  other?: Record<string, any>
) => {
  const params = new URLSearchParams({
    grant_type: 'authorization_code',
    client_id: props.app.id,
    redirect_uri: props.app.redirectUris[0],
    response_type: 'code',
    scope: 'provisioning',
    location_id: props.locationId,
    token,
    ...other,
  })

  return params
}

export const ApplicationCard: FC<ApplicationCardProps> = (props) => {
  const intl = useIntl()
  const [open, setOpen] = useState(false)
  const {
    app,
    handleDeleteApp,
    isDelete,
    locationId,
    disabled,
    token,
    accountId,
  } = props
  const [syncCatalogDialogOpen, setSyncCatalogDialogOpen] = useState(false)
  const { color, label, icon: Icon } = getStatusDetails(app.status)
  const theme = useThemeStore((state) => state.theme)

  const handleEnable = () => {
    if (locationId != null) {
      window.open(
        `${API_ENDPOINT}/oauth2/authorize?${createAuthorizeUrl(
          token?.access_token!,
          { ...props },
          { account_id: accountId }
        )}`,
        '_blank'
      )
    } else {
      setOpen(true)
    }
  }

  function getStatusDetails(
    status: 'PENDING' | 'COMPLETED' | 'FAILED' | undefined
  ) {
    switch (status) {
      case 'PENDING':
        return {
          color: 'orange',
          label: 'label_pending',
          icon: PendingIcon,
        }
      case 'FAILED':
        return {
          color: 'red',
          label: 'label_failed',
          icon: DangerousIcon,
        }
      case 'COMPLETED':
      default:
        return {
          color: 'green',
          label: 'label_connected',
          icon: CheckCircleOutline,
        }
    }
  }

  const handleDisable = () => {
    window.open(
      createAllowedAuthorizeUrl(
        createAuthorizeUrl(token?.access_token!, props),
        token?.access_token!,
        accountId!,
        locationId
      ),
      '_blank'
    )
  }
  const handleCardOnclick = () => {
    if (!disabled) {
      if (app.isEnabled) {
        handleDisable()
      } else {
        handleEnable()
      }
    }
  }
  return (
    <>
      <BaseCard
        onClick={handleCardOnclick}
        disabled={disabled}
        sx={{ width: '100%', cursor: 'pointer' }}
      >
        <CardContent sx={{ display: 'flex', p: 1, pb: 0 }}>
          <Box sx={{ p: 1 }}>
            <CardAvatar src={app.imageUrl} />
          </Box>
          <Box sx={{ px: 1 }}>
            <Typography variant="h6" component="div" id="id_app_name">
              {app.name}
            </Typography>
            <Typography variant="body2" id="id_app_description">
              {app.description}
            </Typography>
          </Box>
        </CardContent>
        {open && (
          <Box>
            <Stack sx={{ width: '100%' }} spacing={2}>
              <Alert severity="warning">
                {intl.formatMessage({
                  id: 'action_create_location_to_connect_app',
                })}
              </Alert>
            </Stack>
          </Box>
        )}
        <CardActions
          disableSpacing
          sx={{
            mt: 'auto',
            p: 0.5,
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <Box>
            {isDelete && (
              <IconButton
                onClick={(e) => {
                  handleDeleteApp(app)
                  e.stopPropagation()
                }}
                size="small"
                id="id_delete_app_icon"
              >
                <Delete />
              </IconButton>
            )}
            {app.isEnabled &&
              app.category === 'POS' &&
              app.isCatalogSyncEnabled === true && (
                <Button
                  onClick={(e) => {
                    setSyncCatalogDialogOpen(true)
                    e.stopPropagation()
                  }}
                  id="id_sync_catalog"
                >
                  {intl.formatMessage({ id: 'action_sync_catalog' })}
                </Button>
              )}
          </Box>
          {isDelete && (
            <Typography
              variant="caption"
              color={color}
              sx={{ display: 'flex', alignItems: 'center', mr: 1 }}
              id={`id_${label}`}
            >
              <Icon sx={{ color: { color }, fontSize: 16, mr: 0.5 }} />

              {intl.formatMessage({ id: label })}
            </Typography>
          )}
        </CardActions>
      </BaseCard>
      {syncCatalogDialogOpen && (
        <SyncCatalogDialog
          open={true}
          applicationId={app.id}
          locationId={locationId}
          onClose={() => setSyncCatalogDialogOpen(false)}
        />
      )}
    </>
  )
}
