import orders2meLogo from './assets/orders2me_logo.jpeg'

export const applications = [
  {
    name: 'Uber Eats',
    category: 'MARKETPLACE',
    availableCountries: ['US', 'CA', 'GB', 'AU', 'NZ'],
    hero: {
      id: 'uber-eats',
      type: 'application',
      data: {
        label: 'Uber Eats',
        src: 'https://t2s-android.s3.amazonaws.com/ThirdPartyAssets/ubereats.png',
      },
      position: { x: -178, y: -80 },
    },
    description:
      'Seamlessly connect your menu to Uber Eats and automate order management by receiving incoming orders directly in your POS system. Streamline operations and expand your reach by leveraging this popular marketplace.',
    imageUrl:
      'https://play-lh.googleusercontent.com/tYRirzPrNIJd6rw48wFf2caJBUTEXaKvlMn7JOm1ALQ2NM7GYwzpMClT_L18bl_MF_mL=w480-h960-rw',
  },
  {
    name: 'Clover',
    category: 'POS',
    availableCountries: ['US', 'CA', 'GB', 'AU', 'NZ'],
    hero: {
      id: 'clover',
      type: 'application',
      data: {
        label: 'Clover',
        src: 'https://t2s-android.s3.amazonaws.com/ThirdPartyAssets/clover.png',
      },
      position: { x: 236, y: -88 },
    },
    description:
      'Integrate your Clover POS system with POS Hub to centralize order handling from diverse online marketplaces. Enjoy efficient synchronization and a unified workflow that minimizes manual effort.',
    imageUrl:
      'https://t2s-android.s3.amazonaws.com/ThirdPartyAssets/clover.png',
  },
  {
    name: 'Lightspeed K Series',
    category: 'POS',
    availableCountries: ['US', 'CA', 'UK'],
    hero: {
      id: 'lightspeed-k',
      type: 'application',
      data: {
        label: 'Lightspeed K',
        src: 'https://media.tryposhub.com/thumbs/media/application/ad62f319-d989-49d4-98b2-ca7542f2831d/fc3c86e4-8824-45a0-9a1e-52c6439cc457',
      },
      position: { x: 212, y: -5 },
    },
    description:
      'Elevate your business by linking your Lightspeed POS system to POS Hub. Effortlessly manage orders from multiple marketplaces and maintain control over your operations in real time.',
    imageUrl:
      'https://media.tryposhub.com/thumbs/media/application/ad62f319-d989-49d4-98b2-ca7542f2831d/fc3c86e4-8824-45a0-9a1e-52c6439cc457',
  },
  {
    name: 'Lightspeed O Series',
    category: 'POS',
    availableCountries: ['AU', 'NZ'],
    hero: {
      id: 'lightspped-o',
      type: 'application',
      data: {
        label: 'Lightspeed',
        src: 'https://t2s-android.s3.amazonaws.com/ThirdPartyAssets/lightspeed.png',
      },
      position: { x: 218, y: -42 },
    },
    description:
      'Elevate your business by linking your Lightspeed POS system to POS Hub. Effortlessly manage orders from multiple marketplaces and maintain control over your operations in real time.',
    imageUrl:
      'https://media.tryposhub.com/thumbs/media/application/270efef5-cd50-4fac-a6b1-b50d17d9307b/26c82f95-f3af-4f8b-86dc-7a7504c501de',
  },
  {
    name: 'Toast',
    category: 'POS',
    availableCountries: ['US', 'CA', 'UK'],
    hero: {
      id: 'toast',
      type: 'application',
      data: {
        label: 'Toast',
        src: 'https://t2s-android.s3.amazonaws.com/ThirdPartyAssets/toast.png',
      },
      position: { x: 247, y: 74 },
    },
    description:
      'Optimize your restaurant operations by integrating your Toast POS system with POS Hub. Consolidate orders from various marketplaces into a single, easy-to-use platform and improve your service efficiency.',
    imageUrl: 'https://t2s-android.s3.amazonaws.com/ThirdPartyAssets/toast.png',
  },
  {
    name: 'DoorDash',
    category: 'MARKETPLACE',
    availableCountries: ['US', 'CA', 'AU', 'NZ'],
    hero: {
      id: 'doordash',
      type: 'application',
      data: {
        label: 'DoorDash',
        src: 'https://t2s-android.s3.amazonaws.com/ThirdPartyAssets/doordash.png',
      },
      position: { x: -150, y: -30 },
    },
    description:
      'Connect your DoorDash account with POS Hub to automatically receive and process orders directly in your POS system. Simplify delivery operations and enhance customer satisfaction with faster order handling.',
    imageUrl:
      'https://t2s-android.s3.amazonaws.com/ThirdPartyAssets/doordash.png',
  },
  {
    name: 'Grubhub',
    category: 'MARKETPLACE',
    availableCountries: ['US', 'CA'],
    hero: {
      id: 'grubhub',
      type: 'application',
      data: {
        label: 'Grubhub',
        src: 'https://t2s-android.s3.amazonaws.com/ThirdPartyAssets/grubhub.png',
      },
      position: { x: -171, y: 6 },
    },
    description:
      'Streamline your order workflow by integrating Grubhub with POS Hub. Manage orders from Grubhub alongside other marketplaces in a single platform, reducing complexity and increasing productivity.',
    imageUrl:
      'https://t2s-android.s3.amazonaws.com/ThirdPartyAssets/grubhub.png',
  },
  {
    name: 'Foodhub',
    category: 'POS',
    availableCountries: ['US', 'CA', 'GB', 'AU', 'NZ'],
    hero: {
      id: 'foodhub',
      type: 'application',
      data: {
        label: 'Foodhub',
        src: 'https://t2s-android.s3.amazonaws.com/ThirdPartyAssets/foodhub.png',
      },
      position: { x: -176, y: 106 },
    },
    description:
      'Leverage the power of POS Hub by connecting your Foodhub POS system to efficiently handle orders from multiple sources. Simplify your operations while ensuring accuracy and speed.',
    imageUrl:
      'https://t2s-android.s3.amazonaws.com/ThirdPartyAssets/foodhub.png',
  },
  {
    name: 'Square',
    category: 'POS',
    availableCountries: ['US', 'CA', 'GB', 'AU'],
    hero: {
      id: 'square',
      type: 'application',
      data: {
        label: 'Square',
        src: 'https://t2s-android.s3.amazonaws.com/ThirdPartyAssets/square.png',
      },
      position: { x: 234, y: 29 },
    },
    description:
      'Unify your order management by integrating your Square POS system with POS Hub. Manage and process orders from various marketplaces without missing a beat, helping you stay ahead in a competitive market.',
    imageUrl:
      'https://t2s-android.s3.amazonaws.com/ThirdPartyAssets/square.png',
  },
  {
    name: 'Shopify',
    category: 'POS',
    availableCountries: ['US', 'CA', 'GB', 'AU'],
    description:
      'Expand your Shopify store’s capabilities by connecting it to POS Hub. Centralize and streamline order management across marketplaces, ensuring seamless fulfillment and enhanced customer experience.',
    imageUrl:
      'https://t2s-android.s3.amazonaws.com/ThirdPartyAssets/shopify.png',
  },
  {
    name: 'Gloria Food',
    category: 'MARKETPLACE',
    availableCountries: ['US', 'CA', 'GB', 'AU'],
    description:
      'Integrate your Gloria Food platform with POS Hub to simplify order processing and maintain a smooth workflow. Automatically sync incoming orders into your POS system for hassle-free management.',
    imageUrl:
      'https://t2s-android.s3.amazonaws.com/ThirdPartyAssets/gloriafood.png',
  },
  {
    name: 'Micros POS',
    category: 'POS',
    availableCountries: ['US', 'CA', 'GB', 'AU'],
    hero: {
      id: 'micros3700',
      type: 'application',
      data: {
        label: 'Micros 3700',
        src: 'https://t2s-android.s3.amazonaws.com/ThirdPartyAssets/micros3700.png',
      },
      position: { x: 222, y: 110 },
    },
    description:
      'Enhance your restaurant operations by integrating your Micros POS system with POS Hub. Centralize order management from multiple marketplaces and streamline your workflow for improved efficiency.',
    imageUrl:
      'https://t2s-android.s3.amazonaws.com/ThirdPartyAssets/micros3700.png',
  },
  {
    name: 'Uber Direct',
    category: 'DELIVERY',
    availableCountries: ['US', 'CA', 'GB', 'AU', 'NZ'],
    hero: {
      id: 'uber-direct',
      type: 'application',
      data: {
        label: 'Uber Direct',
        src: 'https://media.tryposhub.com/thumbs/media/application/7a03de60-fde4-4b14-a4c9-2b00fbe76ca1/5a0def99-4865-4dd2-91e7-488719b7e5d1',
      },
      position: { x: -46, y: 93 },
    },
    desription:
      'Uber Direct is a delivery service that allows you to deliver your products directly to your customers. Connect your Uber Direct account with POS Hub to automatically receive and process orders directly in your POS system. Simplify delivery operations and enhance customer satisfaction with faster order handling.',
    imageUrl:
      'https://media.tryposhub.com/thumbs/media/application/7a03de60-fde4-4b14-a4c9-2b00fbe76ca1/5a0def99-4865-4dd2-91e7-488719b7e5d1',
  },
  // Doordash Drive
  {
    name: 'DoorDash Drive',
    category: 'DELIVERY',
    availableCountries: ['US', 'CA', 'AU', 'NZ'],
    hero: {
      id: 'doordash-drive',
      type: 'application',
      data: {
        label: 'Doordash Drive',
        src: 'https://t2s-android.s3.amazonaws.com/ThirdPartyAssets/doordash.png',
      },
      position: { x: 56, y: 86 },
    },
    description:
      'DoorDash Drive is a delivery service that allows you to deliver your products directly to your customers. Connect your DoorDash Drive account with POS Hub to automatically receive and process orders directly in your POS system. Simplify delivery operations and enhance customer satisfaction with faster order handling.',
    imageUrl:
      'https://t2s-android.s3.amazonaws.com/ThirdPartyAssets/doordash.png',
  },
  // Nash Delivery
  {
    name: 'Nash Delivery',
    category: 'DELIVERY',
    availableCountries: ['US', 'CA', 'GB', 'AU', 'NZ'],
    hero: {
      id: 'nash',
      type: 'application',
      data: {
        label: 'Uber Direct',
        src: 'https://cdn.prod.website-files.com/66e4894b3aec0251ae4a457b/67098827d4101b29c122df6f_6480da10af6e30ac6e2d9d24_Nash%2520logo.png',
      },
      position: { x: 11, y: 112 },
    },
    description:
      'Nash Delivery is a delivery service that allows you to deliver your products directly to your customers. Connect your Nash Delivery account with POS Hub to automatically receive and process orders directly in your POS system. Simplify delivery operations and enhance customer satisfaction with faster order handling.',
    imageUrl:
      'https://cdn.prod.website-files.com/66e4894b3aec0251ae4a457b/67098827d4101b29c122df6f_6480da10af6e30ac6e2d9d24_Nash%2520logo.png',
  },
  // Relay Delivery
  {
    name: 'Relay Delivery',
    category: 'DELIVERY',
    availableCountries: ['US'],
    hero: {
      id: 'relay',
      type: 'application',
      data: {
        label: 'Relay Drive',
        src: 'https://images.squarespace-cdn.com/content/601ebc23252f67260bd1986f/1613672825682-C8ERY29GV7HDR8LTAFY7/favicon.png',
      },
      position: { x: 102, y: 105 },
    },
    description:
      'Relay Delivery is a delivery service that allows you to deliver your products directly to your customers. Connect your Relay Delivery account with POS Hub to automatically receive and process orders directly in your POS system. Simplify delivery operations and enhance customer satisfaction with faster order handling.',
    imageUrl:
      'https://images.squarespace-cdn.com/content/601ebc23252f67260bd1986f/1613672825682-C8ERY29GV7HDR8LTAFY7/favicon.png',
  },
  // Just Eat
  {
    name: 'Just Eat',
    category: 'MARKETPLACE',
    availableCountries: ['GB'],
    hero: {
      id: 'just-eat',
      type: 'application',
      data: {
        label: 'Just Eat',
        src: 'https://t2s-android.s3.amazonaws.com/ThirdPartyAssets/justeat.png',
      },
      position: { x: -163, y: 60 },
    },
    description:
      'Connect your Just Eat account with POS Hub to automatically receive and process orders directly in your POS system. Simplify delivery operations and enhance customer satisfaction with faster order handling.',
    imageUrl:
      'https://t2s-android.s3.amazonaws.com/ThirdPartyAssets/justeat.png',
  },
  {
    name: 'Deliveroo',
    category: 'MARKETPLACE',
    availableCountries: ['GB'],
    hero: {
      id: 'deliveroo',
      type: 'application',
      data: {
        label: 'Deliveroo',
        src: 'https://t2s-android.s3.amazonaws.com/ThirdPartyAssets/deliveroo.png',
      },
      position: { x: -171, y: 6 },
    },
    description:
      'Connect your Deliveroo account with POS Hub to automatically receive and process orders directly in your POS system. Simplify delivery operations and enhance customer satisfaction with faster order handling.',
    imageUrl:
      'https://t2s-android.s3.amazonaws.com/ThirdPartyAssets/deliveroo.png',
  },

  // Communication apps (Whatsapp, Email, SMS)
  {
    name: 'WhatsApp',
    category: 'OTHER',
    availableCountries: ['US', 'CA', 'GB', 'AU', 'NZ'],
    hero: {
      id: 'whatsapp',
      type: 'application',
      data: {
        label: 'WhatsApp',
        src: 'https://t2s-android.s3.amazonaws.com/ThirdPartyAssets/whatsapp.png',
      },
      position: { x: 29, y: -90 },
    },
    description:
      'Connect your WhatsApp account with POS Hub to automatically receive and process orders directly in your POS system. Simplify delivery operations and enhance customer satisfaction with faster order handling.',
    imageUrl:
      'https://t2s-android.s3.amazonaws.com/ThirdPartyAssets/whatsapp.png',
  },
  {
    name: 'Email',
    category: 'OTHER',
    availableCountries: ['US', 'CA', 'GB', 'AU', 'NZ'],
    hero: {
      id: 'email',
      type: 'application',
      data: {
        label: 'Email',
        src: 'https://t2s-android.s3.amazonaws.com/ThirdPartyAssets/email.png',
      },
      position: { x: 110, y: -86 },
    },
    description:
      'Connect your Email account with POS Hub to automatically receive and process orders directly in your POS system. Simplify delivery operations and enhance customer satisfaction with faster order handling.',
    imageUrl: 'https://t2s-android.s3.amazonaws.com/ThirdPartyAssets/email.png',
  },
  {
    name: 'SMS',
    category: 'OTHER',
    availableCountries: ['US', 'CA', 'GB', 'AU', 'NZ'],
    hero: {
      id: 'sms',
      type: 'application',
      data: {
        label: 'SMS',
        src: 'https://t2s-android.s3.amazonaws.com/ThirdPartyAssets/sms.png',
      },
      position: { x: -10, y: -74 },
    },
    description:
      'Connect your SMS account with POS Hub to automatically receive and process orders directly in your POS system. Simplify delivery operations and enhance customer satisfaction with faster order handling.',
    imageUrl: 'https://t2s-android.s3.amazonaws.com/ThirdPartyAssets/sms.png',
  },
  // Fax Plus
  {
    name: 'Fax Plus',
    category: 'OTHER',
    availableCountries: ['US', 'CA', 'GB', 'AU', 'NZ'],
    hero: {
      id: 'fax-plus',
      type: 'application',
      data: {
        label: 'Fax Plus',
        src: 'https://media.tryposhub.com/thumbs/media/application/93e2c4f9-8216-4423-b736-adb42c68180a/6b7450b0-1a02-4e1a-ada6-5f8fcc1402a8',
      },
      position: { x: 70, y: -73 },
    },
    description:
      'Connect your Fax Plus account with POS Hub to automatically receive and process orders directly in your POS system. Simplify delivery operations and enhance customer satisfaction with faster order handling.',
    imageUrl:
      'https://media.tryposhub.com/thumbs/media/application/93e2c4f9-8216-4423-b736-adb42c68180a/6b7450b0-1a02-4e1a-ada6-5f8fcc1402a8',
  },

  // Fresh KDS
  {
    name: 'Fresh KDS',
    category: 'KDS',
    availableCountries: ['US', 'CA', 'GB', 'AU', 'NZ'],
    hero: {
      id: 'fresh-kds',
      type: 'application',
      data: {
        label: 'Fresh KDS',
        src: 'https://t2s-android.s3.amazonaws.com/ThirdPartyAssets/kds.png',
      },
      position: { x: -58, y: -88 },
    },
    description:
      'Connect your Fresh KDS account with POS Hub to automatically receive and process orders directly in your POS system. Simplify delivery operations and enhance customer satisfaction with faster order handling.',
    imageUrl: 'https://t2s-android.s3.amazonaws.com/ThirdPartyAssets/kds.png',
  },

  // Orders2me
  {
    name: 'Orders2me',
    category: 'MARKETPLACE',
    availableCountries: ['US'],
    hero: {
      id: 'orders2me',
      type: 'application',
      data: {
        label: 'Orders2me',
        src: orders2meLogo,
      },
      position: { x: -215, y: 43 },
    },
    description:
      'Make it easy for customers to send in direct orders by using a user-friendly online ordering system. More direct orders means more revenue & net profits.',
    imageUrl: orders2meLogo,
  },
]
