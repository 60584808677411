import * as moment from 'moment-timezone'
import { useAccounts } from '../hooks'
import {
  CatalogCategoryCreateEntity,
  CatalogCategoryPatchEntity,
  CatalogModifierCreateEntity,
  CatalogModifierGroupCreateEntity,
  CatalogModifierGroupPatchEntity,
  CatalogModifierPatchEntity,
  CatalogProductCreateEntity,
  CatalogProductEntity,
  CatalogProductPatchEntity,
  UserPatchEntity,
} from '../types'
import { HandleUndoOptions, AfterSnackBarClosedParams } from './types'
// @ts-ignore
import noImageSmall from '../assets/no_image_small.jpeg'
// import { useState } from 'react'

export function shortenText(text: any, maxLength: any) {
  return text.length > maxLength ? `${text.substring(0, maxLength)}...` : text
}

export function formatUtcDate(utcDate: any) {
  return utcDate != null
    ? moment.utc(utcDate).format('MMM DD YYYY, LT')
    : 'invalid date'
}

export function formatLocalDate(date: any) {
  return date != null
    ? moment.utc(date).local().format('MMM DD YYYY, LT')
    : 'invalid date'
}
// export const [showConfirmationDialog, setShowConfirmationDialog] =
//   useState(false)

export function shortenPrice(price: any, maxLength: any) {
  const priceString = price.toString()
  const truncatedPrice =
    priceString.length > maxLength
      ? `${priceString.substring(0, maxLength)}...`
      : priceString
  return parseFloat(truncatedPrice).toFixed(2) // Ensure the price always displays with 2 decimal places
}

export const setUrlParameter = (name: string, value: any) => {
  const searchParams = new URLSearchParams(window.location.search)
  searchParams.set(name, value)
  const newUrl = `${window.location.pathname}?${searchParams.toString()}`
  window.history.replaceState({ path: newUrl }, '', newUrl)
}

export const getUrlParameter = (name: string) => {
  const searchParams = new URLSearchParams(window.location.search)
  return searchParams.get(name)
}
export const findIdByName = (value: any, arrayList: any) => {
  const foundItem = arrayList?.find((item: any) => item.name === value)
  return foundItem ? foundItem.id : null
}
export const findNameById = (value: any, arrayList: any) => {
  const foundItem = arrayList?.find((item: any) => item.id === value)
  return foundItem ? foundItem.name : null
}

export const defaultImageUrl = noImageSmall
type CurrencySymbolMap = {
  [key: string]: string
}

export const tableImageStyles = { width: 50, height: 50, borderRadius: 8 }

const currencySymbols: CurrencySymbolMap = {
  USD: '$',
  EUR: '€',
  GBP: '£',
  JPY: '¥',
  AUD: 'A$',
  CAD: 'C$',
  CHF: 'Fr',
  CNY: '¥',
  INR: '₹',
}
export const getCurrencySymbol = () => {
  const account = useAccounts()
  const currencySymbol = currencySymbols[account.data.data[0]?.currency]
  return currencySymbol
}
export const getCurrency = () => {
  const account = useAccounts()
  // const accountCurrency = () => {
  //   const account = accounts.data.data.find(
  //     (item: any) => item.id === orderData?.data?.data?.accountId
  //   )

  //   return account ? account.currency : 'GBP'
  // }
  return account.data.data[0].currency
}

export const parseQueryString = (queryString: any) => {
  var params = []
  if (queryString.length > 0) {
    return (params = queryString.split(','))
  }
  return []
}

/** CATEGORY */

export const defaultCatalogCategoryState:
  | CatalogCategoryCreateEntity
  | CatalogCategoryPatchEntity = {
  name: '',
  description: '',
  position: 0,
  posReference: '',
}

export const validateCatalogCategoryEntity = (
  category: CatalogCategoryCreateEntity | CatalogCategoryPatchEntity
) => {
  return category && category.name?.trim() !== ''
}

export const extractCatalogCategoryCreatePayload = (
  category: CatalogCategoryCreateEntity | CatalogCategoryPatchEntity
): CatalogCategoryCreateEntity => {
  return {
    name: category.name!,
    description: category.description!,
    posReference: '',
  }
}

export const extractCatalogCategoryPatchPayload = (
  category: CatalogCategoryCreateEntity | CatalogCategoryPatchEntity
): CatalogCategoryPatchEntity => {
  return {
    name: category.name!,
    description: category.description!,
  }
}

/** PRODUCT */

export const defaultProductData:
  | CatalogProductCreateEntity
  | CatalogProductPatchEntity = {
  name: '',
  description: '',
  price: 0,
  categories: [],
}

/** PRODUCT */

export const defaultCatalogProductData: CatalogProductEntity = {
  name: '',
  description: '',
  id: '',
  price: 0,
  categories: [],
  modifierGroups: [],
  accountId: '',
  showOnline: true,
  locationId: '',
  createdAt: '',
  updatedAt: '',
  posReference: '',
}

export const extractProductPatchPayload = (
  product: CatalogProductPatchEntity | CatalogProductCreateEntity
): CatalogProductPatchEntity => {
  return {
    name: product.name,
    description: product.description,
    price: product.price,
    categories: product.categories,
  }
}
export const validateProductEntity = (
  product: CatalogProductCreateEntity | CatalogProductPatchEntity,
  isLoading: boolean,
  isEdited: boolean
) => {
  return (
    product &&
    product.name!.trim() !== '' &&
    product.price?.toLocaleString().length !== 0 &&
    product.categories?.length !== 0 &&
    !isLoading &&
    isEdited
  )
}

export const extractProductCreatePayload = (
  product: CatalogProductPatchEntity | CatalogProductCreateEntity
): CatalogProductCreateEntity => {
  return {
    name: product.name!,
    description: product.description!,
    price: product.price! * 100,
    showOnline: product.showOnline ?? true,
    modifierGroups: product.modifierGroups ?? [],
    posReference: '',
    categories: product.categories ?? [],
  }
}

/** Modifier */

export const defaultModifierState:
  | CatalogModifierCreateEntity
  | CatalogModifierPatchEntity = {
  name: '',
  description: '',
  price: 0,
  posReference: '',
}

export const validateModifierEntity = (
  modifier: CatalogModifierCreateEntity | CatalogModifierPatchEntity
) => {
  return (
    modifier &&
    modifier.name?.trim() !== '' &&
    modifier.price?.toLocaleString().length !== 0
  )
}

export const extractModifierCreatePayload = (
  modifier: CatalogModifierCreateEntity | CatalogModifierPatchEntity
): CatalogModifierCreateEntity => {
  return {
    name: modifier.name!,
    description: modifier.description!,
    price: modifier.price! * 100,
    posReference: '',
  }
}

export const extractModifierPatchPayload = (
  modifier: CatalogModifierCreateEntity | CatalogModifierPatchEntity
): CatalogModifierPatchEntity => {
  return {
    name: modifier.name!,
    description: modifier.description!,
    price: modifier.price! * 100,
  }
}

/** Modifiers Group */

export const defaultModifierGroupState:
  | CatalogModifierGroupCreateEntity
  | CatalogModifierGroupPatchEntity = {
  name: '',
  description: '',
  modifiers: [],
  posReference: '',
  minPermitted: 0,
  maxPermitted: 0,
}

export const validateModifierGroupEntity = (
  modifierGroup:
    | CatalogModifierGroupCreateEntity
    | CatalogModifierGroupPatchEntity
) => {
  return (
    modifierGroup &&
    modifierGroup.name?.trim() !== '' &&
    modifierGroup.modifiers?.length !== 0
  )
}

export const extractModifierGroupCreatePayload = (
  modifierGroup:
    | CatalogModifierGroupCreateEntity
    | CatalogModifierGroupPatchEntity
): CatalogModifierGroupCreateEntity => {
  const items = modifierGroup.modifiers!.map((item) => ({
    id: item.id!, // Use a default empty string if id is undefined
    price: item.price!, // Use a default value if price is undefined
    name: item.name!,
    // imageUrl: item.imageUrl!,
  }))
  return {
    name: modifierGroup.name!,
    description: modifierGroup.description!,
    modifiers: items!,
    minPermitted: modifierGroup.minPermitted,
    maxPermitted: modifierGroup.maxPermitted,
    posReference: '',
  }
}

export const extractModifierGroupPatchPayload = (
  modifierGroup:
    | CatalogModifierGroupCreateEntity
    | CatalogModifierGroupPatchEntity
): CatalogModifierGroupPatchEntity => {
  return {
    name: modifierGroup.name!,
    description: modifierGroup.description!,
    modifiers: modifierGroup.modifiers!,
    minPermitted: modifierGroup.minPermitted,
    maxPermitted: modifierGroup.maxPermitted,
  }
}

export const isValidString = (data: any) => {
  if (data && (data !== '' || data !== undefined || data !== null)) {
    return true
  } else return false
}

export async function fetchTimeZoneData(latLng: { lat: any; lng: any }) {
  const GOOGLE_MAPS_API_KEY_DETAILS = 'AIzaSyAt_oUjkETF5LF5HkftwDEl7l-hvVROci4'
  const timestamp = Math.floor(Date.now() / 1000) // Current timestamp in seconds
  try {
    const response = await fetch(
      `https://maps.googleapis.com/maps/api/timezone/json?language=es&location=${latLng.lat},${latLng.lng}&timestamp=${timestamp}&key=${GOOGLE_MAPS_API_KEY_DETAILS}`
    )
    if (!response.ok) {
      throw new Error('Network response was not ok')
    }
    const data = await response.json()
    return data
  } catch (error) {
    console.error('Error fetching timezone data:', error)
    return { error: true }
  }
}

export const handleDeleteConfirmed = async (options: any) => {
  const {
    setCategoriesState,
    categoriesState,
    setShowUndoSnackbar,
    deletedList,
    setDeletedList,
    setDeletedIndex,
    setDeletedItem,
    selectedProduct,
    itemsState,
    setItemsState,
    type,
  } = options
  setShowUndoSnackbar(true)
  setDeletedList([...deletedList, selectedProduct.id])
  let deletedItem = null,
    deletedItemIndex = null

  if (type === 'category') {
    const updatedMenu = categoriesState?.filter(
      (cat: any) => ![...deletedList, selectedProduct.id].includes(cat.id)
    )
    deletedItem = categoriesState?.find(
      (cat: any) => cat.id === selectedProduct.id
    )

    deletedItemIndex = categoriesState?.findIndex(
      (cat: any) => cat.id === selectedProduct.id
    )
    setCategoriesState(updatedMenu as any)
  } else {
    const updatedMenu = itemsState?.filter(
      (cat: any) => ![...deletedList, selectedProduct.id].includes(cat.id)
    )
    deletedItem = itemsState?.find((cat: any) => cat.id === selectedProduct.id)

    deletedItemIndex = itemsState?.findIndex(
      (cat: any) => cat.id === selectedProduct.id
    )
    setItemsState(updatedMenu)
  }
  setDeletedItem(deletedItem)
  setDeletedIndex(deletedItemIndex)
}

export const handleSnackbarClose = (reason: any, setShowUndoSnackbar: any) => {
  if (reason === 'clickaway' || reason === 'external') {
    return
  }
  setShowUndoSnackbar(false)
}

const setProductOnUndo = (options: any) => {
  const {
    deletedProduct,
    categoriesState,
    deletedIndex,
    setCategoriesState,
    setItemsState,
    itemsState,
    type,
  } = options

  if (type === 'category') {
    // Create a copy of the menuItems array
    const updatedMenu = categoriesState as any

    if (deletedIndex !== -1) {
      updatedMenu.splice(deletedIndex, 0, deletedProduct)
      const uniqueMenu = updatedMenu.filter((item: any, index: number) => {
        return updatedMenu.findIndex((obj: any) => obj.id === item.id) === index
      })
      setCategoriesState(uniqueMenu)
    }
  } else {
    const updatedMenu = itemsState as any
    if (deletedIndex !== -1) {
      updatedMenu.splice(deletedIndex, 0, deletedProduct)
      const uniqueMenu = updatedMenu.filter((item: any, index: number) => {
        return updatedMenu.findIndex((obj: any) => obj.id === item.id) === index
      })
      setItemsState(uniqueMenu!)
    }
  }
}

export const handleUndo = (options: HandleUndoOptions) => {
  const {
    setShowUndoSnackbar,
    setDeletedList,
    deletedList,
    setHighlightedRow,
  } = options
  setShowUndoSnackbar(false)
  setHighlightedRow(true)
  setDeletedList([...deletedList.slice(0, -1)])
  setProductOnUndo(options)
}
export const afterSnackBarClosed = (options: AfterSnackBarClosedParams) => {
  const {
    setShowUndoSnackbar,
    deletedProduct,
    productDelete,
    setItemError,
    type,
    setCatError,
  } = options

  setShowUndoSnackbar(false)
  //actuall deletion
  productDelete.mutate(deletedProduct.id, {
    onSuccess: () => {
      // setShowConfirmationDialog(false)
    },
    onError: () => {
      type === 'item' ? setItemError() : setCatError()
    },
  })
}

export const highlightedGridStyle = (
  product: any,
  highlightedRow: any,
  deletedProduct: any
) => {
  return {
    cursor: 'default !important',
    backgroundColor:
      highlightedRow && deletedProduct?.id === product.id
        ? '#E2EFFF'
        : 'inherit',
    transition: 'background-color 0.5s linear',
  }
}

export const extractUserPatchPayload = (user: UserPatchEntity) => {
  return {
    firstName: user.firstName,
    lastName: user.lastName,
    language: user.language,
    password: user.password!,
  }
}
interface ResellerUserPatchEntity {
  firstName: string
  lastName: string
  email: string
  phone: string
  password: string
  language: string
  accounts: string[]
}
export const extractResellerUserPatchPayload = (
  user: ResellerUserPatchEntity
) => {
  return {
    firstName: user.firstName,
    lastName: user.lastName,
    language: user.language,
    password: user.password!,
    phone: user.phone!,
    email: user.email!,
    accounts: user.accounts!,
  }
}
interface ResellerAccountPatchEntity {
  name: string
  currency: string
  phoneNumber: string
  address: {
    country: string
    place_id: string
    lat: number
    lng: number
    formatted_address: string
  }
  ownerId: string
  lastOwnerId?: string
}
export const extractResellerAccountPatchPayload = (
  account: ResellerAccountPatchEntity
) => {
  return {
    name: account.name,
    currency: account.currency,
    phoneNumber: account.phoneNumber,
    address: {
      country: account.address.country,
      place_id: account.address.place_id,
      lat: account.address.lat,
      lng: account.address.lng,
      formatted_address: account.address.formatted_address,
    },
    ownerId: account.ownerId,
    lastOwnerId: account?.lastOwnerId!,
  }
}

// helper function to rebuild the menu item data from the catalog product data
export const rebuildMenuItemData = (catalogProductData: any) => {
  // fields to exclude from menuItemData
  const excludedFields = [
    'accountId',
    'createdAt',
    'id', // Replaced by catalogProductId
    'locationId',
    'originalImageUrl',
    'posReference',
    'updatedAt',
    'selections',
  ]

  // Create a new object by filtering out excluded fields
  const menuItemData = Object.keys(catalogProductData)
    .filter((key: any) => !excludedFields.includes(key))
    .reduce((acc: any, key: any) => {
      acc[key] = catalogProductData[key]
      return acc
    }, {})

  // Add any additional transformations or mappings required
  menuItemData.catalogProductId = catalogProductData.id // Map `id` to `catalogProductId`
  menuItemData.fulfillmentTypes = catalogProductData.fulfillmentTypes || []

  return menuItemData
}
export const extractSelectionIds = (selections: any) => {
  const result: any = {
    modifierGroupIds: new Set<string>(),
    modifierIds: new Set<string>(),
  }
  const traverseSelections = (items: any) => {
    items?.forEach((item: any) => {
      if (item.catalogModifierGroupId) {
        result.modifierGroupIds.add(item.catalogModifierGroupId)
      }

      if (item.catalogModifierId) {
        result.modifierIds.add(item.catalogModifierId)
      }

      if (item.selections && item.selections.length > 0) {
        traverseSelections(item.selections)
      }

      if (item.modifiers && item.modifiers.length > 0) {
        traverseSelections(item.modifiers)
      }
    })
  }
  traverseSelections(selections)
  // Convert sets to arrays before returning
  const uniqueResult = {
    modifierGroupIds: Array.from(result.modifierGroupIds),
    modifierIds: Array.from(result.modifierIds),
  }
  return uniqueResult
}

export const findIdsFromMenuData = (ids: any[], data: any, type: string) => {
  /** Return 2 arrays of matching ids and non-matching ids. and the 2 arays names as matchingIds and nonMatchingIds.
   * The function takes in 3 parameters:
   * 1. ids: an array of ids to find in the data array
   * 2. data: an array of objects to search for the ids
   * 3. type: a string to specify the type of id to search for. It can be either 'Modifier' or 'ModifierGroup' or 'Category' or 'Product'
  }*/
  return ids.reduce(
    (acc: any, id: any) => {
      // Find the item in the data array that matches the id if the item exists push the item.id to the matchingIds array
      const foundItem = data.find(
        (item: any) => item[`catalog${type}Id`] === id
      )
      if (foundItem) {
        acc.matchingIds.push(foundItem)
      } else {
        acc.nonMatchingIds.push(id)
      }
      return acc
    },
    { matchingIds: [], nonMatchingIds: [] }
  )
}

const updateModifiers = (
  modifiers: any[],
  modifiersList: any[],
  modifierGroupList: any[]
) => {
  return modifiers?.map((modifier: any) => {
    // Find modifier details from modifiersList
    const foundModifier = modifiersList?.find(
      (modifierItem: any) => modifierItem.id === modifier.menuModifierId
    )

    // Update the modifier with found details or provide defaults
    const updatedModifier = {
      ...modifier,
      name: foundModifier?.name ?? '',
      price: (modifier?.price! ?? foundModifier?.price!) / 100 || 0,
      minPermitted: modifier.minPermitted ?? foundModifier?.minPermitted ?? 0,
      maxPermitted: modifier.maxPermitted ?? foundModifier?.maxPermitted ?? 0,
      inStorePrice:
        (modifier?.inStorePrice! ?? foundModifier?.inStorePrice!) / 100 || 0,
    }

    // If there are nested selections (modifiers), recursively update them
    if (modifier.selections && modifier.selections.length > 0) {
      updatedModifier.selections = modifier.selections?.map(
        (nestedSelection: any) => {
          return updateSelections(
            [nestedSelection],
            modifiersList,
            modifierGroupList
          )[0] // Recursively update nested selections
        }
      )
    }

    return updatedModifier
  })
}

export const updateSelections = (
  selections: any[],
  modifiersList: any[],
  modifierGroupList: any[]
) => {
  return selections?.map((group: any) => {
    // Find modifier group details from modifierGroupList
    const foundGroup = modifierGroupList?.find(
      (groupItem: any) => groupItem.id === group.menuModifierGroupId
    )

    // Update the group with found details or provide defaults
    const updatedGroup = {
      ...group,
      name: foundGroup?.name ?? '',
      minPermitted: group.minPermitted ?? foundGroup?.minPermitted ?? 0,
      maxPermitted: group.maxPermitted ?? foundGroup?.maxPermitted ?? 0,
    }

    // Update the modifiers (including nested ones)
    const updatedModifiers = updateModifiers(
      group.modifiers,
      modifiersList,
      modifierGroupList
    )

    // Return updated group with updated modifiers
    return {
      ...updatedGroup,
      modifiers: updatedModifiers,
    }
  })
}
export const getAddressComponentsFromPlace = (
  place: google.maps.places.PlaceResult
) => {
  const components = place.address_components || []

  const getAddressComponent = (type: string): string => {
    const component = components.find((c) => c.types.includes(type))
    return component ? component.long_name : ''
  }

  return {
    area:
      getAddressComponent('sublocality') || getAddressComponent('neighborhood'),
    country: getAddressComponent('country'),
    flatNo: getAddressComponent('subpremise'),
    city: getAddressComponent('locality'),
    postalCode: getAddressComponent('postal_code'),
    houseNo: getAddressComponent('street_number'),
    addressLine1: getAddressComponent('route'),
    addressLine2: getAddressComponent('premise'),
    state: getAddressComponent('administrative_area_level_1'),
  }
}

export const selectionsMenuIdsMap = (
  modifierGroupIds: any[],
  modiferIds: any[],
  productSelections?: any[]
): any[] => {
  if (!productSelections || !productSelections.length) {
    return []
  }
  const nestedSelections = (selections: any[]): any[] =>
    selections.map((selection) => ({
      ...selection,
      menuModifierGroupId: modifierGroupIds?.find(
        (modifierGroup: any) =>
          modifierGroup.catalogModifierGroupId ===
          selection.catalogModifierGroupId
      )?.id,
      modifiers: selection.modifiers.map((modifier: any) => ({
        ...modifier,
        menuModifierId: modiferIds?.find(
          (modifierId: any) =>
            modifierId.catalogModifierId === modifier.catalogModifierId
        )?.id,
        selections:
          modifier.selections && modifier.selections.length
            ? nestedSelections(modifier.selections)
            : [],
      })),
    }))

  return nestedSelections(productSelections)
}

interface HandleDragEndParams {
  result: any
  sortedList: any[]
  rearrangeKey: string // Key to update in rearrange state ("categories" or "items")
  setRearrangeState?: (callback: (prev: any) => any) => void
  setSortedList: (list: any[]) => void
  mergeExisting?: boolean // For items: merge with prev state; for categories, false.
}

export const handleDragEndGeneric = (props: HandleDragEndParams) => {
  const {
    result,
    sortedList,
    rearrangeKey,
    setRearrangeState,
    setSortedList,
    mergeExisting = false,
  } = props
  const { destination, source } = result
  if (!destination) return

  // Create a copy of the list and reorder it.
  const reorderedList = [...sortedList]
  const [removed] = reorderedList.splice(source.index, 1)
  reorderedList.splice(destination.index, 0, removed)

  // Update positions for each item.
  reorderedList.forEach((item, index) => {
    item.position = index
  })

  // Update rearrange state.
  if (setRearrangeState) {
    setRearrangeState((prev: any) => {
      if (mergeExisting) {
        // Merge with the existing items array (for items use-case)
        const existing = prev?.[rearrangeKey] || []
        const newIds = reorderedList.map((item) => item.id)
        const merged = Array.from(new Set([...existing, ...newIds]))
        return {
          ...prev,
          [rearrangeKey]: merged,
        }
      } else {
        // Simply replace (for categories use-case)
        return {
          ...prev,
          [rearrangeKey]: reorderedList.map((item) => item.id),
        }
      }
    })
  } else {
    setSortedList(reorderedList)
    return { [rearrangeKey]: reorderedList.map((item) => item.id) }
  }

  // Update the local sorted list state.
  setSortedList(reorderedList)
}
export const updatePositionsByKey = (
  rearrangeState: any,
  currentState: any[],
  setState: (state: any[]) => void,
  key: string
) => {
  const order = rearrangeState[key] || []
  // Map over the current state and update the position property
  const updatedState = currentState.map((item) => {
    const newPos = order.indexOf(item.id)
    return newPos !== -1 ? { ...item, position: newPos } : item
  })
  // Optionally, sort the updated state by the new position (if needed)
  updatedState.sort((a, b) => (a.position ?? 0) - (b.position ?? 0))
  setState(updatedState)
}
export const usePaginatedQuery = (
  queryHook: (...args: any[]) => any, // Accepts multiple arguments
  params: any, // Accept both array and single values
  onSuccessHandler: (data: any, fetchNextPage: () => void) => void,
  options?: any
) => {
  // Ensure params is always an array
  const safeParams = Array.isArray(params) ? params : [params]

  const query = queryHook(...safeParams, {
    ...options,
    keepPreviousData: true,
    staleTime: 1000 * 60 * 5, // 5 minutes
    onSuccess(data: any) {
      const lastPage = data.pages.length - 1
      if (data.pages[lastPage]?.hasNextPage) {
        void query.fetchNextPage()
      }
      onSuccessHandler(data, query.fetchNextPage)
    },
  })

  return query
}
