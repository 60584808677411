import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  Typography,
} from '@mui/material'
import { FC } from 'react'

import { useIntl } from 'react-intl'

interface Props {
  loading?: boolean
  open: boolean
  name: string
  source: string
  onConfirm: () => void
  onCancel: () => void
}

export const AppWarningDialog: FC<Props> = ({
  loading,
  open,
  name,
  source,
  onConfirm,
  onCancel,
}) => {
  const intl = useIntl()

  return (
    <>
      <Dialog open={open} sx={{ borderRadius: 2 }} onClose={onCancel}>
        <DialogContent>
          <Typography component={'div'} id="id_delete_confirm_this_name_source">
            {intl.formatMessage(
              {
                id: 'misc_delete_confirm_this_name_source',
              },
              { name: <strong>{name}</strong>, source: source }
            )}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={onCancel} disabled={loading} id="id_cancel_button">
            {intl.formatMessage({ id: 'action_cancel' })}
          </Button>
          <Button
            disabled={loading}
            onClick={onConfirm}
            sx={{ ml: 2 }}
            id="id_delete_button"
          >
            {intl.formatMessage({ id: 'action_delete' })}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}
