import { FC, MouseEventHandler, useState } from 'react'
import { formatUtcDate, formatLocalDate } from '../../pages/util'
import { Typography } from '@mui/material'

export const FormattedDateTime: FC<{ value: string }> = ({ value }) => {
  const [mode, setMode] = useState<'local' | 'utc' | 'iso'>('local')

  const rotate: MouseEventHandler = (event) => {
    if (mode === 'local') {
      setMode('utc')
    } else if (mode === 'utc') {
      setMode('iso')
    } else {
      setMode('local')
    }

    event.stopPropagation()
  }

  return (
    <Typography
      variant="body1"
      style={{ display: 'inline' }}
      id="id_formatted_date_time"
    >
      {mode === 'local' && (
        <span onClick={rotate}>{formatLocalDate(value)}</span>
      )}
      {mode === 'utc' && (
        <span onClick={rotate}>{formatUtcDate(value)} (UTC)</span>
      )}
      {mode === 'iso' && <span onClick={rotate}>{value}</span>}
    </Typography>
  )
}
