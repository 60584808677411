import { FC } from 'react'
import { Grid, Typography } from '@mui/material'

// ------------------------------------------------------------------
interface SettingsItemProps {
  label: string
  description: string
  children: React.ReactNode
}

export const SettingsItem: FC<SettingsItemProps> = ({
  label,
  description,
  children,
}) => {
  return (
    <Grid container spacing={2} sx={{ paddingX: 3, paddingY: 2 }}>
      {/* Left side: label & description */}
      <Grid item xs={12} md={6}>
        <Typography variant="body1">{label}</Typography>
        <Typography variant="body2" color="textSecondary">
          {description}
        </Typography>
      </Grid>

      {/* Right side: the actual input or info */}
      <Grid item xs={12} md={6} sx={{ display: 'flex', alignItems: 'center' }}>
        {children}
      </Grid>
    </Grid>
  )
}
