import {
  Box,
  CardContent,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  useMediaQuery,
} from '@mui/material'
import Logo from './Logo'
import { OrderStatusChip } from './OrderStatusChip'
import FulfillmentType from './FullfillmentType'
import { useNavigate } from 'react-router-dom'
import { FC, useState } from 'react'
import { useIntl } from 'react-intl'
import { NoRecordFound, AppLoader } from '../../components'
import { FormattedMoney } from '../FormattedMoney'
import { getCurrency } from '../../pages/util'
import { FormattedDateTime } from '../FormattedDateTime'
import { UseInfiniteQueryResult } from 'react-query'

interface Column {
  id:
    | 'sourceName'
    | 'id'
    | 'placedOn'
    | 'customer'
    | 'status'
    | 'fulfillmentType'
    | 'locationName'
    | 'amount'
  label: string
  minWidth?: number
  align?: 'left' | 'right' | 'center'
  cell?: (value: any, currency?: any) => any
}

const columns: readonly Column[] = [
  {
    id: 'sourceName',
    label: 'Source',
    cell: (value) => <Logo value={value} />,
  },
  {
    id: 'id',
    label: 'Order ID',
    cell: (value) => '#' + value.slice(-6),
  },
  {
    id: 'customer',
    label: 'Customer',
    cell(value) {
      return value?.length > 10 ? value.substring(0, 10) + '...' : value
    },
  },
  {
    id: 'status',
    label: 'Status',
    align: 'left',
    cell: (value) => <OrderStatusChip value={value} />,
  },
  {
    id: 'fulfillmentType',
    label: 'Fulfillment',
    cell: (value) => <FulfillmentType value={value} />,
  },
  {
    id: 'locationName',
    label: 'Location',
    cell: (value) => value,
  },
  {
    id: 'placedOn',
    label: 'Placed On',
    cell: (value) => value && <FormattedDateTime value={value} />,
  },
  {
    id: 'amount',
    label: 'Total',
    cell: (value, currency) => (
      <FormattedMoney
        value={value / 100}
        currency={currency}
        decimalValue={true}
      />
    ),
  },
]
export interface OrdersTableProps {
  orders: UseInfiniteQueryResult<any, any>
}

export const OrdersTable: FC<OrdersTableProps> = (props) => {
  const { orders } = props
  const intl = useIntl()
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(25)
  const navigate = useNavigate()
  const isTablet = useMediaQuery((theme: any) => theme.breakpoints.up('md'))

  // Flatten the records (ideally we should not do this as it will affect the performance of the app)
  const records = orders.data?.pages[page].data ?? []

  console.log('records', records, orders.data?.pages[page].data)
  const currency = getCurrency()

  // Handle the click event on the order
  const handleOnclickOrder = (orderId: any) => {
    window.scrollTo(0, 0)
    navigate(`${orderId}`, {
      state: {
        back: `${window.location.pathname}${window.location.search}`,
      },
    })
  }

  // If the data is still loading
  if (orders.isLoading) {
    return <AppLoader />
  }

  // If there is an error
  if (orders.isError) {
    return (
      <Typography>{intl.formatMessage({ id: 'error_try_again' })}</Typography>
    )
  }

  // If there are no records
  if (orders.isSuccess && records?.length === 0) {
    return (
      <Box sx={{ marginTop: 4 }}>
        <NoRecordFound title="orders" />
      </Box>
    )
  }

  const totalCount =
    orders.data?.pages.reduce((acc, page) => acc + page.data.length, 0) ?? 0
  const lastPageidx = (orders.data?.pages.length ?? 1) - 1
  const hasNextPage = orders.data?.pages[lastPageidx]?.hasNextPage ?? false

  const onPagePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    targetPage: number
  ) => {
    // If the page is not in the cache, fetch it
    if (!orders.data?.pages[targetPage]) {
      void orders.fetchNextPage().then(() => {
        setPage(targetPage)
      })
    } else {
      setPage(targetPage)
    }
  }

  // If we are displaying on the tablet
  return (
    <Grid container spacing={2} sx={{ marginTop: 3 }}>
      {!isTablet ? (
        <>
          {records?.map((row: any) => (
            <Grid item xs={12} key={row.id}>
              <Paper>
                <CardContent>
                  <TableContainer>
                    <Table>
                      <TableBody>
                        {columns.map((column) => {
                          const value = row[column.id]
                          return (
                            <TableRow
                              key={column.id}
                              onClick={() => handleOnclickOrder(row.id)}
                            >
                              <TableCell>{column.label}</TableCell>
                              <TableCell align={column.align}>
                                {column.cell
                                  ? column.cell(value, currency)
                                  : value}
                              </TableCell>
                            </TableRow>
                          )
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </CardContent>
              </Paper>
            </Grid>
          ))}
          {orders.hasNextPage && (
            <Box display="flex" justifyContent="center" my={2}>
              <TablePagination
                component={'td'}
                colSpan={8}
                page={page}
                rowsPerPage={rowsPerPage}
                count={hasNextPage ? totalCount + rowsPerPage : totalCount}
                rowsPerPageOptions={[25]}
                onPageChange={onPagePage}
                onRowsPerPageChange={(event) =>
                  setRowsPerPage(+event.target.value)
                }
              />
            </Box>
          )}
        </>
      ) : (
        <Grid item xs={12}>
          <TableContainer component={Paper}>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  {columns.map((column, index) => (
                    <TableCell
                      key={index}
                      align={column.align}
                      style={{ minWidth: column.minWidth }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {records?.map((row: any) => (
                  <TableRow
                    key={row.id}
                    onClick={() => handleOnclickOrder(row.id)}
                  >
                    {columns.map((column) => {
                      const value = row[column.id]
                      return (
                        <TableCell key={column.id} align={column.align}>
                          {column.cell ? column.cell(value, currency) : value}
                        </TableCell>
                      )
                    })}
                  </TableRow>
                ))}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TablePagination
                    component={'td'}
                    colSpan={8}
                    page={page}
                    rowsPerPage={rowsPerPage}
                    count={hasNextPage ? totalCount + rowsPerPage : totalCount}
                    rowsPerPageOptions={[25]}
                    onPageChange={onPagePage}
                    onRowsPerPageChange={(event) =>
                      setRowsPerPage(+event.target.value)
                    }
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        </Grid>
      )}
    </Grid>
  )
}
