import { useIntl } from 'react-intl'
import { Box, Button, Grid, InputAdornment, Typography } from '@mui/material'
import { ChangeEvent, useMemo, useState } from 'react'
import { Navigate, useNavigate, useParams } from 'react-router-dom'
import {
  CatalogModifierCreateEntity,
  CatalogModifierPatchEntity,
} from '../../../../../../types'
import {
  getLocationQueryById,
  useModifierById,
  useModifierCreate,
  useModifierUpdate,
} from '../../../../../../hooks'
import { CommonTextField, AppLoader } from '../../../../../../components'
import {
  defaultModifierState,
  extractModifierCreatePayload,
  extractModifierPatchPayload,
  validateModifierEntity,
} from '../../../../../util'
import { getCurrencySymbol } from '../../../../../util'
import { PageContainer } from '../../../../../../components/Page/PageContainer'

export function ModifierUpsert() {
  const navigate = useNavigate()
  const currency = getCurrencySymbol()
  const { locationId, modifierId } = useParams()
  const intl = useIntl()

  // fetching the location by id
  const locations = getLocationQueryById(locationId)
  const locationName = locations.data?.data?.name
  let breadcrumbs: any = [
    { title: 'Locations', path: '/app/locations' },
    { title: locationName, path: `/app/locations/${locationId}/analytics` },
    {
      title: 'Catalog',
      path: `/app/locations/${locationId}/catalog/categories`,
    },
    {
      title: 'Modifiers',
      path: `/app/locations/${locationId}/catalog/modifiers`,
    },
  ]

  const [modifierState, setModifierState] = useState<
    CatalogModifierCreateEntity | CatalogModifierPatchEntity
  >(defaultModifierState)
  const isValid = useMemo(
    () => validateModifierEntity(modifierState),
    [modifierState]
  )

  const [price, setPriceData] = useState<any>()
  const modifierCreate = useModifierCreate(locationId!)
  const modifierUpdate = useModifierUpdate(locationId!, modifierId!)

  const handleNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    setModifierState((state) => ({
      ...state,
      name: event.target.value,
    }))
  }
  const modifier = useModifierById(locationId!, modifierId!, {
    refetchOnMount: 'always',
    onSuccess: (data) => {
      data?.data && setModifierState(data.data)
      setPriceData(data.data?.price! / 100)
    },
  })

  const handlePriceChange = (event: ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value

    if (/^\d*\.?\d*$/.test(inputValue) || inputValue === '') {
      const data = inputValue === '' ? '' : parseFloat(inputValue)
      setModifierState((state: any) => ({
        ...state,
        price: data,
      }))
      setPriceData(inputValue)
    }
  }

  const handleDescriptionChange = (event: ChangeEvent<HTMLInputElement>) => {
    setModifierState((state) => ({
      ...state,
      description: event.target.value,
    }))
  }
  const handleSubmitChange = async () => {
    modifierId
      ? modifierUpdate.mutate(extractModifierPatchPayload(modifierState))
      : modifierCreate.mutate(extractModifierCreatePayload(modifierState))
  }

  if (
    modifier.isLoading ||
    modifier.isFetching ||
    modifierCreate.isLoading ||
    modifierUpdate.isLoading
  ) {
    return <AppLoader />
  }

  if (modifier.isError) {
    return (
      <Typography>{intl.formatMessage({ id: 'error_try_again' })}</Typography>
    )
  }

  if (modifierCreate.isSuccess || modifierUpdate.isSuccess) {
    return (
      <Navigate to={`/app/locations/${locationId}/catalog/modifiers`} replace />
    )
  }

  return (
    <PageContainer title={''} breadcrumbs={breadcrumbs}>
      <Box sx={{ p: 3 }}>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12} sm={6} md={9}>
            <Box marginTop={1}>
              <Typography variant="h6" component="h1">
                {modifierId
                  ? `${intl.formatMessage({ id: 'action_update' })}`
                  : `${intl.formatMessage({ id: 'action_create' })}`}{' '}
                {intl.formatMessage({ id: 'label_modifier' })}
              </Typography>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            md={3}
            justifyContent="flex-end"
            display="flex"
          >
            <Button
              onClick={() =>
                navigate(`/app/locations/${locationId}/catalog/modifiers`)
              }
            >
              {intl.formatMessage({ id: 'action_cancel' })}
            </Button>
            <Button
              onClick={handleSubmitChange}
              disabled={!isValid}
              color="success"
              sx={{ ml: 2 }}
            >
              {modifierId
                ? `${intl.formatMessage({ id: 'action_update' })}`
                : `${intl.formatMessage({ id: 'action_create' })}`}
            </Button>
          </Grid>
        </Grid>
        <Grid container spacing={2} sx={{ marginTop: 1 }}>
          <Grid item xs={12} sm={6}>
            <CommonTextField
              id="outlined-basic"
              label={`${intl.formatMessage({ id: 'label_name' })}${' *'}`}
              autoFocus={true}
              value={modifierState.name || ''}
              onChange={handleNameChange}
            />
          </Grid>
          <Grid item mt={{ xs: 2, sm: 0 }} xs={12} sm={6}>
            <CommonTextField
              id="outlined-basic"
              type="number"
              label={`${intl.formatMessage({ id: 'label_price' })}${' *'}`}
              onChange={handlePriceChange}
              value={price}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">{currency}</InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12} mt={{ xs: 2, sm: 1 }}>
            <CommonTextField
              id="outlined-multiline-static"
              label={intl.formatMessage({ id: 'label_description' })}
              onChange={handleDescriptionChange}
              value={modifierState.description || ''}
              rows={4}
              multiline
            />
          </Grid>
        </Grid>
      </Box>
    </PageContainer>
  )
}
