import { FC, useState } from 'react'
import { Box, Button, Divider, TextField } from '@mui/material'
import { MuiTelInput } from 'mui-tel-input'
import { useIntl } from 'react-intl'
import { UseMutationResult, UseQueryResult } from 'react-query'
import {
  CountryPicker,
  getAddressComponentsFromPlace,
  getDefaultCountryValueFromCode,
  GoogleAddressPicker,
} from '..//Pickers'
import {
  getDefaultTimezoneValueFromCode,
  TimezonePicker,
} from '..//Pickers/TimezonePicker'
import {
  CurrencyPicker,
  getDefaultCurrencyValueFromCode,
} from '..//Pickers/CurrencyPicker'
import { SettingsItem } from './SettingsItem'

// ------------------------------------------------------------------
// A small interface for your overall account props
// ------------------------------------------------------------------
export interface StoreDetailsProps {
  location: UseQueryResult<any, any>
  updateLocation: UseMutationResult<any, any>
}

// ------------------------------------------------------------------
// The main CompanyDetails component
// ------------------------------------------------------------------
export const StoreDetails: FC<StoreDetailsProps> = (props) => {
  const intl = useIntl()
  const [updates, setUpdates] = useState<any>({})

  // Called whenever the address picker changes
  const onPlaceSelected = (place: google.maps.places.PlaceResult) => {
    setUpdates((prev: any) => ({
      ...prev,
      address: {
        address: place.formatted_address,
        googlePlaceId: place.place_id,
        latitude: place.geometry?.location?.lat(),
        longitude: place.geometry?.location?.lng(),
        addressComponents: getAddressComponentsFromPlace(place),
      },
    }))
  }

  const handleSaveChanges = () => {
    props.updateLocation.mutate(updates)
  }

  const isUpdatesPending = Object.keys(updates).length > 0

  return (
    <Box>
      {/* Account Name */}
      <SettingsItem
        label="Name"
        description="The name of the store or location, this should be the pubic facing name"
      >
        <TextField
          disabled={props.location.isLoading || props.updateLocation.isLoading}
          defaultValue={props.location.data?.data.name}
          required
          onChange={(e) =>
            setUpdates((updates: any) => ({ ...updates, name: e.target.value }))
          }
          fullWidth
        />
      </SettingsItem>
      <Divider />

      {/* Location Address */}
      <SettingsItem
        label="Address"
        description="The physical address of the store or location"
      >
        <GoogleAddressPicker
          disabled={props.location.isLoading || props.updateLocation.isLoading}
          placeholder={props.location.data?.data.address.address}
          textFieldProps={{ label: props.location.data?.data.address.address }}
          onPlaceSelected={onPlaceSelected}
          fullWidth
        />
      </SettingsItem>
      <Divider />
      {/* Country / Region (using <CountryPicker>) */}
      <SettingsItem
        label="Country"
        description="The country or region where the business is located"
      >
        <CountryPicker
          disabled={props.location.isLoading || props.updateLocation.isLoading}
          defaultValue={getDefaultCountryValueFromCode(
            props.location.data?.data.countryCode
          )}
          onChange={(newValue: any, country) =>
            setUpdates((prev: any) => ({ ...prev, countryCode: country?.id }))
          }
          fullWidth
        />
      </SettingsItem>
      {/* Account Phone Number */}
      <SettingsItem
        label="Phone Number"
        description="The phone number of the business or account that will be used for notifications"
      >
        <MuiTelInput
          disabled={props.location.isLoading || props.updateLocation.isLoading}
          defaultCountry={props.location.data?.data.countryCode}
          value={updates.phoneNumber ?? props.location.data?.data.phoneNumber}
          onChange={(_: any, info: any) =>
            setUpdates((prev: any) => ({
              ...prev,
              phoneNumber: info.numberValue,
            }))
          }
          fullWidth
        />
      </SettingsItem>
      <Divider />
      {/* Account Currency */}
      <SettingsItem
        label="Currency"
        description="The currency used for billing and transactions, this cannot be changed after the account is created"
      >
        {/* If you want to keep it read-only, a simple Typography might be enough */}
        <CurrencyPicker
          fullWidth
          disabled={props.location.isLoading || props.updateLocation.isLoading}
          defaultValue={getDefaultCurrencyValueFromCode(
            props.location.data?.data.currency
          )}
          onChange={(newValue: any, currency) =>
            setUpdates((prev: any) => ({ ...prev, currency: currency?.code }))
          }
        />
      </SettingsItem>
      <Divider />
      {/* Time Zone */}
      <SettingsItem
        label="Time Zone"
        description="The time zone used for scheduling and notifications regarding the account, such as date/time within reports/invoices"
      >
        <TimezonePicker
          disabled={props.location.isLoading || props.updateLocation.isLoading}
          defaultValue={getDefaultTimezoneValueFromCode(
            props.location.data?.data.timezone
          )}
          fullWidth
          onChange={(newValue: any, timezone) =>
            setUpdates((prev: any) => ({ ...prev, timezone: timezone?.name }))
          }
        />
      </SettingsItem>
      <Divider />

      {/* Single Save/Update Button at the bottom */}
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', p: 2 }}>
        <Button
          variant="contained"
          color="primary"
          onClick={handleSaveChanges}
          disabled={!isUpdatesPending || props.updateLocation.isLoading}
        >
          Save Changes
        </Button>
      </Box>
    </Box>
  )
}
