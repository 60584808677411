import { useState, useRef, useEffect, FC } from 'react'

interface CollapsibleSectionProps {
  menuItemState: any
  show: boolean
  children: React.ReactNode
}

export const CollapsibleSection: FC<CollapsibleSectionProps> = ({
  menuItemState,
  show,
  children,
}) => {
  const [maxHeight, setMaxHeight] = useState('0px')
  const contentRef = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    const handleResize = () => {
      if (contentRef.current) {
        setMaxHeight(show ? `${contentRef.current.scrollHeight}px` : '0px')
      }
    }

    // Initial calculation
    handleResize()

    // Observe changes to the content
    const observer = new ResizeObserver(handleResize)
    if (contentRef.current) {
      observer.observe(contentRef.current)
    }

    // Clean up observer
    return () => {
      if (contentRef.current) {
        observer.unobserve(contentRef.current)
      }
    }
  }, [show, children, menuItemState]) // Recalculate when show or children change

  return (
    <div
      style={{
        maxHeight: maxHeight,
        overflow: 'hidden',
        transition:
          'max-height 0.5s ease-in-out, opacity 0.5s ease-in-out, transform 0.5s ease-in-out',
        opacity: show ? 1 : 0,
        transform: show ? 'translateY(0)' : 'translateY(-20px)',
        width: '100%',
        backgroundColor: 'secondary',
        borderRadius: '8px',
      }}
      ref={contentRef}
    >
      <div
        style={{
          padding: '14px 16px',
        }}
      >
        {children}
      </div>
    </div>
  )
}
