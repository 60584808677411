import {
  Paper,
  Grid,
  List,
  ListItemText,
  ListItemButton,
  ListItemIcon,
  Typography,
} from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { getAccountQueryById, useAccountUpdateMutaion } from '../../hooks'
import { AppLoader } from '../../components'
import { useState } from 'react'
import { Breadcrumb, PageContainer } from '../../components/Page/PageContainer'
import { Business, EventRepeat, Payment, Receipt } from '@mui/icons-material'
import { CompanyDetails } from './CompanyDetails'

export const Account = () => {
  const navigate = useNavigate()
  const account = getAccountQueryById()
  const updateAccount = useAccountUpdateMutaion()
  const [tab, setTab] = useState<string>('company-details')

  if (account?.isLoading) {
    return <AppLoader />
  }

  const breadcrumbs: Breadcrumb[] = [
    // accounts and current account name
    // { title: 'Home', path: '/' },
  ]

  return (
    <PageContainer title={account?.data?.data.name} breadcrumbs={breadcrumbs}>
      {/* <AccountDetails accounts={accounts} modify={modify} /> */}
      <Paper sx={{ minHeight: 'calc(100vh - 200px)', paddingTop: 5 }}>
        {/* Grid that creates a menu on the left, and content area on the right */}
        <Grid container>
          <Grid item xs={5} md={3} lg={3} xl={2}>
            <List>
              <ListItemButton
                onClick={() => setTab('company-details')}
                selected={tab === 'company-details'}
              >
                <ListItemIcon>
                  <Business />
                </ListItemIcon>
                <ListItemText>
                  <Typography variant="body2">Company Details</Typography>
                </ListItemText>
              </ListItemButton>

              {/* <ListItemButton
                onClick={() => setTab('payment-methods')}
                selected={tab === 'payment-methods'}
                disabled
              >
                <ListItemIcon>
                  <Payment />
                </ListItemIcon>
                <ListItemText>
                  <Typography variant="body2">Payment Methods</Typography>
                </ListItemText>
              </ListItemButton>

              <ListItemButton
                onClick={() => setTab('subscriptions')}
                selected={tab === 'subscriptions'}
                disabled
              >
                <ListItemIcon>
                  <EventRepeat />
                </ListItemIcon>
                <ListItemText>
                  <Typography variant="body2">Subscriptions</Typography>
                </ListItemText>
              </ListItemButton>

              <ListItemButton
                onClick={() => setTab('invoices')}
                selected={tab === 'invoices'}
                disabled
              >
                <ListItemIcon>
                  <Receipt />
                </ListItemIcon>
                <ListItemText>
                  <Typography variant="body2">Invoices</Typography>
                </ListItemText>
              </ListItemButton> */}
            </List>
          </Grid>
          <Grid item xs={7} md={9} lg={9} xl={10}>
            {tab === 'company-details' && (
              <CompanyDetails account={account} updateAccount={updateAccount} />
            )}
            {/* {tab === 'payment-methods' && <PaymentMethods account={account} />}
            {tab === 'subscriptions' && <div>Subscription</div>}
            {tab === 'invoices' && <div>Invoices</div>} */}
          </Grid>
        </Grid>
      </Paper>
    </PageContainer>
  )
}
