import { FC, useState } from 'react'
import {
  Box,
  Divider,
  Button,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
} from '@mui/material'
import { useIntl } from 'react-intl'
import { UseMutationResult, UseQueryResult } from 'react-query'
import { SettingsItem } from './SettingsItem'
import { Add, Edit } from '@mui/icons-material'

// ------------------------------------------------------------------
// A small interface for your overall account props
// ------------------------------------------------------------------
export interface TaxRatesProps {
  location: UseQueryResult<any, any>
  updateLocation: UseMutationResult<any, any>
}

// ------------------------------------------------------------------
// The main CompanyDetails component
// ------------------------------------------------------------------
export const TaxRates: FC<TaxRatesProps> = (props) => {
  const intl = useIntl()
  const [updates, setUpdates] = useState<any>({})

  const handleSaveChanges = () => {
    props.updateLocation.mutate(updates)
  }

  const isUpdatesPending = Object.keys(updates).length > 0

  return (
    <>
      <Box>
        {/* Account Name */}
        <SettingsItem
          label="Locations Tax Rates"
          description="This section can be used to configure the tax rates for this location."
        >
          {/* Tax Rates is an array of TAxRate, each wieth a name, type and rate value */}
          {/* Here we dispaly a table */}
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>Type</TableCell>
                  <TableCell>Rate</TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {props.location.data?.data.taxRates?.map((taxRate: any) => (
                  <TableRow key={taxRate.id}>
                    <TableCell>{taxRate.name}</TableCell>
                    <TableCell>{taxRate.type}</TableCell>
                    <TableCell>{taxRate.rate}</TableCell>
                    <TableCell>
                      <IconButton>
                        <Edit />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
                {/* Add a row where the Add button is on the right hand */}
                <TableRow>
                  <TableCell colSpan={4}>
                    <IconButton color="primary">
                      <Add />
                    </IconButton>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </SettingsItem>
        <Divider />
      </Box>

      {/* Save */}
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
        <Button
          variant="contained"
          color="primary"
          disabled={!isUpdatesPending}
          onClick={handleSaveChanges}
        >
          Save Changes
        </Button>
      </Box>
    </>
  )
}
