import {
  Cancel,
  DirectionsCar,
  ExpandLess,
  ExpandMore,
  Person,
  PersonPin,
  Phone,
  Store,
} from '@mui/icons-material'
import {
  Button,
  ButtonGroup,
  Chip,
  CircularProgress,
  Collapse,
  ListItemAvatar,
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuItemProps,
  MenuList,
  MenuListProps,
  Theme,
  Typography,
  useTheme,
} from '@mui/material'
import { FormattedRelativeTime } from '../FormattedRelativeTime'
import { FormattedMoney } from '../FormattedMoney'
import { Schema } from '../../../../../pos-hub/runtime/types'
import { FC, useState } from 'react'
import { UseQueryResult } from 'react-query'
import { FormattedPhoneNumber } from '../FormattedPhoneNumber'
import { useResellerDeliveryDispatchCancelMutation } from '../../hooks'

export type DeliveryDispatched = Schema<'DeliveryEntity'>['dispatched'][0]

const deliveryDriverStatusToPercentageMap: Record<string, number> = {
  UNASSIGNED: 100,
  ASSIGNED: 10,
  ON_ROUTE_TO_PICKUP: 20,
  AT_PICKUP: 40,
  ON_ROUTE_TO_DROP_OFF: 60,
  AT_DROP_OFF: 80,
  RETURNING: 90,
  COMPLETED: 100,
  CANCELLED: 100,
  RETURNED: 100,
}

export const getDeliveryDispatchDriverStatusColor = (
  theme: Theme,
  status: string
) => {
  const deliveryDriverStatusToColorMap: Record<string, string> = {
    UNASSIGNED: theme.palette.grey[300],
    ASSIGNED: theme.palette.success.main,
    ON_ROUTE_TO_PICKUP: theme.palette.success.main,
    AT_PICKUP: theme.palette.success.main,
    ON_ROUTE_TO_DROP_OFF: theme.palette.success.main,
    AT_DROP_OFF: theme.palette.success.main,
    RETURNING: theme.palette.warning.main,
    COMPLETED: theme.palette.success.main,
    CANCELLED: theme.palette.error.main,
    RETURNED: theme.palette.warning.main,
  }

  return deliveryDriverStatusToColorMap[status]
}

/**
 * The following function takes a delivery driver status and returns the properties for the progress bar
 */
export const getProgressFromDeliveryDriverStatus = (
  theme: Theme,
  driverStatus: string
) => {
  return {
    value: deliveryDriverStatusToPercentageMap[driverStatus] || 0,
    color: getDeliveryDispatchDriverStatusColor(theme, driverStatus),
  }
}

/**
 * Delivery Quote List Props
 */
export interface DeliveryDispatchedListProps extends MenuListProps {
  delivery: UseQueryResult<{ data: Schema<'DeliveryEntity'> }>
  disabled?: boolean
}

/**
 *
 */
export const DeliveryDispatchedList: FC<DeliveryDispatchedListProps> = (
  props
) => {
  if (props.delivery.isLoading || props.delivery.isError) {
    return null
  }

  return (
    <MenuList {...props}>
      {props.delivery.data?.data.dispatched.map((dispatched) => (
        <DeliveryDispatchedListItem
          key={dispatched.dispatchedId}
          delivery={props.delivery}
          dispatched={dispatched}
          disabled={props.disabled}
        />
      ))}
    </MenuList>
  )
}

/**
 *
 */
export interface DeliveryDispatchedListItemProps extends MenuItemProps {
  delivery: UseQueryResult<{
    data: Schema<'DeliveryEntity'>
  }>

  dispatched: DeliveryDispatched
  disabled?: boolean
}

export const DeliveryDispatchedListItem: FC<DeliveryDispatchedListItemProps> = (
  props
) => {
  const { dispatched, ...otherProps } = props
  // based ont he status of the dispatched delivery, expand the list
  const isExpandedByDefault = dispatched.driverStatus !== 'UNASSIGNED'
  const theme = useTheme()
  const [isExpanded, setIsExpanded] = useState(isExpandedByDefault)
  const { value, color } = getProgressFromDeliveryDriverStatus(
    theme,
    dispatched.driverStatus
  )
  const cancelDispatch = useResellerDeliveryDispatchCancelMutation({
    resellerId: props.delivery.data?.data.resellerId!,
    deliveryId: props.delivery.data?.data.id!,
    dispatchId: dispatched.dispatchedId,
  })

  /**
   * Determine if the delivery is cancellable
   */
  const isCancellable = dispatched.driverStatus === 'UNASSIGNED'
  const hasTrackingUrl = dispatched.trackingUrl !== undefined
  const hasDriverPhoneNumber = dispatched.driverPhoneNumber !== undefined

  const handleCancelClick = () => {
    cancelDispatch.mutate({}, { onSuccess: () => props.delivery.refetch() })
  }

  return (
    <>
      <MenuItem
        disabled={props.disabled}
        {...otherProps}
        onClick={() => setIsExpanded(!isExpanded)}
      >
        <ListItemIcon>
          <CircularProgress
            variant="determinate"
            color="success"
            size={16}
            value={value}
            sx={{ color }}
            thickness={8}
          />
        </ListItemIcon>
        <ListItemText
          secondary={
            <>
              Dispatched{' '}
              <FormattedRelativeTime value={dispatched.dispatchedAt} />
            </>
          }
        >
          {dispatched.applicationName}{' '}
          <Chip label={dispatched.driverStatus} size="small" />
        </ListItemText>
        <Typography
          variant="body2"
          sx={{ color: 'text.secondary' }}
          id="id_price"
        >
          <FormattedMoney
            value={dispatched.price / 100}
            currency="USD"
            decimalValue
          />
        </Typography>
        {isExpanded ? <ExpandLess /> : <ExpandMore />}
      </MenuItem>
      <Collapse in={isExpanded}>
        <MenuList>
          {dispatched.driverName && (
            <MenuItem>
              <ListItemAvatar>
                <PersonPin />
              </ListItemAvatar>
              <>
                <ListItemText primary={dispatched.driverName} />
                {dispatched.driverPhoneNumber && (
                  <Typography
                    variant="body2"
                    sx={{ color: 'text.secondary' }}
                    id="id_driver_phone"
                  >
                    <FormattedPhoneNumber
                      phoneNumber={dispatched.driverPhoneNumber}
                    />
                  </Typography>
                )}
              </>
            </MenuItem>
          )}
          {dispatched.driverVehicleMake && (
            <MenuItem>
              <ListItemAvatar>
                <DirectionsCar />
              </ListItemAvatar>
              <>
                <ListItemText
                  primary={dispatched.driverVehicleMake}
                  secondary={dispatched.driverVehicleModel}
                />
                <Typography
                  variant="body2"
                  sx={{ color: 'text.secondary' }}
                  id="id_driver_vehicle"
                >
                  {dispatched.driverVehicleLicensePlate}
                </Typography>
              </>
            </MenuItem>
          )}
          {!dispatched.actualPickupTime && dispatched.estimatedPickupTime && (
            <MenuItem>
              <ListItemAvatar>
                <Store />
              </ListItemAvatar>
              <ListItemText primary="Pickup ETA" />
              <Typography
                variant="body2"
                sx={{ color: 'text.secondary' }}
                id="id_pickup_eta"
              >
                <FormattedRelativeTime value={dispatched.estimatedPickupTime} />
              </Typography>
            </MenuItem>
          )}
          {!dispatched.actualDropoffTime && dispatched.estimatedDropoffTime && (
            <MenuItem>
              <ListItemAvatar>
                <Person />
              </ListItemAvatar>
              <ListItemText primary="Dropoff ETA" />
              <Typography
                variant="body2"
                sx={{ color: 'text.secondary' }}
                id="id_dropoff_eta"
              >
                <FormattedRelativeTime
                  value={dispatched.estimatedDropoffTime}
                />
              </Typography>
            </MenuItem>
          )}
          {dispatched.actualPickupTime && (
            <MenuItem>
              <ListItemAvatar>
                <Store />
              </ListItemAvatar>
              <>
                <ListItemText
                  primary="Actual Pickup Time"
                  secondary={
                    <FormattedRelativeTime
                      value={dispatched.actualPickupTime}
                    />
                  }
                />
              </>
            </MenuItem>
          )}
          {dispatched.actualDropoffTime && (
            <MenuItem>
              <ListItemAvatar>
                <Person />
              </ListItemAvatar>
              <>
                <ListItemText
                  primary="Actual Dropoff Time"
                  secondary={
                    <FormattedRelativeTime
                      value={dispatched.actualDropoffTime}
                    />
                  }
                />
              </>
            </MenuItem>
          )}
        </MenuList>

        {/* Add the action button at the bottom */}
        <ButtonGroup fullWidth>
          {hasDriverPhoneNumber && (
            <Button
              startIcon={<Phone />}
              disabled={dispatched.driverPhoneNumber === undefined}
              onClick={() =>
                window.open(`tel:${dispatched.driverPhoneNumber}`, '_blank')
              }
              id="id_call_driver"
            >
              Driver
            </Button>
          )}
          {hasTrackingUrl && (
            <Button
              startIcon={<DirectionsCar />}
              id="id_track_delivery"
              disabled={dispatched.trackingUrl === undefined}
              onClick={() => window.open(dispatched.trackingUrl, '_blank')}
            >
              Track
            </Button>
          )}
          {isCancellable && (
            <Button
              id="id_cancel_dispatch"
              startIcon={<Cancel />}
              onClick={handleCancelClick}
            >
              Cancel
            </Button>
          )}
        </ButtonGroup>
      </Collapse>
    </>
  )
}
