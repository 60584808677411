import { useState, useEffect, useRef, Fragment, FC } from 'react'
import {
  Drawer,
  Box,
  IconButton,
  Menu,
  MenuItem,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material'
import { ChevronLeft, ChevronRight, MoreVert } from '@mui/icons-material'
import { useAccounts, useAuthStore } from '../../hooks'
import { AccountSwitcherDialog } from './AccountSwitcher'
import { AppSideBarMenu } from './AppSideBarMenu'
import SwitchAccountIcon from '@mui/icons-material/SwitchAccount'

const drawerWidthExpanded = 240
const drawerWidthCollapsed = 58

interface SideBarProps {
  setIsDrawerOpen?: any
  isDrawerOpen?: any
}

export const AppSideBar: FC<SideBarProps> = (props) => {
  const [isExpanded, setIsExpanded] = useState(true)
  const [hiddenMenuItems, setHiddenMenuItems] = useState<any[]>([])
  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null)
  const sidebarRef = useRef<HTMLDivElement>(null)

  const toggleSidebar = () => {
    setIsExpanded((prev) => !prev)
  }

  useEffect(() => {
    if (sidebarRef.current) {
      const sidebarHeight = sidebarRef.current.clientHeight
      const menuItems = document.querySelectorAll('.sidebar-menu-item')
      let totalHeight = 0
      const visible: any[] = []
      const hidden: any[] = []

      menuItems.forEach((item: any) => {
        totalHeight += item.offsetHeight
        if (totalHeight < sidebarHeight - 100) {
          visible.push(item)
        } else {
          hidden.push(item)
        }
      })
      setHiddenMenuItems(hidden)
    }
  }, [isExpanded])

  return (
    <Fragment>
      {/* Sidebar Drawer */}
      <Drawer
        variant="permanent"
        sx={{
          width: isExpanded ? drawerWidthExpanded : drawerWidthCollapsed,
          flexShrink: 0,
          height: '100vh', // Ensure full height
          transition: 'width 0.3s ease',
          [`& .MuiDrawer-paper`]: {
            width: isExpanded ? drawerWidthExpanded : drawerWidthCollapsed,
            boxSizing: 'border-box',
            height: '100vh', // Ensure full height
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between', // Align AccountListItem to bottom
          },
        }}
        ref={sidebarRef}
      >
        {/* Sidebar Menu */}
        <Box sx={{ flex: 1, overflowY: 'auto' }}>
          <AppSideBarMenu
            setIsDrawerOpen={props.setIsDrawerOpen}
            isDrawerOpen={props.isDrawerOpen}
            isExpanded={isExpanded}
          />
          {hiddenMenuItems.length > 0 && (
            <Box sx={{ display: 'flex', justifyContent: 'center', my: 1 }}>
              <IconButton onClick={(e) => setMenuAnchorEl(e.currentTarget)}>
                <MoreVert />
              </IconButton>
              <Menu
                anchorEl={menuAnchorEl}
                open={Boolean(menuAnchorEl)}
                onClose={() => setMenuAnchorEl(null)}
              >
                {hiddenMenuItems.map((item, index) => (
                  <MenuItem key={index}>{item.innerText}</MenuItem>
                ))}
              </Menu>
            </Box>
          )}
        </Box>

        {/* Account List at Bottom */}
        <AccountListItem />
      </Drawer>

      {/* Sidebar Toggle Button */}
      <Box
        sx={{
          position: 'fixed',
          zIndex: 99999,
          top: '52px',
          left: isExpanded ? '223px' : '40px',
          backgroundColor: 'background.paper',
          borderRadius: '50%',
          border: '0.5px solid #ccc',
          transition: 'left 0.3s ease',
        }}
      >
        <IconButton onClick={toggleSidebar}>
          {isExpanded ? (
            <ChevronLeft fontSize="small" />
          ) : (
            <ChevronRight fontSize="small" />
          )}
        </IconButton>
      </Box>
    </Fragment>
  )
}

export const AccountListItem: FC = () => {
  const accounts = useAccounts()
  const [accountId] = useAuthStore((store) => [store.accountId])
  const [isSwitcherOpen, setSwitcherOpen] = useState(false)

  if (accounts.isSuccess && accounts.data?.data.length <= 1) {
    return null
  }

  const current = accounts.data?.data.find(
    (account: any) => account.id === accountId
  )

  return (
    <>
      {isSwitcherOpen && (
        <AccountSwitcherDialog onClose={() => setSwitcherOpen(false)} />
      )}
      <List
        onClick={() => setSwitcherOpen(true)}
        sx={{ '& .MuiListItemButton-root': { py: 0 } }}
      >
        <ListItem disablePadding>
          <ListItemButton onClick={() => setSwitcherOpen(true)}>
            <ListItemIcon>
              <SwitchAccountIcon />
            </ListItemIcon>
            <ListItemText primary={current.name} />
          </ListItemButton>
        </ListItem>
      </List>
    </>
  )
}
