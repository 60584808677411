import { FC, useState } from 'react'
import {
  Paper,
  Grid,
  List,
  ListItemText,
  ListItemButton,
  ListItemIcon,
  Typography,
} from '@mui/material'
import { AppLoader } from '../'
import {
  Assignment,
  Business,
  DeliveryDining,
  QueryBuilder,
  SettingsSuggest,
} from '@mui/icons-material'
import { StoreDetails } from './StoreDetails'
import { DeliverySettings } from './DeliverySettings'
import { OrderSettings } from './OrderSettings'
import { OperatingHours } from './OperatingHours'
import {
  UseInfiniteQueryResult,
  UseMutationResult,
  UseQueryResult,
} from 'react-query'
import { TaxRates } from './TaxRates'

export interface LocationSettingsProps {
  location: UseQueryResult<any, any>
  updateLocation: UseMutationResult<any, any>
  locationApplications: UseInfiniteQueryResult<any, any>
}

export const LocationSettings: FC<LocationSettingsProps> = (props) => {
  const [tab, setTab] = useState<string>('store-details')

  if (props.location?.isLoading) {
    return <AppLoader />
  }

  return (
    <Paper sx={{ minHeight: 'calc(100vh - 200px)', paddingTop: 5 }}>
      {/* Grid that creates a menu on the left, and content area on the right */}
      <Grid container>
        <Grid item xs={5} md={3} lg={3} xl={2}>
          <List>
            <ListItemButton
              onClick={() => setTab('store-details')}
              selected={tab === 'store-details'}
            >
              <ListItemIcon children={<Business />} />
              <ListItemText>
                <Typography variant="body2" id="id_location_details">
                  Location Details
                </Typography>
              </ListItemText>
            </ListItemButton>
            <ListItemButton
              onClick={() => setTab('tax-rates')}
              selected={tab === 'tax-rates'}
            >
              <ListItemIcon children={<Assignment />} />
              <ListItemText>
                <Typography variant="body2" id="id_tax_rates">
                  Tax Rates
                </Typography>
              </ListItemText>
            </ListItemButton>
            <ListItemButton
              onClick={() => setTab('operating-hours')}
              selected={tab === 'operating-hours'}
            >
              <ListItemIcon children={<QueryBuilder />} />
              <ListItemText>
                <Typography variant="body2" id="id_operating_hours">
                  Operating Hours
                </Typography>
              </ListItemText>
            </ListItemButton>
            <ListItemButton
              onClick={() => setTab('order-settings')}
              selected={tab === 'order-settings'}
            >
              <ListItemIcon children={<SettingsSuggest />} />
              <ListItemText>
                <Typography variant="body2" id="id_order_settings">
                  Order Settings
                </Typography>
              </ListItemText>
            </ListItemButton>
            <ListItemButton
              onClick={() => setTab('delivery-settings')}
              selected={tab === 'delivery-settings'}
            >
              <ListItemIcon children={<DeliveryDining />} />
              <ListItemText>
                <Typography variant="body2" id="id_delivery_options">
                  Delivery Options
                </Typography>
              </ListItemText>
            </ListItemButton>
          </List>
        </Grid>
        <Grid item xs={7} md={9} lg={9} xl={10} sx={{ padding: 3 }}>
          {tab === 'store-details' && (
            <StoreDetails
              location={props.location}
              updateLocation={props.updateLocation as any}
            />
          )}

          {tab === 'operating-hours' && (
            <OperatingHours
              location={props.location}
              updateLocation={props.updateLocation as any}
            />
          )}

          {tab === 'tax-rates' && (
            <TaxRates
              location={props.location}
              updateLocation={props.updateLocation as any}
            />
          )}

          {tab === 'order-settings' && (
            <OrderSettings
              location={props.location}
              updateLocation={props.updateLocation as any}
            />
          )}

          {tab === 'delivery-settings' && (
            <DeliverySettings
              location={props.location}
              updateLocation={props.updateLocation as any}
              applications={props.locationApplications}
            />
          )}
        </Grid>
      </Grid>
    </Paper>
  )
}
