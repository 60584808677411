import { CatalogViewDetails } from '.'

export const NutritionalInfoView = ({
  label,
  value,
}: {
  label: string
  value: any
}) => {
  if (typeof value === 'object' && value !== null) {
    if (Array.isArray(value)) {
      // Handle non-empty array values (like additives and allergens)
      return value.length > 0
        ? CatalogViewDetails(label, value.join(', '))
        : null
    } else if ('lowerRange' in value || 'upperRange' in value) {
      // Handle range-based values (like kilojoules, carbohydrates)
      return CatalogViewDetails(
        label,
        `${value.lowerRange} - ${value.upperRange}`
      )
    }
  }

  // Handle simple string or number values (like spiciness)
  return value! ? CatalogViewDetails(label, value) : null
}
