import {
  Box,
  Button,
  Grid,
  Typography,
  Autocomplete,
  Radio,
  TextField,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  IconButton,
  Checkbox,
  Chip,
} from '@mui/material'
import { useState, useEffect } from 'react'
import { useIntl } from 'react-intl'
import { CommonTextField, PasswordInputField } from '../../../components'
import { findNameById, findIdByName } from '../../util'
import {
  Close,
  RadioButtonChecked,
  RadioButtonUnchecked,
  CheckBoxOutlineBlank,
  CheckBox,
} from '@mui/icons-material'
import { MuiTelInput } from 'mui-tel-input'
import { useResellerAccounts } from '../../../hooks/useResellerAccounts'
import { useParams } from 'react-router-dom'

const iconRadio = <RadioButtonUnchecked fontSize="small" />
const iconRadioChecked = <RadioButtonChecked fontSize="small" />
const iconCheckBox = <CheckBoxOutlineBlank fontSize="small" />
const iconCheckBoxChecked = <CheckBox fontSize="small" />
const languages = [
  {
    id: 'en-GB',
    name: 'English (United Kingdom)',
  },
  {
    id: 'en-US',
    name: 'English (United States)',
  },
  // {
  //   id: 'fr-FR',
  //   name: 'French',
  // },
  // {
  //   id: 'es-ES',
  //   name: 'Spanish',
  // },
  // {
  //   id: 'de-DE',
  //   name: 'German',
  // },
  // {
  //   id: 'it-IT',
  //   name: 'Italian',
  // },
]
export const ProfileEditForm = (props: any) => {
  const {
    onCancelClick,
    myInstance,
    setMyInstance,
    handleProfileChange,
    isAdmin,
    setUpadtedData,
    error,
  } = props
  const { resellerId } = useParams()
  const intl = useIntl()
  const accounts = useResellerAccounts(
    { resellerId },
    {
      refetchOnMount: 'always',
      onSuccess(data: any) {
        const d = data.pages.length
        if (data.pages[d - 1].hasNextPage) {
          void accounts.fetchNextPage()
        }
      },
    }
  )
  const accountsList = accounts?.data?.pages
    .map((value: any) => value.data)
    .flat()
  const [isValid, setValid] = useState(false)
  const [isPasswordValid, setPasswordValid] = useState(true)
  const [isConfirmPasswordValid, setConfirmPasswordValid] = useState(true)
  const [isFirstNameValid, setFirstNameValid] = useState(true)
  const [changePassword, setChangePassword] = useState(false)
  const [isEmailValid, setEmailValid] = useState(true)
  const [isPhoneValid, setPhoneValid] = useState(true)
  const [emailError, setEmailError] = useState('invalid email')
  const [phoneError, setPhoneError] = useState('invalid phone')
  const [email, setEmail] = useState(myInstance.email)
  const [defaultLanguage, setDefaultLanguage] = useState('' as string)
  const [selectedAccount, setSelectedAccount] = useState([] as any[])
  const accountNames = accountsList?.map((account: any) => account.name) || []
  const languageNames = languages.map((languages: any) => languages.name)
  useEffect(() => {
    setDefaultLanguage(findNameById(myInstance?.language, languages))
    validationCheck()
    myInstance.phone === '' && setPhoneValid(false)
    myInstance.email === '' && setEmailValid(false)
    myInstance?.email && setEmail(myInstance.email)
  }, [myInstance])
  useEffect(() => {
    if (error?.includes('email')) {
      setEmailValid(false)
      setEmailError(error)
    } else if (error?.includes('phone')) {
      setPhoneValid(false)
      setPhoneError(error)
    }
  }, [error])
  useEffect(() => {
    const names = myInstance?.accounts.map((account: string) =>
      findNameById(account, accountsList)
    )
    setSelectedAccount(names)
  }, [accounts?.data, myInstance?.accounts])
  function validateAndSet(profileData: any) {
    var newObject = JSON.parse(JSON.stringify(profileData))
    setMyInstance(newObject)
    validationCheck()
  }
  function validationCheck() {
    setValid(
      myInstance.firstName?.trim() != '' &&
        myInstance.firstName != null &&
        validateFirstName(myInstance.firstName) &&
        myInstance.phone !== null &&
        myInstance.phone &&
        myInstance.phone !== '' &&
        myInstance.language != null &&
        myInstance.language != '' &&
        myInstance.email != '' &&
        myInstance.email != null &&
        (!myInstance?.id
          ? myInstance.password != null &&
            myInstance.password != '' &&
            validatePassword(myInstance.password)
          : true)
    )
  }
  const handleChangeFirstName = (event: any) => {
    myInstance.firstName = event.target.value
    setUpadtedData((prevState: any) => ({
      ...prevState,
      firstName: event.target.value,
    }))
    validateAndSet(myInstance)
  }
  const handleChangeLastName = (event: any) => {
    myInstance.lastName = event.target.value
    setUpadtedData((prevState: any) => ({
      ...prevState,
      lastName: event.target.value,
    }))
    validateAndSet(myInstance)
  }
  const handleChangeLanguage = (event: any, value: any) => {
    const languageId = findIdByName(value, languages)
    myInstance.language = languageId!
    setUpadtedData((prevState: any) => ({
      ...prevState,
      language: languageId!,
    }))
    validateAndSet(myInstance)
  }
  const handlePhoneNumberChange = (number: any) => {
    setPhoneError('')
    setPhoneValid(true)
    const phone = number.replace(/\s/g, '')
    if (!phone.length) {
      setPhoneError('phone required')
      setPhoneValid(false)
    }
    myInstance.phone = phone
    setUpadtedData((prevState: any) => ({
      ...prevState,
      phone: phone,
    }))
    validateAndSet(myInstance)
  }
  const handleChangePassword = (event: any) => {
    const password = event.target.value
    myInstance.password = password

    if (password != null) {
      setPasswordValid(false)
      if (validatePassword(password)) {
        validateAndSet(myInstance)
        setPasswordValid(true)
      } else {
        !myInstance.id && validateAndSet(myInstance)
      }
    }
    myInstance.confirmPassword && handlePasswordMatch()
  }
  const handlePasswordMatch = () => {
    if (myInstance.confirmPassword === myInstance.password) {
      validateAndSet(myInstance)
      setConfirmPasswordValid(true)
      setPasswordValid(true)
      setUpadtedData((prevState: any) => ({
        ...prevState,
        password: myInstance.password,
      }))
    } else {
      setConfirmPasswordValid(false)
    }
  }
  const handleChangeConfirmPassword = (event: any) => {
    const confirmPassword = event.target.value
    myInstance.confirmPassword = confirmPassword
    if (confirmPassword != null && confirmPassword != '') {
      setConfirmPasswordValid(false)
      handlePasswordMatch()
    }
  }
  const handleAccountsChange = (event: any, newValue: any) => {
    setSelectedAccount(newValue)
    const accountIds = newValue.map((account: any) =>
      findIdByName(account, accountsList)
    )
    myInstance.accounts = accountIds
    validateAndSet(myInstance)
    setUpadtedData((prevState: any) => ({
      ...prevState,
      accounts: accountIds,
    }))
  }
  const onClickUpdatePassword = () => {
    handleProfileChange()
    setChangePassword(false)
  }
  const handleChangeEmail = (event: any) => {
    const emailValue = event.target.value
    setEmail(emailValue) // Update local state to reflect input changes
    myInstance.email = emailValue // Update the instance value

    if (emailValue !== null) {
      setValid(false)
      setEmailValid(false)
      setEmailError('invalid email')
      if (validateEmail(emailValue)) {
        setUpadtedData((prevState: any) => ({
          ...prevState,
          email: emailValue,
        }))
        validateAndSet(myInstance)
        setEmailValid(true)
      } else if (emailValue == '') {
        setEmailError('email required')
      }
    } else {
      setValid(true)
      setEmailValid(true)
    }
  }
  function validateEmail(email: any) {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    setEmailValid(emailRegex.test(email))
    return emailRegex.test(email)
  }
  function validateFirstName(firstName: any) {
    setFirstNameValid(firstName.length >= 3)
    return firstName.length >= 3
  }
  function validatePassword(password: any) {
    setPasswordValid(password.length >= 4)
    return password.length >= 4
  }
  return (
    <>
      <Box sx={{ paddingX: 3, paddingY: 2 }}>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12} sm={6} md={9}>
            <Box marginTop={1}>
              <Typography variant="h6" component="h1">
                {myInstance?.id
                  ? `${intl.formatMessage({ id: 'action_edit' })}`
                  : `${intl.formatMessage({ id: 'action_create' })}`}{' '}
                {isAdmin
                  ? `${intl.formatMessage({ id: 'label_user' })}`
                  : `${intl.formatMessage({ id: 'label_profile' })}`}
              </Typography>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            md={3}
            justifyContent="flex-end"
            display="flex"
          >
            <Button onClick={onCancelClick}>
              {intl.formatMessage({ id: 'action_cancel' })}
            </Button>

            <Button
              onClick={handleProfileChange}
              disabled={!isValid}
              color="success"
              sx={{ ml: 2 }}
            >
              {myInstance?.id
                ? `${intl.formatMessage({ id: 'action_update' })}`
                : `${intl.formatMessage({ id: 'action_create' })}`}
            </Button>
          </Grid>
        </Grid>
      </Box>
      <Box sx={{ padding: 3 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={4}>
            <CommonTextField
              id="outlined-basic"
              label={intl.formatMessage({ id: 'label_first_name' })}
              onChange={handleChangeFirstName}
              error={!isFirstNameValid}
              helperText={
                !isFirstNameValid ? 'name must be at least 3 characters' : ' '
              }
              autoFocus={true}
              value={myInstance?.firstName}
            />
          </Grid>
          <Grid item xs={12} sm={4} mt={{ xs: 2, sm: 0 }}>
            <CommonTextField
              id="outlined-basic"
              label={intl.formatMessage({ id: 'label_last_name' })}
              onChange={handleChangeLastName}
              value={myInstance.lastName}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <MuiTelInput
              label={intl.formatMessage({ id: 'label_phone_number' })}
              placeholder="e.g. +1 123-456-7890"
              value={myInstance.phone}
              onChange={handlePhoneNumberChange}
              error={!isPhoneValid}
              helperText={!isPhoneValid ? phoneError : ' '}
              disabled={isAdmin ? false : myInstance?.id ? true : false}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <CommonTextField
              id="outlined-basic"
              label={intl.formatMessage({ id: 'label_email' })}
              disabled={!isAdmin}
              onChange={handleChangeEmail}
              error={!isEmailValid}
              helperText={!isEmailValid ? emailError : ' '}
              value={email}
            />
          </Grid>
          {!myInstance?.id && (
            <Grid item xs={12} sm={4}>
              <PasswordInputField
                handleChangePassword={handleChangePassword}
                isPasswordValid={isPasswordValid}
                label={intl.formatMessage({ id: 'label_new_password' })}
                helperText={intl.formatMessage({
                  id: 'misc_password_min_length',
                })}
                value={myInstance.password}
              />
            </Grid>
          )}
          <Grid item xs={12} sm={4}>
            <Autocomplete
              color="info"
              limitTags={1}
              id="choose-language"
              options={languageNames}
              onChange={handleChangeLanguage}
              value={defaultLanguage}
              disableClearable
              renderOption={(props, option, { selected }) => (
                <li {...props} style={{ padding: 0 }}>
                  <Radio
                    color="info"
                    icon={iconRadio}
                    checkedIcon={iconRadioChecked}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {option}
                </li>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={intl.formatMessage({
                    id: 'action_select_language',
                  })}
                />
              )}
            />
          </Grid>
          {isAdmin && (
            <Grid item xs={12} sm={4}>
              <Autocomplete
                multiple
                color="info"
                size="small"
                limitTags={1}
                id="choose-account"
                options={accountNames}
                value={selectedAccount}
                disableClearable
                disableCloseOnSelect
                onChange={handleAccountsChange}
                renderOption={(props, option, { selected }) => (
                  <li {...props} style={{ padding: 0 }}>
                    <Checkbox
                      color="info"
                      icon={iconCheckBox}
                      checkedIcon={iconCheckBoxChecked}
                      style={{ marginRight: 8 }}
                      checked={selected}
                    />
                    {option}
                  </li>
                )}
                renderTags={(selectedOptions, getTagProps) =>
                  selectedOptions.map((option, index) => (
                    <Chip label={option} {...getTagProps({ index })} />
                  ))
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={intl.formatMessage({
                      id: 'action_select_account',
                    })}
                  />
                )}
              />
            </Grid>
          )}
        </Grid>
      </Box>
      {myInstance?.id && (
        <Box
          sx={{ p: 3, pt: 0, display: 'flex', justifyContent: 'flex-start' }}
        >
          <Button onClick={() => setChangePassword(true)}>
            {intl.formatMessage({ id: 'action_change_password' })}
          </Button>
        </Box>
      )}
      {changePassword ? (
        <Dialog open fullWidth>
          <DialogTitle sx={{ p: 0 }} id="customized-dialog-title">
            {intl.formatMessage({ id: 'action_change_password' })}
          </DialogTitle>
          <IconButton
            aria-label="close"
            onClick={() => setChangePassword(false)}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <Close />
          </IconButton>
          <DialogContent>
            <Grid item xs={12} sm={12}>
              <PasswordInputField
                handleChangePassword={handleChangePassword}
                isPasswordValid={isPasswordValid}
                label={intl.formatMessage({ id: 'label_new_password' })}
                helperText={intl.formatMessage({
                  id: 'misc_password_min_length',
                })}
                autoFocus={true}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <PasswordInputField
                handleChangePassword={handleChangeConfirmPassword}
                isPasswordValid={isConfirmPasswordValid}
                label={intl.formatMessage({ id: 'label_confirm_password' })}
                sx={{ marginTop: 1 }}
                helperText={intl.formatMessage({ id: 'misc_confirm_password' })}
                onKeyDown={onClickUpdatePassword}
              />
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                setChangePassword(false)
                setMyInstance((state: any) => ({
                  ...state,
                  password: null,
                  confirmPassword: null,
                }))
              }}
            >
              {intl.formatMessage({ id: 'action_cancel' })}
            </Button>
            <Button
              disabled={
                !(
                  isPasswordValid &&
                  isConfirmPasswordValid &&
                  myInstance.password &&
                  myInstance.confirmPassword
                )
              }
              onClick={onClickUpdatePassword}
              color="success"
              sx={{ ml: 2 }}
            >
              {intl.formatMessage({ id: 'action_confirm' })}
            </Button>
          </DialogActions>
        </Dialog>
      ) : null}
    </>
  )
}
