import { FC } from 'react'
import { Dialog, DialogProps } from '../../../components/Dialog'
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Typography,
} from '@mui/material'
import { useCategoryDelete, useItemDelete } from '../../../hooks'
import { useIntl } from 'react-intl'

/**
 *
 */
export interface DeleteConfirmationDialogProps extends DialogProps {
  locationId: string
  menuId: string
  categoryId: string
  type: string
  onDeleteConfirmation: () => void
  setProductDelete: (val: any) => void
}

/**
 * DeleteConfirmationDialog.ts
 */
export const DeleteConfirmationDialog: FC<DeleteConfirmationDialogProps> = (
  props
) => {
  const {
    locationId,
    menuId,
    categoryId,
    type,
    onDeleteConfirmation,
    setProductDelete,
    ...dialogProps
  } = props
  const intl = useIntl()
  const categoryDelete = useCategoryDelete(locationId, menuId)
  const itemDelete = useItemDelete(locationId, menuId)
  // const confirm = () => {
  //   if (type === 'category') {
  //     categoryDelete.mutate(categoryId, {
  //       onSuccess: () => {
  //         dialogProps.onClose?.({}, 'escapeKeyDown')
  //       },
  //     })
  //   } else if (type === 'item') {
  //     itemDelete.mutate(categoryId, {
  //       onSuccess: () => {
  //         dialogProps.onClose?.({}, 'escapeKeyDown')
  //       },
  //     })
  //   }
  // }

  return (
    <Dialog
      {...dialogProps}
      fullWidth
      disableEscapeKeyDown={categoryDelete.isLoading}
    >
      <DialogTitle>
        {intl.formatMessage({ id: 'label_remove_with_name' }, { name: type })}
      </DialogTitle>
      <DialogContent>
        {/* Form to get the name and description of the cateogry */}
        <Typography variant="body1">
          {intl.formatMessage(
            { id: 'label_confirmation_for_delete' },
            { type: type }
          )}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={(e) =>
            dialogProps?.onClose && dialogProps.onClose(e, 'escapeKeyDown')
          }
        >
          {intl.formatMessage({ id: 'action_cancel' })}
        </Button>
        <Button
          color="success"
          onClick={() => {
            type === 'category'
              ? setProductDelete(categoryDelete)
              : setProductDelete(itemDelete)
            onDeleteConfirmation()
          }}
          disabled={categoryDelete.isLoading || itemDelete.isLoading}
        >
          {intl.formatMessage({ id: 'action_confirm' })}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
