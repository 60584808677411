import { FC, useEffect, useMemo, useState } from 'react'
import { Dialog, DialogProps } from '../../../components/Dialog'
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Button,
  Box,
} from '@mui/material'
import {
  useCategoryCreate,
  useCategoryUpdate,
  useItemsUpdateWhileUpdateMenu,
} from '../../../hooks'
import { CommonTextField } from '../../../components'
import { useIntl } from 'react-intl'
import {
  MenuCategoryCreateEntity,
  MenuCategoryPatchEntity,
} from '../../../types'
import {
  defaultCategoryState,
  validateCategoryEntity,
} from '../../Locations/CardSelect/Menu/utils'
import { ConfirmationDialog } from './ConfirmationDialog'
import { toast } from 'react-toastify'
import { capitalizeFirstLetter } from '../../Locations/utils'
import { ShowOnlinePicker } from '../../../components/Pickers'

/**
 *
 */
export interface AddCategoryDialogProps extends DialogProps {
  locationId: string
  menuId: string
  items: any
  selectedCategoryData?: any
  setSelectedCategoryData?: any
  selectedCatId?: string
  setCategoriesState?: any
  categoriesState?: any
  setEditCategoryId?: any
  setSelectedCatId?: any
}

/**
 * AddCategoryDialog.ts
 */
export const AddCategoryDialog: FC<AddCategoryDialogProps> = (props) => {
  const {
    locationId,
    menuId,
    items,
    selectedCategoryData,
    setSelectedCategoryData,
    selectedCatId,
    setCategoriesState,
    categoriesState,
    setEditCategoryId,
    setSelectedCatId,
    ...dialogProps
  } = props
  const intl = useIntl()
  const [menuCategoryState, setMenuCategoryState] = useState<
    MenuCategoryCreateEntity | MenuCategoryPatchEntity
  >(defaultCategoryState)
  const [showConfirmDailog, setShowConfirmDailog] = useState(false)
  const [isConfirmed, setIsConfirmed] = useState(false)
  const [categoryUpdateState, setCategoryUpdateState] = useState<any>({})
  const [itemsNotUpdated, setItemsNotUpdated] = useState<boolean>(false)
  const [updatedCategoryState, setUpdatedCategoryState] = useState<any>({})
  const itemUpdateMutation = useItemsUpdateWhileUpdateMenu(locationId!, menuId!)
  const itemsData = items.data?.pages.map((page: any) => page.data).flat()
  const selectedCategoryItems = useMemo(() => {
    return itemsData?.filter((item: any) =>
      item?.categories?.includes(selectedCatId)
    )
  }, [selectedCatId, itemsData])
  const createCategory = useCategoryCreate(locationId, menuId)
  const updateCategory = useCategoryUpdate(locationId, menuId, selectedCatId!)
  const isValid = useMemo(
    () => validateCategoryEntity(menuCategoryState),
    [menuCategoryState]
  )
  useEffect(() => {
    if (isConfirmed) {
      selectedCategoryItems?.map((item: any) => {
        itemUpdateMutation.mutate(
          {
            itemData: { showOnline: categoryUpdateState.showOnline },
            itemId: item.id,
          },
          {
            onSuccess: () => {
              setShowConfirmDailog(false)
              setIsConfirmed(false)
              setSelectedCatId(null)
              items.refetch()
              dialogProps.onClose?.({}, 'escapeKeyDown')
              setCategoriesState(updatedCategoryState)
              setEditCategoryId(undefined)
              setSelectedCategoryData(undefined)
            },
          }
        )
      })
    }
  }, [isConfirmed])
  useEffect(() => {
    if (itemsNotUpdated) {
      dialogProps.onClose?.({}, 'escapeKeyDown')
      setCategoriesState(updatedCategoryState)
      setEditCategoryId(undefined)
      setSelectedCategoryData(undefined)
    }
  }, [itemsNotUpdated])
  useEffect(() => {
    if (selectedCategoryData) {
      setMenuCategoryState(selectedCategoryData)
    }
  }, [selectedCategoryData])
  const handleSave = () => {
    if (selectedCatId) {
      Object.keys(categoryUpdateState).length !== 0
        ? setShowConfirmDailog(true)
        : null
      updateCategory.mutate(menuCategoryState as MenuCategoryPatchEntity, {
        onSuccess: (data: any) => {
          const selectedCatIndex = categoriesState?.findIndex(
            (cat: any) => cat.id === selectedCatId
          )
          let updatedCatState = [...categoriesState]
          updatedCatState[selectedCatIndex] = {
            ...data.data,
          }
          setUpdatedCategoryState(updatedCatState)
          Object.keys(categoryUpdateState).length === 0
            ? (setCategoriesState(updatedCatState),
              setEditCategoryId(undefined),
              setSelectedCategoryData(undefined),
              dialogProps.onClose?.({}, 'escapeKeyDown'))
            : null
        },
      })
    } else {
      createCategory.mutate(
        {
          ...menuCategoryState,
          name: capitalizeFirstLetter(menuCategoryState.name!),
        } as MenuCategoryCreateEntity,
        {
          onSuccess: () => {
            toast.success('Category Created')
            dialogProps.onClose?.({}, 'escapeKeyDown')
          },
        }
      )
    }
  }
  const handleShowOnlineChange = (showOnline: boolean) => {
    setCategoryUpdateState({ showOnline: showOnline })
    setMenuCategoryState((state) => ({
      ...state,
      showOnline: showOnline,
    }))
  }
  const handleNameChange = (event: any) => {
    setMenuCategoryState((state) => ({
      ...state,
      name: event.target.value,
    }))
  }
  const handleDescriptionChange = (event: any) => {
    setMenuCategoryState((state) => ({
      ...state,
      description: event.target.value,
    }))
  }

  return (
    <Dialog
      {...dialogProps}
      fullWidth
      disableEscapeKeyDown={createCategory.isLoading}
    >
      <DialogTitle>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          {intl.formatMessage({
            id: selectedCatId ? 'label_update_category' : 'label_add_category',
          })}
          <Grid
            item
            xs={6}
            md={3}
            mt={1}
            sx={{ display: 'flex', alignItems: 'center' }}
          >
            <Box width={150} mx={1}>
              <ShowOnlinePicker
                value={menuCategoryState.showOnline}
                onChange={(value) => handleShowOnlineChange(value)}
              />
            </Box>
          </Grid>
        </Box>
      </DialogTitle>
      <DialogContent>
        {/* Form to get the name and description of the cateogry */}
        <Grid container spacing={2} py={2}>
          {/* Name */}
          <Grid item xs={12}>
            <CommonTextField
              id="outlined-basic"
              label={`${intl.formatMessage({ id: 'label_name' })}${' *'}`}
              onChange={handleNameChange}
              value={menuCategoryState?.name || ''}
              autoFocus={true}
            />
          </Grid>
          {/* Description */}
          <Grid item xs={12}>
            <CommonTextField
              id="outlined-basic"
              label={`${intl.formatMessage({
                id: 'label_description',
              })}`}
              onChange={handleDescriptionChange}
              value={menuCategoryState?.description || ''}
              rows={4}
              multiline
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => dialogProps.onClose?.({}, 'escapeKeyDown')}>
          {intl.formatMessage({ id: 'action_cancel' })}
        </Button>
        <Button
          onClick={() => handleSave()}
          color="success"
          disabled={createCategory.isLoading || !isValid}
        >
          {intl.formatMessage({
            id: selectedCatId ? 'action_update' : 'action_create',
          })}
        </Button>
      </DialogActions>
      {showConfirmDailog && (
        <ConfirmationDialog
          open
          title="Confirmation"
          message="Would you like to change the showonline for the all the items inside this category?"
          updateNewState={setIsConfirmed}
          setShowConfirmDailog={setShowConfirmDailog}
          setItemsNotUpdated={setItemsNotUpdated}
        />
      )}
    </Dialog>
  )
}
