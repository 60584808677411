import {
  LogoutOutlined,
  SettingsOutlined,
  DeveloperModeOutlined,
  ContrastOutlined,
  ArticleOutlined,
} from '@mui/icons-material'
import {
  IconButton,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Avatar,
  ListItem,
  ListItemAvatar,
} from '@mui/material'
import { useState } from 'react'
import { useAuthStore } from '../../hooks'
import { useNavigate } from 'react-router-dom'
import { useProfile } from '../../hooks/useProfile'
import { useThemeStore } from '../../theme/ThemeProvider'

/**
 *
 */
export const UserDropdown = () => {
  const [accountId] = useAuthStore((t) => [t.accountId])
  const [anchor, setAnchor] = useState<null | HTMLElement>(null)
  const [theme, setTheme] = useThemeStore((state) => [
    state.theme,
    state.setTheme,
  ])
  const isOpen = anchor !== null
  const [selectedItem, setSelectedItem] = useState('')
  const navigate = useNavigate()
  const profile = useProfile()
  const logout = useAuthStore((state) => state.logout)
  const handleLogout = () => {
    logout()
    navigate('/signin')
  }
  const styles = {
    menuItem: {
      display: 'flex',
      alignItems: 'center',
      padding: '10px 16px',
    },
  }

  return (
    <>
      <IconButton onClick={(event) => setAnchor(event.currentTarget)}>
        <Avatar variant="rounded">
          {profile.data?.data?.firstName?.charAt(0)?.toUpperCase()}
        </Avatar>
      </IconButton>
      <Menu
        anchorEl={anchor}
        open={isOpen}
        onClose={() => setAnchor(null)}
        onClick={() => setAnchor(null)}
        PaperProps={{ sx: { overflow: 'visible', mt: 1, minWidth: 300 } }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
      >
        <MenuItem
          selected={selectedItem === 'profile'}
          sx={styles.menuItem}
          onClick={() => {
            navigate('/app/profile/me')
            setSelectedItem('profile')
          }}
        >
          <ListItem disableGutters>
            <ListItemAvatar>
              <Avatar
                alt={profile.data?.data?.firstName}
                variant="rounded"
                sx={{ width: 36, height: 36 }}
              />
            </ListItemAvatar>
            <ListItemText
              primary={[
                profile.data?.data?.firstName,
                profile.data?.data?.lastName,
              ].join(' ')}
              secondary={profile.data?.data?.email}
            />
          </ListItem>
        </MenuItem>

        <MenuItem
          sx={styles.menuItem}
          selected={selectedItem === 'developer'}
          onClick={() => {
            navigate('/app/developer')
            setSelectedItem('developer')
          }}
        >
          <ListItemIcon>
            <DeveloperModeOutlined />
          </ListItemIcon>
          <ListItemText primary="Developer" />
        </MenuItem>
        <MenuItem
          sx={styles.menuItem}
          onClick={() =>
            window.open(`${API_ENDPOINT}/docs/index.html`, '_blank')
          }
        >
          <ListItemIcon>
            <ArticleOutlined />
          </ListItemIcon>
          <ListItemText primary="API Documentation" />
        </MenuItem>
        {accountId && (
          <MenuItem
            sx={styles.menuItem}
            selected={selectedItem === 'accounts'}
            onClick={() => {
              navigate('/app/accounts')
              setSelectedItem('accounts')
            }}
          >
            <ListItemIcon>
              <SettingsOutlined />
            </ListItemIcon>
            <ListItemText primary="Manage Account" />
          </MenuItem>
        )}
        <MenuItem style={styles.menuItem} onClick={handleLogout}>
          <ListItemIcon>
            <LogoutOutlined sx={{ marginRight: 2 }} />
          </ListItemIcon>
          <ListItemText primary="Logout" />
        </MenuItem>
        <MenuItem
          style={styles.menuItem}
          onClick={() => setTheme(theme === 'light' ? 'dark' : 'light')}
        >
          <ListItemIcon>
            <ContrastOutlined sx={{ marginRight: 2 }} />
          </ListItemIcon>
          <ListItemText
            id="switch-list-label-theme-mode"
            primary={`${theme === 'light' ? 'Dark' : 'Light'} mode`}
          />
        </MenuItem>
      </Menu>
    </>
  )
}
