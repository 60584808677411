import { Box, Grid, Typography, Button } from '@mui/material'
import { CommonTextField } from '../CommonTextField'
import { useIntl } from 'react-intl'
import { ChangeEvent, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { MenuModifierPatchEntity } from '../../types'
import { validationCheckUpdateforModifier } from '../../pages/Locations/CardSelect/Menu/utils'
import { getCurrencySymbol } from '../../pages/util'
import { CommonPriceInputField } from '../CommonPriceField'
import { CollapsibleSection } from '../CollapsibleSection'
import { MenuAdvancedOptions } from './MenuAdvancedOptions'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import { NestedModifiers } from './NestedModifiersEditForm'
import MinMaxField from '../MinMaxFields'
import { ShowOnlinePicker } from '../Pickers'

export const ModifierEditForm = (props: any) => {
  const {
    modifierState,
    handleModifierUpdate,
    handleNavigation,
    isNestedModifiers,
    updatedSelections,
  } = props
  const currency = getCurrencySymbol()
  const { itemId, modifierGroupId, modifierId } = useParams()
  const intl = useIntl()
  const [valid, setValid] = useState(false)
  const [showAdvancedOptions, setShowAdvancedOptions] = useState(false)
  const [showNestedModifiers, setShowNestedModifiers] =
    useState(isNestedModifiers)
  const [updatedState, setUpdatedState] = useState<MenuModifierPatchEntity>({})
  const [currentModifier, setCurrentModifier] = useState<any>({})
  useEffect(() => {
    const currentModifier = updatedSelections
      ?.find((group: any) => group.menuModifierGroupId === modifierGroupId)
      ?.modifiers?.find(
        (modifier: any) => modifier.menuModifierId === modifierId
      )
    setCurrentModifier(currentModifier)
  }, [updatedSelections])

  type NestedData =
    | {
        [key: string]: any // Represents objects with arbitrary keys
      }
    | any[]
    | null
    | undefined

  const removeNameProperty = (data: NestedData): NestedData => {
    if (Array.isArray(data)) {
      return data.map((item) => removeNameProperty(item))
    }

    if (typeof data === 'object' && data !== null) {
      const newData = { ...data }
      delete newData.name

      Object.keys(newData).forEach((key) => {
        newData[key] = removeNameProperty(newData[key])
      })

      return newData
    }

    return data
  }

  const handleNestedModifiersUpdate = (selections: any) => {
    const updatedSelections = removeNameProperty(selections)
    validateAndSet({ selections: updatedSelections })
  }
  function validationCheckUpdate(updatedState: any) {
    const validationResults = validationCheckUpdateforModifier(updatedState)
    const validMinAndMax =
      updatedState?.maxPermitted === 0 ||
      (updatedState?.minPermitted || 0) <= (updatedState?.maxPermitted || 0)

    // Check if all the validations pass
    const isValid =
      Object.values(validationResults).every((value) => value) && validMinAndMax
    setValid(isValid)
  }
  function validateAndSet(accountData: any) {
    var newObject = JSON.parse(JSON.stringify(accountData))
    setUpdatedState({ ...updatedState, ...newObject })
    validationCheckUpdate({ ...updatedState, ...newObject })
  }
  const handleNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    updatedState.name = event.target.value
    validateAndSet(updatedState)
  }
  const handlePriceChange = (data: any) => {
    updatedState.price = data
    validateAndSet(updatedState)
  }
  const handleInStorePriceChange = (data: any) => {
    updatedState.inStorePrice = data
    validateAndSet(updatedState)
  }
  const handleAdvancedOptionsShow = () => {
    setShowAdvancedOptions(!showAdvancedOptions)
  }
  const handleDescriptionChange = (event: ChangeEvent<HTMLInputElement>) => {
    updatedState.description = event.target.value
    validateAndSet(updatedState)
  }
  const handleShowOnlineChange = (value: boolean) => {
    updatedState.showOnline = value
    validateAndSet(updatedState)
  }
  const handleNestedModifiersOptionsShow = () => {
    setShowNestedModifiers(!showNestedModifiers)
  }
  return (
    <>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Typography variant="h6" component="h1" id="id_modifier_edit_form">
          {modifierId
            ? `${intl.formatMessage({ id: 'action_update' })}`
            : `${intl.formatMessage({ id: 'action_create' })}`}{' '}
          {intl.formatMessage({ id: 'label_modifier' })}
        </Typography>
        <Box>
          <Button onClick={() => handleNavigation()} id="id_cancel_modifier">
            {intl.formatMessage({ id: 'action_cancel' })}
          </Button>
          <Button
            onClick={() => handleModifierUpdate(updatedState)}
            disabled={!valid}
            color="success"
            sx={{ ml: 2 }}
            id="id_save_modifier"
          >
            {modifierId
              ? `${intl.formatMessage({ id: 'action_update' })}`
              : `${intl.formatMessage({ id: 'action_create' })}`}
          </Button>
        </Box>
      </Box>
      <Box sx={{ mt: 2 }}>
        <Grid container spacing={2} sx={{ marginTop: 1 }}>
          <Grid item xs={12} sm={10}>
            <CommonTextField
              id="outlined-basic"
              label={`${intl.formatMessage({ id: 'label_name' })}${' *'}`}
              onChange={handleNameChange}
              value={updatedState?.name ?? modifierState?.name}
              autoFocus={true}
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <ShowOnlinePicker
              value={
                'showOnline' in updatedState
                  ? updatedState.showOnline
                  : modifierState.showOnline
              }
              onChange={(value) => handleShowOnlineChange(value)}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <CommonPriceInputField
              label={`${intl.formatMessage({ id: 'label_price' })}${' *'}`}
              currency={currency}
              value={updatedState?.price ?? modifierState.price!}
              onChange={handlePriceChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <CommonPriceInputField
              label={`${intl.formatMessage({
                id: 'label_inStorePrice',
              })}`}
              currency={currency}
              value={updatedState?.inStorePrice ?? modifierState.inStorePrice!}
              onChange={handleInStorePriceChange}
            />
          </Grid>
          <MinMaxField
            size={{ xs: 12, sm: 6 }}
            minValue={
              updatedState?.minPermitted ?? (modifierState.minPermitted || 0)
            }
            maxValue={
              updatedState?.maxPermitted ?? (modifierState.maxPermitted || 0)
            }
            onChange={validateAndSet}
          />
          <Grid item xs={12}>
            <CommonTextField
              id="outlined-multiline-static"
              label={intl.formatMessage({ id: 'label_description' })}
              onChange={handleDescriptionChange}
              value={
                updatedState?.description ?? (modifierState.description || '')
              }
              rows={4}
              multiline
            />
          </Grid>
          <Grid item xs={12}>
            <Box
              onClick={(e) => {
                e.stopPropagation()
                handleAdvancedOptionsShow()
              }}
              sx={{
                color: '#1976d2',
                cursor: 'pointer',
                display: 'flex',
                justifyContent: 'space-between',
                p: 1,
              }}
            >
              <Typography
                variant="body1"
                component="a"
                sx={{ color: 'inherit' }}
                id="id_advanced_options"
              >
                {intl.formatMessage({ id: 'label_advanced_options' })}
              </Typography>

              <KeyboardArrowDownIcon
                style={{
                  fontSize: '24px',
                  transform: showAdvancedOptions ? 'rotate(180deg)' : 'none',
                  transition: 'transform 0.5s ease-in',
                }}
              />
            </Box>
          </Grid>
          <Grid item xs={12} sx={{ pt: '0 !important' }}>
            <CollapsibleSection
              menuItemState={modifierState}
              show={showAdvancedOptions}
              children={
                <MenuAdvancedOptions
                  menuItemState={modifierState}
                  updatedState={updatedState}
                  validateAndSet={validateAndSet}
                  disabled={itemId ? true : false}
                />
              }
            />
          </Grid>
          {isNestedModifiers ? (
            <>
              <Grid item xs={12}>
                <Box
                  onClick={(e) => {
                    e.stopPropagation()
                    handleNestedModifiersOptionsShow()
                  }}
                  sx={{
                    color: '#1976d2',
                    cursor: 'pointer',
                    display: 'flex',
                    justifyContent: 'space-between',
                    p: 1,
                  }}
                >
                  <Typography
                    variant="body1"
                    component="a"
                    sx={{ color: 'inherit' }}
                    id="id_nested_modifiers"
                  >
                    {intl.formatMessage({ id: 'label_nested_modifiers' })}
                  </Typography>

                  <KeyboardArrowDownIcon
                    style={{
                      fontSize: '24px',
                      transform: showNestedModifiers
                        ? 'rotate(180deg)'
                        : 'none',
                      transition: 'transform 0.5s ease-in',
                    }}
                  />
                </Box>
              </Grid>
              {showNestedModifiers ? (
                <Grid item xs={12}>
                  <NestedModifiers
                    currentModifiersSelection={currentModifier?.selections!}
                    handleNestedModifiersUpdate={handleNestedModifiersUpdate}
                  />
                </Grid>
              ) : null}
            </>
          ) : null}
        </Grid>
      </Box>
    </>
  )
}
