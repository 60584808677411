import {
  Box,
  Button,
  Chip,
  Dialog,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Snackbar,
  SnackbarContentProps,
  Typography,
  Paper,
} from '@mui/material'
import {
  AppLoader,
  AppWarningDialog,
  ClickableIcon,
  CreateButtonFlexEnd,
  ItemsListPop,
  ModifierGroupListTable,
  NoRecordFound,
  StyledButtonContainerForTable,
} from '../../../../../../components'
import { MenuModifierGroupEntity } from '../../../../../../types'
import { useNavigate, useParams } from 'react-router-dom'
import { formatUtcDate, shortenText } from '../../../../../util'
import { useIntl } from 'react-intl'
import {
  useMenuModifierGroupDelete,
  useMenuModifierGroups,
  useMenuModifiers,
} from '../../../../../../hooks'
import { DeleteOutline, EditOutlined } from '@mui/icons-material'
import { Fragment, useEffect, useState } from 'react'

export function MenuModifierGroups() {
  const navigate = useNavigate()
  const intl = useIntl()
  const [open, setOpen] = useState(false)
  const { locationId, menuId } = useParams()
  const modifierGroups = useMenuModifierGroups(locationId!, menuId!, {
    refetchOnMount: 'always',
    onSuccess: (data: any) => {
      const d = data.pages.length
      if (data.pages[d - 1].hasNextPage) {
        void modifierGroups.fetchNextPage()
      }
      setSelectedModifierGroup(
        data?.pages?.map((value: any) => value.data).flat()
      )
    },
  })
  const [isFetching, setIsFetching] = useState(true)
  const modifiers = useMenuModifiers(locationId!, menuId!, {
    refetchOnMount: 'always',
    onSuccess: (data: any) => {
      const d = data.pages.length
      if (data.pages[d - 1].hasNextPage) {
        void modifiers.fetchNextPage()
        setIsFetching(true)
      }
      setIsFetching(false)
      setModifiersData(data?.pages?.map((value: any) => value.data).flat())
    },
  })
  const modifierOptions = modifiers.data?.pages
    .map((value) => value.data)
    .flat()
  const sortedModifierOptions: any = modifierOptions?.sort(
    (a: any, b: any) => a.position - b.position
  )
  const [modifiersData, setModifiersData] = useState<any[]>([])
  const [modifierName, setModifierGroupName] = useState<string>('')
  const [popOpen, setPopOpen] = useState<boolean>(false)
  const modifierGroupDelete = useMenuModifierGroupDelete(locationId!, menuId!)
  const records = modifierGroups.data?.pages?.map((value) => value.data).flat()
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false)
  const [selectedModifierGroup, setSelectedModifierGroup] = useState(
    {} as MenuModifierGroupEntity
  )
  const [modifierGroupState, setModifierGroupState] = useState<any[]>([])
  const [showUndoSnackbar, setShowUndoSnackbar] = useState(false)
  const [highlightedRow, setHighlightedRow] = useState(false)
  const [deletedItem, setDeletedItem] = useState<any>(null)
  const [deletedList, setDeletedList] = useState<string[]>([])
  const [deletedIndex, setDeletedIndex] = useState<any>(null)
  const undoDuration = 3000

  useEffect(() => {
    // Sort myModifiers based on the position from modifierList
    if (records?.length && sortedModifierOptions?.length) {
      const sortedModifiersData = records.map((modifierGroup: any) => {
        let sortedModifiers: any[] = []
        if (modifierGroup.modifiers?.length) {
          const sortedModifier = modifierGroup.modifiers.sort(
            (a: any, b: any) => {
              const aPosition =
                (sortedModifierOptions &&
                  sortedModifierOptions?.find((mod: any) => mod.name === a.name)
                    ?.position) ||
                0
              const bPosition =
                (sortedModifierOptions &&
                  sortedModifierOptions?.find((mod: any) => mod.name === b.name)
                    ?.position) ||
                0
              return aPosition - bPosition
            }
          )
          const modifiersData = sortedModifier.map((modifier: any) => {
            const modifierData = sortedModifierOptions?.find(
              (option: any) => option.id === modifier
            )
            return modifierData
          })
          sortedModifiers.push(...modifiersData)
        }
        const sortedModifiersData = sortedModifiers.sort(
          (a: any, b: any) => a.position - b.position
        )
        return {
          ...modifierGroup,
          modifiers: sortedModifiersData,
        }
      })
      const modifierGroupsSorted = sortedModifiersData.sort(
        (a: any, b: any) => a.position - b.position
      )
      setModifierGroupState(modifierGroupsSorted)
    } else {
      records?.length ? setModifierGroupState(records) : null
    }
  }, [records?.length, sortedModifierOptions?.length, modifierGroups?.data])

  // const handleDeleteConfirmed = async () => {
  //   modifierGroupDelete.mutate(selectedModifierGroup.id, {
  //     onSuccess: () => {
  //       setShowConfirmationDialog(false)
  //     },
  //   })
  // }
  const handleDeleteConfirmed = async () => {
    setShowConfirmationDialog(false)
    setShowUndoSnackbar(true)
    setDeletedList([...deletedList, selectedModifierGroup.id])

    const updatedMenu = modifierGroupState?.filter(
      (modifierGroup) =>
        ![...deletedList, selectedModifierGroup.id].includes(modifierGroup.id)
    )
    const deletedItem = records?.find(
      (modifierGroup) => modifierGroup.id === selectedModifierGroup.id
    )

    const deletedItemIndex = modifierGroupState?.findIndex(
      (modifierGroup) => modifierGroup.id === deletedItem?.id
    )

    setDeletedIndex(deletedItemIndex)

    setDeletedItem(deletedItem)

    setModifierGroupState(updatedMenu as any)
  }

  const handleUndo = () => {
    setShowUndoSnackbar(false)
    setHighlightedRow(true)
    setDeletedList([...deletedList.slice(0, -1)])
    if (deletedItem) {
      // Create a copy of the menuItems array
      const updatedMenu = modifierGroupState as any

      if (deletedIndex !== -1) {
        const sortedModifiers = []
        if (deletedItem.modifiers?.length) {
          const sortedModifier = deletedItem.modifiers.sort(
            (a: any, b: any) => {
              const aPosition =
                (sortedModifierOptions &&
                  sortedModifierOptions?.find((mod: any) => mod.name === a.name)
                    ?.position) ||
                0
              const bPosition =
                (sortedModifierOptions &&
                  sortedModifierOptions?.find((mod: any) => mod.name === b.name)
                    ?.position) ||
                0
              return aPosition - bPosition
            }
          )
          const modifiersData = sortedModifier.map((modifier: any) => {
            const modifierData = sortedModifierOptions?.find(
              (option: any) => option.id === modifier
            )
            return modifierData
          })
          sortedModifiers.push(...modifiersData)
        }
        const sortedModifiersData = sortedModifiers.sort(
          (a: any, b: any) => a.position - b.position
        )
        let data = { ...deletedItem, modifiers: sortedModifiersData }
        updatedMenu.splice(deletedIndex, 0, data)

        const uniqueMenu = updatedMenu.filter((item: any, index: number) => {
          return (
            updatedMenu.findIndex((obj: any) => obj.id === item.id) === index
          )
        })
        setModifierGroupState(uniqueMenu)
      }
    }
  }

  const afterSnackBarClosed = () => {
    setShowUndoSnackbar(false)
    //actuall deletion
    modifierGroupDelete.mutate(selectedModifierGroup.id, {
      onSuccess: () => {
        // setShowConfirmationDialog(false)
      },
    })
  }

  const action = (
    <Fragment>
      <Button
        variant="text"
        color="info"
        size="medium"
        onClick={handleUndo}
        style={{ textTransform: 'none' }}
      >
        Undo
      </Button>
    </Fragment>
  )
  useEffect(() => {
    if (highlightedRow !== false) {
      const timeoutId = setTimeout(() => {
        setHighlightedRow(false)
      }, 1500)
      return () => clearTimeout(timeoutId)
    }
  }, [highlightedRow])

  useEffect(() => {
    let timeoutId: any
    if (showUndoSnackbar) {
      timeoutId = setTimeout(() => {
        setShowUndoSnackbar(false)
        afterSnackBarClosed()
      }, undoDuration)
    }
    return () => clearTimeout(timeoutId)
  }, [showUndoSnackbar, setShowUndoSnackbar, afterSnackBarClosed])

  const handleClick = (category?: any) => {
    const itemNames = category.modifiers.map((item: any) => {
      return item.name
    })
    setModifiersData(itemNames)
    setModifierGroupName(category.name)
    setPopOpen(true)
  }
  if (modifierGroups.isLoading || modifiers.isLoading) {
    return <AppLoader />
  }

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Typography variant="body2">
          {isFetching
            ? `${intl.formatMessage({ id: 'label_fetching_data' })}`
            : ''}
        </Typography>
        <CreateButtonFlexEnd
          handleClick={() => setOpen(true)}
          buttonType="modifier_group"
        />
      </Box>
      {modifierGroups.isSuccess && !records?.length ? (
        <NoRecordFound title="ModifierGroup" />
      ) : (
        <>
          <TableContainer component={Paper}>
            <Table stickyHeader aria-label="sticky table" size="small">
              <TableHead>
                <TableRow>
                  <TableCell>
                    {intl.formatMessage({ id: 'label_name' })}
                  </TableCell>
                  <TableCell>
                    {intl.formatMessage({ id: 'label_modifiers' })}
                  </TableCell>
                  <TableCell>
                    {intl.formatMessage({ id: 'label_last_modified_at' })}
                  </TableCell>
                  <TableCell>
                    {intl.formatMessage({ id: 'label_actions' })}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {modifierGroupState?.map((modifierGroup: any, index: any) => {
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={index}
                      sx={{
                        cursor: 'default !important',
                        backgroundColor:
                          highlightedRow &&
                          deletedItem?.name === modifierGroup.name &&
                          index == deletedIndex
                            ? '#fff1d5'
                            : 'inherit',
                        transition: 'background-color 0.5s linear',
                      }}
                      onClick={() =>
                        navigate(
                          `/app/locations/${locationId}/menu/${menuId}/modifierGroup/${modifierGroup.id}`
                        )
                      }
                    >
                      <TableCell>
                        {shortenText(modifierGroup.name, 25)}
                      </TableCell>
                      <TableCell>
                        {modifierGroup.modifiers
                          .slice(0, 2)
                          .map((modifier: any, index: number) => {
                            const dataName = modifier?.name
                            const maxLength = 40
                            const truncatedDataName = dataName
                              ? shortenText(dataName, maxLength)
                              : dataName
                            return (
                              <Fragment key={index}>
                                {dataName && dataName?.length > maxLength ? (
                                  <Tooltip title={dataName}>
                                    <Chip
                                      label={truncatedDataName}
                                      sx={{ margin: 0.3 }}
                                    />
                                  </Tooltip>
                                ) : (
                                  <Chip
                                    label={truncatedDataName}
                                    sx={{ margin: 0.3 }}
                                  />
                                )}
                                {index === 1 &&
                                  modifierGroup?.modifiers.length > 2 && (
                                    <>
                                      <Chip
                                        key={dataName}
                                        label={`+${
                                          modifierGroup?.modifiers.length - 2
                                        }`}
                                        sx={{ margin: 0.3 }}
                                        onClick={() =>
                                          handleClick(modifierGroup)
                                        }
                                      />
                                    </>
                                  )}
                              </Fragment>
                            )
                          })}
                      </TableCell>
                      <TableCell>
                        {formatUtcDate(modifierGroup.createdAt)}
                      </TableCell>
                      <TableCell onClick={(e) => e.stopPropagation()}>
                        <Box sx={{ display: 'flex' }}>
                          <ClickableIcon
                            handleNavigate={() =>
                              navigate(
                                `/app/locations/${locationId}/menu/${menuId}/modifierGroup/${modifierGroup.id}`
                              )
                            }
                            disabled={isFetching}
                            children={<EditOutlined />}
                          />
                          <ClickableIcon
                            handleNavigate={() => [
                              setShowConfirmationDialog(true),
                              setSelectedModifierGroup(modifierGroup),
                              showUndoSnackbar && afterSnackBarClosed(),
                            ]}
                            disabled={isFetching}
                            children={<DeleteOutline />}
                          />
                        </Box>
                      </TableCell>
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
          </TableContainer>
          {modifierGroups.hasNextPage && (
            <StyledButtonContainerForTable
              display="flex"
              justifyContent="center"
              py={2}
            >
              <Button
                onClick={() => modifierGroups.fetchNextPage()}
                disabled={modifierGroups.isFetching}
              >
                {modifierGroups.isFetching
                  ? `${intl.formatMessage({ id: 'status_loading' })}`
                  : `${intl.formatMessage({ id: 'action_load_more' })}`}
              </Button>
            </StyledButtonContainerForTable>
          )}
        </>
      )}
      <Dialog
        open={open}
        fullWidth
        maxWidth={'lg'}
        onClose={() => setOpen(false)}
        aria-labelledby="responsive-dialog-title"
      >
        <ModifierGroupListTable
          handleClose={() => setOpen(false)}
          locationId={locationId!}
        />
      </Dialog>
      <AppWarningDialog
        name={selectedModifierGroup.name}
        source="modifierGroups"
        open={showConfirmationDialog}
        onConfirm={handleDeleteConfirmed}
        onCancel={() => setShowConfirmationDialog(false)}
      />
      <ItemsListPop
        items={modifiersData}
        categoryName={modifierName}
        open={popOpen}
        onClose={() => {
          setPopOpen(false)
        }}
      />
      <Snackbar
        open={showUndoSnackbar}
        autoHideDuration={undoDuration}
        onClose={(event, reason) => {
          if (reason === 'clickaway') {
            return
          }
          afterSnackBarClosed()
        }}
        message={
          <Typography variant="body2" sx={{ color: 'black' }}>
            Deleted{' '}
            <span style={{ fontWeight: 'bold' }}>{deletedItem?.name}</span>
          </Typography>
        }
        action={action}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        ContentProps={{
          sx: {
            backgroundColor: 'white',
          } as SnackbarContentProps['sx'],
        }}
      />
    </>
  )
}
