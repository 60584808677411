import { Route, Routes as DomRoutes } from 'react-router-dom'
import { Locations } from './Locations'
import { Orders } from './Orders'
import { ApplicationRoute } from './ApplicationRoute'
import { useAuthStore } from '../hooks'
import { AppLoader } from '../components/AppLoader'
import { Authorize } from './Authorize'
import { SignIn } from './SignIn'
import { SignUp } from './SignUp'
import { ResetPassword } from './ResetPassword'
import { Profile } from './Profile'
import { DeveloperApplications } from './Developer'
import { ProfileEdit } from './Profile/edit'
import { Account } from './Account'
import { AccountUpsert } from './Account/upsert'
import { Dashboard } from './Dashboard'
import { PageNotFound } from '../components/PageNotFound'
import { LocationUpsert } from './Locations/upsert'
import { MenuUpsert } from './Locations/CardSelect/Menu/upsert'
import { MenuCategoryUpsert } from './Locations/CardSelect/Menu/Mapping/Category/upsert'
import { MenuItemUpsert } from './Locations/CardSelect/Menu/Mapping/Items/upsert'
import { MappingCatalogLocation } from './Locations/CardSelect/CatalogMapping'
import { CatalogCategoryUpsert } from './Locations/CardSelect/CatalogMapping/Category/upsert'
import { ModifierUpsert } from './Locations/CardSelect/CatalogMapping/Modifiers/upsert'
import { ModifierGroupUpsert } from './Locations/CardSelect/CatalogMapping/ModifierGroups/upsert'
import { MappingMenu } from './Locations/CardSelect/Menu/Mapping'
import { MappingLocation } from './Locations/LocationView'
import { OrderDetails } from './Orders/OrderDetails'
import { MenuV2 } from './MenuV2'
import { ProductView } from './Locations/CardSelect/CatalogMapping/Products/upsert/ItemView'
import { ModifierGroupsView } from './Locations/CardSelect/CatalogMapping/ModifierGroups/upsert/modifierGroupsView'
import { DeveloperAppAudits } from './Developer/audit/index'
import { MenuModifierGroupUpsert } from './Locations/CardSelect/Menu/Mapping/ModifierGroups/upsert'
import { MenuModifierUpsert } from './Locations/CardSelect/Menu/Mapping/Modifiers/upsert'
import { Reseller } from './Reseller'
import { ResellerAccounts } from './Reseller/Accounts'
import { ResellerUsers } from './Reseller/Users'
import { ResellerDashboard } from './Reseller/Dashboard'
import { ResellerOrders } from './Reseller/Orders'
import { ResellerDeliveries, ResellerDeliveryById } from './Reseller/Deliveries'
import { ResellerAccountView } from './Reseller/Accounts/AccountView'
import { ResellerUserView } from './Reseller/Users/UserView'
import { ResellerLocations } from './Reseller/Locations'
import { ResellerLocationView } from './Reseller/Locations/LocationView'
import { ResellerAccountEdit } from './Reseller/Accounts/AccountEdit'
import { ResellerAccountLocationEdit } from './Reseller/Accounts/AccountLocationEdit'
import { ModifierView } from './Locations/CardSelect/CatalogMapping/Modifiers/upsert/ModifierView'
import { EditItemMenuV2 } from './MenuV2/dialogs/EditItemMenuV2'
import { ViewOrder } from './Reseller/Orders/ViewOrder'
import { ResellerAudits } from './Reseller/Audits'
import { MenuItemModifierGroupEdit } from './MenuV2/MenuItemModifierGroupEdit/Index'
import { MenuItemModifierGroupModifierEdit } from './MenuV2/MenuItemModifierGroupModifierEdit/Index'
import { AccountDeliveries, AccountDeliveryById } from './Deliveries'
import { DeveloperApplicationById } from './Developer/DeveloperApplicationById'
import { WebsiteHome } from './Website'

export const Routes = () => {
  const isHydrated = useAuthStore((store) => store.isHydrated)

  /**
   * Ensure that our underlying datastore has been hydrated before we load up the actual application routes
   * This will ensure that persisted state is available immediatluy to the routes, ensuring readyness
   */
  if (!isHydrated) {
    return <AppLoader />
  }

  /**
   * Return all the routes
   */
  return (
    <DomRoutes>
      {/* Public Routes */}
      <Route path="*" element={<PageNotFound />} />
      {/* Website */}
      <Route path="/">
        <Route index element={<WebsiteHome />} />
      </Route>

      <Route index path="/signin" element={<SignIn />} />
      <Route path="register" element={<SignUp />} />
      <Route path="reset-password" element={<ResetPassword />} />
      <Route path="/authorize" element={<Authorize />} />
      {/* Application Routes */}
      <Route path="/app" element={<ApplicationRoute />}>
        <Route path="dashboard">
          <Route index element={<Dashboard />} />
        </Route>
        <Route path="locations">
          <Route index element={<Locations />} />
          <Route path="new" element={<LocationUpsert />} />
          <Route path=":locationId/" element={<LocationUpsert />} />
          <Route path=":locationId/:data" element={<MappingLocation />} />
          <Route
            path=":locationId/catalog/:data"
            element={<MappingCatalogLocation />}
          />
          <Route
            path=":locationId/catalog/category/new"
            element={<CatalogCategoryUpsert />}
          />
          <Route
            path=":locationId/catalog/category/:categoryId"
            element={<CatalogCategoryUpsert />}
          />
          {/* <Route
            path=":locationId/catalog/product/new"
            element={<ProductUpsert />}
          /> */}
          {/* <Route
            path=":locationId/catalog/product/:productId"
            element={<ProductUpsert />}
          /> */}
          <Route
            path=":locationId/catalog/product/:productId/view"
            element={<ProductView />}
          />
          <Route
            path=":locationId/catalog/modifier-group/:modifierGroupId/view"
            element={<ModifierGroupsView />}
          />
          <Route
            path=":locationId/catalog/modifier/new"
            element={<ModifierUpsert />}
          />
          <Route
            path=":locationId/catalog/modifier/:modifierId"
            element={<ModifierUpsert />}
          />
          <Route
            path=":locationId/catalog/modifier/:modifierId/view"
            element={<ModifierView />}
          />
          <Route
            path=":locationId/catalog/modifier-group/new"
            element={<ModifierGroupUpsert />}
          />
          <Route
            path=":locationId/catalog/modifier-group/:modifierGroupId"
            element={<ModifierGroupUpsert />}
          />
          <Route path=":locationId/menu/new" element={<MenuUpsert />} />
          <Route path=":locationId/menu/:menuId" element={<MenuUpsert />} />
          <Route path=":locationId/menus/:menuId">
            <Route index element={<MenuV2 />} />
            <Route path="items/:itemId/edit" element={<EditItemMenuV2 />} />
            <Route
              path="items/:itemId/modifier-groups/:modifierGroupId"
              element={<MenuItemModifierGroupEdit />}
            />
            <Route
              path="items/:itemId/modifier-groups/:modifierGroupId/modifier/:modifierId"
              element={<MenuItemModifierGroupModifierEdit />}
            />
          </Route>
          <Route
            path=":locationId/menu/:menuId/:data"
            element={<MappingMenu />}
          />
          <Route
            path=":locationId/menu/:menuId/item/:itemId/modifier-groups/:modifierGroupId"
            element={<MenuItemModifierGroupEdit />}
          />
          <Route
            path=":locationId/menu/:menuId/item/:itemId/modifier-groups/:modifierGroupId/modifier/:modifierId"
            element={<MenuItemModifierGroupModifierEdit />}
          />
          <Route
            path=":locationId/menu/:menuId/category/new"
            element={<MenuCategoryUpsert />}
          />
          <Route
            path=":locationId/menu/:menuId/category/:categoryId"
            element={<MenuCategoryUpsert />}
          />
          <Route
            path=":locationId/menu/:menuId/:menuId/item/new"
            element={<MenuItemUpsert />}
          />
          <Route
            path=":locationId/menu/:menuId/item/:itemId"
            element={<MenuItemUpsert />}
          />
          <Route
            path=":locationId/menu/:menuId/:menuId/modifier/new"
            element={<MenuModifierUpsert />}
          />
          <Route
            path=":locationId/menu/:menuId/modifier/:modifierId"
            element={<MenuModifierUpsert />}
          />
          <Route
            path=":locationId/menu/:menuId/:menuId/modifierGroup/new"
            element={<MenuModifierGroupUpsert />}
          />
          <Route
            path=":locationId/menu/:menuId/modifierGroup/:modifierGroupId"
            element={<MenuModifierGroupUpsert />}
          />
        </Route>
        <Route path="deliveries">
          <Route index element={<AccountDeliveries />} />
          <Route index path=":deliveryId" element={<AccountDeliveryById />} />
        </Route>
        <Route path="resellers">
          <Route index element={<Reseller />} />
          <Route path=":resellerId">
            <Route index path="dashboard" element={<ResellerDashboard />} />
            <Route path="orders">
              <Route index element={<ResellerOrders />} />
              <Route path=":orderId" element={<ViewOrder />} />
            </Route>
            <Route path="deliveries">
              <Route index element={<ResellerDeliveries />} />
              <Route path=":deliveryId" element={<ResellerDeliveryById />} />
            </Route>
            <Route path="audits">
              <Route index element={<ResellerAudits />} />
              {/* <Route path=":auditId" element={<ResellerAuditView />} /> */}
            </Route>
            <Route path="users">
              <Route index element={<ResellerUsers />} />
              <Route path="new" element={<ResellerUserView />} />
              <Route path=":userId/edit">
                <Route index element={<ResellerUserView />} />
              </Route>
            </Route>
            <Route path="accounts">
              <Route index element={<ResellerAccounts />} />
              <Route path="new" element={<ResellerAccountEdit />} />
              <Route path=":accountId">
                <Route index element={<ResellerAccountView />} />
                <Route path="edit" element={<ResellerAccountEdit />} />
                <Route
                  path="locations/:locationId/edit"
                  element={<ResellerAccountLocationEdit />}
                />
                <Route
                  path="locations/new"
                  element={<ResellerAccountLocationEdit />}
                />
                <Route
                  path="locations/:locationId/:data"
                  element={<MappingLocation />}
                />
                <Route path=":data" element={<ResellerAccountView />} />
              </Route>
            </Route>
            <Route path="locations">
              <Route index element={<ResellerLocations />} />
              <Route path=":locationId/:data" element={<MappingLocation />} />
              <Route path="new" element={<ResellerLocationView />} />
              <Route path=":locationId/edit">
                <Route index element={<ResellerLocationView />} />
              </Route>
            </Route>
          </Route>
        </Route>
        <Route path="orders">
          <Route index element={<Orders />} />
          <Route index path=":orderId" element={<OrderDetails />} />
        </Route>
        <Route path="profile/me">
          <Route index element={<Profile />} />
          <Route path="edit" element={<ProfileEdit />} />
        </Route>
        <Route path="developer">
          {/* <Route path="new" element={<DeveloperUpsert />} /> */}
          <Route index element={<DeveloperApplications />} />
          <Route path=":applicationId">
            <Route index element={<DeveloperApplicationById />} />
            <Route path="audits" element={<DeveloperAppAudits />} />
          </Route>
        </Route>
        <Route path="accounts">
          <Route index element={<Account />} />
          <Route path="new" element={<AccountUpsert />} />
          <Route path="edit/:accountId" element={<AccountUpsert />} />
        </Route>
      </Route>
    </DomRoutes>
  )
}
