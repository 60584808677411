import { FC } from 'react'
import {
  Avatar,
  Box,
  Card,
  CardContent,
  CardMedia,
  Grid,
  Typography,
} from '@mui/material'
/**
 * @fileoverview This component is responsible for rendering the features section of the website.
 */

/**
 * FeaturesSection component
 */
export const ScrollingApplicationsSection: FC<{ applications: any[] }> = ({
  applications,
}) => {
  return (
    <Grid container padding={4}>
      <Grid item xs={12}>
        <Box
          sx={{
            overflow: 'hidden',
            position: 'relative',
            width: '100%',
            padding: 2,
          }}
        >
          <Box
            sx={{
              display: 'flex',
              animation: 'scroll 40s linear infinite',
              '&:hover': { animationPlayState: 'paused' },
              '@keyframes scroll': {
                '0%': { transform: 'translateX(0)' },
                '50%': { transform: 'translateX(-100%)' },
                '100%': { transform: 'translateX(0)' },
              },
            }}
          >
            {applications.map((app) => (
              <Grid
                key={app.name}
                item
                xl={2}
                lg={2}
                md={3}
                xs={6}
                sx={{
                  flex: '0 0 auto',
                  marginRight: 2,
                }}
              >
                <IntegratonBox name={app.name} src={app.imageUrl} />
              </Grid>
            ))}
          </Box>
        </Box>
      </Grid>
    </Grid>
  )
}

export const IntegratonBox: FC<{ name: string; src: string }> = ({
  name,
  src,
}) => {
  return (
    <Card
      variant="outlined"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center',
        padding: 0,
      }}
    >
      <CardMedia>
        <Avatar src={src} sx={{ width: 100, height: 100, m: 2 }} />
      </CardMedia>
      <CardContent>
        <Typography variant="caption">{name}</Typography>
      </CardContent>
    </Card>
  )
}
