import { FC, useState, useEffect, useCallback } from 'react'
import {
  Box,
  FormControlLabel,
  Switch,
  Grid,
  TextField,
  Typography,
} from '@mui/material'
import { TimePicker } from '@mui/x-date-pickers/TimePicker'
import { useIntl } from 'react-intl'

export interface TimePeriod {
  startTime?: string
  endTime?: string
}

export interface Availability {
  weekday:
    | 'MONDAY'
    | 'TUESDAY'
    | 'WEDNESDAY'
    | 'THURSDAY'
    | 'FRIDAY'
    | 'SATURDAY'
    | 'SUNDAY'
  timePeriods: TimePeriod[]
}

export interface ServiceAvailabilityV2Props {
  value?: Availability[]
  defaultValue?: Availability[]
  onChange?: (newAvailability: Availability[]) => void
  label?: string
}

// Days in display order:
const ORDERED_DAYS: Availability['weekday'][] = [
  'MONDAY',
  'TUESDAY',
  'WEDNESDAY',
  'THURSDAY',
  'FRIDAY',
  'SATURDAY',
  'SUNDAY',
]

function parseTimeString(timeString?: string): Date | null {
  if (!timeString) return null
  const [hourStr, minuteStr] = timeString.split(':')
  const hour = parseInt(hourStr, 10)
  const minute = parseInt(minuteStr, 10)
  if (
    Number.isNaN(hour) ||
    Number.isNaN(minute) ||
    hour < 0 ||
    hour > 23 ||
    minute < 0 ||
    minute > 59
  ) {
    return null
  }
  const d = new Date()
  d.setHours(hour, minute, 0, 0)
  return d
}

function formatToTimeString(date: Date | null | undefined) {
  if (!date) return ''
  const hours = date.getHours().toString().padStart(2, '0')
  const minutes = date.getMinutes().toString().padStart(2, '0')
  return `${hours}:${minutes}`
}

export const ServiceAvailabilityV2: FC<ServiceAvailabilityV2Props> = ({
  value,
  defaultValue,
  onChange,
  label = 'Business Hours',
}) => {
  const intl = useIntl()

  // Local (uncontrolled) state if `value` not provided:
  const [internalAvailability, setInternalAvailability] = useState<
    Availability[]
  >(defaultValue || [])

  // Keep internal state in sync if we're controlled:
  useEffect(() => {
    if (value) {
      setInternalAvailability(value)
    }
  }, [value])

  // Use parent's value if provided, otherwise use our internal state
  const availability = value ?? internalAvailability

  // Unified update helper:
  const handleChange = useCallback(
    (newAvail: Availability[]) => {
      // Filter to only include days that are "enabled" (i.e. have timePeriods)
      const enabledAvail = newAvail.filter((a) => a.timePeriods.length > 0)
      if (value === undefined) {
        setInternalAvailability(enabledAvail)
      }
      onChange?.(enabledAvail)
    },
    [value, onChange]
  )

  // Get or create an Availability entry for a specific day
  const getDayData = (day: Availability['weekday']) => {
    const existing = availability.find((a) => a.weekday === day)
    return existing || { weekday: day, timePeriods: [] }
  }

  // Enable/disable a day. When enabling, copy from the nearest previous enabled day if available.
  const toggleDay = (day: Availability['weekday'], enabled: boolean) => {
    const newAvail = [...availability]
    const dayIndex = newAvail.findIndex((a) => a.weekday === day)

    // Helper to create or update a day entry
    const setDayTimePeriods = (periods: TimePeriod[]) => {
      if (dayIndex === -1) {
        newAvail.push({ weekday: day, timePeriods: periods })
      } else {
        newAvail[dayIndex].timePeriods = periods
      }
    }

    if (enabled) {
      // If day is not in the array or is disabled, (re)enable it:
      if (dayIndex === -1 || newAvail[dayIndex].timePeriods.length === 0) {
        // Find the index of the current day in ORDERED_DAYS
        const orderedIndex = ORDERED_DAYS.indexOf(day)
        // Look backwards to find the nearest enabled day
        let copiedPeriods: TimePeriod[] | null = null
        for (let i = orderedIndex - 1; i >= 0; i--) {
          const prevDay = ORDERED_DAYS[i]
          const prevAvail = newAvail.find((a) => a.weekday === prevDay)
          if (prevAvail && prevAvail.timePeriods.length > 0) {
            // Shallow copy to avoid mutation
            copiedPeriods = prevAvail.timePeriods.map((tp) => ({ ...tp }))
            break
          }
        }
        if (copiedPeriods) {
          setDayTimePeriods(copiedPeriods)
        } else {
          // If no previous enabled day exists, default to a standard time period
          setDayTimePeriods([{ startTime: '10:00', endTime: '22:00' }])
        }
      }
    } else {
      // If disabling, clear out the timePeriods
      if (dayIndex !== -1) {
        newAvail[dayIndex].timePeriods = []
      }
    }

    handleChange(newAvail)
  }

  // Update a single time range for a given day.
  // If both start and end are empty strings, clear the timePeriods array.
  const setDayTimeRange = (
    day: Availability['weekday'],
    start?: string,
    end?: string
  ) => {
    const newAvail = [...availability]
    let entry = newAvail.find((a) => a.weekday === day)
    if (!entry) {
      entry = { weekday: day, timePeriods: [] }
      newAvail.push(entry)
    }

    // If both start and end times are empty, remove the time period.
    if (!start && !end) {
      entry.timePeriods = []
    } else {
      if (entry.timePeriods.length === 0) {
        entry.timePeriods.push({ startTime: '10:00', endTime: '22:00' })
      }
      entry.timePeriods[0] = { startTime: start, endTime: end }
    }
    handleChange(newAvail)
  }

  return (
    <>
      {/* Rows for each day */}
      <Grid container>
        {ORDERED_DAYS.map((day) => {
          const dayData = getDayData(day)
          const isEnabled = dayData.timePeriods.length > 0
          const timePeriod = dayData.timePeriods[0] || {}
          const startVal = parseTimeString(timePeriod.startTime)
          const endVal = parseTimeString(timePeriod.endTime)

          return (
            <Grid container key={day} gap={1} pb={2}>
              {/* Day name */}
              <Grid item xs={3}>
                {day.charAt(0) + day.slice(1).toLowerCase()}
              </Grid>

              {/* Status Switch */}
              <Grid item xs={2}>
                <FormControlLabel
                  control={
                    <Switch
                      color="success"
                      checked={isEnabled}
                      onChange={(e) => toggleDay(day, e.target.checked)}
                    />
                  }
                  label=""
                  style={{ marginLeft: 0 }}
                />
              </Grid>

              {/* Start (From) TimePicker */}
              <Grid item xs={3}>
                {isEnabled ? (
                  <TimePicker
                    label="From"
                    value={startVal}
                    ampm={false}
                    onChange={(newDate) => {
                      const newStart = formatToTimeString(newDate)
                      setDayTimeRange(day, newStart, timePeriod.endTime)
                    }}
                    renderInput={(params: any) => <TextField {...params} />}
                  />
                ) : (
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    sx={{ fontStyle: 'italic', textAlign: 'center' }}
                  >
                    Closed
                  </Typography>
                )}
              </Grid>

              {/* End (To) TimePicker */}
              <Grid item xs={3}>
                {isEnabled ? (
                  <TimePicker
                    label="To"
                    value={endVal}
                    ampm={false}
                    onChange={(newDate) => {
                      const newEnd = formatToTimeString(newDate)
                      setDayTimeRange(day, timePeriod.startTime, newEnd)
                    }}
                    renderInput={(params) => <TextField {...params} />}
                  />
                ) : null}
              </Grid>
            </Grid>
          )
        })}
      </Grid>
    </>
  )
}
