import { Card, Grid, TextField, styled } from '@mui/material'

/**
 * Basic Card
 */
export const ItemCard = styled(Card)(({ theme }) => ({
  display: 'flex',
  cursor: 'pointer',
  minHeight: '50px',
  border: 'none',
  marginBottom: '8px',
  '& .MuiCardContent-root': {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: 0,
    paddingLeft: 8,
    paddingRight: 8,
    width: '100%',
  },
  '& .MuiCardContent-root:last-child': {
    paddingBottom: 0,
  },
}))

export const PriceInput = styled(TextField)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    padding: 0,
    marginRight: 2,
  },
  // Custom styles to remove up/down arrows
  '& input[type=number]': {
    MozAppearance: 'textfield', // Updated to camelCase
  },
  '& input[type=number]::-webkit-outer-spin-button, & input[type=number]::-webkit-inner-spin-button':
    {
      WebkitAppearance: 'none', // Updated to camelCase
      margin: 0,
    },
}))
export const StyledGridContainer = styled(Grid)(({ theme }) => ({
  borderBottom: `1px solid ${theme.palette.secondary.disabled}`,
  padding: 8,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: '8px',
  marginBottom: 8,
  '& .MuiGrid-item': {
    padding: 0,
  },
}))
