import { FC } from 'react'
import {
  Node,
  Edge,
  ReactFlow,
  ReactFlowProvider,
  useNodesState,
  useEdgesState,
  NodeMouseHandler,
} from '@xyflow/react'
import '@xyflow/react/dist/style.css'
import { HubNode } from './HubNode'
import { Box, Grid, Typography } from '@mui/material'
import { ApplicationNode } from './ApplicationNode'
import { ApplicationEdge } from './ApplicationEdge'

// /**
//  * Nodes for the hero section
//  */
// const initialNodes: Node[] = [
//   {
//     id: 'poshub',
//     type: 'HUB',
//     data: { label: 'POS Hub' },
//     position: { x: 0, y: 0 },
//     measured: { width: 80, height: 30 },
//   },
//   {
//     id: 'clover',
//     type: 'application',
//     data: {
//       label: 'Clover',
//       src: 'https://t2s-android.s3.amazonaws.com/ThirdPartyAssets/clover.png',
//     },
//     position: { x: 236, y: -88 },
//   },
//   {
//     id: 'lightspped-o',
//     type: 'application',
//     data: {
//       label: 'Lightspeed',
//       src: 'https://t2s-android.s3.amazonaws.com/ThirdPartyAssets/lightspeed.png',
//     },
//     position: { x: 218, y: -42 },
//   },
//   {
//     id: 'lightspeed-k',
//     type: 'application',
//     data: {
//       label: 'Lightspeed',
//       src: 'https://t2s-android.s3.amazonaws.com/ThirdPartyAssets/lightspeed.png',
//     },
//     position: { x: 212, y: -5 },
//   },
//   {
//     id: 'micros3700',
//     type: 'application',
//     data: {
//       label: 'Micros 3700',
//       src: 'https://t2s-android.s3.amazonaws.com/ThirdPartyAssets/micros3700.png',
//     },
//     position: { x: 222, y: 110 },
//   },
//   {
//     id: 'toast',
//     type: 'application',
//     data: {
//       label: 'Toast',
//       src: 'https://t2s-android.s3.amazonaws.com/ThirdPartyAssets/toast.png',
//     },
//     position: { x: 247, y: 74 },
//   },
//   {
//     id: 'square',
//     type: 'application',
//     data: {
//       label: 'Square',
//       src: 'https://t2s-android.s3.amazonaws.com/ThirdPartyAssets/square.png',
//     },
//     position: { x: 234, y: 29 },
//   },
//   {
//     id: 'uber-eats',
//     type: 'application',
//     data: {
//       label: 'Uber Eats',
//       src: 'https://t2s-android.s3.amazonaws.com/ThirdPartyAssets/ubereats.png',
//     },
//     position: { x: -178, y: -80 },
//   },
//   {
//     id: 'doordash',
//     type: 'application',
//     data: {
//       label: 'DoorDash',
//       src: 'https://t2s-android.s3.amazonaws.com/ThirdPartyAssets/doordash.png',
//     },
//     position: { x: -150, y: -30 },
//   },
//   {
//     id: 'grubhub',
//     type: 'application',
//     data: {
//       label: 'Grubhub',
//       src: 'https://t2s-android.s3.amazonaws.com/ThirdPartyAssets/grubhub.png',
//     },
//     position: { x: -171, y: 6 },
//   },
//   {
//     id: 'gloriafood',
//     type: 'application',
//     data: {
//       label: 'GloriaFood',
//       src: 'https://t2s-android.s3.amazonaws.com/ThirdPartyAssets/gloriafood.png',
//     },
//     position: { x: -215, y: 43 },
//   },
//   {
//     id: 'just-eat',
//     type: 'application',
//     data: {
//       label: 'Just Eat',
//       src: 'https://t2s-android.s3.amazonaws.com/ThirdPartyAssets/justeat.png',
//     },
//     position: { x: -163, y: 60 },
//   },
//   {
//     id: 'foodhub',
//     type: 'application',
//     data: {
//       label: 'Foodhub',
//       src: 'https://t2s-android.s3.amazonaws.com/ThirdPartyAssets/foodhub.png',
//     },
//     position: { x: -176, y: 106 },
//   },
//   {
//     id: 'uber-direct',
//     type: 'application',
//     data: {
//       label: 'Uber Direct',
//       src: 'https://t2s-android.s3.amazonaws.com/ThirdPartyAssets/ubereats.png',
//     },
//     position: { x: -46, y: 93 },
//   },
//   {
//     id: 'nash',
//     type: 'application',
//     data: {
//       label: 'Uber Direct',
//       src: 'https://cdn.prod.website-files.com/66e4894b3aec0251ae4a457b/67098827d4101b29c122df6f_6480da10af6e30ac6e2d9d24_Nash%2520logo.png',
//     },
//     position: { x: 11, y: 112 },
//   },
//   {
//     id: 'doordash-drive',
//     type: 'application',
//     data: {
//       label: 'Doordash Drive',
//       src: 'https://t2s-android.s3.amazonaws.com/ThirdPartyAssets/doordash.png',
//     },
//     position: { x: 56, y: 86 },
//   },
//   {
//     id: 'relay',
//     type: 'application',
//     data: {
//       label: 'Relay Drive',
//       src: 'https://images.squarespace-cdn.com/content/601ebc23252f67260bd1986f/1613672825682-C8ERY29GV7HDR8LTAFY7/favicon.png',
//     },
//     position: { x: 102, y: 105 },
//   },
//   {
//     id: 'whatsapp',
//     type: 'application',
//     data: {
//       label: 'WhatsApp',
//       src: 'https://t2s-android.s3.amazonaws.com/ThirdPartyAssets/whatsapp.png',
//     },
//     position: { x: 29, y: -90 },
//     selected: true,
//     dragging: false,
//   },
//   {
//     id: 'sms',
//     type: 'application',
//     data: {
//       label: 'SMS',
//       src: 'https://t2s-android.s3.amazonaws.com/ThirdPartyAssets/sms.png',
//     },
//     position: { x: -10, y: -74 },
//   },
//   {
//     id: 'fax-plus',
//     type: 'application',
//     data: {
//       label: 'Fax Plus',
//       src: 'https://media.tryposhub.com/thumbs/media/application/93e2c4f9-8216-4423-b736-adb42c68180a/6b7450b0-1a02-4e1a-ada6-5f8fcc1402a8',
//     },
//     position: { x: 70, y: -73 },
//   },
//   {
//     id: 'email',
//     type: 'application',
//     data: {
//       label: 'Email',
//       src: 'https://t2s-android.s3.amazonaws.com/ThirdPartyAssets/email.png',
//     },
//     position: { x: 110, y: -86 },
//   },
//   {
//     id: 'fresh-kds',
//     type: 'application',
//     data: {
//       label: 'Fresh KDS',
//       src: 'https://t2s-android.s3.amazonaws.com/ThirdPartyAssets/kds.png',
//     },
//     position: { x: -58, y: -88 },
//   },
// ]

/**
 * Edges
 */
const initialEdges: Edge[] = [
  {
    id: 'poshub->clover',
    source: 'poshub',
    target: 'clover',
    type: 'application',
    sourceHandle: 'source-right',
    data: {
      animationOffset: '2s',
      animationDuration: '1s',
    },
  },
  {
    id: 'poshub->lightspeed-o',
    source: 'poshub',
    target: 'lightspped-o',
    type: 'application',
    sourceHandle: 'source-right',
  },
  {
    id: 'poshub->lightspeed-k',
    source: 'poshub',
    target: 'lightspeed-k',
    type: 'application',
    sourceHandle: 'source-right',
  },
  {
    id: 'poshub->micros3700',
    source: 'poshub',
    target: 'micros3700',
    type: 'application',
    sourceHandle: 'source-right',
  },
  {
    id: 'poshub->toast',
    source: 'poshub',
    target: 'toast',
    type: 'application',
    sourceHandle: 'source-right',
    animated: true,
  },
  {
    id: 'poshub->square',
    source: 'poshub',
    target: 'square',
    type: 'application',
    sourceHandle: 'source-right',
  },
  {
    id: 'poshub->uber-eats',
    source: 'uber-eats',
    target: 'poshub',
    type: 'application',
    targetHandle: 'target-left',
    animated: true,
  },
  {
    id: 'poshub->doordash',
    source: 'doordash',
    target: 'poshub',
    type: 'application',
    targetHandle: 'target-left',
    animated: true,
  },
  {
    id: 'poshub->grubhub',
    source: 'grubhub',
    target: 'poshub',
    type: 'application',
    targetHandle: 'target-left',
  },
  {
    id: 'poshub->orders2me',
    source: 'orders2me',
    target: 'poshub',
    type: 'application',
    targetHandle: 'target-left',
  },
  {
    id: 'poshub->just-eat',
    source: 'just-eat',
    target: 'poshub',
    type: 'application',
    targetHandle: 'target-left',
  },
  {
    id: 'poshub->foodhub',
    source: 'foodhub',
    target: 'poshub',
    type: 'application',
    targetHandle: 'target-left',
    animated: true,
  },
  // Delivery drive apps (gargetSource = target-bottom)
  {
    id: 'poshub->uber-direct',
    source: 'poshub',
    target: 'uber-direct',
    type: 'application',
    sourceHandle: 'source-bottom',
    targetHandle: 'target-top',
  },
  {
    id: 'poshub->nash',
    source: 'poshub',
    target: 'nash',
    type: 'application',
    sourceHandle: 'source-bottom',
    targetHandle: 'target-top',
  },
  {
    id: 'poshub->doordash-drive',
    source: 'poshub',
    target: 'doordash-drive',
    type: 'application',
    sourceHandle: 'source-bottom',
    targetHandle: 'target-top',
    animated: true,
  },
  {
    id: 'poshub->relay',
    source: 'poshub',
    target: 'relay',
    type: 'application',
    sourceHandle: 'source-bottom',
    targetHandle: 'target-top',
  },

  // Communication Apps
  {
    id: 'poshub->whatsapp',
    source: 'poshub',
    target: 'whatsapp',
    type: 'application',
    sourceHandle: 'source-top',
    targetHandle: 'target-bottom',
  },
  {
    id: 'poshub->sms',
    source: 'poshub',
    target: 'sms',
    type: 'application',
    sourceHandle: 'source-top',
    targetHandle: 'target-bottom',
  },
  {
    id: 'poshub->fax-plus',
    source: 'poshub',
    target: 'fax-plus',
    type: 'application',
    sourceHandle: 'source-top',
    targetHandle: 'target-bottom',
  },
  {
    id: 'poshub->email',
    source: 'poshub',
    target: 'email',
    type: 'application',
    sourceHandle: 'source-top',
    targetHandle: 'target-bottom',
  },
  {
    id: 'poshub->fresh-kds',
    source: 'poshub',
    target: 'fresh-kds',
    type: 'application',
    sourceHandle: 'source-top',
    targetHandle: 'target-bottom',
  },
]

export interface HeroSectionProps {
  applications: any[]
}

/**
 * HeroSectionFlow component
 */
export const HeroSection: FC<HeroSectionProps> = (props) => {
  /**
   * Returns a hero component where the title and text is on the left and the diagram is on the right
   */
  return (
    <ReactFlowProvider>
      {/* Grid that splits the hero into two sections, left for title and subtext, and right for the heroflow */}
      <Grid container minHeight={500}>
        <Grid item sm={0} md={0} lg={4}>
          <Box style={{ position: 'relative', left: '10%', top: '30%' }}>
            <Typography variant="h2" fontWeight={'bold'}>
              POS Hub
            </Typography>
            <Typography variant="h6" sx={{ maxWidth: 600, mb: 3 }}>
              Connecting the leading POS systems with top marketplaces like Uber
              Eats, Foodhub and Just Eat, powered by an app marketplace designed
              with scalability, reliability and real time data in mind.
            </Typography>
            <Typography variant="caption" sx={{ color: 'text.secondary' }}>
              We provide the technology—you drive the innovation.
            </Typography>
          </Box>
        </Grid>
        <Grid item sm={12} md={12} lg={8}>
          <HeroFlow applications={props.applications} />
        </Grid>
      </Grid>
    </ReactFlowProvider>
  )
}

/**
 *
 */
export const HeroFlow: FC<{ applications: any[] }> = (props) => {
  // const [nodes, setNodes, onNodesChange] = useNodesState(initialNodes)
  const [nodes, setNodes, onNodesChange] = useNodesState([
    ...createNodesFromApps(props.applications),
    {
      id: 'poshub',
      type: 'HUB',
      data: { label: 'POS Hub' },
      position: { x: 0, y: 0 },
      measured: { width: 80, height: 30 },
    },
  ])
  const [edges, setEdges, onEdgesChange] = useEdgesState(initialEdges)

  /**
   * Handle when a node is clicked
   */
  const onNodeClick: NodeMouseHandler<Node> = (event, node) => {
    if (node.type === 'HUB') return
    setEdges((edges) =>
      edges.map((edge) => {
        const isActive = edge.source === node.id || edge.target === node.id
        return {
          ...edge,
          animated: isActive ? !edge.animated : edge.animated,
        }
      })
    )
  }

  return (
    <ReactFlow
      nodes={nodes}
      edges={edges}
      onNodesChange={onNodesChange}
      onEdgesChange={onEdgesChange}
      onNodeClick={onNodeClick}
      zoomOnPinch={false}
      zoomOnScroll={false}
      panOnDrag={false}
      preventScrolling={false}
      draggable={false}
      nodesDraggable={false}
      defaultEdgeOptions={{ selectable: false }}
      fitView
      nodeTypes={{
        HUB: HubNode,
        application: ApplicationNode,
      }}
      edgeTypes={{
        application: ApplicationEdge,
        // application: applicationEdge,
        // application: applicationEdge,
      }}
    />
  )
}

const createNodesFromApps = (applications: any[]) => {
  return applications
    .filter((app) => app.hero !== undefined)
    .map((app) => app.hero)
}
