import { FC, useState } from 'react'
import { Box, Button, Divider, MenuItem, TextField } from '@mui/material'
import { UseMutationResult, UseQueryResult } from 'react-query'
import { SettingsItem } from './SettingsItem'
import { BasePicker } from '../Pickers/BasePicker'

/**
 * Generate a list of time intervals (in seconds) for the auto close orders setting.
 * Options range from 3 hours to 24 hours in one-hour increments.
 */
const hourIntervals: number[] = []
for (let h = 3; h <= 24; h++) {
  hourIntervals.push(h * 3600)
}

/**
 * Helper function to format seconds into a "X hours" string.
 */
const formatSecondsToHours = (seconds: number): string => {
  const hours = seconds / 3600
  return `${hours} ${hours === 1 ? 'hour' : 'hours'}`
}

export interface OrderSettingsProps {
  location: UseQueryResult<any, any>
  updateLocation: UseMutationResult<any, any>
}

export const OrderSettings: FC<OrderSettingsProps> = (props) => {
  const [updates, setUpdates] = useState<any>({})

  const handleSaveChanges = () => {
    props.updateLocation.mutate(updates, {
      onSuccess: () => {},
    })
  }

  const isUpdatesPending = Object.keys(updates).length > 0
  console.log(hourIntervals)
  return (
    <Box>
      <SettingsItem
        label="Auto Close Orders"
        description="When enabled, orders will automatically be closed a set amount of time after the order has started."
      >
        <BasePicker
          fullWidth
          multiple={false}
          disableClearable
          filterSelectedOptions={false}
          value={
            updates.autoCloseOrdersAfter ??
            props.location.data?.data.autoCloseOrdersAfter
          }
          onChange={(event, value) => {
            setUpdates((prev: any) => ({
              ...prev,
              autoCloseOrdersAfter: value,
            }))
          }}
          options={hourIntervals}
          getOptionLabel={(option) => formatSecondsToHours(option)}
          renderOption={(props, option, { selected }) => (
            <MenuItem {...props} selected={selected}>
              {formatSecondsToHours(option)}
            </MenuItem>
          )}
          renderInput={(params) => (
            <TextField {...params} label={'Auto Close After'} />
          )}
        />
      </SettingsItem>
      <Divider />
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
        <Button
          variant="contained"
          color="primary"
          disabled={!isUpdatesPending}
          onClick={handleSaveChanges}
        >
          Save Changes
        </Button>
      </Box>
    </Box>
  )
}
