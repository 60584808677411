import {
  Box,
  Button,
  Grid,
  List,
  ListItemText,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  styled,
} from '@mui/material'
import { Navigate, useNavigate, useParams } from 'react-router-dom'
import { useEffect, useState } from 'react'
import {
  CatalogModifierGroupCreateEntity,
  CatalogModifierGroupPatchEntity,
} from '../../../../../../types'
import {
  useModifierGroupById,
  useModifierGroupCreate,
  useModifierGroupUpdate,
  useModifiers,
  getLocationQueryById,
} from '../../../../../../hooks'
import { AppLoader } from '../../../../../../components'
import { useIntl } from 'react-intl'
import { defaultModifierGroupState } from '../../../../../util'
import { PageContainer } from '../../../../../../components/Page/PageContainer'

const StyledTable = styled(Table)(({ theme }) => ({
  maxWidth: 380,
  border: '1px solid #ccc',
  borderRadius: theme.spacing(2),
  tableLayout: 'fixed',
  marginTop: theme.spacing(1),
  '& .MuiTableRow-root .MuiTableCell-root': {
    cursor: 'default',
    padding: '0px !important',
    textAlign: 'center',
    borderRight: '1px solid #ccc',
  },
  '& .MuiTableCell-root .MuiTable-root': {
    borderWidth: '0px',
  },
  '& .MuiTableCell-root .MuiTable-root .MuiTableRow-root .MuiTableCell-root': {
    borderBottom: '1px solid #ccc',
  },
  '& .MuiTableRow-root:last-child .MuiTableCell-root .MuiTable-root .MuiTableRow-root:last-child .MuiTableCell-root':
    {
      borderBottom: 'none',
    },
}))
export function ModifierGroupsView() {
  const navigate = useNavigate()
  const intl = useIntl()
  const { locationId, modifierGroupId } = useParams()
  const modifiers = useModifiers(locationId!)
  const modifiersList = modifiers.data?.pages.map((value) => value.data).flat()
  // fetching the location by id
  const locations = getLocationQueryById(locationId)
  const locationName = locations.data?.data?.name
  let breadcrumbs: any = [
    { title: 'Locations', path: '/app/locations' },
    { title: locationName, path: `/app/locations/${locationId}/analytics` },
    {
      title: 'Catalog',
      path: `/app/locations/${locationId}/catalog/categories`,
    },
    {
      title: 'Modifier-group',
      path: `/app/locations/${locationId}/catalog/modifier-group`,
    },
  ]
  const modifierGroupCreate = useModifierGroupCreate(locationId!)
  const modifierGroupUpdate = useModifierGroupUpdate(
    locationId!,
    modifierGroupId!
  )
  const modifierGroupById = useModifierGroupById(locationId, modifierGroupId, {
    refetchOnMount: 'always',
    onSuccess: (data) => {
      data?.data && setModifierGroupState(data.data)
    },
  })
  const [modifierGroupState, setModifierGroupState] = useState<
    CatalogModifierGroupCreateEntity | CatalogModifierGroupPatchEntity
  >(defaultModifierGroupState)

  useEffect(() => {
    modifiers.hasNextPage && void modifiers.fetchNextPage()
    // Sort myModifiers based on the position from modifierList
    if (modifierGroupState.modifiers?.length) {
      setModifierGroupState((prevState) => ({
        ...prevState,
        modifiers: modifierGroupState.modifiers,
      }))
    }
  }, [modifierGroupState.modifiers, modifiers.data])
  if (
    modifierGroupCreate.isLoading ||
    modifierGroupUpdate.isLoading ||
    modifierGroupById.isFetching ||
    modifierGroupById.isLoading
  ) {
    return <AppLoader />
  }

  if (modifierGroupCreate.isSuccess || modifierGroupUpdate.isSuccess) {
    return (
      <Navigate
        to={`/app/locations/${locationId}/catalog/modifier-group`}
        replace
      />
    )
  }

  return (
    <PageContainer title={''} breadcrumbs={breadcrumbs}>
      <Box sx={{ p: 3 }}>
        <Grid container spacing={2} alignItems="center">
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            justifyContent="flex-end"
            display="flex"
          >
            <Button
              onClick={() =>
                navigate(`/app/locations/${locationId}/catalog/modifier-group`)
              }
            >
              {intl.formatMessage({ id: 'action_cancel' })}
            </Button>
          </Grid>
        </Grid>
        <Grid container spacing={2} sx={{ marginTop: 1 }}>
          <Grid item xs={12} md={8} sm={12}>
            <Box>
              <List>
                {modifierGroupState.name && (
                  <ListItemText sx={{ marginTop: 1 }}>
                    <Typography component="span" color="action">
                      <Typography component={'span'} color={'grayText'}>
                        {intl.formatMessage({
                          id: 'label_modifier_group_name',
                        })}
                        {' :'}
                      </Typography>
                      <Typography component={'span'} sx={{ marginLeft: 1 }}>
                        {modifierGroupState.name}
                      </Typography>
                    </Typography>
                  </ListItemText>
                )}
                {modifierGroupState.description && (
                  <ListItemText sx={{ marginTop: 1 }}>
                    <Typography component="span" color="action">
                      <Typography component={'span'} color={'grayText'}>
                        {intl.formatMessage({
                          id: 'label_description',
                        })}
                        {' :'}
                      </Typography>
                      <Typography component={'span'} sx={{ marginLeft: 1 }}>
                        {modifierGroupState.description}
                      </Typography>
                    </Typography>
                  </ListItemText>
                )}
                <ListItemText sx={{ marginTop: 1 }}>
                  <Typography component="span" color="action">
                    <Typography component={'span'} color={'grayText'}>
                      {intl.formatMessage({
                        id: 'label_min_required',
                      })}
                      {' :'}
                    </Typography>
                    <Typography component={'span'} sx={{ marginLeft: 1 }}>
                      {modifierGroupState.minPermitted || 0}
                    </Typography>
                  </Typography>
                </ListItemText>
                <ListItemText sx={{ marginTop: 1 }}>
                  <Typography component="span" color="action">
                    <Typography component={'span'} color={'grayText'}>
                      {intl.formatMessage({
                        id: 'label_max_permitted',
                      })}
                      {' :'}
                    </Typography>
                    <Typography component={'span'} sx={{ marginLeft: 1 }}>
                      {modifierGroupState.maxPermitted || 0}
                    </Typography>
                  </Typography>
                </ListItemText>
                {modifierGroupState?.modifiers && (
                  <ListItemText sx={{ marginTop: 1 }}>
                    <Typography component={'span'} color={'grayText'}>
                      {intl.formatMessage({ id: 'label_modifier' })}
                      {' :'}
                    </Typography>
                    <StyledTable size="small">
                      <TableHead>
                        <TableRow>
                          <TableCell>
                            {intl.formatMessage({
                              id: 'label_modifier_name',
                            })}
                          </TableCell>
                          <TableCell>
                            {intl.formatMessage({
                              id: 'label_price',
                            })}
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {modifierGroupState?.modifiers.map((item: any) => (
                          <TableRow sx={{ cursor: 'default' }}>
                            <TableCell>{item.name}</TableCell>
                            <TableCell>{item.price / 100}</TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </StyledTable>
                  </ListItemText>
                )}
              </List>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </PageContainer>
  )
}
