import { useInfiniteQuery, UseInfiniteQueryOptions } from 'react-query'
import { useAccessToken } from './useAccessToken'
import { api } from '../services/api'

/**
 * @name useAccountLocations
 * @description Hook to get account locations
 * @param accountId Account ID
 * @param options Query options
 * @returns infinite query
 */
export const useAccountLocations = (
  accountId: string,
  options?: Omit<UseInfiniteQueryOptions, 'queryKey' | 'queryFn'>
) => {
  const token = useAccessToken()

  return useInfiniteQuery(
    ['v1', 'accounts', accountId, 'locations'],
    ({ pageParam }) =>
      api
        .get(`/v1/accounts/${accountId}/locations`, {
          params: { nextPageToken: pageParam },
          headers: { Authorization: `Bearer ${token?.access_token}` },
        })
        .then(({ data }) => data),
    {
      ...options,
      getNextPageParam: (lastPage: any) => lastPage?.nextPageToken,
      enabled:
        !!token?.access_token && !!accountId && options?.enabled !== false,
    } as Omit<UseInfiniteQueryOptions, 'queryKey' | 'queryFn'>
  )
}
