import { FC, useEffect, useState, SyntheticEvent } from 'react'
import { BasePicker, BasePickerProps } from '../BasePicker'
import { MenuItem, TextField, Box } from '@mui/material'

const showOnlineOptions = [
  { id: true, label: 'Available', color: '#63c156' },
  { id: false, label: 'Sold out', color: '#f36f64' },
]

interface ShowOnlinePickerProps
  extends Omit<
    BasePickerProps<any, false, true, false>,
    'renderInput' | 'onChange' | 'options'
  > {
  value?: boolean
  onChange: (value: boolean) => void
}

export const ShowOnlinePicker: FC<ShowOnlinePickerProps> = ({
  value,
  onChange,
  ...props
}) => {
  const [selectedOption, setSelectedOption] = useState(
    showOnlineOptions.find((option) => option.id === value) || null
  )

  useEffect(() => {
    setSelectedOption(
      showOnlineOptions.find((option) => option.id === value) || null
    )
  }, [value])

  return (
    <BasePicker
      {...props} // Pass down additional props
      options={showOnlineOptions}
      value={selectedOption}
      onChange={(_event: SyntheticEvent, newValue: { id: boolean } | null) => {
        if (newValue) {
          const fullOption =
            showOnlineOptions.find((option) => option.id === newValue.id) ||
            null
          onChange(newValue.id)
          setSelectedOption(fullOption) // Ensure full object is stored
        }
      }}
      disableClearable
      getOptionLabel={(option) => option.label}
      isOptionEqualToValue={(option, value) => option?.id === value?.id}
      renderOption={(props, option, { selected }) => (
        <MenuItem {...props} selected={selected}>
          <Box
            sx={{
              width: 10,
              height: 10,
              borderRadius: '50%',
              backgroundColor: option.color,
              marginRight: 0.8,
            }}
          />
          {option.label}
        </MenuItem>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          inputProps={{
            ...params.inputProps,
            readOnly: true, // Prevent user from typing
          }}
          InputProps={{
            ...params.InputProps,
            startAdornment: selectedOption && (
              <Box
                sx={{
                  width: 10,
                  height: 10,
                  borderRadius: '50%',
                  backgroundColor: selectedOption.color,
                  marginLeft: 0.5,
                }}
              />
            ),
          }}
        />
      )}
    />
  )
}
