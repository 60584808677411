import { useEffect, useState } from 'react'
import { Box, Tabs, Tab, Button } from '@mui/material'
import { useNavigate, useParams } from 'react-router-dom'
import {
  MenuSyncDialog,
  ItemsWarningDialog,
  // MenuSwitch,
} from '../../../../../components'
import { MenuItems } from './Items'
import { MenuCategory } from './Category'
import { MenuModifiers } from './Modifiers'
import { MenuModifierGroups } from './ModifierGroups'
import { getLocationQueryById, useMenuById } from '../../../../../hooks'
import { ArrowBack } from '@mui/icons-material'
import { useIntl } from 'react-intl'
import { PageContainer } from '../../../../../components/Page/PageContainer'

export function MappingMenu() {
  const intl = useIntl()
  const [currentTab, setCurrentTab] = useState(0)
  const { locationId, menuId, data } = useParams()
  const [dataAfterPublish, setDataAfterPublish] = useState<any>(undefined)

  // fetching the location by id
  const locations = getLocationQueryById(locationId)
  const locationName = locations.data?.data?.name
  const menus = useMenuById(locationId!, menuId!)
  const menuName = menus.data?.data?.name
  let breadcrumbs: any = [
    { title: 'Locations', path: '/app/locations' },
    { title: locationName, path: `/app/locations/${locationId}/analytics` },
    { title: 'Menus', path: `/app/locations/${locationId}/menus` },
  ]
  const [syncDialogId, setSyncDialogId] = useState<string | undefined>(
    undefined
  )
  const [showWarningDialog, setShowWarningDialog] = useState(false)
  const navigate = useNavigate()
  useEffect(() => {
    if (data === 'categories') {
      setCurrentTab(0)
    } else if (data === 'items') {
      setCurrentTab(1)
    } else if (data === 'modifierGroups') {
      setCurrentTab(2)
    } else {
      setCurrentTab(3)
    }
  }, [data])
  const handleOnClosePublishDialog = (data: any) => {
    setDataAfterPublish(data),
      data?.warnings?.length > 0 && setShowWarningDialog(true)
    setSyncDialogId(undefined)
  }
  const onCancelItemsWarning = () => {
    setShowWarningDialog(false)
    setDataAfterPublish(undefined)
  }

  return (
    <PageContainer title={menuName} breadcrumbs={breadcrumbs}>
      <Tabs value={currentTab}>
        <Tab
          value={'back'}
          icon={<ArrowBack />}
          sx={{ textTransform: 'none', minWidth: 0 }}
          onClick={() => navigate(`/app/locations/${locationId}/menus`)}
        />
        <Tab
          label="Categories"
          value={0}
          sx={{ textTransform: 'none' }}
          onClick={() =>
            navigate(`/app/locations/${locationId}/menu/${menuId}/categories`, {
              replace: true,
            })
          }
        />
        <Tab
          label="Items"
          value={1}
          sx={{ textTransform: 'none' }}
          onClick={() =>
            navigate(`/app/locations/${locationId}/menu/${menuId}/items`, {
              replace: true,
            })
          }
        />
        <Tab
          label="Modifier Groups"
          value={2}
          sx={{ textTransform: 'none' }}
          onClick={() =>
            navigate(
              `/app/locations/${locationId}/menu/${menuId}/modifierGroups`,
              { replace: true }
            )
          }
        />
        <Tab
          label="Modifiers"
          value={3}
          sx={{ textTransform: 'none' }}
          onClick={() =>
            navigate(`/app/locations/${locationId}/menu/${menuId}/modifiers`, {
              replace: true,
            })
          }
        />

        <Button
          color="success"
          sx={{ alignSelf: 'center', marginLeft: 'auto' }}
          onClick={() => {
            setSyncDialogId(menuId)
          }}
        >
          {intl.formatMessage({ id: 'action_publish' })}
        </Button>
      </Tabs>
      <Box sx={{ maxHeight: 'calc(100vh - 150px)', overflowY: 'auto' }}>
        {currentTab === 0 && <MenuCategory />}
        {currentTab === 1 && <MenuItems />}
        {currentTab === 2 && <MenuModifierGroups />}
        {currentTab === 3 && <MenuModifiers />}
      </Box>
      {syncDialogId && (
        <MenuSyncDialog
          menuId={syncDialogId}
          onClose={handleOnClosePublishDialog}
        />
      )}
      {showWarningDialog && (
        <ItemsWarningDialog
          dataAfterPublish={dataAfterPublish}
          onCancelItemsWarning={onCancelItemsWarning}
        />
      )}
    </PageContainer>
  )
}
