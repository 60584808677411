import { FC, useState } from 'react'
import {
  IconButton,
  List,
  ListItemButton,
  Menu,
  MenuItem,
  Typography,
} from '@mui/material'
import MenuIcon from '@mui/icons-material/Menu'

// Example nav links
const pages = [
  { label: 'Features', href: '#features' },
  { label: 'Pricing', href: '#pricing' },
]

export const WebsiteAppBarNavigation: FC = () => {
  return (
    <List
      disablePadding
      sx={{ display: 'flex', flexDirection: 'row', overflowX: 'auto' }}
    >
      <ListItemButton
        onClick={() =>
          document.getElementById('features')?.scrollIntoView({
            behavior: 'smooth',
          })
        }
      >
        Features
      </ListItemButton>
      <ListItemButton
        onClick={() =>
          document
            .getElementById('coverage')
            ?.scrollIntoView({ behavior: 'smooth', block: 'center' })
        }
      >
        Coverage
      </ListItemButton>
      {/* <ListItemButton
        onClick={() =>
          document
            .getElementById('pricing')
            ?.scrollIntoView({ behavior: 'smooth' })
        }
      >
        Pricing
      </ListItemButton> */}
      <ListItemButton
        onClick={() =>
          document.getElementById('faq')?.scrollIntoView({ behavior: 'smooth' })
        }
      >
        FAQ
      </ListItemButton>
    </List>
  )
}

export const WebsiteAppBarMobileNavigation: FC = () => {
  // Mobile nav menu stat
  const [anchorElNav, setAnchorElNav] = useState<null | HTMLElement>(null)

  // Handlers: open/close mobile menu
  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget)
  }
  const handleCloseNavMenu = () => {
    setAnchorElNav(null)
  }

  return (
    <>
      <IconButton
        size="large"
        aria-label="open navigation menu"
        onClick={handleOpenNavMenu}
      >
        <MenuIcon />
      </IconButton>
      <Menu
        anchorEl={anchorElNav}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        keepMounted
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
        open={Boolean(anchorElNav)}
        onClose={handleCloseNavMenu}
        sx={{ display: { xs: 'block', md: 'none' } }}
      >
        <MenuItem
          key={'Features'}
          onClick={() =>
            document
              .getElementById('features')
              ?.scrollIntoView({ behavior: 'smooth' })
          }
        >
          <Typography textAlign="center">Features</Typography>
        </MenuItem>
        <MenuItem
          key={'coverage'}
          onClick={() =>
            document
              .getElementById('coverage')
              ?.scrollIntoView({ behavior: 'smooth' })
          }
        >
          <Typography textAlign="center">Coverage</Typography>
        </MenuItem>
        <MenuItem
          key={'FAQ'}
          onClick={() =>
            document
              .getElementById('faq')
              ?.scrollIntoView({ behavior: 'smooth' })
          }
        >
          <Typography textAlign="center">FAQ</Typography>
        </MenuItem>
      </Menu>
    </>
  )
}
