import { useState } from 'react'
import { Typography, Box, Grid } from '@mui/material'
import { useIntl } from 'react-intl'
import { getCurrencySymbol } from '../../pages/util'
import { CommonPriceInputField } from '../CommonPriceField'
import MinMaxField from '../MinMaxFields'
import { ClickableIcon } from '../ClickableIcon'
import TurnRightOutlinedIcon from '@mui/icons-material/TurnRightOutlined'
import { ShowOnlinePicker } from '../Pickers'

export function NestedModifiers(props: any) {
  const { currentModifiersSelection, handleNestedModifiersUpdate } = props

  // State to hold the selections (deep copy to prevent mutation of props)
  const [modifiersSelection, setModifiersSelection] = useState(
    JSON.parse(JSON.stringify(currentModifiersSelection))
  )

  const updatePrices = (data: any): any => {
    if (Array.isArray(data)) {
      return data.map((item) => updatePrices(item))
    }

    if (typeof data === 'object' && data !== null) {
      const updatedData = { ...data }

      // Multiply price by 100 if the key exists and is a number
      if (typeof updatedData.price === 'number') {
        updatedData.price *= 100
      }
      if (typeof updatedData.inStorePrice === 'number') {
        updatedData.inStorePrice *= 100
      }

      // Recursively process nested properties
      Object.keys(updatedData).forEach((key) => {
        updatedData[key] = updatePrices(updatedData[key])
      })

      return updatedData
    }

    return data
  }

  // Handle change for both modifier group and modifier input fields
  const handleChange = (
    groupId: string,
    modifierId: string | null,
    fields: { [key: string]: string | number } // Allow multiple fields
  ) => {
    const updatedSelections = updateSelection(
      JSON.parse(JSON.stringify(modifiersSelection)),
      groupId,
      modifierId,
      fields
    )

    // Update all price keys before calling handleNestedModifiersUpdate
    const updatedSelectionsWithPrices = updatePrices(updatedSelections)

    setModifiersSelection(updatedSelections)
    handleNestedModifiersUpdate(updatedSelectionsWithPrices)
  }

  // Recursive function to update the correct selection based on groupId and modifierId
  const updateSelection = (
    selections: any[],
    groupId: string,
    modifierId: string | null,
    fields: { [key: string]: string | number }
  ): any => {
    return selections.map((selection) => {
      if (selection.catalogModifierGroupId === groupId) {
        if (!modifierId) {
          // Update the entire group if no specific modifierId is provided
          return {
            ...selection,
            ...fields, // Merge all changes
          }
        }
        // Proceed with updating the modifiers within the group
        return {
          ...selection,
          modifiers: selection.modifiers.map((modifier: any) => {
            // Check if the current modifier is the one being updated
            if (modifier.catalogModifierId === modifierId) {
              return {
                ...modifier,
                ...fields, // Merge all changes
              }
            }

            // If the modifier has nested selections, update them recursively
            if (modifier.selections && modifier.selections.length > 0) {
              return {
                ...modifier,
                selections: updateSelection(
                  modifier.selections,
                  groupId,
                  modifierId,
                  fields
                ),
              }
            }

            // Return the original modifier if it's not being updated
            return modifier
          }),
        }
      }

      // If there are nested selections in the current group, update them recursively
      if (selection.selections && selection.selections.length > 0) {
        return {
          ...selection,
          selections: updateSelection(
            selection.selections,
            groupId,
            modifierId,
            fields
          ),
        }
      }
      if (selection.modifiers && selection.modifiers.length > 0) {
        return {
          ...selection,
          modifiers: updateSelection(
            selection.modifiers,
            groupId,
            modifierId,
            fields
          ),
        }
      }
      // Return the original selection if it's not being updated
      return selection
    })
  }

  return (
    <ModifierGroupUI
      initialSelections={modifiersSelection}
      handleChange={handleChange}
    />
  )
}

// Recursive component to render modifiers and nested selections
const ModifierUI = ({ selection, handleChange, title }: any) => {
  const intl = useIntl()
  const currency = getCurrencySymbol()
  const handleChangeValue = (value: {
    minPermitted: number
    maxPermitted: number
  }) => {
    handleChange(
      selection.catalogModifierGroupId, // Pass groupId
      null, // null for modifierId indicates its a modifier group
      { minPermitted: value.minPermitted, maxPermitted: value.maxPermitted } // Pass both fields
    )
  }
  const handleChangeModifierMinMaxValue = (
    value: {
      minPermitted: number
      maxPermitted: number
    },
    modifierId: any
  ) => {
    // Handle change for the modifier min and max permitted values
    handleChange(
      selection.catalogModifierGroupId, // Pass groupId
      modifierId,
      { minPermitted: value.minPermitted, maxPermitted: value.maxPermitted } // Pass both fields
    )
  }
  const handleShowOnlineChange = (value: boolean, id: any) => {
    handleChange(
      selection.catalogModifierGroupId, // Pass groupId
      id,
      { showOnline: value } // Pass showOnline field
    )
  }
  const [expandedModifierId, setExpandedModifierId] = useState<string | null>(
    null
  )

  const handleExpandClick = (modifierId: string) => {
    // Toggle the expanded state for the clicked modifier
    setExpandedModifierId((prevId) =>
      prevId === modifierId ? null : modifierId
    )
  }

  return (
    <Box>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Typography variant="subtitle2" id="id_modifier_group">
            {title ? title : ''}{' '}
            {intl.formatMessage({ id: 'label_modifier_group' })}
          </Typography>
        </Grid>
        {/* Render the main modifier group */}
        <Grid item xs={12} sm={4}>
          <Typography variant="body1" id="id_selection_name">
            {selection.name}
          </Typography>
        </Grid>
        <MinMaxField
          minValue={selection.minPermitted || 0}
          maxValue={selection.maxPermitted || 0}
          onChange={handleChangeValue}
          size={{ xs: 12, sm: 4 }}
        />
        <Grid
          item
          xs={12}
          sx={{
            border: '1px solid #ccc',
            borderRadius: 2,
            pb: 2,
            mt: 2,
          }}
        >
          <Typography variant="subtitle2" id="id_modifiers">
            {title ? title : ''} {intl.formatMessage({ id: 'label_modifiers' })}
          </Typography>
          {selection.modifiers &&
            selection.modifiers.map((modifier: any) => (
              <Box sx={{ position: 'relative' }}>
                <Grid
                  container
                  spacing={3}
                  style={{ margin: 0, width: '99.8%', padding: '0.2rem' }}
                >
                  <Grid item xs={12} sm={2}>
                    <Typography id="id_modifier_name">
                      {modifier.name}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={1.8}>
                    <CommonPriceInputField
                      label={`${intl.formatMessage({
                        id: 'label_price',
                      })}${' *'}`}
                      currency={currency}
                      value={modifier.price || ''} // Ensure value is string or number
                      onChange={(data: any) =>
                        handleChange(
                          selection.catalogModifierGroupId,
                          modifier.catalogModifierId,
                          { price: data }
                        )
                      }
                    />
                  </Grid>
                  <Grid item xs={12} sm={1.8}>
                    <CommonPriceInputField
                      label={intl.formatMessage({
                        id: 'label_inStorePrice',
                      })}
                      currency={currency}
                      value={modifier.inStorePrice || ''} // Ensure value is string or number
                      onChange={(data: any) =>
                        handleChange(
                          selection.catalogModifierGroupId,
                          modifier.catalogModifierId,
                          { inStorePrice: data }
                        )
                      }
                    />
                  </Grid>
                  <MinMaxField
                    minValue={modifier.minPermitted || 0}
                    maxValue={modifier.maxPermitted || 0}
                    onChange={(value: any) =>
                      handleChangeModifierMinMaxValue(
                        value,
                        modifier.catalogModifierId
                      )
                    }
                    size={{ xs: 11.5, sm: 1.5 }}
                  />
                  <Grid item sm={2} xs={12}>
                    <ShowOnlinePicker
                      value={
                        'showOnline' in modifier ? modifier.showOnline : true
                      }
                      onChange={(value) =>
                        handleShowOnlineChange(
                          value,
                          modifier?.catalogModifierId
                        )
                      }
                    />
                  </Grid>
                  <Grid item xs={1} sm={1}>
                    {modifier.selections && modifier.selections.length > 0 && (
                      <ClickableIcon
                        handleNavigate={(e: any) => {
                          e.stopPropagation()
                          handleExpandClick(modifier.catalogModifierId)
                        }}
                        children={
                          <TurnRightOutlinedIcon
                            sx={{
                              transform: 'scale(1.2) rotate(-270deg)', // Adjust size (default is 1)
                              color: 'gray',
                            }}
                          />
                        }
                        tootltipTitle={intl.formatMessage({
                          id: 'label_nested_modifiers',
                        })}
                      />
                    )}
                  </Grid>

                  {/* Conditional Rendering for Nested Modifiers */}
                  {expandedModifierId === modifier.catalogModifierId && (
                    <Box>
                      {modifier.selections.map((nestedSelection: any) => (
                        <ModifierUI
                          key={nestedSelection.catalogModifierGroupId}
                          selection={nestedSelection}
                          handleChange={handleChange}
                          title={intl.formatMessage({
                            id: 'label_Side_Linked',
                          })}
                        />
                      ))}
                    </Box>
                  )}
                </Grid>
              </Box>
            ))}
        </Grid>
      </Grid>
    </Box>
  )
}

// Main component to render initial selections
const ModifierGroupUI = ({ initialSelections, handleChange }: any) => {
  return (
    <Box>
      {initialSelections?.map((mod: any) => (
        <ModifierUI
          key={mod.catalogModifierGroupId}
          selection={mod}
          handleChange={handleChange}
        />
      ))}
    </Box>
  )
}
