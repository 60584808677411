import { useNavigate, useParams } from 'react-router-dom'
import { FC, useState } from 'react'
import {
  IconButton,
  Skeleton,
  Typography,
  Paper,
  Tabs,
  Tab,
  Avatar,
  CardHeader,
} from '@mui/material'
import { Edit } from '@mui/icons-material'
import {
  useUserApplicationById,
  useUserApplicationImageMutation,
} from '../../hooks'
import { Breadcrumb, PageContainer } from '../../components/Page/PageContainer'
import { UserApplicationOverview } from './components/UserApplicationOverview'
import { UserApplicationConnections } from './components/UserApplicationConnections'
import { UserApplicationAnalytics } from './components/UserApplicationAnalytics'
import { UserApplicationAudits } from './components/UserApplicationAudits'
import { useTwoPhaseUpload } from '../../hooks/useTwoPhaseUpload'

export const DeveloperApplicationById: FC = () => {
  const navigate = useNavigate()
  const { applicationId } = useParams()
  const [tab, setTab] = useState(0)
  const application = useUserApplicationById(applicationId)
  const applicationImageSigner = useUserApplicationImageMutation(applicationId!)
  const imageUploadMutation = useTwoPhaseUpload({
    useSignedUrlMutation: applicationImageSigner,
  })

  if (application.isLoading) {
    return (
      <PageContainer
        title={<Skeleton variant="text" width={200} />}
        toolbar={<Skeleton variant="text" width={40} height={40} />}
      ></PageContainer>
    )
  }

  /**
   * Title component contain a badge for the category of the application followed by the name
   */
  const title = (
    <CardHeader
      disableTypography
      sx={{ padding: 0 }}
      avatar={<Avatar src={application.data.imageUrl} />}
      title={<Typography variant="h4">{application.data.name}</Typography>}
    />
  )

  /**
   * Define the toolbar
   */
  const toolbar = (
    <>
      <IconButton onClick={() => navigate('edit')}>
        <Edit />
      </IconButton>
    </>
  )

  /**
   * Breadcrumbs for the page
   */
  const breadcrumbs: Breadcrumb[] = []

  return (
    <PageContainer title={title} toolbar={toolbar} breadcrumbs={breadcrumbs}>
      {/* break the application into tabs */}
      <Tabs value={tab} onChange={(_, value) => setTab(value)}>
        <Tab value={0} label="Overview" />
        <Tab value={1} label="Installations" />
        <Tab value={2} label="Analytics" />
        <Tab value={3} label="Audits " />
      </Tabs>
      <Paper sx={{ p: 2 }}>
        {tab === 0 && (
          <UserApplicationOverview
            application={application}
            imageUploadMutation={imageUploadMutation}
          />
        )}
        {tab === 1 && <UserApplicationConnections application={application} />}
        {tab === 2 && <UserApplicationAnalytics application={application} />}
        {tab === 3 && <UserApplicationAudits application={application} />}
      </Paper>
    </PageContainer>
  )
}
