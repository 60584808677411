import { Typography } from '@mui/material'
import {
  FC,
  useState,
  // useState
} from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { AppLoader, Column, GenericTable } from '../../../components'
import { useResellerAccounts } from '../../../hooks/useResellerAccounts'
import { CopyToClipboard } from '../../../components/CopyToClipboard'
import { ResellerSearch } from '../ResellerSearch'
import { useQueryString } from '../../../hooks/useQueryString'
import {
  Breadcrumb,
  PageContainer,
} from '../../../components/Page/PageContainer'

const columns: readonly Column[] = [
  {
    id: 'id',
    label: 'ID',
    component: (row) => (
      <CopyToClipboard value={row?.id}>
        <Typography variant="caption">{row?.id}</Typography>
      </CopyToClipboard>
    ),
  },
  {
    id: 'name',
    label: 'Name',
    component(row) {
      return <Typography variant="caption">{row?.name}</Typography>
    },
  },
  {
    id: 'currency',
    label: 'Currency',
    component(row) {
      return <Typography variant="caption">{row?.currency}</Typography>
    },
  },
  {
    id: 'address',
    label: 'Address',
    component: (row) => (
      <Typography variant="caption">
        {row?.address?.formatted_address || row?.address?.address}
      </Typography>
    ),
  },
]

export const ResellerAccounts: FC = () => {
  const { resellerId } = useParams()
  const navigate = useNavigate()
  const [queryParamURL] = useQueryString(window.location, navigate)
  const [filters, setFilters] = useState(queryParamURL as any)
  const accounts = useResellerAccounts(
    { resellerId: resellerId!, filters: filters! },
    { refetchOnMount: 'always' }
  )

  let breadcrumbs: Breadcrumb[] = [
    { title: 'Reseller', path: `/app/resellers/${resellerId}/dashboard` },
    { title: 'Accounts', path: `/app/resellers/${resellerId}/accounts` },
  ]
  return (
    <PageContainer
      title="Accounts"
      breadcrumbs={breadcrumbs}
      toolbar={
        <ResellerSearch
          setFilters={setFilters}
          isLoading={accounts.isFetching}
        />
      }
    >
      {accounts.isLoading ? (
        <AppLoader />
      ) : (
        <GenericTable
          columns={columns}
          query={accounts}
          filters={filters}
          noDataMessage="No accounts found"
          enableDelete={false}
          tableType="account"
          // handleDelete={handleDeleteClick}
        />
      )}
      {/* <AppWarningDialog
        loading={accountDelete.isLoading}
        name={selectedAccount?.name}
        source="Reseller Account"
        open={showConfirmationDialog}
        onConfirm={handleDeleteConfirmed}
        onCancel={() => setShowConfirmationDialog(false)}
      /> */}
    </PageContainer>
  )
}
