import {
  UseQueryOptions,
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
} from 'react-query'
import { api } from '../services/api'
import { useAuthStore } from './useAuthStore'
import { useAccessToken } from './useAccessToken'
import { signalToCancellationToken } from './utils'
import {
  ErrorResponse,
  GetAccountMenuByIdResponse,
  ListAccountMenusResponse,
  MenuCreateEntity,
  MenuPatchEntity,
} from '../types'
import { toast } from 'react-toastify'
import { useParams } from 'react-router-dom'

export const useMenus = (locationId: string) => {
  const accountId = useAuthStore((t) => t.accountId)
  const token = useAccessToken()

  return useInfiniteQuery<ListAccountMenusResponse>(
    ['accounts', accountId, 'locations', locationId, 'menus'],
    ({ pageParam }) =>
      api
        .get<ListAccountMenusResponse>(
          `/v1/accounts/${accountId}/locations/${locationId}/menus`,
          {
            params: {
              nextPageKey: pageParam,
            },
            headers: { Authorization: `Bearer ${token?.access_token}` },
            cancelToken: signalToCancellationToken()?.token,
          }
        )
        .then(({ data }) => data),
    {
      getNextPageParam: (lastPage) => lastPage.nextPageKey,
      keepPreviousData: true,
      enabled: !!token?.access_token && !!accountId,
    }
  )
}

export const useMenuDelete = (locationId: string) => {
  const queryClient = useQueryClient()
  const [accountId] = useAuthStore((t) => [t.accountId])
  const token = useAccessToken()

  return useMutation(
    (menuId: any) =>
      api
        .delete(
          `/v1/accounts/${accountId}/locations/${locationId}/menus/${menuId}`,
          {
            headers: { Authorization: `Bearer ${token?.access_token}` },
          }
        )
        .then(({ data }) => data),
    {
      onSuccess: () => {
        void queryClient.refetchQueries({
          queryKey: ['accounts', accountId, 'locations', locationId, 'menus'],
          exact: true,
        })
        toast.success('Menu Deleted')
      },
      onError: (error: any) => {
        if (error.response && error.response.data) {
          toast.error(JSON.stringify(error.response.data.data))
        } else {
          toast.error(JSON.stringify(error.message))
        }
      },
    }
  )
}

export const useMenuCreate = (locationId: string, isChecked: any) => {
  const queryClient = useQueryClient()
  const [accountId] = useAuthStore((t) => [t.accountId])
  const token = useAccessToken()

  return useMutation<
    GetAccountMenuByIdResponse,
    ErrorResponse,
    MenuCreateEntity
  >(
    (menu) =>
      api
        .post<GetAccountMenuByIdResponse>(
          `/v1/accounts/${accountId}/locations/${locationId}/menus?autoImport=${isChecked}`,
          menu,
          {
            headers: { Authorization: `Bearer ${token?.access_token}` },
          }
        )
        .then(({ data }) => data),
    {
      onSuccess: () => {
        // const menuId = data?.data?.id
        void queryClient.refetchQueries({
          queryKey: ['accounts', accountId, 'locations', locationId, 'menus'],
          exact: true,
        })
        // queryClient.invalidateQueries({
        //   queryKey: [
        //     'accounts',
        //     accountId,
        //     'locations',
        //     locationId,
        //     'menu',
        //     menuId,
        //     'items',
        //   ],
        //   exact: true,
        // })
      },
      onError: (error: any) => {
        if (error.response && error.response.data) {
          toast.error(JSON.stringify(error.response.data.data))
        } else {
          toast.error(JSON.stringify(error.message))
        }
      },
    }
  )
}

export interface UseMenuByIdProps<
  TData = GetAccountMenuByIdResponse,
  TError = ErrorResponse
> extends UseQueryOptions<TData, TError> {}

export const useMenuById = (
  locationId?: string,
  menuId?: string,
  props?: UseMenuByIdProps
) => {
  const [accountId] = useAuthStore((t) => [t.accountId])
  const token = useAccessToken()

  return useQuery<GetAccountMenuByIdResponse, ErrorResponse>(
    ['accounts', accountId, 'locations', locationId, 'menus', menuId],
    ({ signal }) =>
      api
        .get<GetAccountMenuByIdResponse>(
          `/v1/accounts/${accountId}/locations/${locationId}/menus/${menuId}`,
          {
            headers: { Authorization: `Bearer ${token?.access_token}` },
            cancelToken: signalToCancellationToken(signal)?.token,
          }
        )
        .then(({ data }) => data),
    {
      staleTime: 3600 * 60,
      enabled: !!token?.access_token && !!accountId && !!menuId,
      ...props,
    }
  )
}

export const useMenuUpdate = (locationId: any, menuId: any, isChecked: any) => {
  const queryClient = useQueryClient()
  const [accountId] = useAuthStore((t) => [t.accountId])
  const token = useAccessToken()
  return useMutation<
    GetAccountMenuByIdResponse,
    ErrorResponse,
    MenuPatchEntity
  >(
    (menu) =>
      api
        .patch<GetAccountMenuByIdResponse>(
          `/v1/accounts/${accountId}/locations/${locationId}/menus/${menuId}?autoImport=${isChecked}`,
          menu,
          {
            headers: { Authorization: `Bearer ${token?.access_token}` },
          }
        )
        .then(({ data }) => data),
    {
      onSuccess: () => {
        void queryClient.refetchQueries({
          queryKey: ['accounts', accountId, 'locations', locationId, 'menus'],
          exact: true,
        })
      },
      onError: (error: any) => {
        if (error?.response && error.response.data) {
          toast.error(JSON.stringify(error.response.data.data))
        } else {
          toast.error(JSON.stringify(error.message))
        }
      },
    }
  )
}

/**
 * useMenuPublishMutation
 */
export const useMenuPublishMutation = () => {
  const queryClient = useQueryClient()
  const [accountId] = useAuthStore((t) => [t.accountId])
  const token = useAccessToken()
  const { locationId } = useParams()

  return useMutation<
    any,
    any,
    {
      applicationId: string
      menuId: string
      locationId: any
      connectionId: any
      autoPublishInterval: number
    }
  >(
    (props) =>
      api
        .post(
          `/v1/accounts/${accountId}/connections/${props.connectionId}/publish`,
          {
            menuId: props.menuId,
            autoPublishInterval: props.autoPublishInterval,
          },
          {
            params: {
              locationId: props.locationId!,
              applicationId: props.applicationId,
            },
            headers: { Authorization: `Bearer ${token?.access_token}` },
          }
        )
        .then(({ data }) => data),
    {
      onSuccess: (data) => {
        void queryClient.refetchQueries({
          queryKey: ['accounts', accountId, 'locations', locationId!, 'menus'],
          exact: true,
        })
        toast.success('Menu Published')
      },
    }
  )
}
