import { FC, useState } from 'react'
import { Box, Divider, Button } from '@mui/material'
import { useIntl } from 'react-intl'
import { UseMutationResult, UseQueryResult } from 'react-query'
import { SettingsItem } from './SettingsItem'
import { ServiceAvailabilityV2 } from '../ServiceAvailabilityInput/ServiceAvailabilityV2'

// ------------------------------------------------------------------
// A small interface for your overall account props
// ------------------------------------------------------------------
export interface OperatingHoursProps {
  location: UseQueryResult<any, any>
  updateLocation: UseMutationResult<any, any>
}

// ------------------------------------------------------------------
// The main CompanyDetails component
// ------------------------------------------------------------------
export const OperatingHours: FC<OperatingHoursProps> = (props) => {
  const intl = useIntl()
  const [updates, setUpdates] = useState<any>({})

  const handleSaveChanges = () => {
    props.updateLocation.mutate(updates)
  }

  const isUpdatesPending = Object.keys(updates).length > 0

  return (
    <>
      <Box>
        {/* Account Name */}
        <SettingsItem
          label="Default Opening Hours"
          description="The default opening hours for this location for both delivery and collection."
        >
          <ServiceAvailabilityV2
            defaultValue={props.location.data?.data.businessHours}
            value={updates.businessHours}
            onChange={(businessHours) => {
              setUpdates((prev: any) => ({ ...prev, businessHours }))
            }}
          />
        </SettingsItem>
        <Divider />

        {/* Custom Collection Override */}
        <SettingsItem
          label="Collection Override"
          description="Override the default opening hours for collection."
        >
          <ServiceAvailabilityV2
            defaultValue={props.location.data?.data.availability?.collection}
            value={updates.availability?.collection}
            onChange={(collection) => {
              setUpdates((prev: any) => ({
                ...prev,
                availability: { ...prev.availability, collection },
              }))
            }}
          />
        </SettingsItem>
        <Divider />

        {/* Custom Delivery Override */}
        <SettingsItem
          label="Delivery Override"
          description="Override the default opening hours for delivery."
        >
          <ServiceAvailabilityV2
            defaultValue={updates.availability?.delivery}
            value={updates.availability?.delivery}
            onChange={(delivery) => {
              setUpdates((prev: any) => ({
                ...prev,
                availability: { ...prev.availability, delivery },
              }))
            }}
          />
        </SettingsItem>
      </Box>

      {/* Save */}
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
        <Button
          variant="contained"
          color="primary"
          disabled={!isUpdatesPending}
          onClick={handleSaveChanges}
        >
          Save Changes
        </Button>
      </Box>
    </>
  )
}
