import { useParams } from 'react-router-dom'
import { AuditsTable } from '../../../components/AuditsTable'
import { useResellerAudits } from '../../../hooks/useResellerAudits'
import {
  Breadcrumb,
  PageContainer,
} from '../../../components/Page/PageContainer'

/**
 *
 * @returns
 */
export const ResellerAudits = () => {
  const { resellerId } = useParams()
  const audits = useResellerAudits({ resellerId: resellerId! })

  const breadcrumbs: Breadcrumb[] = [
    { title: 'Reseller', path: `/app/resellers/${resellerId}/dashboard` },
    { title: 'Audits', path: `/app/resellers/${resellerId}/audits` },
  ]

  return (
    <PageContainer title="" breadcrumbs={breadcrumbs}>
      <AuditsTable audits={audits} />
    </PageContainer>
  )
}
