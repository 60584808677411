import { useState, ChangeEvent, useEffect, Fragment } from 'react'
import { Grid, Typography } from '@mui/material'
import { useIntl } from 'react-intl'
import { CommonTextField } from '../CommonTextField'

interface MinMaxFieldProps {
  minValue: number
  maxValue: number
  onChange: (
    updatedValues: {
      minPermitted: number
      maxPermitted: number
    },
    error: boolean
  ) => void
  size: any
  labelVisiblity?: boolean
}
const MinMaxField: React.FC<MinMaxFieldProps> = ({
  minValue,
  maxValue,
  onChange,
  size,
  labelVisiblity = true,
}) => {
  const [errorMessage, setErrorMessage] = useState<string>('')
  const [localState, setLocalState] = useState({
    minPermitted: minValue || 0,
    maxPermitted: maxValue || 0,
  })
  const intl = useIntl()

  useEffect(() => {
    setLocalState({ minPermitted: minValue || 0, maxPermitted: maxValue || 0 })
  }, [minValue, maxValue])

  const handleValueChange = (
    event: ChangeEvent<HTMLInputElement>,
    field: 'minPermitted' | 'maxPermitted'
  ) => {
    const inputValue = event.target.value

    if (/^\d+$/.test(inputValue) || inputValue === '') {
      const updatedState = {
        ...localState,
        [field]: inputValue !== '' ? parseInt(inputValue) : 0,
      }

      // Ensure the other field is set
      if (field === 'minPermitted') {
        updatedState.maxPermitted = updatedState.maxPermitted ?? maxValue
      } else {
        updatedState.minPermitted = updatedState.minPermitted ?? minValue
      }

      // Validation function
      const validateMinMaxValues = (min: number, max: number) => {
        return max === 0 || min <= max
      }

      // Validate the values
      const isValid = validateMinMaxValues(
        updatedState.minPermitted,
        updatedState.maxPermitted
      )

      // Set error message immediately based on the validation
      if (!isValid) {
        setErrorMessage('Minimum cannot be greater than maximum')
      } else {
        setErrorMessage('')
      }

      // Call onChange with the updated validation result instead of relying on state
      onChange(updatedState, !isValid) // Pass `true` if error exists
      setLocalState(updatedState)
    }
  }

  return (
    <Fragment>
      <Grid item {...size}>
        <CommonTextField
          id="min-permitted"
          label={
            labelVisiblity
              ? intl.formatMessage({ id: 'label_min_required' })
              : ''
          }
          onChange={(e: any) => handleValueChange(e, 'minPermitted')}
          value={localState.minPermitted}
          error={!!errorMessage}
        />
        <Typography
          variant="caption"
          color="error"
          sx={{ position: 'absolute', display: 'flex', mb: 1 }}
        >
          {errorMessage ? errorMessage : ' '}
        </Typography>
      </Grid>
      <Grid item {...size}>
        <CommonTextField
          id="max-permitted"
          label={
            labelVisiblity
              ? intl.formatMessage({ id: 'label_max_permitted' })
              : ''
          }
          onChange={(e: any) => handleValueChange(e, 'maxPermitted')}
          value={localState.maxPermitted}
        />
      </Grid>
    </Fragment>
  )
}

export default MinMaxField
