import {
  UseQueryOptions,
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
} from 'react-query'
import { api } from '../services/api'
import { useAuthStore } from './useAuthStore'
import { useAccessToken } from './useAccessToken'
import { signalToCancellationToken } from './utils'
import {
  ErrorResponse,
  GetAccountMenuModifierGroupByIdResponse,
  ListAccountMenuModifierGroupResponse,
  MenuModifierGroupCreateEntity,
  MenuModifierGroupPatchEntity,
} from '../types'
import { toast } from 'react-toastify'

export interface UseMenuModifierGroupByIdProps {
  refetchOnMount?: boolean | 'always'
}

export interface UseMenuModifierGroupByIdProps<
  TData = GetAccountMenuModifierGroupByIdResponse,
  TError = ErrorResponse
> extends UseQueryOptions<TData, TError> {}

export const useMenuModifierGroups = (
  locationId: string,
  menuId: string,
  options?: any
) => {
  const accountId = useAuthStore((t) => t.accountId)
  const token = useAccessToken()

  return useInfiniteQuery<ListAccountMenuModifierGroupResponse>(
    [
      'accounts',
      accountId,
      'locations',
      locationId,
      'menu',
      menuId,
      'modifier-groups',
    ],
    ({ pageParam }) =>
      api
        .get(
          `/v1/accounts/${accountId}/locations/${locationId}/menus/${menuId}/modifier-groups`,
          {
            params: {
              limit: 100,
              nextPageKey: pageParam,
            },
            headers: { Authorization: `Bearer ${token?.access_token}` },
            cancelToken: signalToCancellationToken()?.token,
          }
        )
        .then(({ data }) => data),
    {
      getNextPageParam: (lastPage: any) => lastPage.nextPageKey,
      keepPreviousData: true,
      enabled: !!token?.access_token && !!accountId && !!locationId && !!menuId,
      ...options,
    }
  )
}

export const useMenuModifierGroupCreate = (locationId: any, menuId: any) => {
  const queryClient = useQueryClient()
  const [accountId] = useAuthStore((t) => [t.accountId])
  const token = useAccessToken()

  return useMutation<
    GetAccountMenuModifierGroupByIdResponse,
    ErrorResponse,
    MenuModifierGroupCreateEntity
  >(
    (modifierGroup) =>
      api
        .post<GetAccountMenuModifierGroupByIdResponse>(
          `/v1/accounts/${accountId}/locations/${locationId}/menus/${menuId}/modifier-groups`,
          modifierGroup,
          {
            headers: { Authorization: `Bearer ${token?.access_token}` },
          }
        )
        .then(({ data }) => data),
    {
      onSuccess: () => {
        void queryClient.refetchQueries([
          'accounts',
          accountId,
          'locations',
          locationId,
          'menu',
          menuId,
          'modifier-groups',
        ])
      },
      onError: (error: any) => {
        if (error.response && error.response.data) {
          toast.error(JSON.stringify(error.response.data.data))
        } else {
          toast.error(JSON.stringify(error.message))
        }
      },
    }
  )
}

export const useMenuModifierGroupUpdate = (
  locationId: string,
  menuId: string,
  modifierGroupId: string
) => {
  const queryClient = useQueryClient()
  const [accountId] = useAuthStore((t) => [t.accountId])
  const token = useAccessToken()

  return useMutation<
    GetAccountMenuModifierGroupByIdResponse,
    ErrorResponse,
    MenuModifierGroupPatchEntity
  >(
    (modifierGroup) =>
      api
        .patch<GetAccountMenuModifierGroupByIdResponse>(
          `/v1/accounts/${accountId}/locations/${locationId}/menus/${menuId}/modifier-groups/${modifierGroupId}`,
          modifierGroup,
          {
            headers: { Authorization: `Bearer ${token?.access_token}` },
          }
        )
        .then(({ data }) => data),
    {
      onSuccess: () => {
        void queryClient.refetchQueries([
          'accounts',
          accountId,
          'locations',
          locationId,
          'menu',
          menuId,
          'modifier-groups',
        ])
        toast.success('Modifier Updated')
      },
      onError: (error: any) => {
        if (error?.response && error.response.data) {
          toast.error(JSON.stringify(error.response.data.data))
        } else {
          toast.error(JSON.stringify(error.message))
        }
      },
    }
  )
}

export const useMenuModifierGroupById = (
  locationId?: string,
  menuId?: string,
  modifierGroupId?: string,
  props?: UseMenuModifierGroupByIdProps
) => {
  const [accountId] = useAuthStore((t) => [t.accountId])
  const token = useAccessToken()
  return useQuery<GetAccountMenuModifierGroupByIdResponse, ErrorResponse>(
    [
      'accounts',
      accountId,
      'locations',
      locationId,
      'menus',
      menuId,
      'modifier-groups',
      modifierGroupId,
    ],
    ({ signal }) =>
      api
        .get(
          `/v1/accounts/${accountId}/locations/${locationId}/menus/${menuId}/modifier-groups/${modifierGroupId}`,
          {
            headers: { Authorization: `Bearer ${token?.access_token}` },
            cancelToken: signalToCancellationToken(signal)?.token,
          }
        )
        .then(({ data }) => data),
    {
      staleTime: 3600 * 60,
      enabled:
        !!token?.access_token && !!accountId && !!menuId && !!modifierGroupId,
      ...props,
    }
  )
}

export const useMenuModifierGroupDelete = (
  locationId: string,
  menuId: string
) => {
  const queryClient = useQueryClient()
  const [accountId] = useAuthStore((t) => [t.accountId])
  const token = useAccessToken()

  return useMutation(
    (modifierGroupId: any) =>
      api
        .delete(
          `/v1/accounts/${accountId}/locations/${locationId}/menus/${menuId}/modifier-groups/${modifierGroupId}`,
          {
            headers: { Authorization: `Bearer ${token?.access_token}` },
          }
        )
        .then(({ data }) => data),
    {
      onSuccess: () => {
        // void queryClient.refetchQueries([
        //   'accounts',
        //   accountId,
        //   'locations',
        //   locationId,
        //   'menu',
        //   menuId,
        //   'modifier-groups',
        // ])
        toast.success('Modifier Group Deleted')
      },
      onError: (error: any) => {
        if (error.response && error.response.data) {
          toast.error(JSON.stringify(error.response.data.data))
        } else {
          toast.error(JSON.stringify(error.message))
        }
      },
    }
  )
}

export const useMenuModifierGroupUpdateForItemEdit = (
  locationId: string,
  menuId: string
) => {
  const queryClient = useQueryClient()
  const [accountId] = useAuthStore((t) => [t.accountId])
  const token = useAccessToken()

  return useMutation<
    GetAccountMenuModifierGroupByIdResponse,
    ErrorResponse,
    { modifierGroupId: string; modifierGroup: MenuModifierGroupPatchEntity }
  >(
    ({ modifierGroupId, modifierGroup }) =>
      api
        .patch<GetAccountMenuModifierGroupByIdResponse>(
          `/v1/accounts/${accountId}/locations/${locationId}/menus/${menuId}/modifier-groups/${modifierGroupId}`,
          modifierGroup,
          {
            headers: { Authorization: `Bearer ${token?.access_token}` },
          }
        )
        .then(({ data }) => data),
    {
      onSuccess: () => {
        void queryClient.refetchQueries([
          'accounts',
          accountId,
          'locations',
          locationId,
          'menu',
          menuId,
          'modifier-groups',
        ])
        toast.success('Modifier Group Updated')
      },
      onError: (error: any) => {
        if (error?.response && error.response.data) {
          toast.error(JSON.stringify(error.response.data.data))
        } else {
          toast.error(JSON.stringify(error.message))
        }
      },
    }
  )
}
