import {
  UseQueryOptions,
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
} from 'react-query'
import { api } from '../services/api'
import { useAuthStore } from './useAuthStore'
import { useAccessToken } from './useAccessToken'
import { signalToCancellationToken } from './utils'
import {
  ErrorResponse,
  GetAccountMenuModifierByIdResponse,
  ListAccountMenuModifierResponse,
  MenuModifierCreateEntity,
  MenuModifierPatchEntity,
} from '../types'
import { toast } from 'react-toastify'

export interface UseMenuModifierByIdProps {
  refetchOnMount?: boolean | 'always'
}

export interface UseMenuModifierByIdProps<
  TData = GetAccountMenuModifierByIdResponse,
  TError = ErrorResponse
> extends UseQueryOptions<TData, TError> {}

export const useMenuModifiers = (
  locationId: string,
  menuId: string,
  options?: any
) => {
  const accountId = useAuthStore((t) => t.accountId)
  const token = useAccessToken()

  return useInfiniteQuery<ListAccountMenuModifierResponse>(
    [
      'accounts',
      accountId,
      'locations',
      locationId,
      'menu',
      menuId,
      'modifiers',
    ],
    ({ pageParam }) =>
      api
        .get(
          `/v1/accounts/${accountId}/locations/${locationId}/menus/${menuId}/modifiers`,
          {
            params: {
              limit: 100,
              nextPageKey: pageParam,
            },
            headers: { Authorization: `Bearer ${token?.access_token}` },
            cancelToken: signalToCancellationToken()?.token,
          }
        )
        .then(({ data }) => data),
    {
      getNextPageParam: (lastPage: any) => lastPage.nextPageKey,
      keepPreviousData: true,
      enabled: !!token?.access_token && !!accountId && !!locationId && !!menuId,
      ...options,
    }
  )
}

export const useMenuModifierCreate = (locationId: any, menuId: any) => {
  const queryClient = useQueryClient()
  const [accountId] = useAuthStore((t) => [t.accountId])
  const token = useAccessToken()

  return useMutation<
    GetAccountMenuModifierByIdResponse,
    ErrorResponse,
    MenuModifierCreateEntity
  >(
    (modifier) =>
      api
        .post<GetAccountMenuModifierByIdResponse>(
          `/v1/accounts/${accountId}/locations/${locationId}/menus/${menuId}/modifiers`,
          modifier,
          {
            headers: { Authorization: `Bearer ${token?.access_token}` },
          }
        )
        .then(({ data }) => data),
    {
      onSuccess: (data: any) => {
        void queryClient.refetchQueries([
          'accounts',
          accountId,
          'locations',
          locationId,
          'menu',
          menuId,
          'modifiers',
        ])
        return data.data
      },
      onError: (error: any) => {
        if (error.response && error.response.data) {
          toast.error(JSON.stringify(error.response.data.data))
        } else {
          toast.error(JSON.stringify(error.message))
        }
      },
    }
  )
}

export const useMenuModifierUpdate = (
  locationId: string,
  menuId: string,
  modifierId: string
) => {
  const queryClient = useQueryClient()
  const [accountId] = useAuthStore((t) => [t.accountId])
  const token = useAccessToken()

  return useMutation<
    GetAccountMenuModifierByIdResponse,
    ErrorResponse,
    MenuModifierPatchEntity
  >(
    (modifier) =>
      api
        .patch<GetAccountMenuModifierByIdResponse>(
          `/v1/accounts/${accountId}/locations/${locationId}/menus/${menuId}/modifiers/${modifierId}`,
          modifier,
          {
            headers: { Authorization: `Bearer ${token?.access_token}` },
          }
        )
        .then(({ data }) => data),
    {
      onSuccess: () => {
        void queryClient.refetchQueries([
          'accounts',
          accountId,
          'locations',
          locationId,
          'menu',
          menuId,
          'modifiers',
        ])
        void queryClient.refetchQueries([
          'accounts',
          accountId,
          'locations',
          locationId,
          'menus',
          menuId,
          'modifiers',
          modifierId,
        ])
        toast.success('Modifier Updated')
      },
      onError: (error: any) => {
        if (error?.response && error.response.data) {
          toast.error(JSON.stringify(error.response.data.data))
        } else {
          toast.error(JSON.stringify(error.message))
        }
      },
    }
  )
}

export const useMenuModifierById = (
  locationId?: string,
  menuId?: string,
  modifierId?: string,
  props?: UseMenuModifierByIdProps
) => {
  const [accountId] = useAuthStore((t) => [t.accountId])
  const token = useAccessToken()
  return useQuery<GetAccountMenuModifierByIdResponse, ErrorResponse>(
    [
      'accounts',
      accountId,
      'locations',
      locationId,
      'menus',
      menuId,
      'modifiers',
      modifierId,
    ],
    ({ signal }) =>
      api
        .get(
          `/v1/accounts/${accountId}/locations/${locationId}/menus/${menuId}/modifiers/${modifierId}`,
          {
            headers: { Authorization: `Bearer ${token?.access_token}` },
            cancelToken: signalToCancellationToken(signal)?.token,
          }
        )
        .then(({ data }) => data),
    {
      staleTime: 3600 * 60,
      enabled: !!token?.access_token && !!accountId && !!menuId && !!modifierId,
      ...props,
    }
  )
}

export const useMenuModifierDelete = (locationId: string, menuId: string) => {
  const queryClient = useQueryClient()
  const [accountId] = useAuthStore((t) => [t.accountId])
  const token = useAccessToken()

  return useMutation(
    (modifierId: any) =>
      api
        .delete(
          `/v1/accounts/${accountId}/locations/${locationId}/menus/${menuId}/modifiers/${modifierId}`,
          {
            headers: { Authorization: `Bearer ${token?.access_token}` },
          }
        )
        .then(({ data }) => data),
    {
      onSuccess: () => {
        // void queryClient.refetchQueries([
        //   'accounts',
        //   accountId,
        //   'locations',
        //   locationId,
        //   'menu',
        //   menuId,
        //   'modifiers',
        // ])
        toast.success('Modifier Deleted')
      },
      onError: (error: any) => {
        if (error.response && error.response.data) {
          toast.error(JSON.stringify(error.response.data.data))
        } else {
          toast.error(JSON.stringify(error.message))
        }
      },
    }
  )
}

// create a new hook called useMenuModifierForItemEdit that will be used in the MenuItemModifierGroupEdit component to update the modifier from modifierGroup
export const useMenuModifierUpdateForItemEdit = (
  locationId: string,
  menuId: string
) => {
  const queryClient = useQueryClient()
  const [accountId] = useAuthStore((t) => [t.accountId])
  const token = useAccessToken()

  return useMutation<
    GetAccountMenuModifierByIdResponse,
    ErrorResponse,
    { modifierId: string; modifier: MenuModifierPatchEntity }
  >(
    ({ modifierId, modifier }) =>
      api
        .patch<GetAccountMenuModifierByIdResponse>(
          `/v1/accounts/${accountId}/locations/${locationId}/menus/${menuId}/modifiers/${modifierId}`,
          modifier,
          {
            headers: { Authorization: `Bearer ${token?.access_token}` },
          }
        )
        .then(({ data }) => data),
    {
      onSuccess: () => {
        void queryClient.refetchQueries([
          'accounts',
          accountId,
          'locations',
          locationId,
          'menu',
          menuId,
          'modifiers',
        ])
        toast.success('Modifier Updated')
      },
      onError: (error: any) => {
        if (error?.response && error.response.data) {
          toast.error(JSON.stringify(error.response.data.data))
        } else {
          toast.error(JSON.stringify(error.message))
        }
      },
    }
  )
}
